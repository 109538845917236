/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import { TextField } from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useForm } from "react-hook-form";
import { motherTongueList } from "../../jsonFiles/motherTongueList";
import { communities } from "../../jsonFiles/communities";
import { stateCityList } from "../../jsonFiles/stateCityList";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

export const religionList = [
  "Hindu",
  "Muslim",
  "Christian",
  "Sikh",
  "Jain",
  "Buddhist",
  "Jewish",
  "Parsi",
  "Other",
];

export const heightList = [
  `4' 0" (1.22 mts)`,
  `4' 1" (1.24 mts)`,
  `4' 2" (1.28 mts)`,
  `4' 3" (1.31 mts)`,
  `4' 4" (1.34 mts)`,
  `4' 5" (1.35 mts)`,
  `4' 6" (1.37 mts)`,
  `4' 7" (1.40 mts)`,
  `4' 8" (1.42 mts)`,
  `4' 9" (1.45 mts)`,
  `4' 10" (1.47 mts)`,
  `4' 11" (1.50 mts)`,
  `5' 0" (1.52 mts)`,
  `5' 1" (1.55 mts)`,
  `5' 2" (1.58 mts)`,
  `5' 3" (1.60 mts)`,
  `5' 4" (1.63 mts)`,
  `5' 5" (1.65 mts)`,
  `5' 6" (1.68 mts)`,
  `5' 7" (1.70 mts)`,
  `5' 8" (1.73 mts)`,
  `5' 9" (1.75 mts)`,
  `5' 10" (1.78 mts)`,
  `5' 11" (1.80 mts)`,
  `6' 0" (1.83 mts)`,
  `6' 1" (1.85 mts)`,
  `6' 2" (1.88 mts)`,
  `6' 3" (1.91 mts)`,
  `6' 4" (1.93 mts)`,
  `6' 5" (1.96 mts)`,
  `6' 6" (1.98 mts)`,
  `6' 7" (2.01 mts)`,
  `6' 8" (2.03 mts)`,
  `6' 9" (2.06 mts)`,
  `6' 10" (2.08 mts)`,
  `6' 11" (2.11 mts)`,
  `7' (2.13 mts)`,
];

function Basic() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const navigate = useNavigate();
  const storageRegisterData = JSON.parse(localStorage.getItem("registerData"));
  const storageBasicDetails = JSON.parse(
    localStorage.getItem("basicDetailsData")
  );
  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm();
  const [selectedState, setSelectedState] = useState(
    storageBasicDetails?.state || ""
  );

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleSubmitData = (data) => {
    const day = parseInt(data?.day.trim());
    const month = parseInt(data?.month.trim()) - 1; // JavaScript months are 0-based
    const year = parseInt(data?.year.trim());

    const dobDate = new Date(year, month, day);

    // Validate DOB
    if (
      dobDate.getFullYear() !== year ||
      dobDate.getMonth() !== month ||
      dobDate.getDate() !== day
    ) {
      toast.error("Invalid date of birth");
      return;
    }

    const dobMilliseconds = dobDate.getTime();

    const currentDate = new Date();
    const minAgeDate = new Date(
      currentDate.getFullYear() - 18,
      currentDate.getMonth(),
      currentDate.getDate()
    );
    const maxAgeDate = new Date(
      currentDate.getFullYear() - 60,
      currentDate.getMonth(),
      currentDate.getDate()
    );

    if (dobMilliseconds > minAgeDate.getTime()) {
      toast.error("Age must be at least 18 years");
      return;
    }

    if (dobMilliseconds < maxAgeDate.getTime()) {
      toast.error("Age must be less than 60 years");
      return;
    }

    const formData = {
      dob: dobMilliseconds.toString(),
      motherTongue: data?.motherTongue,
      religion: data?.religion,
      community: data?.community,
      maritalStatus: data?.maritalStatus,
      height: data?.height,
      state: selectedState,
      city: data?.city,
      pinCode: data?.pinCode,
      // For app functionality
      day: data?.day,
      month: data?.month,
      year: data?.year,
    };

    localStorage.setItem("basicDetailsData", JSON.stringify(formData));
    navigate("/careerfamily");
  };

  let uniqueCommunitiesList =
    getValues("religion") === "Hindu"
      ? communities
          ?.filter((e) => e?.religion === "Hindu")
          ?.map((e) => e?.community)
      : getValues("religion") === "Muslim"
      ? communities
          ?.filter((e) => e?.religion === "Muslim")
          ?.map((e) => e?.community)
      : communities?.map((e) => e?.community);

  return (
    <>
      <section className="register-page-bg pb-1">
        <div className="sticky-top bg-white">
          <div className="container py-2">
            <div className="px-lg-0 px-2 d-flex justify-content-between align-items-center z-2 position-relative">
              <img src="/assets/images/logo-red.png" height="35" />
              <div className="d-flex float-end align-items-center">
                <img
                  src="/assets/images/help.png"
                  height="25"
                  className="mx-4"
                />
                <Link
                  to="/login"
                  className="px-4 py-2 rounded-3 bg-white btn-shadow cursor-pointer"
                >
                  <p className="mx-2 home-login-btn fw-medium text-blue">
                    Login
                  </p>
                </Link>
              </div>
            </div>
          </div>
        </div>

        <div className="d-flex mt-1 justify-content-center mb-4">
          <div className="col-lg-6 col-md-9 col-11">
            <form
              className="p-lg-5 mx-lg-5 p-4 mt-4 bg-white rounded-3"
              onSubmit={handleSubmit(handleSubmitData)}
            >
              <div className="p-2">
                <div className="mb-4">
                  <h5 className="fs-6 fw-medium text-secondary">BASIC</h5>
                </div>
                <div className="row g-3">
                  <div className="col-3">
                    <TextField
                      id="outlined-basic"
                      label="Day"
                      placeholder="DD"
                      variant="outlined"
                      className="w-100 mb-3"
                      defaultValue={storageBasicDetails?.day}
                      {...register("day", { required: true })}
                      error={errors?.day}
                    />
                  </div>
                  <div className="col-5">
                    <TextField
                      id="outlined-basic"
                      label="Month"
                      placeholder="MM"
                      variant="outlined"
                      className="w-100 mb-3"
                      defaultValue={storageBasicDetails?.month}
                      {...register("month", { required: true })}
                      error={errors?.month}
                    />
                  </div>
                  <div className="col-4">
                    <TextField
                      id="outlined-basic"
                      label="Year"
                      placeholder="YYYY"
                      variant="outlined"
                      className="w-100 mb-3"
                      defaultValue={storageBasicDetails?.year}
                      {...register("year", { required: true })}
                      error={errors?.year}
                    />
                  </div>
                </div>
                <FormControl className="w-100 mb-3" fullWidth>
                  <InputLabel id="demo-select-small-label">Height</InputLabel>
                  <Select
                    labelId="demo-select-small-label"
                    id="demo-select-small"
                    label="Height"
                    defaultValue={storageBasicDetails?.height}
                    {...register("height", { required: true })}
                    error={errors?.height}
                  >
                    {heightList?.map((item, index) => (
                      <MenuItem key={index} value={item}>
                        {item}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <FormControl className="w-100 mb-3" fullWidth>
                  <InputLabel id="demo-select-small-label">
                    Marital Status
                  </InputLabel>
                  <Select
                    labelId="demo-select-small-label"
                    id="demo-select-small"
                    label="Marital Status"
                    defaultValue={storageBasicDetails?.maritalStatus}
                    {...register("maritalStatus", { required: true })}
                    error={errors?.maritalStatus}
                  >
                    {[
                      "Never Married",
                      "Awaiting Divorce",
                      "Divorced",
                      "Widowed",
                      "Annulled",
                    ]?.map((item, index) => (
                      <MenuItem key={index} value={item}>
                        {item}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <div className="my-4">
                  <h5 className="fs-6 fw-medium text-secondary">SOCIAL</h5>
                </div>
                <FormControl className="w-100 mb-3" fullWidth>
                  <InputLabel id="demo-select-small-label">
                    Mother Tongue
                  </InputLabel>
                  <Select
                    labelId="demo-select-small-label"
                    id="demo-select-small"
                    label="Mother Tongue"
                    defaultValue={storageBasicDetails?.motherTongue}
                    {...register("motherTongue", { required: true })}
                    error={errors?.motherTongue}
                  >
                    {motherTongueList?.map((item, index) => (
                      <MenuItem key={index} value={item?.Name}>
                        {item?.Name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <FormControl className="w-100 mb-3" fullWidth>
                  <InputLabel id="demo-select-small-label">Religion</InputLabel>
                  <Select
                    labelId="demo-select-small-label"
                    id="demo-select-small"
                    label="Religion"
                    defaultValue={storageBasicDetails?.religion}
                    {...register("religion", { required: true })}
                    error={errors?.religion}
                  >
                    {religionList?.map((item, index) => (
                      <MenuItem key={index} value={item}>
                        {item}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <FormControl className="w-100 mb-3" fullWidth>
                  <InputLabel id="demo-select-small-label">
                    Community
                  </InputLabel>
                  <Select
                    labelId="demo-select-small-label"
                    id="demo-select-small"
                    label="Community"
                    defaultValue={storageBasicDetails?.community}
                    {...register("community", { required: true })}
                    error={errors?.community}
                  >
                    {uniqueCommunitiesList?.map((item, index) => (
                      <MenuItem value={item} key={index}>
                        {item}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <FormControl className="w-100 mb-3" fullWidth>
                  <InputLabel id="demo-select-small-label">State</InputLabel>
                  <Select
                    labelId="demo-select-small-label"
                    id="demo-select-small"
                    label="State"
                    value={selectedState}
                    defaultValue={storageBasicDetails?.State}
                    onChange={(e) => setSelectedState(e.target.value)}
                    error={errors?.state}
                  >
                    {[...new Set(stateCityList?.map((e) => e?.State))]?.map(
                      (item, index) => (
                        <MenuItem key={index} value={item}>
                          {item}
                        </MenuItem>
                      )
                    )}
                  </Select>
                </FormControl>
                <FormControl className="w-100 mb-3" fullWidth>
                  <InputLabel id="demo-select-small-label">City</InputLabel>
                  <Select
                    labelId="demo-select-small-label"
                    id="demo-select-small"
                    label="City"
                    defaultValue={storageBasicDetails?.city}
                    {...register("city", { required: true })}
                    error={errors?.city}
                  >
                    {stateCityList
                      ?.filter((e) => e?.State === selectedState)
                      ?.map((item, index) => (
                        <MenuItem key={index} value={item?.City}>
                          {item?.City}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
                <TextField
                  id="outlined-basic"
                  label="Pincode"
                  variant="outlined"
                  className="w-100 mb-3"
                  defaultValue={storageBasicDetails?.pinCode}
                  {...register("pinCode", {
                    required: true,
                    minLength: {
                      value: 6,
                    },
                    maxLength: {
                      value: 6,
                    },
                  })}
                  error={errors?.pinCode}
                />

                <div className="pt-3">
                  <button type="submit" className="bg-crimson px-4 py-2">
                    <p className="my-1 fw-medium text-capitalize text-white fs-6 mx-5">
                      Continue
                    </p>
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </section>
    </>
  );
}

export default Basic;
