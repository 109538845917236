/* eslint-disable jsx-a11y/alt-text */
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  getConnectionsRequestsToMe,
  getMyAcceptedConnections,
  getMyConnectionAction,
} from "../../redux/actions/connect";
import { myShortlistedUsersActions } from "../../redux/actions/shortlist";
import { toast } from "react-toastify";
import { defaultUserImage, server } from "../../server";
import { calculateAge } from "../../utills/calculateAge";
import axios from "axios";

function Activity() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const navigate = useNavigate();
  const { user } = useSelector((state) => state?.profile);
  const dispatch = useDispatch();
  const { genderProfiles } = useSelector((state) => state?.match);
  const { myShortlists, loading: shortListLoading } = useSelector(
    (state) => state?.shortlist
  );
  const {
    myConnections,
    connectionsToMe,
    acceptedConnections,
    loading: connectLoading,
  } = useSelector((state) => state?.connect);
  const [matches, setMatches] = useState([]);
  const [activeActivity, setActiveActivity] = useState("Inbox");
  const [visitedProfiles, setVisitedProfiles] = useState();
  const [requestedPhotoProfiles, setRequestedPhotoProfiles] = useState();

  useEffect(() => {
    const updateState = () => {
      if (activeActivity === "Shortlisted") {
        return setMatches(myShortlists || []);
      }
      if (activeActivity === "Received") {
        return setMatches(connectionsToMe);
      }
      if (activeActivity === "Accepted") {
        return setMatches(acceptedConnections);
      }
      if (activeActivity === "Sent") {
        return setMatches(myConnections || []);
      }
    };

    return updateState();
  }, [myShortlists, myConnections, connectionsToMe, activeActivity]);

  useEffect(() => {
    // switch (activeActivity) {
    //   case "Received":
    dispatch(getConnectionsRequestsToMe());
    // break;

    // case "Accepted":
    dispatch(getMyAcceptedConnections());
    // break;

    // case "Sent":
    dispatch(getMyConnectionAction("sent"));
    setMatches(myConnections || []);
    // break;

    // case "Shortlisted":
    dispatch(myShortlistedUsersActions());
    // break;

    // default:
    // console.log("Case didn't match");
    // }
  }, [activeActivity]);

  // Profile Visited
  useEffect(() => {
    const getVisitedProfiles = async () => {
      const token = localStorage.getItem("token");

      try {
        const { data } = await axios.get(
          `${server}/profileVisited/visitedProfileByOthers/${user?._id}/1/1`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        setVisitedProfiles(data);
      } catch (e) {
        toast(e?.response?.data?.message || e?.message);
      }
    };

    getVisitedProfiles();
  }, []);

  // Request Photos
  useEffect(() => {
    const getVisitedProfiles = async () => {
      const token = localStorage.getItem("token");

      try {
        const { data } = await axios.get(
          `${server}/requestPhoto/mySentRequests/1/1`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setRequestedPhotoProfiles(data);
      } catch (e) {
        toast(e?.response?.data?.message || e?.message);
      }
    };

    getVisitedProfiles();
  }, []);

  return (
    <>
      <div className="block-margin-top mb-4">
        <div className="py-4 bg-white rounded-4 block-border">
          <div className="px-4">
            <div className="row g-2">
              <div
                className="col-lg-3 col-md-4 col-6"
                onClick={() => setActiveActivity("Accepted")}
              >
                <div className="p-4 rounded-4 dashboard-bg">
                  <i className="bi bi-envelope-check fs-4 text-secondary"></i>
                  <h4 className="fw-bold">
                    0{acceptedConnections?.length || 0}
                  </h4>
                  <p className="fs-7 text-secondary lh-1">
                    Accepted
                    <br />
                    Interests
                  </p>
                </div>
              </div>
              <div
                className="col-lg-3 col-md-4 col-6"
                onClick={() => setActiveActivity("Sent")}
              >
                <div className="p-4 rounded-4 dashboard-bg">
                  <i className="bi bi-envelope-arrow-up fs-4 text-secondary"></i>
                  <h4 className="fw-bold">0{myConnections?.length || 0}</h4>
                  <p className="fs-7 text-secondary lh-1">
                    Interests
                    <br />
                    Sent
                  </p>
                </div>
              </div>
              <div
                className="col-lg-3 col-md-4 col-6"
                onClick={() => setActiveActivity("Received")}
              >
                <div className="p-4 rounded-4 dashboard-bg">
                  <i className="bi bi-envelope-arrow-down fs-4 text-secondary"></i>
                  <h4 className="fw-bold">0{connectionsToMe?.length || 0}</h4>
                  <p className="fs-7 text-secondary lh-1">
                    Interests
                    <br />
                    Received
                  </p>
                </div>
              </div>
              <div
                className="col-lg-3 col-md-4 col-6"
                onClick={() => setActiveActivity("Shortlisted")}
              >
                <div className="p-4 rounded-4 dashboard-bg">
                  <i className="bi bi-envelope-heart fs-4 text-secondary"></i>
                  <h4 className="fw-bold">0{myShortlists?.length || 0}</h4>
                  <p className="fs-7 text-secondary lh-1">
                    Profile
                    <br />
                    Shortlist
                  </p>
                </div>
              </div>
            </div>
          </div>

          {/* Accepted Interest */}
          {acceptedConnections?.length > 0 && (
            <div className="my-5">
              <div className="mb-3 px-4">
                <h5 className="fs-6 mb-0 fw-medium">
                  Interest Accepted ({acceptedConnections?.length || 0})
                </h5>
                <p className="fs-8 text-secondary">
                  Interest Accepted profiles!
                </p>
              </div>
              <div>
                <div className="d-flex flex-row flex-nowrap overflow-x-scroll">
                  <div className="ps-4"></div>
                  {acceptedConnections?.map((item, index) => (
                    <div className="col-lg-6 col-10 pe-2" key={index}>
                      <div
                        onClick={() =>
                          navigate("./", {
                            state: {
                              Information: "Details",
                              profileData: item,
                            },
                          })
                        }
                        type="button"
                        className="position-relative d-flex justify-content-center align-items-end rounded-4 overflow-hidden"
                      >
                        <img
                          src={
                            item?.profile?.[0]?.images?.[0]?.docUrl ||
                            defaultUserImage
                          }
                          className="w-100 object-fit-cover"
                          height="380"
                        />
                        <div className="w-100 h-75 activity-profile-block position-absolute d-flex align-items-end">
                          <div className="p-4">
                            <div>
                              <p className="m-0 fs-9 text-white">{`GRSTY${item?.user?.[0]?.customId}`}</p>
                              <h6 className="m-0 mb-2 text-white">
                                {item?.user?.[0]?.name},{" "}
                                {calculateAge(item?.profile?.[0]?.dob)}
                              </h6>
                              <p className="m-0 fs-9 text-white">
                                {item?.profile?.[0]?.height}
                                <span class="mx-2 text-white">•</span>
                                {item?.profile?.[0]?.city}
                                <span class="mx-2">•</span>
                                {item?.profile?.[0]?.community}
                              </p>
                              <p className="m-0 fs-9 text-white">
                                {item?.profile?.[0]?.jobTitle || "Not Working"}
                              </p>
                              <p className="m-0 fs-9 text-white">
                                {item?.profile?.[0]?.annualIncome ||
                                  "No Income"}
                              </p>
                            </div>
                            <div className="d-flex align-items-center profile-details-line">
                              <div className="d-flex align-items-center">
                                <p className="m-0 fs-9 text-white">
                                  {item?.profile?.[0]?.highestDegree}
                                </p>
                              </div>
                              <span class="mx-2 text-white">•</span>
                              <div className="d-flex align-items-center">
                                <p className="m-0 fs-9 text-white">
                                  {item?.profile?.[0]?.maritalStatus}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                  <div className="pe-3"></div>
                  {(!acceptedConnections ||
                    acceptedConnections.length === 0) && <ProfileNotFound />}
                </div>
              </div>
            </div>
          )}

          {/* Sent Interest */}
          {myConnections?.length > 0 && (
            <div className="my-5">
              <div className="mb-3 px-4">
                <h5 className="fs-6 mb-0 fw-medium">
                  Interest Sent ({myConnections?.length || 0})
                </h5>
                <p className="fs-8 text-secondary">
                  You have sent interest to these profiles!
                </p>
              </div>
              <div>
                <div className="d-flex flex-row flex-nowrap overflow-x-scroll">
                  <div className="ps-4"></div>
                  {myConnections?.map((item, index) => (
                    <div className="col-lg-6 col-10 pe-2" key={index}>
                      <div
                        onClick={() =>
                          navigate("./", {
                            state: {
                              Information: "Details",
                              profileData: item,
                            },
                          })
                        }
                        type="button"
                        className="position-relative d-flex justify-content-center align-items-end rounded-4 overflow-hidden"
                      >
                        <img
                          src={
                            item?.profile?.[0]?.images?.[0]?.docUrl ||
                            defaultUserImage
                          }
                          className="w-100 object-fit-cover"
                          height="380"
                        />
                        <div className="w-100 h-75 activity-profile-block position-absolute d-flex align-items-end">
                          <div className="p-4">
                            <div>
                              <p className="m-0 fs-9 text-white">{`GRSTY${item?.user?.[0]?.customId}`}</p>
                              <h6 className="m-0 mb-2 text-white">
                                {item?.user?.[0]?.name},{" "}
                                {calculateAge(item?.profile?.[0]?.dob)}
                              </h6>
                              <p className="m-0 fs-9 text-white">
                                {item?.profile?.[0]?.height}
                                <span class="mx-2 text-white">•</span>
                                {item?.profile?.[0]?.city}
                                <span class="mx-2">•</span>
                                {item?.profile?.[0]?.community}
                              </p>
                              <p className="m-0 fs-9 text-white">
                                {item?.profile?.[0]?.jobTitle || "Not Working"}
                              </p>
                              <p className="m-0 fs-9 text-white">
                                {item?.profile?.[0]?.annualIncome ||
                                  "No Income"}
                              </p>
                            </div>
                            <div className="d-flex align-items-center profile-details-line">
                              <div className="d-flex align-items-center">
                                <p className="m-0 fs-9 text-white">
                                  {item?.profile?.[0]?.highestDegree}
                                </p>
                              </div>
                              <span class="mx-2 text-white">•</span>
                              <div className="d-flex align-items-center">
                                <p className="m-0 fs-9 text-white">
                                  {item?.profile?.[0]?.maritalStatus}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                  <div className="pe-3"></div>

                  {(!myConnections || myConnections.length === 0) && (
                    <ProfileNotFound />
                  )}
                </div>
              </div>
            </div>
          )}

          {/* Received Interest */}
          {connectionsToMe?.length > 0 && (
            <div className="my-5">
              <div className="mb-3 px-4">
                <h5 className="fs-6 mb-0 fw-medium">
                  Interest Received ({connectionsToMe?.length || 0})
                </h5>
                <p className="fs-8 text-secondary">
                  You have received interest from these profiles!
                </p>
              </div>
              <div>
                <div className="d-flex flex-row flex-nowrap overflow-x-scroll">
                  <div className="ps-4"></div>
                  {myConnections?.map((item, index) => (
                    <div className="col-lg-6 col-10 pe-2" key={index}>
                      <div
                        onClick={() =>
                          navigate("./", {
                            state: {
                              Information: "Details",
                              profileData: item,
                            },
                          })
                        }
                        type="button"
                        className="position-relative d-flex justify-content-center align-items-end rounded-4 overflow-hidden"
                      >
                        <img
                          src={
                            item?.profile?.[0]?.images?.[0]?.docUrl ||
                            defaultUserImage
                          }
                          className="w-100 object-fit-cover"
                          height="380"
                        />
                        <div className="w-100 h-75 activity-profile-block position-absolute d-flex align-items-end">
                          <div className="p-4">
                            <div>
                              <p className="m-0 fs-9 text-white">{`GRSTY${item?.user?.[0]?.customId}`}</p>
                              <h6 className="m-0 mb-2 text-white">
                                {item?.user?.[0]?.name},{" "}
                                {calculateAge(item?.profile?.[0]?.dob)}
                              </h6>
                              <p className="m-0 fs-9 text-white">
                                {item?.profile?.[0]?.height}
                                <span class="mx-2 text-white">•</span>
                                {item?.profile?.[0]?.city}
                                <span class="mx-2">•</span>
                                {item?.profile?.[0]?.community}
                              </p>
                              <p className="m-0 fs-9 text-white">
                                {item?.profile?.[0]?.jobTitle || "Not Working"}
                              </p>
                              <p className="m-0 fs-9 text-white">
                                {item?.profile?.[0]?.annualIncome ||
                                  "No Income"}
                              </p>
                            </div>
                            <div className="d-flex align-items-center profile-details-line">
                              <div className="d-flex align-items-center">
                                <p className="m-0 fs-9 text-white">
                                  {item?.profile?.[0]?.highestDegree}
                                </p>
                              </div>
                              <span class="mx-2 text-white">•</span>
                              <div className="d-flex align-items-center">
                                <p className="m-0 fs-9 text-white">
                                  {item?.profile?.[0]?.maritalStatus}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                  <div className="pe-3"></div>
                  {(!myConnections || myConnections.length === 0) && (
                    <ProfileNotFound />
                  )}
                </div>
              </div>
            </div>
          )}

          {/* Shortlisted Profiles */}
          {myShortlists?.length > 0 && (
            <div className="my-5">
              <div className="mb-3 px-4">
                <h5 className="fs-6 mb-0 fw-medium">
                  Profiles Shortlisted ({myShortlists?.length || 0})
                </h5>
                <p className="fs-8 text-secondary">
                  You have Shortlisted these profiles!
                </p>
              </div>
              <div>
                <div className="d-flex flex-row flex-nowrap overflow-x-scroll">
                  <div className="ps-4"></div>
                  {myShortlists?.map((item, index) => (
                    <div className="col-lg-6 col-10 pe-2" key={index}>
                      <div
                        onClick={() =>
                          navigate("./", {
                            state: {
                              Information: "Details",
                              profileData: item,
                            },
                          })
                        }
                        type="button"
                        className="position-relative d-flex justify-content-center align-items-end rounded-4 overflow-hidden"
                      >
                        <img
                          src={
                            item?.profile?.[0]?.images?.[0]?.docUrl ||
                            defaultUserImage
                          }
                          className="w-100 object-fit-cover"
                          height="380"
                        />
                        <div className="w-100 h-75 activity-profile-block position-absolute d-flex align-items-end">
                          <div className="p-4">
                            <div>
                              <p className="m-0 fs-9 text-white">{`GRSTY${item?.user?.[0]?.customId}`}</p>
                              <h6 className="m-0 mb-2 text-white">
                                {item?.user?.[0]?.name},{" "}
                                {calculateAge(item?.profile?.[0]?.dob)}
                              </h6>
                              <p className="m-0 fs-9 text-white">
                                {item?.profile?.[0]?.height}
                                <span class="mx-2 text-white">•</span>
                                {item?.profile?.[0]?.city}
                                <span class="mx-2">•</span>
                                {item?.profile?.[0]?.community}
                              </p>
                              <p className="m-0 fs-9 text-white">
                                {item?.profile?.[0]?.jobTitle || "Not Working"}
                              </p>
                              <p className="m-0 fs-9 text-white">
                                {item?.profile?.[0]?.annualIncome ||
                                  "No Income"}
                              </p>
                            </div>
                            <div className="d-flex align-items-center profile-details-line">
                              <div className="d-flex align-items-center">
                                <p className="m-0 fs-9 text-white">
                                  {item?.profile?.[0]?.highestDegree}
                                </p>
                              </div>
                              <span class="mx-2 text-white">•</span>
                              <div className="d-flex align-items-center">
                                <p className="m-0 fs-9 text-white">
                                  {item?.profile?.[0]?.maritalStatus}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                  <div className="pe-3"></div>
                  {(!myShortlists || myShortlists.length === 0) && (
                    <ProfileNotFound />
                  )}
                </div>
              </div>
            </div>
          )}

          <div className="px-4">
            <div className="bg-light-blue rounded-4 p-4 mt-4">
              <div className="mb-3">
                <h5 className="fs-6 mb-0 fw-medium">Something for you.</h5>
                <p className="fs-8 text-secondary">
                  We've curated some insights that you might like
                </p>
              </div>
              <div className="row g-2 mt-3">
                <div className="col-lg-4 col-md-5 col-6">
                  <div className="p-4 rounded-4 bg-white d-flex align-items-center flex-column">
                    <div className="mb-3 d-flex">
                      <img
                        src={defaultUserImage}
                        width="50"
                        className="rounded-pill group-profiles"
                      />
                      <img
                        src={defaultUserImage}
                        width="50"
                        className="rounded-pill group-profiles"
                      />
                      <img
                        src={defaultUserImage}
                        width="50"
                        className="rounded-pill group-profiles"
                      />
                    </div>
                    <h4 className="fw-bold">{visitedProfiles?.count || 0}</h4>
                    <p className="fs-7 text-secondary lh-1 text-center">
                      Profile
                      <br />
                      Visitors
                    </p>
                  </div>
                </div>
                <div className="col-lg-4 col-md-5 col-6">
                  <div className="p-4 rounded-4 bg-white d-flex align-items-center flex-column">
                    <div className="mb-3 d-flex">
                      <img
                        src={defaultUserImage}
                        width="50"
                        className="rounded-pill group-profiles"
                      />
                      <img
                        src={defaultUserImage}
                        width="50"
                        className="rounded-pill group-profiles"
                      />
                    </div>
                    <h4 className="fw-bold">
                      {requestedPhotoProfiles?.count || 0}
                    </h4>
                    <p className="fs-7 text-secondary lh-1 text-center">
                      Requested
                      <br />
                      Photo
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Activity;

const ProfileNotFound = () => {
  return <div>Opps! Profile not found!</div>;
};
