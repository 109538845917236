import React from "react";
import { useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";

export default function AppAuth() {
  const { user } = useSelector((state) => state?.profile);

  console.log(user);

  if (!user) {
    return <Navigate to="/" />;
  }

  return (
    <div>
      <div style={{ minHeight: "100vh" }}>
        <Outlet />
      </div>
    </div>
  );
}
