import {createReducer} from '@reduxjs/toolkit';

const initialState = {
  loading: false,
  error: null,
};

export const shortListReducer = createReducer(initialState, builder => {
  builder
    .addCase('SEND_SHORTLIST_REQUEST', (state, action) => {
      state.loading = true;
      state.error = null;
    })
    .addCase('SEND_SHORTLIST_SUCCESS', (state, action) => {
      state.loading = false;
      state.status = action.payload.status;
      state.error = null;
    })
    .addCase('SEND_SHORTLIST_FAIL', (state, action) => {
      state.loading = false;
      state.error = action.payload.error;
    });

  builder
    .addCase('CANCEL_SHORTLIST_REQUEST', (state, action) => {
      state.loading = true;
      state.error = null;
    })
    .addCase('CANCEL_SHORTLIST_SUCCESS', (state, action) => {
      state.loading = false;
      state.message = action.payload.message;
      state.error = null;
    })
    .addCase('CANCEL_SHORTLIST_FAIL', (state, action) => {
      state.loading = false;
      state.error = action.payload.error;
    });

  builder
    .addCase('CANCEL_SHORTLISTED_USER_REQUEST', (state, action) => {
      state.loading = true;
      state.error = null;
    })
    .addCase('CANCEL_SHORTLISTED_USER_SUCCESS', (state, action) => {
      state.loading = false;
      state.myShortlists = action.payload.myShortlists;
      state.error = null;
    })
    .addCase('CANCEL_SHORTLISTED_USER_FAIL', (state, action) => {
      state.loading = false;
      state.error = action.payload.error;
    });

  builder
    .addCase('GET_MY_SHORTLISTED_USERS_REQUEST', (state, action) => {
      state.loading = true;
      state.error = null;
    })
    .addCase('GET_MY_SHORTLISTED_USERS_SUCCESS', (state, action) => {
      state.loading = false;
      state.myShortlists = action.payload.myShortlists;
      state.error = null;
    })
    .addCase('GET_MY_SHORTLISTED_USERS_FAIL', (state, action) => {
      state.loading = false;
      state.error = action.payload.error;
    });
});
