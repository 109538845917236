import { createReducer } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  error: null,
  user: null,
  token: null,
};
export const registerReducer = createReducer(initialState, (builder) => {
  builder
    .addCase("USER_COMPLETE_REGISTRATION_REQUEST", (state, action) => {
      state.loading = true;
      state.error = null;
    })
    .addCase("USER_COMPLETE_REGISTRATION_SUCCESS", (state, action) => {
      state.loading = false;
      state.user = action.payload.user;
      state.token = action.payload.token;
      state.isAuthenticated = true;
      state.error = null;
    })
    .addCase("USER_COMPLETE_REGISTRATION_FAIL", (state, action) => {
      state.loading = false;
      state.user = null;
      state.error = action.payload.error;
    });

  builder
    .addCase("CHECK_USER_TOKEN_REQUEST", (state, action) => {
      state.loading = false;
      state.error = null;
    })
    .addCase("CHECK_USER_TOKEN_SUCCESS", (state, action) => {
      state.loading = false;
      state.isAuthenticated = true;
      state.error = null;
    })
    .addCase("CHECK_USER_TOKEN_FAIL", (state, action) => {
      state.loading = false;
      state.isAuthenticated = false;
      state.error = action.payload.error;
    });

  builder
    .addCase("SEND_OTP_REQUEST", (state, action) => {
      state.loading = true;
      state.error = null;
    })
    .addCase("SEND_OTP_SUCCESS", (state, action) => {
      state.loading = false;
      state.otp = action.payload.otp;
      state.error = null;
    })
    .addCase("SEND_OTP_FAIL", (state, action) => {
      state.loading = false;
      state.otp = "";
      state.error = action.payload.error;
    });

  builder
    .addCase("VERIFY_OTP_REQUEST", (state, action) => {
      state.loading = true;
      state.error = null;
      state.user = null;
    })
    .addCase("VERIFY_OTP_SUCCESS", (state, action) => {
      state.loading = false;
      state.user = action.payload.user;
      state.isAuthenticated = true;
      state.error = null;
    })
    .addCase("VERIFY_OTP_FAIL", (state, action) => {
      state.loading = false;
      state.user = null;
      state.error = action.payload.error;
    });

  builder
    .addCase("DELETE_PROFILE_REQUEST", (state, action) => {
      state.loading = true;
      state.error = null;
      state.user = null;
    })
    .addCase("DELETE_PROFILE_SUCCESS", (state, action) => {
      state.loading = false;
      state.user = null;
      state.isAuthenticated = false;
    })
    .addCase("DELETE_PROFILE_FAIL", (state, action) => {
      state.loading = false;
      state.user = null;
      state.error = action.payload.error;
    });
});
