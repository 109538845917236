import axios from "axios";
import { server } from "../../server";

export const getMyChatProfileList = (formData) => async (dispatch) => {
  dispatch({ type: "GET_MY_CHATS_LIST_REQUEST" });
  try {
    const { data } = await axios.get(`${server}/chat/myChatsList`);

    dispatch({
      type: "GET_MY_CHATS_LIST_SUCCESS",
      payload: {
        chatList: data?.data,
      },
    });
  } catch (e) {
    dispatch({
      type: "GET_MY_CHATS_LIST_FAIL",
      payload: { error: e?.response?.data?.message || e?.message },
    });
  }
};
