/* eslint-disable jsx-a11y/alt-text */
import axios from "axios";
import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import { server } from "../../server";

function Images() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const navigate = useNavigate();
  const [previewImages, setPreviewImages] = useState([]);
  const { handleSubmit } = useForm();
  const [images, setImages] = useState([]);
  const [loading, setLoading] = useState(false);
  const storageImages = localStorage.getItem("images");

  useEffect(() => {
    window.scrollTo(0, 0);
    if (storageImages) {
      setImages(JSON.parse(storageImages));
    }
  }, []);

  const onSubmit = async () => {
    if (storageImages) {
      return navigate("/verifyOtp");
    }

    setLoading(true);
    if (previewImages?.length === 0) return alert("Please choose images");

    const formData = new FormData();

    for (let i = 0; i < previewImages.length; i++) {
      formData.append("file", previewImages[i]?.file);
    }

    try {
      const { data } = await axios.patch(
        `${server}/user/uploadPhotoForRegistration`,
        formData
      );
      setLoading(false);
      localStorage.setItem("images", JSON.stringify(data?.data));
      setImages([]);
      setPreviewImages([]);
      console.log(data);
    } catch (e) {
      setLoading(false);
      console.log(e?.response?.data?.message || e?.message);
    }
  };

  const handleSkipImageUpload = async () => {
    const formData = new FormData();

    for (let i = 0; i < images.length; i++) {
      formData.append("file", []);
    }
    navigate("/verifyOtp");
  };

  const handleAddPhoto = (e) => {
    if (storageImages) {
      return;
    }

    const files = e.target.files;
    if (files.length > 10) return alert("You can choose maximum 10 images");
    let tempArr = [];

    const previewImagesArray = [];
    for (let i = 0; i < files.length; i++) {
      tempArr.push(files[i]);

      const file = files[i];
      const reader = new FileReader();

      if (previewImages?.length + tempArr?.length >= 11) {
        break;
      }

      reader.onload = (e) => {
        previewImagesArray.push({ file: files[i], base64: e.target.result });
        setPreviewImages([...previewImages, ...previewImagesArray]);
      };

      reader.readAsDataURL(file);
    }
    setImages(tempArr);
  };

  const handleDeletePhoto = (item) => {
    let tempArr = previewImages?.filter((e) => e !== item);
    setPreviewImages(tempArr);
  };

  return (
    <>
      <section className="register-page-bg min-vh-100 pb-1">
        <div className="sticky-top bg-white">
          <div className="container py-2">
            <div className="px-lg-0 px-2 d-flex justify-content-between align-items-center z-2 position-relative">
              <img src="/assets/images/logo-red.png" height="35" />
              <div className="d-flex float-end align-items-center">
                <img
                  src="/assets/images/help.png"
                  height="25"
                  className="mx-4"
                />
                <Link
                  to="/login"
                  className="px-4 py-2 rounded-3 bg-white btn-shadow cursor-pointer"
                >
                  <p className="mx-2 home-login-btn fw-medium text-blue">
                    Login
                  </p>
                </Link>
              </div>
            </div>
          </div>
        </div>

        <div className="d-flex mt-1 justify-content-center mb-4">
          <div className="col-lg-6 col-md-9 col-11">
            <form
              className="p-lg-5 mx-lg-5 p-4 mt-4 bg-white rounded-3"
              onSubmit={handleSubmit(onSubmit)}
            >
              <div className="p-2">
                <div className="d-flex justify-content-center">
                  <div className="px-5 mx-5 py-4">
                    <div className="d-flex justify-content-center">
                      <label for="fileInput" className="w-100">
                        <div className="bg-lightgrey py-4 rounded-4 d-flex justify-content-center">
                          <img
                            src="https://cdn-icons-png.flaticon.com/512/13434/13434886.png"
                            width="200"
                            onClick={() => handleAddPhoto}
                          />
                        </div>
                      </label>
                    </div>

                    <div className="px-lg-4 p-4 mx-lg-2 mx-0">
                      <div className="pb-3">
                        <div className="d-flex flex-row flex-wrap">
                          {/* Local images */}
                          {previewImages.map((item, index) => (
                            <div
                              className="d-flex justify-content-center"
                              key={index}
                            >
                              <img
                                key={index}
                                className="rounded-4 m-1 object-fit-cover"
                                src={item?.base64}
                                width="70"
                                height="70"
                                alt="Preview"
                              />
                              <div className="d-flex justify-content-end">
                                <div className="position-absolute">
                                  <div className="bg-dark delete-image-button rounded-pill">
                                    <button
                                      className="rounded-pill"
                                      onClick={() => handleDeletePhoto(item)}
                                      disabled={
                                        loading || (storageImages && true)
                                      }
                                    >
                                      <i className="bi bi-trash"></i>
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))}

                          {/* Storage Images */}
                          {storageImages &&
                            images.map((item, index) => (
                              <div
                                className="col-lg-3 col-md-5 col-6 position-relative d-flex justify-content-end"
                                key={index}
                              >
                                <img
                                  className="w-100 rounded-4 object-fit-cover"
                                  src={item?.docUrl}
                                  height="105"
                                  alt="Preview"
                                />
                              </div>
                            ))}
                        </div>
                        <input
                          id="fileInput"
                          type="file"
                          className="d-none"
                          multiple
                          onChange={handleAddPhoto}
                          accept="image/*"
                          max={10}
                          disabled={loading || storageImages}
                        />
                      </div>
                    </div>

                    <div className="mx-5 mb-3 pt-3 d-flex justify-content-center">
                      <button
                        type="submit"
                        className="rounded-3 bg-secondary px-5 py-2"
                        disabled={loading}
                      >
                        {!storageImages && (
                          <p className="my-1 fw-medium text-capitalize text-white fs-7 mx-5">
                            {loading ? "Please wait.." : "Upload"}
                            {storageImages && "Continue"}
                          </p>
                        )}
                        {storageImages && (
                          <p className="my-1 fw-medium text-capitalize text-white fs-7 mx-5">
                            Continue
                          </p>
                        )}
                      </button>
                    </div>

                    {(!storageImages || storageImages?.length === 0) && (
                      <div className="pt-1 text-center mx-lg-5 mx-2">
                        <div className="text-center fw-bold mb-2">
                          <button
                            className="border-0 bg-transparent m-0 text-secondary"
                            onClick={handleSkipImageUpload}
                            type="button"
                          >
                            I'll do this later
                            <i className="bi bi-arrow-right  ms-2"></i>
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </section>
    </>
  );
}

export default Images;
