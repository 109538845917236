import { useEffect } from "react";
import Header from "../../components/Header";
import Footer from "../../components/Footer";

function FraudAlert() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <section class="mb-5 position-relative overflow-hidden">
        <div class="container z-index-9 position-relative">
          <div class="row py-0 align-items-center text-center text-sm-start">
            <div class="col-sm-11 col-lg-8 col-xl-8 all-text-white">
              <div class="mt-5">
                <h1 class="display-5">Fraud Alert</h1>
                <p class="m-0">
                  Find Solutions to Common Queries: Navigate Through the
                  Frequently Asked Questions (FAQs) Section at GoRishtey.com. If
                  not find answer contact our support team.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="mb-5 pb-5 position-relative overflow-hidden">
        <div class="container z-index-9 position-relative">
          <h5 className="fw-normal mb-2 mt-4">Your Safety is Our Priority</h5>
          <p>
            At GoRishtey.com, we are committed to creating a safe and secure
            platform for individuals seeking genuine connections. We take the
            issue of fraudulent activity seriously and want to ensure that
            you're well-informed and protected during your journey with us.
          </p>

          <h5 className="fw-normal mb-2 mt-4">Stay Vigilant</h5>
          <p>
            While we strive to verify profiles and maintain a secure
            environment, it's important to exercise caution and stay vigilant.
            Be cautious of individuals who ask for financial assistance, share
            personal or sensitive information too quickly, or display suspicious
            behavior.
          </p>

          <h5 className="fw-normal mb-2 mt-4">Protecting Your Information</h5>
          <p>
            At GoRishtey.com, we will never ask you for your financial
            information, passwords, or any sensitive personal details. Please
            refrain from sharing such information with anyone claiming to be
            associated with our platform.
          </p>

          <h5 className="fw-normal mb-2 mt-4">Reporting Suspicious Activity</h5>
          <p>
            If you come across any suspicious profiles or interactions, we
            encourage you to report them to our customer support team
            immediately. Your report can help us maintain the integrity of our
            community and take swift action against any fraudulent activity.
          </p>

          <h5 className="fw-normal mb-2 mt-4">Educate Yourself</h5>
          <p>
            Stay informed about common online scams and fraud tactics.
            Familiarize yourself with typical warning signs and best practices
            to protect yourself while engaging with others on the internet.
          </p>

          <h5 className="fw-normal mb-2 mt-4">Our Commitment</h5>
          <p>
            Your safety and satisfaction are our top priorities. We're dedicated
            to continuously enhancing our security measures and educating our
            users about potential risks. Remember that while we do our utmost to
            provide a secure platform, personal awareness and caution are
            equally important in safeguarding your online experience.
          </p>
          <p>
            Thank you for entrusting GoRishtey.com with your journey towards
            meaningful connections. We're here to guide you every step of the
            way and ensure that your experience with us is positive, secure, and
            rewarding.
          </p>
        </div>
      </section>

      <Footer />
    </>
  );
}

export default FraudAlert;
