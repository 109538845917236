import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { server } from "../server";
import PageHeader from "../components/PageHeader";

const reportOptions = [
  "Inappropriate Language/Content",
  "Sexual Content",
  "False Information/Incorrect Details",
  "Scam/Fraudulent Activity",
  "Offensive Behavior",
  "Discriminatory Content",
  "Violent Content",
  "Harassment/Bullying",
  "Hate Speech",
  "Spam/Advertisement",
  "Underage User",
  "Impersonation/Fake Profile",
  "Privacy Violation",
  "Stalking/Unwanted Attention",
  "Other",
];

export default function Report() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const navigate = useNavigate();
  const location = useLocation();
  const { userInfo } = location?.state;
  const [reason, setReason] = useState("");
  const [reportLoading, setReportLoading] = useState(false);

  const handleReportUser = async () => {
    if (!reason) return toast.warn("Reason required");
    try {
      setReportLoading(true);
      const formData = {
        reportTo: userInfo?.user?.[0]?._id,
        reason,
      };
      const { data } = await axios.post(`${server}/report/new`, formData, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      setReportLoading(false);
      toast.success(data?.message);
      navigate(-1);
    } catch (e) {
      setReportLoading(false);
      toast.error(e?.response?.data?.message || e?.message);
    }
  };

  return (
    <div className="block-margin-top mb-4">
      <div className="py-3 bg-white rounded-4 block-border">
        <PageHeader name="Report Account" />

        <div className="px-4 pb-3">
          <FormControl className="w-100 mb-3" fullWidth>
            <InputLabel id="demo-select-small-label">Reason</InputLabel>
            <Select
              labelId="demo-select-small-label"
              id="demo-select-small"
              label="Reason"
              value={reason}
              onChange={(e) => setReason(e.target.value)}
            >
              {reportOptions?.map((item, index) => (
                <MenuItem key={index} value={item}>
                  {item}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <button
            loading={reportLoading}
            onClick={handleReportUser}
            disabled={reportLoading}
            className="rounded-3 bg-crimson mt-2 px-5 py-2"
          >
            <p className="my-1 fw-medium text-capitalize text-white fs-7 mx-3">
              Report Now
            </p>
          </button>
        </div>
      </div>
    </div>
  );
}
