import axios from "axios";
import { server } from "../../../server";
import { toast } from "react-toastify";

export const getMyProfileAction = () => async (dispatch) => {
  dispatch({
    type: "GET_MY_PROFILE_REQUEST",
  });

  try {
    const { data } = await axios.get(`${server}/user/me`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
    localStorage.setItem("myProfile", JSON.stringify(data?.user));
    localStorage.setItem("token", data?.token);

    dispatch({
      type: "GET_MY_PROFILE_SUCCESS",
      payload: { user: data?.user, token: data?.token },
    });
  } catch (e) {
    dispatch({
      type: "GET_MY_PROFILE_FAIL",
      payload: { error: e?.response?.data?.message || e.message },
    });
  }
};

export const getUserProfileByIdAction = (userId) => async (dispatch) => {
  dispatch({
    type: "GET_USER_PROFILE_BY_ID_REQUEST",
  });

  try {
    const { data } = await axios.get(`${server}/profile/user/${userId}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });

    dispatch({
      type: "GET_USER_PROFILE_BY_ID_SUCCESS",
      payload: { reduxProfile: data?.data },
    });
  } catch (e) {
    dispatch({
      type: "GET_USER_PROFILE_BY_ID_FAIL",
      payload: { error: e?.response?.data?.message || e.message },
    });
  }
};

export const editProfileDetails = (formData, user) => async (dispatch) => {
  dispatch({
    type: "EDIT_USER_DETAILS_REQUEST",
  });

  try {
    const { data } = await axios.put(`${server}/profile/update`, formData, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });

    dispatch({
      type: "EDIT_USER_DETAILS_SUCCESS",
      payload: { user: data?.user },
    });

    dispatch({
      type: "GET_MY_PROFILE_SUCCESS",
      payload: { user: { ...data?.user } },
    });

    toast.message(data?.message);
  } catch (e) {
    console.log(e?.response?.data?.message || e.message);
    dispatch({
      type: "EDIT_USER_DETAILS_FAIL",
      payload: { error: e?.response?.data?.message || e.message },
    });
  }
};

export const getUserLoggedOut = (navigate) => async (dispatch) => {
  dispatch({
    type: "LOGOUT_REQUEST",
  });

  localStorage.removeItem("myProfile");
  localStorage.removeItem("token");

  dispatch({
    type: "REMOVE_ALL_MATCHES",
  });
  dispatch({
    type: "LOGOUT_SUCCESS",
  });
  navigate("/");
};
