/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useEffect } from "react";
import { TextField } from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Select from "@mui/material/Select";
import { Link, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { qualificationList } from "../../jsonFiles/qualificationList";
import { jobTitlesList } from "../../jsonFiles/jobTitlesList";

function Career() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm();
  const storageCareerFamily = JSON.parse(
    localStorage.getItem("careerFamilyData")
  );
  const [selectedJobCategory, setSelectedJobCategory] = useState(
    storageCareerFamily?.jobCategory || ""
  );
  const [selectedQualification, setSelectedQualification] = useState(
    storageCareerFamily?.qualification || ""
  );
  const [working, setWorking] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleSubmitData = (data) => {
    const formData = {
      highestDegree: data?.highestDegree,
      jobTitle: data?.jobTitle,
      employedIn: data?.employedIn,
      annualIncome: data?.annualIncome,
      familyType: data?.familyType,
      fatherStatus: data?.fatherStatus,
      motherStatus: data?.motherStatus,
      noOfBrothers: data?.noOfBrothers,
      noOfSisters: data?.noOfSisters,
      familyLivingIn: data?.familyLivingIn,
      //
      qualification: selectedQualification,
      jobCategory: selectedJobCategory,
    };

    localStorage.setItem("careerFamilyData", JSON.stringify(formData));
    navigate("/images");
  };

  return (
    <>
      <section className="register-page-bg pb-1">
        <div className="sticky-top bg-white">
          <div className="container py-2">
            <div className="px-lg-0 px-2 d-flex justify-content-between align-items-center z-2 position-relative">
              <img src="/assets/images/logo-red.png" height="35" />
              <div className="d-flex float-end align-items-center">
                <img
                  src="/assets/images/help.png"
                  height="25"
                  className="mx-4"
                />
                <Link
                  to="/login"
                  className="px-4 py-2 rounded-3 bg-white btn-shadow cursor-pointer"
                >
                  <p className="mx-2 home-login-btn fw-medium text-blue">
                    Login
                  </p>
                </Link>
              </div>
            </div>
          </div>
        </div>

        <div className="d-flex mt-1 justify-content-center mb-4">
          <div className="col-lg-6 col-md-9 col-11">
            <form
              className="p-lg-5 mx-lg-5 p-4 mt-4 bg-white rounded-3"
              onSubmit={handleSubmit(handleSubmitData)}
            >
              <div className="p-2">
                <div className="mb-4">
                  <h5 className="fs-6 fw-medium text-secondary">EDUCATION</h5>
                </div>
                <FormControl className="w-100 mb-3" fullWidth>
                  <InputLabel id="demo-select-small-label">
                    Highest Qualification
                  </InputLabel>
                  <Select
                    labelId="demo-select-small-label"
                    id="demo-select-small"
                    label="Highest Qualification"
                    value={selectedQualification}
                    onChange={(e) => setSelectedQualification(e?.target?.value)}
                    error={errors?.highestQualification}
                  >
                    {qualificationList
                      ?.map((e) => e?.name)
                      ?.map((item, index) => (
                        <MenuItem key={index} value={item}>
                          {item}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
                <FormControl className="w-100 mb-3" fullWidth>
                  <InputLabel id="demo-select-small-label">
                    Highest Degree
                  </InputLabel>
                  <Select
                    labelId="demo-select-small-label"
                    id="demo-select-small"
                    label="Highest Degree"
                    defaultValue={storageCareerFamily?.highestDegree}
                    {...register("highestDegree", { required: true })}
                    error={errors?.highestDegree}
                  >
                    {qualificationList
                      ?.find((e) => e?.name === selectedQualification)
                      ?.category?.map((item, index) => (
                        <MenuItem key={index} value={item}>
                          {item}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>

                <div className="mt-4">
                  <h5 className="fs-6 fw-medium text-secondary">CAREER</h5>
                </div>

                <FormControlLabel
                  className="mb-4"
                  control={<Checkbox />}
                  label="Not Working"
                  defaultChecked={
                    storageCareerFamily?.jobCategory ? false : true
                  }
                  checked={!working}
                  onChange={(e) => setWorking(!e.target.checked)}
                />

                {working && (
                  <div>
                    <FormControl className="w-100 mb-3" fullWidth>
                      <InputLabel id="demo-select-small-label">
                        Job Category
                      </InputLabel>
                      <Select
                        labelId="demo-select-small-label"
                        id="demo-select-small"
                        label="Job Category"
                        value={selectedJobCategory}
                        defaultValue={storageCareerFamily?.jobCategory}
                        onChange={(e) => setSelectedJobCategory(e.target.value)}
                        error={errors?.jobCategory}
                      >
                        {jobTitlesList
                          ?.map((e) => e?.name)
                          ?.map((item, index) => (
                            <MenuItem key={index} value={item}>
                              {item}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>

                    <FormControl className="w-100 mb-3" fullWidth>
                      <InputLabel id="demo-select-small-label">
                        Job Title
                      </InputLabel>
                      <Select
                        labelId="demo-select-small-label"
                        id="demo-select-small"
                        label="Job Title"
                        defaultValue={storageCareerFamily?.jobTitle}
                        {...register("jobTitle", { required: true })}
                        error={errors?.jobTitle}
                      >
                        {jobTitlesList
                          ?.find((e) => e?.name === selectedJobCategory)
                          ?.category?.map((item, index) => (
                            <MenuItem key={index} value={item}>
                              {item}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>

                    <FormControl className="w-100 mb-3" fullWidth>
                      <InputLabel id="demo-select-small-label">
                        Employed In
                      </InputLabel>
                      <Select
                        labelId="demo-select-small-label"
                        id="demo-select-small"
                        label="Employed In"
                        defaultValue={storageCareerFamily?.employedIn}
                        {...register("employedIn", { required: true })}
                        error={errors?.employedIn}
                      >
                        {[
                          "Private Company",
                          "Government / Public Sector",
                          "Defense / Civil Services ",
                          "Business / Self Employed",
                          "Not Working",
                        ]?.map((item, index) => (
                          <MenuItem key={index} value={item}>
                            {item}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>

                    <FormControl className="w-100 mb-3" fullWidth>
                      <InputLabel id="demo-select-small-label">
                        Annual Income
                      </InputLabel>
                      <Select
                        labelId="demo-select-small-label"
                        id="demo-select-small"
                        label="Annual Income"
                        defaultValue={storageCareerFamily?.annualIncome}
                        {...register("annualIncome", { required: true })}
                        error={errors?.annualIncome}
                      >
                        {[
                          "Rs. 0 - 1 Lakh",
                          "Rs. 1 - 2 Lakh",
                          "Rs. 2 - 3 Lakh",
                          "Rs. 3 - 4 Lakh",
                          "Rs. 4 - 5 Lakh",
                          "Rs. 5 - 7.5 Lakh",
                          "Rs. 7.5 - 10 Lakh",
                          "Rs. 10 - 15 Lakh",
                          "Rs. 15 - 20 Lakh",
                          "Rs. 20 - 25 Lakh",
                          "Rs. 25 - 35 Lakh",
                          "Rs. 35 - 50 Lakh",
                          "Rs. 50 - 70 Lakh",
                          "Rs. 70 Lakh - 1 crore",
                          "Rs. 1 crore & above",
                        ]?.map((item, index) => (
                          <MenuItem key={index} value={item}>
                            {item}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                )}
                <div className="my-4">
                  <h5 className="fs-6 fw-medium text-secondary">FAMILY</h5>
                </div>

                <FormControl className="w-100 mb-3" fullWidth>
                  <InputLabel id="demo-select-small-label">
                    Family Type
                  </InputLabel>
                  <Select
                    labelId="demo-select-small-label"
                    id="demo-select-small"
                    label="Family Type"
                    defaultValue={storageCareerFamily?.familyType}
                    {...register("familyType", { required: true })}
                    error={errors?.familyType}
                  >
                    {["Joint Family", "Nuclear Family", "Other"]?.map(
                      (item, index) => (
                        <MenuItem key={index} value={item}>
                          {item}
                        </MenuItem>
                      )
                    )}
                  </Select>
                </FormControl>
                <FormControl className="w-100 mb-3" fullWidth>
                  <InputLabel id="demo-select-small-label">
                    Father Status
                  </InputLabel>
                  <Select
                    labelId="demo-select-small-label"
                    id="demo-select-small"
                    label="Father Status"
                    defaultValue={storageCareerFamily?.fatherStatus}
                    {...register("fatherStatus", { required: true })}
                    error={errors?.fatherStatus}
                  >
                    {[
                      "Employed",
                      "Retired",
                      "Business",
                      "Not Employed",
                      "Passed Away",
                    ]?.map((item, index) => (
                      <MenuItem key={index} value={item}>
                        {item}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <FormControl className="w-100 mb-3" fullWidth>
                  <InputLabel id="demo-select-small-label">
                    Mother Status
                  </InputLabel>
                  <Select
                    labelId="demo-select-small-label"
                    id="demo-select-small"
                    label="Mother Status"
                    defaultValue={storageCareerFamily?.motherStatus}
                    {...register("motherStatus", { required: true })}
                    error={errors?.motherStatus}
                  >
                    {[
                      "Homemaker",
                      "Employed",
                      "Retired",
                      "Business",
                      "Passed Away",
                    ]?.map((item, index) => (
                      <MenuItem key={index} value={item}>
                        {item}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <TextField
                  id="outlined-basic"
                  label="Family Living In (City)"
                  variant="outlined"
                  className="w-100 mb-3"
                  defaultValue={storageCareerFamily?.familyLivingIn}
                  {...register("familyLivingIn", { required: true })}
                  error={errors?.familyLivingIn}
                />
                <FormControl className="w-100 mb-3" fullWidth>
                  <InputLabel id="demo-select-small-label">
                    No. Of Brothers
                  </InputLabel>
                  <Select
                    labelId="demo-select-small-label"
                    id="demo-select-small"
                    label="No. Of Brothers"
                    defaultValue={storageCareerFamily?.noOfBrothers}
                    {...register("noOfBrothers", { required: true })}
                    error={errors?.noOfBrothers}
                  >
                    {["0", "1", "2", "3", "4", "5 +"]?.map((item, index) => (
                      <MenuItem key={index} value={item}>
                        {item}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <FormControl className="w-100 mb-3" fullWidth>
                  <InputLabel id="demo-select-small-label">
                    No. Of Sisters
                  </InputLabel>
                  <Select
                    labelId="demo-select-small-label"
                    id="demo-select-small"
                    label="No. Of Sisters"
                    defaultValue={storageCareerFamily?.noOfSisters}
                    {...register("noOfSisters", { required: true })}
                    error={errors?.noOfSisters}
                  >
                    {["0", "1", "2", "3", "4", "5 +"]?.map((item, index) => (
                      <MenuItem key={index} value={item}>
                        {item}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                <div className="pt-3">
                  <button type="submit" className="bg-crimson px-4 py-2">
                    <p className="my-1 fw-medium text-capitalize text-white fs-6 mx-5">
                      Continue
                    </p>
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </section>
    </>
  );
}

export default Career;
