import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getNotifications } from "../../redux/actions/notification";
import PageHeader from "../../components/PageHeader";
import { defaultUserImage, server } from "../../server";

const Notifications = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const dispatch = useDispatch();
  const { notifications, loading } = useSelector(
    (state) => state?.notification
  );

  // Seen notifications
  useEffect(() => {
    dispatch(getNotifications());
  }, []);

  return (
    <>
      <div className="block-margin-top mb-4">
        <div className="py-3 bg-white rounded-4 block-border">
          <PageHeader name="Notifications" />

          <div className="px-4 pb-3">
            {notifications?.map((item, index) => (
              <div key={index}>
                <div className="light-crimson-bg px-4 py-3 rounded-4">
                  <div className="d-flex align-items-center">
                    <img
                      src={defaultUserImage}
                      width="50"
                      height="50"
                      className="rounded-pill"
                    />
                    <div className="ms-3">
                      <h6 className="mb-0 fs-8">
                        {item?.title} (
                        {new Date(
                          Date.parse(item?.createdAt)
                        )?.toLocaleDateString("en-In")}
                        )
                      </h6>
                      <p className="fs-9 mt-0">{item?.content}</p>
                    </div>
                  </div>
                </div>
              </div>
            ))}

            {(!notifications || notifications?.length === 0) && !loading && (
              <div className="text-center mt-5">
                You don't have notifications
              </div>
            )}

            {loading && (
              <div className="d-flex justify-content-center mt-5">
                <div class="spinner-border text-secondary" role="status"></div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Notifications;
