import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getUserLoggedOut } from "../../redux/actions/profile/profileAction";
import { defaultUserImage } from "../../server";

function Header() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user } = useSelector((state) => state?.profile);

  const handleLogOut = () => {
    dispatch(getUserLoggedOut(navigate));
  };

  return (
    <>
      <div
        class="offcanvas offcanvas-start"
        tabindex="-1"
        id="offcanvasMenu"
        aria-labelledby="offcanvasMenuLabel"
      >
        <div class="offcanvas-header mx-2">
          <h5 class="offcanvas-title" id="offcanvasMenuLabel">
            <img src="/assets/images/logo-red.png" height="35" />
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          ></button>
        </div>
        <div class="offcanvas-body">
          <div className="px-2 pb-4">
            <div className="pb-4 d-flex align-items-center">
              <img
                src={
                  user?.profile?.images?.length > 0
                    ? user?.profile?.images[0]?.docUrl
                    : defaultUserImage
                }
                width="60"
                height="60"
                className="rounded-pill object-fit-cover"
              />
              <div className="ms-3">
                <h5 className="m-0">Hi, {(user?.name || "User").split(" ")[0]}</h5>
                <p className="fs-9">
                  GRSTY{user?.customId}
                  <span
                    onClick={() =>
                      navigate("./", { state: { Information: "Update" } })
                    }
                    type="button"
                    data-bs-dismiss="offcanvas"
                    className="ms-3 text-danger fw-medium"
                  >
                    Edit Profile
                  </span>
                </p>
              </div>
            </div>
            <div>
              <div
                onClick={() =>
                  navigate("./", { state: { Information: "Matches" } })
                }
                type="button"
                data-bs-dismiss="offcanvas"
                className="d-flex justify-content-between align-items-center py-2 mb-2"
              >
                <p className="fs-6 text-dark fw-medium">Matches</p>
                <i className="bi bi-chevron-right fs-8"></i>
              </div>
              <div
                onClick={() =>
                  navigate("./", { state: { Information: "Activity" } })
                }
                type="button"
                data-bs-dismiss="offcanvas"
                className="d-flex justify-content-between align-items-center py-2 mb-2"
              >
                <p className="fs-6 text-secondary">Activity</p>
                <i className="bi bi-chevron-right fs-8"></i>
              </div>
              <div
                onClick={() =>
                  navigate("./", { state: { Information: "Search" } })
                }
                type="button"
                data-bs-dismiss="offcanvas"
                className="d-flex justify-content-between align-items-center py-2 mb-2"
              >
                <p className="fs-6 text-secondary">Search</p>
                <i className="bi bi-chevron-right fs-8"></i>
              </div>
              <div
                onClick={() =>
                  navigate("./", { state: { Information: "Messages" } })
                }
                type="button"
                data-bs-dismiss="offcanvas"
                className="d-flex justify-content-between align-items-center py-2 mb-2"
              >
                <p className="fs-6 text-secondary">Messages</p>
                <i className="bi bi-chevron-right fs-8"></i>
              </div>
              <div
                onClick={() =>
                  navigate("./", { state: { Information: "Upgrade" } })
                }
                type="button"
                data-bs-dismiss="offcanvas"
                className="d-flex justify-content-between align-items-center py-2 mb-2"
              >
                <p className="fs-6 text-secondary">Upgrade</p>
                <i className="bi bi-chevron-right fs-8"></i>
              </div>
            </div>
          </div>
        </div>
      </div>

      <section className="bg-white fixed-top dashboard-header">
        <div className="container py-2">
          <div className="py-1 d-flex justify-content-between align-items-center z-2 position-relative">
            <div className="d-flex align-items-center">
              <i
                data-bs-toggle="offcanvas"
                href="#offcanvasMenu"
                role="button"
                aria-controls="offcanvasMenu"
                className="bi bi-justify fs-3 d-lg-none d-block me-3 text-dark"
              ></i>
              <img src="/assets/images/logo-red.png" height="35" />
            </div>
            <div className="d-flex float-end align-items-center">
              <i
                onClick={() =>
                  navigate("./", { state: { Information: "Notifications" } })
                }
                type="button"
                className="bi bi-bell fs-5 text-secondary"
              ></i>
              <i
                onClick={handleLogOut}
                className="bi bi-power fs-4 text-secondary ms-4 cursor-pointer"
              ></i>

              <a
                href="https://play.google.com/store/apps/details?id=com.gorishteyapp"
                target="_blank"
                className="px-4 py-2 rounded-3 bg-crimson btn-shadow cursor-pointer ms-4"
              >
                <p className="home-login-btn fs-7 fw-medium text-white">
                  MOBILE APP
                </p>
              </a>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Header;
