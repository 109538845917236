import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import { useEffect, useState } from "react";
import { server } from "../../server";
import axios from "axios";
import { toast } from "react-toastify";
import { motherTongueList } from "../../jsonFiles/motherTongueList";
import { religionList } from "../register/Basic";
import { useForm } from "react-hook-form";
import { stateCityList } from "../../jsonFiles/stateCityList";
import { useNavigate } from "react-router-dom";

function Search() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [customId, setCustomId] = useState("");
  const [loading, setLoading] = useState(false);
  const [activeSearch, setActiveSearch] = useState("searchById");
  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(100);

  async function handleSearch() {
    if (!customId) {
      return null;
    }

    try {
      setLoading(true);
      const { data } = await axios.get(
        `${server}/user/search/${customId?.toString()}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      setLoading(false);
      navigate("./", {
        state: {
          Information: "Details",
          profileData: data?.data?.[0],
        },
      });
    } catch (e) {
      setLoading(false);
      toast.error(e?.response?.data?.message || e?.message);
    }
  }

  const handleAdvanceSearch = async (data) => {
    try {
      setLoading(true);
      const { data: resData } = await axios.get(
        `${server}/profile/advanceSearch`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          params: {
            motherTongue: data?.motherTongue,
            religion: data?.religion,
            maritalStatus: data?.maritalStatus,
            state: data?.state,
            highestQualification: data?.highestQualification,
            familyType: data?.familyType,
            jobCategory: data?.jobCategory,
            employedIn: data?.employedIn,
            annualIncome: data?.annualIncome,
            pageNo: pageNo,
            pageSize: pageSize,
          },
        }
      );
      setLoading(false);
      navigate("./", {
        state: {
          Information: "SearchedProfiles",
          profilesData: resData?.data,
        },
      });
    } catch (e) {
      setLoading(false);
      toast.error(e?.response?.data?.message || e?.message);
    }
  };

  return (
    <>
      <div className="block-margin-top mb-4">
        <div className="py-4 bg-white rounded-4 block-border">
          <div className="px-4">
            <div className="d-flex justify-content-center search-category">
              <div
                className={`px-4 py-2 fs-7 cursor-pointer me-2 ${
                  activeSearch === "advanceSearch" &&
                  "fw-medium search-category-block"
                }`}
                onClick={() => setActiveSearch("advanceSearch")}
              >
                Advanced Search
              </div>

              <div
                className={`px-4 py-2 fs-7 cursor-pointer ${
                  activeSearch === "searchById" &&
                  "fw-medium search-category-block"
                }`}
                onClick={() => setActiveSearch("searchById")}
              >
                Search by Profile ID
              </div>
            </div>

            {activeSearch === "searchById" && (
              <div>
                <div className="m-5">
                  <div>
                    <TextField
                      label="Enter Profile ID"
                      id="outlined-start-adornment"
                      className="w-100"
                      sx={{ m: 1, width: "25ch" }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            grsty -
                          </InputAdornment>
                        ),
                      }}
                      value={customId}
                      onChange={(e) => setCustomId(e.target.value)}
                    />
                  </div>
                </div>

                <div className="d-flex justify-content-center mb-3">
                  <button
                    className="bg-danger rounded-pill py-2 px-5 cursor-pointer"
                    onClick={handleSearch}
                    disables={loading}
                  >
                    <p className="fs-7 text-white text-center my-1 mx-4">
                      Show Me Profile
                    </p>
                  </button>
                </div>
              </div>
            )}

            {activeSearch === "advanceSearch" && (
              <form onSubmit={handleSubmit(handleAdvanceSearch)}>
                <div className="mx-lg-5 mx-3 my-5">
                  <div>
                    {/* Mother Tongue */}
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">
                        Mother Tongue
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label="Mother Tongue"
                        {...register("motherTongue")}
                      >
                        <MenuItem value="">Select an option</MenuItem>
                        {motherTongueList?.map((item, index) => (
                          <MenuItem value={item?.Name} key={index}>
                            {item?.Name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>

                    {/*Religion  */}
                    <FormControl fullWidth sx={{ mt: 2 }}>
                      <InputLabel id="demo-simple-select-label">
                        Religion
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label="Religion"
                        {...register("religion")}
                      >
                        <MenuItem value="">Select an option</MenuItem>
                        {religionList?.map((item, index) => (
                          <MenuItem value={item} key={index}>
                            {item}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>

                    {/* Marital Status  */}
                    <FormControl fullWidth sx={{ mt: 2 }}>
                      <InputLabel id="demo-simple-select-label">
                        Marital Status
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label="Marital Status"
                        {...register("maritalStatus")}
                      >
                        <MenuItem value="">Select an option</MenuItem>
                        {[
                          "Never Married",
                          "Awaiting Divorce",
                          "Divorced",
                          "Widowed",
                          "Annulled",
                        ]?.map((item, index) => (
                          <MenuItem value={item} key={index}>
                            {item}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>

                    {/* State  */}
                    <FormControl fullWidth sx={{ mt: 2 }}>
                      <InputLabel id="demo-simple-select-label">
                        State
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label="State"
                        {...register("state")}
                      >
                        <MenuItem value="">Select an option</MenuItem>
                        {[...new Set(stateCityList?.map((e) => e?.State))]?.map(
                          (item, index) => (
                            <MenuItem value={item} key={index}>
                              {item}
                            </MenuItem>
                          )
                        )}
                      </Select>
                    </FormControl>

                    {/* Family Type */}
                    <FormControl fullWidth sx={{ mt: 2 }}>
                      <InputLabel id="demo-simple-select-label">
                        Family Type
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label="Family Type"
                        {...register("familyType")}
                      >
                        <MenuItem value="">Select an option</MenuItem>
                        {["Joint Family", "Nuclear Family", "Other"]?.map(
                          (item, index) => (
                            <MenuItem key={index} value={item}>
                              {item}
                            </MenuItem>
                          )
                        )}
                      </Select>
                    </FormControl>

                    {/* Employed In  */}
                    <FormControl fullWidth sx={{ mt: 2 }}>
                      <InputLabel id="demo-simple-select-label">
                        Employed in
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label="Employed In"
                        {...register("employedIn")}
                      >
                        <MenuItem value="">Select an option</MenuItem>
                        {[
                          "Private Company",
                          "Government / Public Sector",
                          "Defense / Civil Services ",
                          "Business / Self Employed",
                          "Not Working",
                        ]?.map((item, index) => (
                          <MenuItem value={item} key={index}>
                            {item}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>

                    {/* Employed In  */}
                    <FormControl fullWidth sx={{ mt: 2 }}>
                      <InputLabel id="demo-simple-select-label">
                        Annual Income
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label="Annual Income"
                        {...register("annualIncome")}
                      >
                        <MenuItem value="">Select an option</MenuItem>
                        {[
                          "Rs. 0 - 1 Lakh",
                          "Rs. 1 - 2 Lakh",
                          "Rs. 2 - 3 Lakh",
                          "Rs. 3 - 4 Lakh",
                          "Rs. 4 - 5 Lakh",
                          "Rs. 5 - 7.5 Lakh",
                          "Rs. 7.5 - 10 Lakh",
                          "Rs. 10 - 15 Lakh",
                          "Rs. 15 - 20 Lakh",
                          "Rs. 20 - 25 Lakh",
                          "Rs. 25 - 35 Lakh",
                          "Rs. 35 - 50 Lakh",
                          "Rs. 50 - 70 Lakh",
                          "Rs. 70 Lakh - 1 crore",
                          "Rs. 1 crore & above",
                        ]?.map((item, index) => (
                          <MenuItem value={item} key={index}>
                            {item}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>

                    {/* <FormControl fullWidth sx={{ mt: 2 }}>
                      <InputLabel id="demo-simple-select-label">
                        Min Age
                      </InputLabel>
                      <TextField
                        variant="outlined"
                        type="number"
                        {...register("minAge", {
                          required: false,
                          min: 18,
                          max: 60,
                        })}
                        error={errors?.minAge}
                      />
                    </FormControl>

                    <FormControl fullWidth sx={{ mt: 2 }}>
                      <InputLabel id="demo-simple-select-label">
                        Max Age
                      </InputLabel>
                      <TextField
                        variant="outlined"
                        type="number"
                        {...register("maxAge", {
                          required: false,
                          min: 18,
                          max: 60,
                        })}
                        error={errors?.maxAge}
                      />
                    </FormControl> */}
                  </div>
                </div>

                <div className="d-flex justify-content-center mb-3">
                  <button
                    className="bg-danger rounded-pill py-2 px-5 cursor-pointer"
                    type="submit"
                    disables={loading}
                  >
                    <p className="fs-7 text-white text-center my-1 mx-4">
                      {loading ? "Please wait.." : "Show Me Profile"}
                    </p>
                  </button>
                </div>
              </form>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default Search;
