export const jobTitlesList = [
  {
    name: "Accounting, Banking & Finance",
    category: [
      "Banking Professional",
      "Chartered Accountant",
      "Company Secretary",
      "Finance Professional",
      "Investment Professional",
      "Accounting Professional (Other)",
    ],
  },
  {
    name: "Administration & HR",
    category: ["Admin Professional", "Human Resources Professional"],
  },
  {
    name: "Advertising, Media, & Entertainment",
    category: [
      "Advertising Professional",
      "Entertainment professional",
      "Event Manager",
      "Journalist",
      "Media Professional",
      "Public Relation Professional",
    ],
  },
  {
    name: "Agriculture",
    category: [
      "Farming",
      "Horticulturist",
      "Agricultural Professional (Other)",
    ],
  },
  {
    name: "Airline & Aviation",
    category: [
      "Air Hostess/Flight Attendant",
      "Pilot/Co-Pilot",
      "Other Airline Professional",
    ],
  },
  {
    name: "Architecture & Design",
    category: ["Architect", "Interior Designer", "Landscape Architect"],
  },
  {
    name: "Artists, Animators & Web Designer",
    category: [
      "Animator",
      "Commercial Artist",
      "Web/ox Designer",
      "Artist",
      "Other",
    ],
  },
  {
    name: "Beauty, Fashion & Jewelry Designers",
    category: [
      "Beautician",
      "Fashion Designer",
      "Hair Stylist",
      "Jewelry Designer",
      "Designer (Other)",
    ],
  },
  {
    name: "BPU, KPO & Customer Support",
    category: ["Customer Support/BPO/KPO Professional"],
  },
  {
    name: "Civil Services/Law Enforcement",
    category: [
      "IAS/IPS/IES/IFS",
      "Indian Police Services IPS",
      "Law Enforcement (Other)",
    ],
  },
  {
    name: "Defense",
    category: ["Air force", "Army", "Navy", "Defense Services (Other)"],
  },
  {
    name: "Education & Training",
    category: [
      "Lecturer",
      "Professor",
      "Research Assistant",
      "Research Scholar",
      "Teacher",
      "Training Professional (Other)",
    ],
  },
  {
    name: "Engineering",
    category: [
      "Civil Engineer",
      "Electronic/Telecom Engineer",
      "Mechanical/Production Engineer",
      "Non IT Engineer (Other)",
    ],
  },
  {
    name: "Hotel & Hospitality",
    category: [
      "Chief/Sommelier/Food Critic",
      "Caterlng Professional",
      "Hotel/Hospitality Professional (Others)",
    ],
  },
  {
    name: "IT & Software Engineering",
    category: [
      "Software Developer/Programmer",
      "Software Consultant",
      "Hardware & Networking Professional",
      "Software Professional (Others)",
    ],
  },
  {
    name: "Legal",
    category: ["Lawyer", "Legal Assistant", "Legal Professional (Others)"],
  },
  {
    name: "Medical & Healthcare",
    category: [
      "Dentist",
      "Doctor",
      "Medical Transcriptionist",
      "Nurse",
      "Pharmacist",
      "Physician Assistant",
      "Physiotherapist/Occupational Therapist",
      "Psychologist",
      "Surgeon",
      "Veterinary Doctor",
      "Therapist (Others)",
      "Medical/Healthcare Professional (Others)",
    ],
  },
  { name: "Merchant Navy", category: ["Merchant Naval Officer", "Mariner"] },
  {
    name: "Sales & Marketing",
    category: ["Marketing Professional", "Sales Professional"],
  },
  {
    name: "Science",
    category: [
      "Biologist/Botanist",
      "Physicist",
      "Science Professional (Others)",
    ],
  },
  {
    name: "Corporate Professional",
    category: [
      "Cxo/Chairman/Director/President",
      "VP/AVP/GM/DGM",
      "Sr. Manager/Manager",
      "Consultant/Supervisor/Team Leader",
      "Team Member/Staff",
    ],
  },
  {
    name: "Others",
    category: [
      "Agent/Broker/Trader/Contractor",
      "Business Owner/Entrepreneur",
      "Politician",
      "Social Worker/Volunteer/NGO",
      "Sportsman",
      "Travel & Transport Professional",
      "Writer",
      "Postman",
    ],
  },
];
