import {createReducer} from '@reduxjs/toolkit';

const initialState = {
  loading: false,
  error: null,
};

export const notificationReducer = createReducer(initialState, builder => {
  builder
    .addCase('FETCH_NOTIFICATION_REQUEST', (state, action) => {
      state.loading = true;
      state.error = null;
    })
    .addCase('FETCH_NOTIFICATION_SUCCESS', (state, action) => {
      state.loading = false;
      state.notifications = action.payload.data;
      state.error = null;
    })
    .addCase('FETCH_NOTIFICATION_FAIL', (state, action) => {
      state.loading = false;
      state.error = action.payload.error;
    });
});
