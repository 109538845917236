import axios from "axios";
import { server } from "../../server";
import { toast } from "react-toastify";

export const newShortlistAction =
  (shortlistedUserId, genderProfiles) => async (dispatch) => {
    dispatch({
      type: "SEND_SHORTLIST_REQUEST",
    });

    try {
      const { data } = await axios.post(
        `${server}/shortlist/new`,
        shortlistedUserId,
        {
          headers: { "Content-Type": "application/json" },
        }
      );

      dispatch({
        type: "SEND_SHORTLIST_SUCCESS",
        payload: { status: data?.data?.status },
      });

      // Update gender profile
      dispatch({
        type: "GET_GENDER_PROFILES_REQUEST",
      });
      // Update gender profile
      let profiles = genderProfiles.map((item) =>
        item._id === data?.data?.shortlistedUserId
          ? {
              ...item,
              shortlist: [...(item.shortlist || []), data?.data],
            }
          : item
      );
      dispatch({
        type: "GET_GENDER_PROFILES_SUCCESS",
        payload: { genderProfiles: profiles },
      });
    } catch (e) {
      toast.error(e?.response?.data?.message || e.message);
      dispatch({
        type: "SEND_SHORTLIST_FAIL",
        payload: { error: e?.response?.data?.message || e.message },
      });
    }
  };

export const cancelShortlistAction =
  (id, genderProfiles) => async (dispatch) => {
    dispatch({
      type: "CANCEL_SHORTLIST_REQUEST",
    });

    try {
      const { data } = await axios.delete(`${server}/shortlist/delete/${id}`, {
        headers: { "Content-Type": "application/json" },
      });

      dispatch({
        type: "CANCEL_SHORTLIST_SUCCESS",
        payload: { message: data?.message },
      });

      // Update gender profile
      dispatch({
        type: "GET_GENDER_PROFILES_REQUEST",
      });
      // Update gender profile
      let profiles = genderProfiles.map((item) =>
        item._id === id
          ? {
              ...item,
              shortlist: [],
            }
          : item
      );
      dispatch({
        type: "GET_GENDER_PROFILES_SUCCESS",
        payload: { genderProfiles: profiles },
      });
    } catch (e) {
      toast.error(e?.response?.data?.message || e.message);
      dispatch({
        type: "CANCEL_SHORTLIST_FAIL",
        payload: { error: e?.response?.data?.message || e.message },
      });
      dispatch({
        type: "GET_GENDER_PROFILES_FAIL",
        payload: { error: e?.response?.data?.message || e.message },
      });
    }
  };

export const cancelShortlistedUserAction =
  (id, myShortlists) => async (dispatch) => {
    dispatch({
      type: "CANCEL_SHORTLIST_REQUEST",
    });

    try {
      const { data } = await axios.delete(`${server}/shortlist/delete/${id}`, {
        headers: { "Content-Type": "application/json" },
      });

      dispatch({
        type: "CANCEL_SHORTLIST_SUCCESS",
        payload: { message: data?.message },
      });

      // Update shortlisted user profile
      dispatch({
        type: "CANCEL_SHORTLISTED_USER_REQUEST",
      });
      // Update gender profile
      let profiles = myShortlists.filter((e) => e.user[0]?._id !== id);
      dispatch({
        type: "CANCEL_SHORTLISTED_USER_SUCCESS",
        payload: { myShortlists: profiles },
      });
    } catch (e) {
      toast.error(e?.response?.data?.message || e.message);
      dispatch({
        type: "CANCEL_SHORTLIST_FAIL",
        payload: { error: e?.response?.data?.message || e.message },
      });
      dispatch({
        type: "CANCEL_SHORTLISTED_USER_FAIL",
        payload: { error: e?.response?.data?.message || e.message },
      });
    }
  };

export const myShortlistedUsersActions = () => async (dispatch) => {
  dispatch({
    type: "GET_MY_SHORTLISTED_USERS_REQUEST",
  });

  try {
    const { data } = await axios.get(`${server}/shortlist/my`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });

    dispatch({
      type: "GET_MY_SHORTLISTED_USERS_SUCCESS",
      payload: { myShortlists: data?.data },
    });
  } catch (e) {
    toast.error(e?.response?.data?.message || e.message);
    dispatch({
      type: "GET_MY_SHORTLISTED_USERS_FAIL",
      payload: { error: e?.response?.data?.message || e.message },
    });
  }
};
