import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Home from "./Home";
import FraudAlert from "./pages/content/FraudAlert";
import TermsOfUse from "./pages/content/TermsOfUse";
import PrivacyPolicy from "./pages/content/PrivacyPolicy";
import Payment from "./pages/content/Payment";
import Basic from "./pages/register/Basic";
import CareerFamily from "./pages/register/CareerFamily";
import Images from "./pages/register/Images";
import VerifyOTP from "./pages/register/VerifyOTP";
import Login from "./pages/Login";
import Dashboard from "./pages/dashboard/Dashboard";
import VerifyLoginOTP from "./pages/VerifyLoginOTP";
import AppAuth from "./AppAuth";
import { AuthProvider } from "./hooks/useAuth";
import Cart from "./pages/dashboard/Cart";
import Update from "./pages/dashboard/Update";
import ProfileImages from "./pages/ProfileImages";

function App() {
  return (
    <BrowserRouter>
      <ToastContainer position="bottom-center" autoClose={4000} />
      <AuthProvider>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/basic" element={<Basic />} />
          <Route path="/careerfamily" element={<CareerFamily />} />
          <Route path="/images" element={<Images />} />
          <Route path="/verifyOTP" element={<VerifyOTP />} />
          <Route path="/fraudalert" element={<FraudAlert />} />
          <Route path="/termsofuse" element={<TermsOfUse />} />
          <Route path="/privacypolicy" element={<PrivacyPolicy />} />
          <Route path="payment/:merchantTransactionId" element={<Payment />} />
          <Route path="/login" element={<Login />} />
          <Route path="/verifyLoginOtp" element={<VerifyLoginOTP />} />
          <Route path="/my" element={<AppAuth />}>
            <Route path="" element={<Dashboard />} />
            <Route path="profile-images" element={<ProfileImages />} />
            <Route path="update" element={<Update />} />
            <Route path="cart" element={<Cart />} />
          </Route>
        </Routes>
      </AuthProvider>
    </BrowserRouter>
  );
}

export default App;
