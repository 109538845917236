import {createReducer} from '@reduxjs/toolkit';

const initialState = {
  loading: false,
  error: null,
  regStage: 'newUser',
  register: null,
  basicData: null,
  careerFamilyData: null,
  images: [],
};
export const registrationStageReducer = createReducer(initialState, builder => {
  builder
    .addCase('REGISTRATION_STAGE_REQUEST', (state, action) => {
      state.loading = true;
      state.error = null;
    })
    .addCase('REGISTRATION_STAGE_SUCCESS', (state, action) => {
      state.loading = false;
      state.regStage = action.payload.stage;
      state.registerData = action.payload.registerData;
      state.basicData = action.payload.basicData;
      state.careerFamilyData = action.payload.careerFamilyData;
      state.images = action.payload.images;
      state.error = null;
    })
    // Make images empty
    .addCase('REGISTRATION_STAGE_MAKE_EMPTY_IMAGE', (state, action) => {
      state.images = [];
    })
    .addCase('REGISTRATION_STAGE_FAIL', (state, action) => {
      state.loading = false;
      state.user = null;
      state.error = action.payload.error;
    });
});
