import { createReducer } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  error: null,
};

export const chatReducer = createReducer(initialState, (builder) => {
  builder
    .addCase("GET_MY_CHATS_LIST_REQUEST", (state, action) => {
      state.loading = true;
      state.message = null;
      state.error = null;
    })
    .addCase("GET_MY_CHATS_LIST_SUCCESS", (state, action) => {
      state.loading = false;
      state.chatList = action.payload?.chatList;
      state.error = null;
    })
    .addCase("GET_MY_CHATS_LIST_FAIL", (state, action) => {
      state.loading = false;
      state.message = null;
      state.error = action.payload?.error;
    });
});
