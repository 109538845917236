import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import PageHeader from "../../components/PageHeader";
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import axios from "axios";
import { server } from "../../server";
import { communities } from "../../jsonFiles/communities";
import { motherTongueList } from "../../jsonFiles/motherTongueList";
import { stateCityList } from "../../jsonFiles/stateCityList";
import { jobTitlesList } from "../../jsonFiles/jobTitlesList";
import { qualificationList } from "../../jsonFiles/qualificationList";
import { religionList } from "../register/Basic";

const ageArray = Array.from(
  { length: Math.floor((60 - 18) / 1) + 1 },
  (_, i) => 18 + i * 1
);

let heightList = [
  { name: "4'0\"", slug: 48 },
  { name: "4'1\"", slug: 49 },
  { name: "4'2\"", slug: 50 },
  { name: "4'3\"", slug: 51 },
  { name: "4'4\"", slug: 52 },
  { name: "4'5\"", slug: 53 },
  { name: "4'6\"", slug: 54 },
  { name: "4'7\"", slug: 55 },
  { name: "4'8\"", slug: 56 },
  { name: "4'9\"", slug: 57 },
  { name: "4'10\"", slug: 58 },
  { name: "4'11\"", slug: 59 },
  { name: "5'0\"", slug: 60 },
  { name: "5'1\"", slug: 61 },
  { name: "5'2\"", slug: 62 },
  { name: "5'3\"", slug: 63 },
  { name: "5'4\"", slug: 64 },
  { name: "5'5\"", slug: 65 },
  { name: "5'6\"", slug: 66 },
  { name: "5'7\"", slug: 67 },
  { name: "5'8\"", slug: 68 },
  { name: "5'9\"", slug: 69 },
  { name: "5'10\"", slug: 70 },
  { name: "5'11\"", slug: 71 },
  { name: "6'0\"", slug: 72 },
  { name: "6'1\"", slug: 73 },
  { name: "6'2\"", slug: 74 },
  { name: "6'3\"", slug: 75 },
  { name: "6'4\"", slug: 76 },
  { name: "6'5\"", slug: 77 },
  { name: "6'6\"", slug: 78 },
  { name: "6'7\"", slug: 79 },
  { name: "6'8\"", slug: 80 },
  { name: "6'9\"", slug: 81 },
  { name: "6'10\"", slug: 82 },
  { name: "6'11\"", slug: 83 },
  { name: "7'0\"", slug: 84 },
];

function PartnerPreferences() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();
  const [loading, setLoading] = useState(false);
  const [pLoading, setPLoading] = useState("");
  const [existingPreferences, setExistingPreferences] = useState("");
  const [minAge, setMinAge] = useState(0);
  const [maxAge, setMaxAge] = useState(0);
  const [minHeight, setMinHeight] = useState(0);
  const [maxHeight, setMaxHeight] = useState(0);
  const [religion, setReligion] = useState("");
  const [annualIncome, setAnnualIncome] = useState("");
  const [community, setCommunity] = useState("");
  const [employedIn, setEmployedIn] = useState("");
  const [familyType, setFamilyType] = useState("");
  const [jobCategory, setJobCategory] = useState("");
  const [maritalStatus, setMaritalStatus] = useState("");
  const [qualification, setQualification] = useState("");
  const [state, setState] = useState("");

  useEffect(() => {
    const fetchExistingPreferences = async () => {
      try {
        const { data } = await axios.get(`${server}/preferences/my`, {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        });

        setExistingPreferences(data?.data);
        setMinAge(data?.data?.minAge);
        setMaxAge(data?.data?.maxAge);
        setMinHeight(data?.data?.minHeight);
        setMaxHeight(data?.data?.maxHeight);
        setReligion(data?.data?.religion);
        setAnnualIncome(data?.data?.annualIncome);
        setCommunity(data?.data?.community);
        setEmployedIn(data?.data?.employedIn);
        setFamilyType(data?.data?.familyType);
        setJobCategory(data?.data?.jobCategory);
        setMaritalStatus(data?.data?.maritalStatus);
        setQualification(data?.data?.qualification);
        setState(data?.data?.state);
      } catch (e) {
        console.log(e?.response?.data?.message || e?.message);
      }
    };

    fetchExistingPreferences();
  }, []);

  const handleSubmitData = async (data) => {
    try {
      setLoading(true);

      const formData = {
        minAge,
        maxAge,
        minHeight,
        maxHeight,
        religion,
        annualIncome,
        community,
        employedIn,
        familyType,
        jobCategory,
        maritalStatus,
        qualification,
        state,
      };

      const { data: resData } = await axios.post(
        `${server}/preferences/new`,
        formData,
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );

      toast.success(resData?.message);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      toast.error(e?.response?.data?.message || e?.message);
    }
  };

  return (
    <>
      <div className="block-margin-top mb-4">
        <div className="py-4 bg-white rounded-4 block-border">
          <PageHeader name="Partner Preferences" />

          <form className="px-5 pt-4" onSubmit={handleSubmit(handleSubmitData)}>
            <div className="row">
              <div className="col-6">
                <FormControl className="w-100 mb-3" fullWidth>
                  <InputLabel id="demo-select-small-label">Min Age</InputLabel>
                  <Select
                    labelId="demo-select-small-label"
                    id="demo-select-small"
                    label="Min Age"
                    value={minAge}
                    onChange={(e) => setMinAge(e.target.value)}
                  >
                    <MenuItem value="">Select an option</MenuItem>
                    {ageArray?.map((item, index) => (
                      <MenuItem key={index} value={item}>
                        {item}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
              <div className="col-6">
                <FormControl className="w-100 mb-3" fullWidth>
                  <InputLabel id="demo-select-small-label">Max Age</InputLabel>
                  <Select
                    labelId="demo-select-small-label"
                    id="demo-select-small"
                    label="Max Age"
                    value={maxAge}
                    onChange={(e) => setMaxAge(e.target.value)}
                  >
                    <MenuItem value="">Select an option</MenuItem>
                    {ageArray?.slice(minAge - 17)?.map((item, index) => (
                      <MenuItem key={index} value={item}>
                        {item}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
            </div>
            {/* <div className="row">
              <div className="col-6">
                <FormControl className="w-100 mb-3" fullWidth>
                  <InputLabel id="demo-select-small-label">
                    Min Height
                  </InputLabel>
                  <Select
                    labelId="demo-select-small-label"
                    id="demo-select-small"
                    label="Min Height"
                    value={minHeight}
                    onChange={(e) => setMinHeight(e?.target?.value)}
                  >
                    <MenuItem value="">Select an option</MenuItem>
                    {heightList?.map((item, index) => (
                      <MenuItem value={item?.slug} key={index}>
                        {item?.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
              <div className="col-6">
                <FormControl className="w-100 mb-3" fullWidth>
                  <InputLabel id="demo-select-small-label">
                    Max Height
                  </InputLabel>
                  <Select
                    labelId="demo-select-small-label"
                    id="demo-select-small"
                    label="Max Height"
                    value={maxHeight}
                    onChange={(e) => setMaxHeight(e?.target?.value)}
                  >
                    <MenuItem value="">Select an option</MenuItem>
                    {heightList?.slice(minHeight - 47)?.map((item, index) => (
                      <MenuItem value={item?.slug} key={index}>
                        {item?.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
            </div> */}

            <FormControl className="w-100 mb-3" fullWidth>
              <InputLabel id="demo-select-small-label">
                Marital Status
              </InputLabel>
              <Select
                labelId="demo-select-small-label"
                id="demo-select-small"
                label="Marital Status"
                value={maritalStatus}
                defaultValue={existingPreferences?.maritalStatus}
                {...register("maritalStatus")}
              >
                <MenuItem value="">Select an option</MenuItem>
                {[
                  "Never Married",
                  "Awaiting Divorce",
                  "Divorced",
                  "Widowed",
                  "Annulled",
                ]?.map((item, index) => (
                  <MenuItem value={item} key={index}>
                    {item}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl className="w-100 mb-3" fullWidth>
              <InputLabel id="demo-select-small-label">Employed In</InputLabel>
              <Select
                labelId="demo-select-small-label"
                id="demo-select-small"
                label="Employed In"
                value={employedIn}
                {...register("employedIn")}
              >
                <MenuItem value="">Select an option</MenuItem>
                {[
                  "Private Company",
                  "Government / Public Sector",
                  "Defense / Civil Services ",
                  "Business / Self Employed",
                  "Not Working",
                ]?.map((item, index) => (
                  <MenuItem key={index} value={item}>
                    {item}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl className="w-100 mb-3" fullWidth>
              <InputLabel id="demo-select-small-label">
                Annual Income
              </InputLabel>
              <Select
                labelId="demo-select-small-label"
                id="demo-select-small"
                label="Annual Income"
                value={annualIncome}
                {...register("annualIncome")}
              >
                <MenuItem value="">Select an option</MenuItem>
                {[
                  "Rs. 0 - 1 Lakh",
                  "Rs. 1 - 2 Lakh",
                  "Rs. 2 - 3 Lakh",
                  "Rs. 3 - 4 Lakh",
                  "Rs. 4 - 5 Lakh",
                  "Rs. 5 - 7.5 Lakh",
                  "Rs. 7.5 - 10 Lakh",
                  "Rs. 10 - 15 Lakh",
                  "Rs. 15 - 20 Lakh",
                  "Rs. 20 - 25 Lakh",
                  "Rs. 25 - 35 Lakh",
                  "Rs. 35 - 50 Lakh",
                  "Rs. 50 - 70 Lakh",
                  "Rs. 70 Lakh - 1 crore",
                  "Rs. 1 crore & above",
                ]?.map((item, index) => (
                  <MenuItem key={index} value={item}>
                    {item}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl className="w-100 mb-3" fullWidth>
              <InputLabel id="demo-select-small-label">Religion</InputLabel>
              <Select
                labelId="demo-select-small-label"
                id="demo-select-small"
                label="Religion"
                value={religion}
                onChange={(e) => setReligion(e.target.value)}
              >
                <MenuItem value="">Select an option</MenuItem>
                {religionList?.map((item, index) => (
                  <MenuItem key={index} value={item}>
                    {item}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl className="w-100 mb-3" fullWidth>
              <InputLabel id="demo-select-small-label">Community</InputLabel>
              <Select
                labelId="demo-select-small-label"
                id="demo-select-small"
                label="Community"
                value={community}
                onChange={(e) => setCommunity(e.target.value)}
                {...register("community")}
              >
                <MenuItem value="">Select an option</MenuItem>
                {communities
                  ?.filter((e) => e?.religion === religion)
                  ?.map((item, index) => (
                    <MenuItem value={item?.community} key={index}>
                      {item?.community}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>

            <FormControl className="w-100 mb-3" fullWidth>
              <InputLabel id="demo-select-small-label">Family Type</InputLabel>
              <Select
                labelId="demo-select-small-label"
                id="demo-select-small"
                label="Family Type"
                value={familyType}
                onChange={(e) => setFamilyType(e.target.value)}
              >
                {["Joint Family", "Nuclear Family", "Other"]?.map(
                  (item, index) => (
                    <MenuItem key={index} value={item}>
                      {item}
                    </MenuItem>
                  )
                )}
              </Select>
            </FormControl>

            <FormControl className="w-100 mb-3" fullWidth>
              <InputLabel id="demo-select-small-label">State</InputLabel>
              <Select
                labelId="demo-select-small-label"
                id="demo-select-small"
                label="State"
                value={state}
                onChange={(e) => setState(e.target.value)}
              >
                {[...new Set(stateCityList?.map((e) => e?.State))]?.map(
                  (item, index) => (
                    <MenuItem key={index} value={item}>
                      {item}
                    </MenuItem>
                  )
                )}
              </Select>
            </FormControl>

            <FormControl className="w-100 mb-3" fullWidth>
              <InputLabel id="demo-select-small-label">Job Category</InputLabel>
              <Select
                labelId="demo-select-small-label"
                id="demo-select-small"
                label="Job Category"
                value={jobCategory}
                onChange={(e) => setJobCategory(e.target.value)}
              >
                {jobTitlesList
                  ?.map((e) => e?.name)
                  ?.map((item, index) => (
                    <MenuItem key={index} value={item}>
                      {item}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>

            <FormControl className="w-100 mb-3" fullWidth>
              <InputLabel id="demo-select-small-label">
                Highest Qualification
              </InputLabel>
              <Select
                labelId="demo-select-small-label"
                id="demo-select-small"
                label="Highest Qualification"
                value={qualification}
                onChange={(e) => setQualification(e?.target?.value)}
              >
                {qualificationList
                  ?.map((e) => e?.name)
                  ?.map((item, index) => (
                    <MenuItem key={index} value={item}>
                      {item}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
            <div className="mx-5 mb-3 pt-3 d-flex justify-content-center">
              <button
                className="rounded-3 bg-secondary px-5 py-2"
                type="submit"
              >
                <p className="my-1 fw-medium text-capitalize text-white fs-7 mx-5">
                  {loading ? "Please wait.." : "Save"}
                </p>
              </button>
            </div>
          </form>
        </div>
      </div>

      {pLoading && <div>Processing..</div>}
    </>
  );
}

export default PartnerPreferences;
