import { Navigate, useLocation } from "react-router-dom";
import Header from "./Header";
import Left from "./Left";
import Right from "./Right";
import Matches from "./Matches";
import Activity from "./Activity";
import Search from "./Search";
import Upgrade from "./Upgrade";
import UpdateCareer from "../update/CareerFamily";
import UpdateBasic from "../update/Basic";
import Update from "./Update";
import UpdateImages from "../update/Images";
import Notifications from "./Notifications";
import Details from "../Details";
import Report from "../Report";
import PartnerPreferences from "../update/PartnerPreferences";
import Chat from "./Chat";
import Messages from "./Messages";
import SearchedProfiles from "./searchedProfiles";

function Dashboard() {
  const location = useLocation();
  const pageInformation = location.state?.Information;

  let renderedComponent;
  let renderedComponentName;
  if (pageInformation === "Matches") {
    renderedComponent = <Matches />;
    renderedComponentName = "Matches";
  } else if (pageInformation === "Activity") {
    renderedComponent = <Activity />;
    renderedComponentName = "Activity";
  } else if (pageInformation === "Search") {
    renderedComponent = <Search />;
    renderedComponentName = "Search";
  } else if (pageInformation === "Upgrade") {
    renderedComponent = <Upgrade />;
    renderedComponentName = "Upgrade";
  } else if (pageInformation === "Update") {
    renderedComponent = <Update />;
    renderedComponentName = "Update";
  } else if (pageInformation === "updateBasic") {
    renderedComponent = <UpdateBasic />;
    renderedComponentName = "updateBasic";
  } else if (pageInformation === "updateCareer") {
    renderedComponent = <UpdateCareer />;
    renderedComponentName = "updateCareer";
  } else if (pageInformation === "updateImages") {
    renderedComponent = <UpdateImages />;
    renderedComponentName = "updateImages";
  } else if (pageInformation === "Notifications") {
    renderedComponent = <Notifications />;
    renderedComponentName = "Notifications";
  } else if (pageInformation === "Details") {
    renderedComponent = <Details />;
    renderedComponentName = "Details";
  } else if (pageInformation === "Report") {
    renderedComponent = <Report />;
    renderedComponentName = "Report";
  } else if (pageInformation === "partnerPreferences") {
    renderedComponent = <PartnerPreferences />;
    renderedComponentName = "partnerPreferences";
  } else if (pageInformation === "Chat") {
    renderedComponent = <Chat />;
    renderedComponentName = "Chat";
  } else if (pageInformation === "Messages") {
    renderedComponent = <Messages />;
    renderedComponentName = "Messages";
  } else if (pageInformation === "SearchedProfiles") {
    renderedComponent = <SearchedProfiles />;
    renderedComponentName = "SearchedProfiles";
  } else {
    renderedComponent = <Matches />;
    renderedComponentName = "Matches";
  }

  return (
    <div className="dashboard dashboard-bg">
      <Header />

      <div className="container">
        <div className="row">
          <div className="col-lg-3 col-12 d-lg-block d-none">
            <div className="sticky-top block-margin-top z-1">
              <Left />
            </div>
          </div>
          <div className="col-lg-6 col-12">{renderedComponent}</div>
          <div className="col-lg-3 col-12 d-lg-block d-none">
            <div className="sticky-top block-margin-top z-1">
              <Right />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
