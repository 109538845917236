import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { getGenderProfilesAction } from "../../redux/actions/home/matchesAction";
import { defaultUserImage, server } from "../../server";
import { calculateAge } from "../../utills/calculateAge";
import axios from "axios";
import { toast } from "react-toastify";

function Matches() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { genderProfiles, loading: genderProfileLoading } = useSelector(
    (state) => state?.match
  );
  const { user } = useSelector((state) => state?.profile);

  const [matches, setMatches] = useState(genderProfiles || []);
  const [filteredMatches, setFilteredMatches] = useState(genderProfiles || []);
  const [sync, setSync] = useState(0);
  const [loading, setLoading] = useState(false);
  const [requestPhotoLoading, setRequestPhotoLoading] = useState({});
  const [filters, setFilters] = useState({
    justJoined: false,
    nearby: false,
    neverMarried: false,
    businessSelfEmployed: false,
    profileManagedBySelf: false,
    hasPhoto: false,
  });
  const [activeFilter, setActiveFilter] = useState(null);

  useEffect(() => {
    const getData = async () => {
      let storageProfile = localStorage.getItem("myProfile");

      dispatch(
        getGenderProfilesAction(
          JSON.parse(storageProfile)?.gender
            ? JSON.parse(storageProfile)?.gender
            : user?.gender
        )
      );
    };

    if (matches?.length === 0 || !matches) {
      getData();
      setMatches(genderProfiles);
    }
  }, [sync, user, genderProfiles]);

  useEffect(() => {
    applyFilters(matches);
  }, [filters, matches]);

  const applyFilters = (matchesArray) => {
    if (!Array.isArray(matchesArray)) {
      console.error("Matches is not an array:", matchesArray);
      return;
    }

    let updatedMatches = [...matchesArray];

    if (activeFilter) {
      switch (activeFilter) {
        case "justJoined":
          updatedMatches.sort((a, b) => {
            const dateA = new Date(a?.profile?.[0]?.createdAt);
            const dateB = new Date(b?.profile?.[0]?.createdAt);

            if (isNaN(dateA) || isNaN(dateB)) {
              console.error("Invalid date:", { dateA, dateB });
              return 0;
            }
            return dateA - dateB;
          });
          break;

        case "nearby":
          updatedMatches = updatedMatches.filter(
            (item) => item?.profile?.[0]?.state === user?.profile?.state
          );
          break;

        case "neverMarried":
          updatedMatches = updatedMatches.filter(
            (item) => item?.profile?.[0]?.maritalStatus === "Never Married"
          );
          break;

        case "businessSelfEmployed":
          updatedMatches = updatedMatches.filter(
            (item) =>
              item?.profile?.[0]?.employedIn === "Business / Self Employed"
          );
          break;

        case "profileManagedBySelf":
          updatedMatches = updatedMatches.filter(
            (item) => item?.user?.[0]?.profileFor === "Self"
          );
          break;

        case "hasPhoto":
          updatedMatches = updatedMatches.filter(
            (item) => item?.profile[0]?.images?.length > 0
          );
          break;

        default:
          console.warn("No valid filter selected");
          break;
      }
    }

    setFilteredMatches(updatedMatches);
  };

  const handleFilterToggle = (filterName) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [filterName]: !prevFilters[filterName],
    }));
    setActiveFilter(filterName);
  };

  const handleRequestPhoto = async (item) => {
    console.log("Hello", item);
    try {
      setRequestPhotoLoading({ userId: item?.user?.[0]?._id, loading: true });
      const token = localStorage.getItem("token");
      const { data } = await axios.post(
        `${server}/requestPhoto/newRequest/${item?.user?.[0]?._id}`,
        null,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      setRequestPhotoLoading({ userId: item?.user?.[0]?._id, loading: false });
      // Update matches state
      let newMatches = [...matches]?.map((e) =>
        e?.user?.[0]?._id === item?.user?.[0]?._id
          ? { ...e, requestPhoto: [data?.data] }
          : e
      );

      setMatches(newMatches);
      dispatch({
        type: "GET_GENDER_PROFILES_SUCCESS",
        payload: { genderProfiles: newMatches },
      });
    } catch (e) {
      console.log(e?.response?.data?.message || e?.message);
      setRequestPhotoLoading({ userId: item?.user?.[0]?._id, loading: false });
      toast.error(e?.response?.data?.message || e?.message);
    }
  };

  // Navigate to chat page
  const handleNavigateToChatPage = async (item) => {
    const formData = {
      sender: user?._id,
      receiver: item?.user?.[0]?._id,
    };

    try {
      const token = localStorage.getItem("token");
      const { data } = await axios.post(
        `${server}/conversation/new`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      navigate("./", {
        state: {
          Information: "Chat",
          selectedUser: { ...item?.user?.[0], profile: item?.profile?.[0] },
        },
      });
    } catch (e) {
      toast.error(e?.response?.data?.message || e?.message);
    }
  };

  return (
    <>
      {/* Filter Button */}
      <div className="sticky-top block-margin-top dashboard-bg rounded-bottom-4 z-1">
        <div className="py-3 bg-white rounded-4 block-border">
          <div className="d-flex overflow-x-scroll ps-4 pe-3">
            <div
              className={`px-4 py-2 fs-8 button-border text-nowrap rounded-pill text-black cursor-pointer me-2 ${
                activeFilter === "justJoined" ? "crimson-button" : ""
              }`}
              type="button"
              onClick={() => handleFilterToggle("justJoined")}
            >
              Just Joined
            </div>
            <div
              className={`px-4 py-2 fs-8 button-border text-nowrap rounded-pill text-black cursor-pointer me-2 ${
                activeFilter === "nearby" ? "crimson-button" : ""
              }`}
              type="button"
              onClick={() => handleFilterToggle("nearby")}
            >
              Nearby
            </div>
            <div
              className={`px-4 py-2 fs-8 button-border text-nowrap rounded-pill text-black cursor-pointer me-2 ${
                activeFilter === "hasPhoto" ? "crimson-button" : ""
              }`}
              onClick={() => handleFilterToggle("hasPhoto")}
            >
              Having Photo in Profile
            </div>
            <div
              className={`px-4 py-2 fs-8 button-border text-nowrap rounded-pill text-black cursor-pointer me-2 ${
                activeFilter === "neverMarried" ? "crimson-button" : ""
              }`}
              onClick={() => handleFilterToggle("neverMarried")}
            >
              Never Married
            </div>
            <div
              className={`px-4 py-2 fs-8 button-border text-nowrap rounded-pill text-black cursor-pointer me-2 ${
                activeFilter === "businessSelfEmployed" ? "crimson-button" : ""
              }`}
              onClick={() => handleFilterToggle("businessSelfEmployed")}
            >
              Business / Self Employed
            </div>
            <div
              className={`px-4 py-2 fs-8 button-border text-nowrap rounded-pill text-black cursor-pointer me-2 ${
                activeFilter === "profileManagedBySelf" ? "crimson-button" : ""
              }`}
              onClick={() => handleFilterToggle("profileManagedBySelf")}
            >
              Profile Created / Managed by Self
            </div>
          </div>
        </div>
      </div>

      {/* Matches List */}
      <div>
        <div className="my-3">
          {filteredMatches?.map((item, index) => (
            <div
              className="block-border bg-white rounded-4 overflow-hidden mb-lg-2 mb-4"
              key={index}
            >
              <div className="row g-0">
                <div className="col-lg-4 col-md-5 col-12">
                  <div className="h-100">
                    <div className="position-relative w-100 d-flex justify-content-end">
                      <img
                        src={
                          item?.profile?.[0]?.images?.[0]?.docUrl ||
                          defaultUserImage
                        }
                        height="270"
                        className="w-100 object-fit-cover object-position-top"
                      />
                      {item?.profile[0]?.images?.length > 0 && (
                        <div
                          className="position-absolute profile-image-icon cursor-pointer"
                          onClick={() =>
                            navigate("./profile-images", {
                              state: item?.profile[0]?.images,
                            })
                          }
                        >
                          <i className="bi bi-images text-white"></i>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-lg-8 col-md-7 col-12">
                  <div className="bg-white h-100 d-flex flex-column justify-content-between">
                    <div className="py-3 px-3 mt-3">
                      <div>
                        <p className="m-0 fs-8 text-secondary">{`GRSTY${item?.user?.[0]?.customId}`}</p>
                        <h5
                          className="m-0 fs-6 text-uppercase fw-medium d-flex align-items-center"
                          onClick={() =>
                            navigate("./", {
                              state: {
                                Information: "Details",
                                profileData: item,
                              },
                            })
                          }
                          type="button"
                        >
                          {item?.user?.[0]?.name},{" "}
                          {calculateAge(item?.profile?.[0]?.dob)}
                          <i className="bi bi-patch-check-fill ms-2 tick-verified-icon"></i>
                        </h5>
                        <p className="m-0 fs-8 text-secondary">
                          {item?.profile?.[0]?.height}
                          <span className="mx-2">•</span>
                          {item?.profile?.[0]?.city}
                          <span className="mx-2">•</span>
                          {item?.profile?.[0]?.community}
                        </p>
                      </div>
                      <div className="d-flex align-items-center mt-2 profile-details-line">
                        <div className="d-flex align-items-center">
                          <i className="bi bi-briefcase text-secondary me-2"></i>
                          <p className="m-0 fs-8 text-secondary">
                            {item?.profile?.[0]?.jobTitle || "Not Working"}
                          </p>
                        </div>
                      </div>
                      <div className="d-flex align-items-center profile-details-line">
                        <div className="d-flex align-items-center">
                          <i className="bi bi-wallet2 text-secondary me-2"></i>
                          <p className="m-0 fs-8 text-secondary">
                            {item?.profile?.[0]?.annualIncome || "No Income"}
                          </p>
                        </div>
                      </div>
                      <div className="d-flex align-items-center profile-details-line">
                        <div className="d-flex align-items-center">
                          <p className="m-0 fs-8 text-secondary">
                            {item?.profile?.[0]?.highestDegree}
                          </p>
                        </div>
                        <span className="mx-2">•</span>
                        <div className="d-flex align-items-center">
                          <p className="m-0 fs-8 text-secondary">
                            {item?.profile?.[0]?.maritalStatus}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="p-3 light-crimson-bg">
                      <div className="row">
                        {item?.profile[0]?.images?.length === 0 && (
                          <div
                            className="col-4 text-center"
                            onClick={() => handleRequestPhoto(item)}
                            type="button"
                          >
                            <p className="fs-8 text-crimson">
                              <i className="bi bi-image-alt"></i> Request Photo
                            </p>
                          </div>
                        )}
                        <div
                          className="col-4 text-center"
                          onClick={() =>
                            navigate("./", {
                              state: {
                                Information: "Details",
                                profileData: item,
                              },
                            })
                          }
                          type="button"
                        >
                          <p className="fs-8 text-crimson">
                            <i className="bi bi-eye"></i> View Profile
                          </p>
                        </div>
                        <div className="col-4 text-center">
                          <p
                            className="fs-8 text-crimson cursor-pointer"
                            onClick={() => handleNavigateToChatPage(item)}
                          >
                            <i className="bi bi-chat"></i> Chat
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}

          {(filteredMatches?.length === 0 || !filteredMatches) &&
            !loading &&
            !genderProfileLoading && (
              <div className="text-center mt-5">Oops! Profile not found!</div>
            )}

          {(loading || genderProfileLoading) && (
            <div className="d-flex justify-content-center mt-5">
              <div
                className="spinner-border text-secondary"
                role="status"
              ></div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default Matches;
