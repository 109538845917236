import { TextField } from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Select from "@mui/material/Select";
import PageHeader from "../../components/PageHeader";
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import axios from "axios";
import { server } from "../../server";
import { jobTitlesList } from "../../jsonFiles/jobTitlesList";
import { qualificationList } from "../../jsonFiles/qualificationList";
import { useSelector } from "react-redux";

function UpdateCareer() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  
  const { user } = useSelector((state) => state?.profile);
  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm();
  const [working, setWorking] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleSubmitData = async (data) => {
    setLoading(true);
    const formData = {
      highestDegree: data?.highestDegree,
      jobTitle: data?.jobTitle,
      employedIn: data?.employedIn,
      annualIncome: data?.annualIncome,
      familyType: data?.familyType,
      fatherStatus: data?.fatherStatus,
      motherStatus: data?.motherStatus,
      noOfBrothers: data?.noOfBrothers,
      noOfSisters: data?.noOfSisters,
      familyLivingIn: data?.familyLivingIn,
      //
      qualification: selectedQualification,
      jobCategory: selectedJobCategory,
    };

    try {
      const { data } = await axios.put(`${server}/profile/update`, formData, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      setLoading(false);
      toast.success("Career Family updated successfully!");
    } catch (e) {
      setLoading(false);
      toast.error(e?.response?.data?.message || e?.message);
    }
  };

  const [selectedQualification, setSelectedQualification] = useState(
    qualificationList.reduce((result, currentItem) => {
      // Find the category item within the current item's category array
      const foundCategory = currentItem.category.find(
        (category) => category === user?.profile?.highestDegree
      );
      if (foundCategory) {
        result = currentItem.name;
      }
      return result;
    }, "")
  );

  const [selectedJobCategory, setSelectedJobCategory] = useState(
    jobTitlesList.reduce((result, currentItem) => {
      // Find the category item within the current item's category array
      const foundCategory = currentItem.category.find(
        (category) => category === user?.profile?.jobTitle
      );
      if (foundCategory) {
        result = currentItem.name;
      }
      return result;
    }, "")
  );

  return (
    <>
      <div className="block-margin-top mb-4">
        <div className="py-4 bg-white rounded-4 block-border">
          <PageHeader name="Career & Family" />

          <form className="px-5" onSubmit={handleSubmit(handleSubmitData)}>
            <div className="mb-4">
              <h5 className="fs-6 fw-medium text-secondary">EDUCATION</h5>
            </div>
            <FormControl className="w-100 mb-3" fullWidth>
              <InputLabel id="demo-select-small-label">
                Highest Qualification
              </InputLabel>
              <Select
                labelId="demo-select-small-label"
                id="demo-select-small"
                label="Highest Qualification"
                value={selectedQualification}
                onChange={(e) => setSelectedQualification(e?.target?.value)}
                error={errors?.highestQualification}
              >
                {qualificationList
                  ?.map((e) => e?.name)
                  ?.map((item, index) => (
                    <MenuItem key={index} value={item}>
                      {item}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
            <FormControl className="w-100 mb-3" fullWidth>
              <InputLabel id="demo-select-small-label">
                Highest Degree
              </InputLabel>
              <Select
                labelId="demo-select-small-label"
                id="demo-select-small"
                label="Highest Degree"
                defaultValue={user?.profile?.highestDegree}
                {...register("highestDegree", { required: true })}
                error={errors?.highestDegree}
              >
                {qualificationList
                  ?.find((e) => e?.name === selectedQualification)
                  ?.category?.map((item, index) => (
                    <MenuItem key={index} value={item}>
                      {item}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>

            <div className="mt-4">
              <h5 className="fs-6 fw-medium text-secondary">CAREER</h5>
            </div>

            <FormControlLabel
              className="mb-4"
              control={<Checkbox />}
              label="Not Working"
              defaultChecked={user?.profile?.jobCategory ? false : true}
              checked={!working}
              onChange={(e) => setWorking(!e.target.checked)}
            />

            {working && (
              <div>
                <FormControl className="w-100 mb-3" fullWidth>
                  <InputLabel id="demo-select-small-label">
                    Job Category
                  </InputLabel>
                  <Select
                    labelId="demo-select-small-label"
                    id="demo-select-small"
                    label="Job Category"
                    value={selectedJobCategory}
                    defaultValue={user?.profile?.jobCategory}
                    onChange={(e) => setSelectedJobCategory(e.target.value)}
                    error={errors?.jobCategory}
                  >
                    {jobTitlesList
                      ?.map((e) => e?.name)
                      ?.map((item, index) => (
                        <MenuItem key={index} value={item}>
                          {item}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>

                <FormControl className="w-100 mb-3" fullWidth>
                  <InputLabel id="demo-select-small-label">
                    Job Title
                  </InputLabel>
                  <Select
                    labelId="demo-select-small-label"
                    id="demo-select-small"
                    label="Job Title"
                    defaultValue={user?.profile?.jobTitle}
                    {...register("jobTitle", { required: true })}
                    error={errors?.jobTitle}
                  >
                    {jobTitlesList
                      ?.find((e) => e?.name === selectedJobCategory)
                      ?.category?.map((item, index) => (
                        <MenuItem key={index} value={item}>
                          {item}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>

                <FormControl className="w-100 mb-3" fullWidth>
                  <InputLabel id="demo-select-small-label">
                    Employed In
                  </InputLabel>
                  <Select
                    labelId="demo-select-small-label"
                    id="demo-select-small"
                    label="Employed In"
                    defaultValue={user?.profile?.employedIn}
                    {...register("employedIn", { required: true })}
                    error={errors?.employedIn}
                  >
                    {[
                      "Private Company",
                      "Government / Public Sector",
                      "Defense / Civil Services ",
                      "Business / Self Employed",
                      "Not Working",
                    ]?.map((item, index) => (
                      <MenuItem key={index} value={item}>
                        {item}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                <FormControl className="w-100 mb-3" fullWidth>
                  <InputLabel id="demo-select-small-label">
                    Annual Income
                  </InputLabel>
                  <Select
                    labelId="demo-select-small-label"
                    id="demo-select-small"
                    label="Annual Income"
                    defaultValue={user?.profile?.annualIncome}
                    {...register("annualIncome", { required: true })}
                    error={errors?.annualIncome}
                  >
                    {[
                      "Rs. 0 - 1 Lakh",
                      "Rs. 1 - 2 Lakh",
                      "Rs. 2 - 3 Lakh",
                      "Rs. 3 - 4 Lakh",
                      "Rs. 4 - 5 Lakh",
                      "Rs. 5 - 7.5 Lakh",
                      "Rs. 7.5 - 10 Lakh",
                      "Rs. 10 - 15 Lakh",
                      "Rs. 15 - 20 Lakh",
                      "Rs. 20 - 25 Lakh",
                      "Rs. 25 - 35 Lakh",
                      "Rs. 35 - 50 Lakh",
                      "Rs. 50 - 70 Lakh",
                      "Rs. 70 Lakh - 1 crore",
                      "Rs. 1 crore & above",
                    ]?.map((item, index) => (
                      <MenuItem key={index} value={item}>
                        {item}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
            )}
            <div className="my-4">
              <h5 className="fs-6 fw-medium text-secondary">FAMILY</h5>
            </div>

            <FormControl className="w-100 mb-3" fullWidth>
              <InputLabel id="demo-select-small-label">Family Type</InputLabel>
              <Select
                labelId="demo-select-small-label"
                id="demo-select-small"
                label="Family Type"
                defaultValue={user?.profile?.familyType}
                {...register("familyType", { required: true })}
                error={errors?.familyType}
              >
                {["Joint Family", "Nuclear Family", "Other"]?.map(
                  (item, index) => (
                    <MenuItem key={index} value={item}>
                      {item}
                    </MenuItem>
                  )
                )}
              </Select>
            </FormControl>
            <FormControl className="w-100 mb-3" fullWidth>
              <InputLabel id="demo-select-small-label">
                Father Status
              </InputLabel>
              <Select
                labelId="demo-select-small-label"
                id="demo-select-small"
                label="Father Status"
                defaultValue={user?.profile?.fatherStatus}
                {...register("fatherStatus", { required: true })}
                error={errors?.fatherStatus}
              >
                {[
                  "Employed",
                  "Retired",
                  "Business",
                  "Not Employed",
                  "Passed Away",
                ]?.map((item, index) => (
                  <MenuItem key={index} value={item}>
                    {item}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl className="w-100 mb-3" fullWidth>
              <InputLabel id="demo-select-small-label">
                Mother Status
              </InputLabel>
              <Select
                labelId="demo-select-small-label"
                id="demo-select-small"
                label="Mother Status"
                defaultValue={user?.profile?.motherStatus}
                {...register("motherStatus", { required: true })}
                error={errors?.motherStatus}
              >
                {[
                  "Homemaker",
                  "Employed",
                  "Retired",
                  "Business",
                  "Passed Away",
                ]?.map((item, index) => (
                  <MenuItem key={index} value={item}>
                    {item}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <TextField
              id="outlined-basic"
              label="Family Living In (City)"
              variant="outlined"
              className="w-100 mb-3"
              defaultValue={user?.profile?.familyLivingIn}
              {...register("familyLivingIn", { required: true })}
              error={errors?.familyLivingIn}
            />
            <FormControl className="w-100 mb-3" fullWidth>
              <InputLabel id="demo-select-small-label">
                No. Of Brothers
              </InputLabel>
              <Select
                labelId="demo-select-small-label"
                id="demo-select-small"
                label="No. Of Brothers"
                defaultValue={user?.profile?.noOfBrothers}
                {...register("noOfBrothers", { required: true })}
                error={errors?.noOfBrothers}
              >
                {["0", "1", "2", "3", "4", "5 +"]?.map((item, index) => (
                  <MenuItem key={index} value={item}>
                    {item}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl className="w-100 mb-3" fullWidth>
              <InputLabel id="demo-select-small-label">
                No. Of Sisters
              </InputLabel>
              <Select
                labelId="demo-select-small-label"
                id="demo-select-small"
                label="No. Of Sisters"
                defaultValue={user?.profile?.noOfSisters}
                {...register("noOfSisters", { required: true })}
                error={errors?.noOfSisters}
              >
                {["0", "1", "2", "3", "4", "5 +"]?.map((item, index) => (
                  <MenuItem key={index} value={item}>
                    {item}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <div className="mx-5 mb-3 pt-3 d-flex justify-content-center">
              <button
                type="submit"
                className="rounded-3 bg-secondary px-5 py-2"
                disabled={loading}
              >
                <p className="my-1 fw-medium text-capitalize text-white fs-7 mx-5">
                  {loading ? "Please wait.." : "Save"}
                </p>
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

export default UpdateCareer;
