import axios from "axios";
import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

export default function Payment() {
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState("processing");
  const { merchantTransactionId } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const postRequest = async () => {
      setLoading(true);
      try {
        const { data } = await axios.post(
          `https://gorishtey.cloud/api/gorishtey/v1/payment/status/${merchantTransactionId}`
        );
        setSuccess("success");
        setLoading(false);
      } catch (e) {
        setSuccess("failed");
        setLoading(false);
      }
    };

    postRequest();
  }, []);

  return (
    <div className="container vh-100 overflow-hidden d-flex flex-column justify-content-center px-0">
      <div className="col-12 text-center">
        <h5>Payment</h5>
        <img src="assets/images/logo.png" height="40" />
      </div>
      <div className="mt-5">{loading && "Verifying Payment"}</div>

      {success === "success" && (
        <div className="row">
          <div className="col-12 text-center">
            <div className="mt-2">
              <div className="text-center">
                <h1 className="display-1 fw-bold">
                  🎉
                  <br />
                  Success
                </h1>
                <p className="mt-2">
                  Congratulations! Your payment on GoRishtey.com was
                  successful. Enjoy premium access to our exclusive features.
                </p>

                <p className="mt-2">Please logged out and login again</p>
              </div>
            </div>
          </div>
        </div>
      )}

      {success === "failed" && (
        <div className="row">
          <div className="col-12 text-center">
            <div className="mt-2">
              <div className="text-center">
                <h1 className="display-1 fw-bold">
                  😔
                  <br />
                  Failed
                </h1>
                <p className="mt-2">
                  Oops! Your payment on GoRishtey.com encountered an issue.
                  Please double-check your payment details & try again.
                </p>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
