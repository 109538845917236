export const stateCityList = [
  {
    Id: "1",
    State: "Maharashtra",
    City: "Kolhapur",
  },
  {
    Id: "2",
    State: "Andaman & Nicobar Islands",
    City: "Port Blair",
  },
  {
    Id: "3",
    State: "Andhra Pradesh",
    City: "Adilabad",
  },
  {
    Id: "4",
    State: "Andhra Pradesh",
    City: "Adoni",
  },
  {
    Id: "5",
    State: "Andhra Pradesh",
    City: "Amadalavalasa",
  },
  {
    Id: "6",
    State: "Andhra Pradesh",
    City: "Amalapuram",
  },
  {
    Id: "7",
    State: "Andhra Pradesh",
    City: "Anakapalle",
  },
  {
    Id: "8",
    State: "Andhra Pradesh",
    City: "Anantapur",
  },
  {
    Id: "9",
    State: "Andhra Pradesh",
    City: "Badepalle",
  },
  {
    Id: "10",
    State: "Andhra Pradesh",
    City: "Banganapalle",
  },
  {
    Id: "11",
    State: "Andhra Pradesh",
    City: "Bapatla",
  },
  {
    Id: "12",
    State: "Andhra Pradesh",
    City: "Bellampalle",
  },
  {
    Id: "13",
    State: "Andhra Pradesh",
    City: "Bethamcherla",
  },
  {
    Id: "14",
    State: "Andhra Pradesh",
    City: "Bhadrachalam",
  },
  {
    Id: "15",
    State: "Andhra Pradesh",
    City: "Bhainsa",
  },
  {
    Id: "16",
    State: "Andhra Pradesh",
    City: "Bheemunipatnam",
  },
  {
    Id: "17",
    State: "Andhra Pradesh",
    City: "Bhimavaram",
  },
  {
    Id: "18",
    State: "Andhra Pradesh",
    City: "Bhongir",
  },
  {
    Id: "19",
    State: "Andhra Pradesh",
    City: "Bobbili",
  },
  {
    Id: "20",
    State: "Andhra Pradesh",
    City: "Bodhan",
  },
  {
    Id: "21",
    State: "Andhra Pradesh",
    City: "Chilakaluripet",
  },
  {
    Id: "22",
    State: "Andhra Pradesh",
    City: "Chirala",
  },
  {
    Id: "23",
    State: "Andhra Pradesh",
    City: "Chittoor",
  },
  {
    Id: "24",
    State: "Andhra Pradesh",
    City: "Cuddapah",
  },
  {
    Id: "25",
    State: "Andhra Pradesh",
    City: "Devarakonda",
  },
  {
    Id: "26",
    State: "Andhra Pradesh",
    City: "Dharmavaram",
  },
  {
    Id: "27",
    State: "Andhra Pradesh",
    City: "Eluru",
  },
  {
    Id: "28",
    State: "Andhra Pradesh",
    City: "Farooqnagar",
  },
  {
    Id: "29",
    State: "Andhra Pradesh",
    City: "Gadwal",
  },
  {
    Id: "30",
    State: "Andhra Pradesh",
    City: "Gooty",
  },
  {
    Id: "31",
    State: "Andhra Pradesh",
    City: "Gudivada",
  },
  {
    Id: "32",
    State: "Andhra Pradesh",
    City: "Gudur",
  },
  {
    Id: "33",
    State: "Andhra Pradesh",
    City: "Guntakal",
  },
  {
    Id: "34",
    State: "Andhra Pradesh",
    City: "Guntur",
  },
  {
    Id: "35",
    State: "Andhra Pradesh",
    City: "Hanuman Junction",
  },
  {
    Id: "36",
    State: "Andhra Pradesh",
    City: "Hindupur",
  },
  {
    Id: "37",
    State: "Andhra Pradesh",
    City: "Hyderabad",
  },
  {
    Id: "38",
    State: "Andhra Pradesh",
    City: "Ichchapuram",
  },
  {
    Id: "39",
    State: "Andhra Pradesh",
    City: "Jaggaiahpet",
  },
  {
    Id: "40",
    State: "Andhra Pradesh",
    City: "Jagtial",
  },
  {
    Id: "41",
    State: "Andhra Pradesh",
    City: "Jammalamadugu",
  },
  {
    Id: "42",
    State: "Andhra Pradesh",
    City: "Jangaon",
  },
  {
    Id: "43",
    State: "Andhra Pradesh",
    City: "Kadapa",
  },
  {
    Id: "44",
    State: "Andhra Pradesh",
    City: "Kadiri",
  },
  {
    Id: "45",
    State: "Andhra Pradesh",
    City: "Kagaznagar",
  },
  {
    Id: "46",
    State: "Andhra Pradesh",
    City: "Kakinada",
  },
  {
    Id: "47",
    State: "Andhra Pradesh",
    City: "Kalyandurg",
  },
  {
    Id: "48",
    State: "Andhra Pradesh",
    City: "Kamareddy",
  },
  {
    Id: "49",
    State: "Andhra Pradesh",
    City: "Kandukur",
  },
  {
    Id: "50",
    State: "Andhra Pradesh",
    City: "Karimnagar",
  },
  {
    Id: "51",
    State: "Andhra Pradesh",
    City: "Kavali",
  },
  {
    Id: "52",
    State: "Andhra Pradesh",
    City: "Khammam",
  },
  {
    Id: "53",
    State: "Andhra Pradesh",
    City: "Koratla",
  },
  {
    Id: "54",
    State: "Andhra Pradesh",
    City: "Kothagudem",
  },
  {
    Id: "55",
    State: "Andhra Pradesh",
    City: "Kothapeta",
  },
  {
    Id: "56",
    State: "Andhra Pradesh",
    City: "Kovvur",
  },
  {
    Id: "57",
    State: "Andhra Pradesh",
    City: "Kurnool",
  },
  {
    Id: "58",
    State: "Andhra Pradesh",
    City: "Kyathampalle",
  },
  {
    Id: "59",
    State: "Andhra Pradesh",
    City: "Macherla",
  },
  {
    Id: "60",
    State: "Andhra Pradesh",
    City: "Machilipatnam",
  },
  {
    Id: "61",
    State: "Andhra Pradesh",
    City: "Madanapalle",
  },
  {
    Id: "62",
    State: "Andhra Pradesh",
    City: "Mahbubnagar",
  },
  {
    Id: "63",
    State: "Andhra Pradesh",
    City: "Mancherial",
  },
  {
    Id: "64",
    State: "Andhra Pradesh",
    City: "Mandamarri",
  },
  {
    Id: "65",
    State: "Andhra Pradesh",
    City: "Mandapeta",
  },
  {
    Id: "66",
    State: "Andhra Pradesh",
    City: "Manuguru",
  },
  {
    Id: "67",
    State: "Andhra Pradesh",
    City: "Markapur",
  },
  {
    Id: "68",
    State: "Andhra Pradesh",
    City: "Medak",
  },
  {
    Id: "69",
    State: "Andhra Pradesh",
    City: "Miryalaguda",
  },
  {
    Id: "70",
    State: "Andhra Pradesh",
    City: "Mogalthur",
  },
  {
    Id: "71",
    State: "Andhra Pradesh",
    City: "Nagari",
  },
  {
    Id: "72",
    State: "Andhra Pradesh",
    City: "Nagarkurnool",
  },
  {
    Id: "73",
    State: "Andhra Pradesh",
    City: "Nandyal",
  },
  {
    Id: "74",
    State: "Andhra Pradesh",
    City: "Narasapur",
  },
  {
    Id: "75",
    State: "Andhra Pradesh",
    City: "Narasaraopet",
  },
  {
    Id: "76",
    State: "Andhra Pradesh",
    City: "Narayanpet",
  },
  {
    Id: "77",
    State: "Andhra Pradesh",
    City: "Narsipatnam",
  },
  {
    Id: "78",
    State: "Andhra Pradesh",
    City: "Nellore",
  },
  {
    Id: "79",
    State: "Andhra Pradesh",
    City: "Nidadavole",
  },
  {
    Id: "80",
    State: "Andhra Pradesh",
    City: "Nirmal",
  },
  {
    Id: "81",
    State: "Andhra Pradesh",
    City: "Nizamabad",
  },
  {
    Id: "82",
    State: "Andhra Pradesh",
    City: "Nuzvid",
  },
  {
    Id: "83",
    State: "Andhra Pradesh",
    City: "Ongole",
  },
  {
    Id: "84",
    State: "Andhra Pradesh",
    City: "Palacole",
  },
  {
    Id: "85",
    State: "Andhra Pradesh",
    City: "Palasa Kasibugga",
  },
  {
    Id: "86",
    State: "Andhra Pradesh",
    City: "Palwancha",
  },
  {
    Id: "87",
    State: "Andhra Pradesh",
    City: "Parvathipuram",
  },
  {
    Id: "88",
    State: "Andhra Pradesh",
    City: "Pedana",
  },
  {
    Id: "89",
    State: "Andhra Pradesh",
    City: "Peddapuram",
  },
  {
    Id: "90",
    State: "Andhra Pradesh",
    City: "Pithapuram",
  },
  {
    Id: "91",
    State: "Andhra Pradesh",
    City: "Pondur",
  },
  {
    Id: "92",
    State: "Andhra Pradesh",
    City: "Ponnur",
  },
  {
    Id: "93",
    State: "Andhra Pradesh",
    City: "Proddatur",
  },
  {
    Id: "94",
    State: "Andhra Pradesh",
    City: "Punganur",
  },
  {
    Id: "95",
    State: "Andhra Pradesh",
    City: "Puttur",
  },
  {
    Id: "96",
    State: "Andhra Pradesh",
    City: "Rajahmundry",
  },
  {
    Id: "97",
    State: "Andhra Pradesh",
    City: "Rajam",
  },
  {
    Id: "98",
    State: "Andhra Pradesh",
    City: "Ramachandrapuram",
  },
  {
    Id: "99",
    State: "Andhra Pradesh",
    City: "Ramagundam",
  },
  {
    Id: "100",
    State: "Andhra Pradesh",
    City: "Rayachoti",
  },
  {
    Id: "101",
    State: "Andhra Pradesh",
    City: "Rayadurg",
  },
  {
    Id: "102",
    State: "Andhra Pradesh",
    City: "Renigunta",
  },
  {
    Id: "103",
    State: "Andhra Pradesh",
    City: "Repalle",
  },
  {
    Id: "104",
    State: "Andhra Pradesh",
    City: "Sadasivpet",
  },
  {
    Id: "105",
    State: "Andhra Pradesh",
    City: "Salur",
  },
  {
    Id: "106",
    State: "Andhra Pradesh",
    City: "Samalkot",
  },
  {
    Id: "107",
    State: "Andhra Pradesh",
    City: "Sangareddy",
  },
  {
    Id: "108",
    State: "Andhra Pradesh",
    City: "Sattenapalle",
  },
  {
    Id: "109",
    State: "Andhra Pradesh",
    City: "Siddipet",
  },
  {
    Id: "110",
    State: "Andhra Pradesh",
    City: "Singapur",
  },
  {
    Id: "111",
    State: "Andhra Pradesh",
    City: "Sircilla",
  },
  {
    Id: "112",
    State: "Andhra Pradesh",
    City: "Srikakulam",
  },
  {
    Id: "113",
    State: "Andhra Pradesh",
    City: "Srikalahasti",
  },
  {
    Id: "115",
    State: "Andhra Pradesh",
    City: "Suryapet",
  },
  {
    Id: "116",
    State: "Andhra Pradesh",
    City: "Tadepalligudem",
  },
  {
    Id: "117",
    State: "Andhra Pradesh",
    City: "Tadpatri",
  },
  {
    Id: "118",
    State: "Andhra Pradesh",
    City: "Tandur",
  },
  {
    Id: "119",
    State: "Andhra Pradesh",
    City: "Tanuku",
  },
  {
    Id: "120",
    State: "Andhra Pradesh",
    City: "Tenali",
  },
  {
    Id: "121",
    State: "Andhra Pradesh",
    City: "Tirupati",
  },
  {
    Id: "122",
    State: "Andhra Pradesh",
    City: "Tuni",
  },
  {
    Id: "123",
    State: "Andhra Pradesh",
    City: "Uravakonda",
  },
  {
    Id: "124",
    State: "Andhra Pradesh",
    City: "Venkatagiri",
  },
  {
    Id: "125",
    State: "Andhra Pradesh",
    City: "Vicarabad",
  },
  {
    Id: "126",
    State: "Andhra Pradesh",
    City: "Vijayawada",
  },
  {
    Id: "127",
    State: "Andhra Pradesh",
    City: "Vinukonda",
  },
  {
    Id: "128",
    State: "Andhra Pradesh",
    City: "Visakhapatnam",
  },
  {
    Id: "129",
    State: "Andhra Pradesh",
    City: "Vizianagaram",
  },
  {
    Id: "130",
    State: "Andhra Pradesh",
    City: "Wanaparthy",
  },
  {
    Id: "131",
    State: "Andhra Pradesh",
    City: "Warangal",
  },
  {
    Id: "132",
    State: "Andhra Pradesh",
    City: "Yellandu",
  },
  {
    Id: "133",
    State: "Andhra Pradesh",
    City: "Yemmiganur",
  },
  {
    Id: "134",
    State: "Andhra Pradesh",
    City: "Yerraguntla",
  },
  {
    Id: "135",
    State: "Andhra Pradesh",
    City: "Zahirabad",
  },
  {
    Id: "136",
    State: "Andhra Pradesh",
    City: "Rajampet",
  },
  {
    Id: "137",
    State: "Arunachal Pradesh",
    City: "Along",
  },
  {
    Id: "138",
    State: "Arunachal Pradesh",
    City: "Bomdila",
  },
  {
    Id: "139",
    State: "Arunachal Pradesh",
    City: "Itanagar",
  },
  {
    Id: "140",
    State: "Arunachal Pradesh",
    City: "Naharlagun",
  },
  {
    Id: "141",
    State: "Arunachal Pradesh",
    City: "Pasighat",
  },
  {
    Id: "142",
    State: "Assam",
    City: "Abhayapuri",
  },
  {
    Id: "143",
    State: "Assam",
    City: "Amguri",
  },
  {
    Id: "144",
    State: "Assam",
    City: "Anandnagaar",
  },
  {
    Id: "145",
    State: "Assam",
    City: "Barpeta",
  },
  {
    Id: "146",
    State: "Assam",
    City: "Barpeta Road",
  },
  {
    Id: "147",
    State: "Assam",
    City: "Bilasipara",
  },
  {
    Id: "148",
    State: "Assam",
    City: "Bongaigaon",
  },
  {
    Id: "149",
    State: "Assam",
    City: "Dhekiajuli",
  },
  {
    Id: "150",
    State: "Assam",
    City: "Dhubri",
  },
  {
    Id: "151",
    State: "Assam",
    City: "Dibrugarh",
  },
  {
    Id: "152",
    State: "Assam",
    City: "Digboi",
  },
  {
    Id: "153",
    State: "Assam",
    City: "Diphu",
  },
  {
    Id: "154",
    State: "Assam",
    City: "Dispur",
  },
  {
    Id: "156",
    State: "Assam",
    City: "Gauripur",
  },
  {
    Id: "157",
    State: "Assam",
    City: "Goalpara",
  },
  {
    Id: "158",
    State: "Assam",
    City: "Golaghat",
  },
  {
    Id: "159",
    State: "Assam",
    City: "Guwahati",
  },
  {
    Id: "160",
    State: "Assam",
    City: "Haflong",
  },
  {
    Id: "161",
    State: "Assam",
    City: "Hailakandi",
  },
  {
    Id: "162",
    State: "Assam",
    City: "Hojai",
  },
  {
    Id: "163",
    State: "Assam",
    City: "Jorhat",
  },
  {
    Id: "164",
    State: "Assam",
    City: "Karimganj",
  },
  {
    Id: "165",
    State: "Assam",
    City: "Kokrajhar",
  },
  {
    Id: "166",
    State: "Assam",
    City: "Lanka",
  },
  {
    Id: "167",
    State: "Assam",
    City: "Lumding",
  },
  {
    Id: "168",
    State: "Assam",
    City: "Mangaldoi",
  },
  {
    Id: "169",
    State: "Assam",
    City: "Mankachar",
  },
  {
    Id: "170",
    State: "Assam",
    City: "Margherita",
  },
  {
    Id: "171",
    State: "Assam",
    City: "Mariani",
  },
  {
    Id: "172",
    State: "Assam",
    City: "Marigaon",
  },
  {
    Id: "173",
    State: "Assam",
    City: "Nagaon",
  },
  {
    Id: "174",
    State: "Assam",
    City: "Nalbari",
  },
  {
    Id: "175",
    State: "Assam",
    City: "North Lakhimpur",
  },
  {
    Id: "176",
    State: "Assam",
    City: "Rangia",
  },
  {
    Id: "177",
    State: "Assam",
    City: "Sibsagar",
  },
  {
    Id: "178",
    State: "Assam",
    City: "Silapathar",
  },
  {
    Id: "179",
    State: "Assam",
    City: "Silchar",
  },
  {
    Id: "180",
    State: "Assam",
    City: "Tezpur",
  },
  {
    Id: "181",
    State: "Assam",
    City: "Tinsukia",
  },
  {
    Id: "182",
    State: "Bihar",
    City: "Amarpur",
  },
  {
    Id: "183",
    State: "Bihar",
    City: "Araria",
  },
  {
    Id: "184",
    State: "Bihar",
    City: "Areraj",
  },
  {
    Id: "185",
    State: "Bihar",
    City: "Arrah",
  },
  {
    Id: "186",
    State: "Bihar",
    City: "Asarganj",
  },
  {
    Id: "187",
    State: "Bihar",
    City: "Aurangabad",
  },
  {
    Id: "188",
    State: "Bihar",
    City: "Bagaha",
  },
  {
    Id: "189",
    State: "Bihar",
    City: "Bahadurganj",
  },
  {
    Id: "190",
    State: "Bihar",
    City: "Bairgania",
  },
  {
    Id: "191",
    State: "Bihar",
    City: "Bakhtiarpur",
  },
  {
    Id: "192",
    State: "Bihar",
    City: "Banka",
  },
  {
    Id: "193",
    State: "Bihar",
    City: "Banmankhi Bazar",
  },
  {
    Id: "194",
    State: "Bihar",
    City: "Barahiya",
  },
  {
    Id: "195",
    State: "Bihar",
    City: "Barauli",
  },
  {
    Id: "196",
    State: "Bihar",
    City: "Barbigha",
  },
  {
    Id: "197",
    State: "Bihar",
    City: "Barh",
  },
  {
    Id: "198",
    State: "Bihar",
    City: "Begusarai",
  },
  {
    Id: "199",
    State: "Bihar",
    City: "Behea",
  },
  {
    Id: "200",
    State: "Bihar",
    City: "Bettiah",
  },
  {
    Id: "201",
    State: "Bihar",
    City: "Bhabua",
  },
  {
    Id: "202",
    State: "Bihar",
    City: "Bhagalpur",
  },
  {
    Id: "203",
    State: "Bihar",
    City: "Bihar Sharif",
  },
  {
    Id: "204",
    State: "Bihar",
    City: "Bikramganj",
  },
  {
    Id: "205",
    State: "Bihar",
    City: "Bodh Gaya",
  },
  {
    Id: "206",
    State: "Bihar",
    City: "Buxar",
  },
  {
    Id: "207",
    State: "Bihar",
    City: "Chandan Bara",
  },
  {
    Id: "208",
    State: "Bihar",
    City: "Chanpatia",
  },
  {
    Id: "209",
    State: "Bihar",
    City: "Chhapra",
  },
  {
    Id: "210",
    State: "Bihar",
    City: "Colgong",
  },
  {
    Id: "211",
    State: "Bihar",
    City: "Dalsinghsarai",
  },
  {
    Id: "212",
    State: "Bihar",
    City: "Darbhanga",
  },
  {
    Id: "213",
    State: "Bihar",
    City: "Daudnagar",
  },
  {
    Id: "214",
    State: "Bihar",
    City: "Dehri-on-Sone",
  },
  {
    Id: "215",
    State: "Bihar",
    City: "Dhaka",
  },
  {
    Id: "216",
    State: "Bihar",
    City: "Dighwara",
  },
  {
    Id: "217",
    State: "Bihar",
    City: "Dumraon",
  },
  {
    Id: "218",
    State: "Bihar",
    City: "Fatwah",
  },
  {
    Id: "219",
    State: "Bihar",
    City: "Forbesganj",
  },
  {
    Id: "220",
    State: "Bihar",
    City: "Gaya",
  },
  {
    Id: "221",
    State: "Bihar",
    City: "Gogri Jamalpur",
  },
  {
    Id: "222",
    State: "Bihar",
    City: "Gopalganj",
  },
  {
    Id: "223",
    State: "Bihar",
    City: "Hajipur",
  },
  {
    Id: "224",
    State: "Bihar",
    City: "Hilsa",
  },
  {
    Id: "225",
    State: "Bihar",
    City: "Hisua",
  },
  {
    Id: "226",
    State: "Bihar",
    City: "Islampur",
  },
  {
    Id: "227",
    State: "Bihar",
    City: "Jagdispur",
  },
  {
    Id: "228",
    State: "Bihar",
    City: "Jamalpur",
  },
  {
    Id: "229",
    State: "Bihar",
    City: "Jamui",
  },
  {
    Id: "230",
    State: "Bihar",
    City: "Jehanabad",
  },
  {
    Id: "231",
    State: "Bihar",
    City: "Jhajha",
  },
  {
    Id: "232",
    State: "Bihar",
    City: "Jhanjharpur",
  },
  {
    Id: "233",
    State: "Bihar",
    City: "Jogabani",
  },
  {
    Id: "234",
    State: "Bihar",
    City: "Kanti",
  },
  {
    Id: "235",
    State: "Bihar",
    City: "Katihar",
  },
  {
    Id: "236",
    State: "Bihar",
    City: "Khagaria",
  },
  {
    Id: "237",
    State: "Bihar",
    City: "Kharagpur",
  },
  {
    Id: "238",
    State: "Bihar",
    City: "Kishanganj",
  },
  {
    Id: "239",
    State: "Bihar",
    City: "Lakhisarai",
  },
  {
    Id: "240",
    State: "Bihar",
    City: "Lalganj",
  },
  {
    Id: "241",
    State: "Bihar",
    City: "Madhepura",
  },
  {
    Id: "242",
    State: "Bihar",
    City: "Madhubani",
  },
  {
    Id: "243",
    State: "Bihar",
    City: "Maharajganj",
  },
  {
    Id: "244",
    State: "Bihar",
    City: "Mahnar Bazar",
  },
  {
    Id: "245",
    State: "Bihar",
    City: "Makhdumpur",
  },
  {
    Id: "246",
    State: "Bihar",
    City: "Maner",
  },
  {
    Id: "247",
    State: "Bihar",
    City: "Manihari",
  },
  {
    Id: "248",
    State: "Bihar",
    City: "Marhaura",
  },
  {
    Id: "249",
    State: "Bihar",
    City: "Masaurhi",
  },
  {
    Id: "250",
    State: "Bihar",
    City: "Mirganj",
  },
  {
    Id: "251",
    State: "Bihar",
    City: "Mokameh",
  },
  {
    Id: "252",
    State: "Bihar",
    City: "Motihari",
  },
  {
    Id: "253",
    State: "Bihar",
    City: "Motipur",
  },
  {
    Id: "254",
    State: "Bihar",
    City: "Munger",
  },
  {
    Id: "255",
    State: "Bihar",
    City: "Murliganj",
  },
  {
    Id: "256",
    State: "Bihar",
    City: "Muzaffarpur",
  },
  {
    Id: "257",
    State: "Bihar",
    City: "Narkatiaganj",
  },
  {
    Id: "258",
    State: "Bihar",
    City: "Naugachhia",
  },
  {
    Id: "259",
    State: "Bihar",
    City: "Nawada",
  },
  {
    Id: "260",
    State: "Bihar",
    City: "Nokha",
  },
  {
    Id: "261",
    State: "Bihar",
    City: "Patna",
  },
  {
    Id: "262",
    State: "Bihar",
    City: "Piro",
  },
  {
    Id: "263",
    State: "Bihar",
    City: "Purnia",
  },
  {
    Id: "264",
    State: "Bihar",
    City: "Rafiganj",
  },
  {
    Id: "265",
    State: "Bihar",
    City: "Rajgir",
  },
  {
    Id: "266",
    State: "Bihar",
    City: "Ramnagar",
  },
  {
    Id: "267",
    State: "Bihar",
    City: "Raxaul Bazar",
  },
  {
    Id: "268",
    State: "Bihar",
    City: "Revelganj",
  },
  {
    Id: "269",
    State: "Bihar",
    City: "Rosera",
  },
  {
    Id: "270",
    State: "Bihar",
    City: "Saharsa",
  },
  {
    Id: "271",
    State: "Bihar",
    City: "Samastipur",
  },
  {
    Id: "272",
    State: "Bihar",
    City: "Sasaram",
  },
  {
    Id: "273",
    State: "Bihar",
    City: "Sheikhpura",
  },
  {
    Id: "274",
    State: "Bihar",
    City: "Sheohar",
  },
  {
    Id: "275",
    State: "Bihar",
    City: "Sherghati",
  },
  {
    Id: "276",
    State: "Bihar",
    City: "Silao",
  },
  {
    Id: "277",
    State: "Bihar",
    City: "Sitamarhi",
  },
  {
    Id: "278",
    State: "Bihar",
    City: "Siwan",
  },
  {
    Id: "279",
    State: "Bihar",
    City: "Sonepur",
  },
  {
    Id: "280",
    State: "Bihar",
    City: "Sugauli",
  },
  {
    Id: "281",
    State: "Bihar",
    City: "Sultanganj",
  },
  {
    Id: "282",
    State: "Bihar",
    City: "Supaul",
  },
  {
    Id: "283",
    State: "Bihar",
    City: "Warisaliganj",
  },
  {
    Id: "284",
    State: "Chhattisgarh",
    City: "Ahiwara",
  },
  {
    Id: "285",
    State: "Chhattisgarh",
    City: "Akaltara",
  },
  {
    Id: "286",
    State: "Chhattisgarh",
    City: "Ambagarh Chowki",
  },
  {
    Id: "287",
    State: "Chhattisgarh",
    City: "Ambikapur",
  },
  {
    Id: "288",
    State: "Chhattisgarh",
    City: "Arang",
  },
  {
    Id: "289",
    State: "Chhattisgarh",
    City: "Bade Bacheli",
  },
  {
    Id: "290",
    State: "Chhattisgarh",
    City: "Balod",
  },
  {
    Id: "291",
    State: "Chhattisgarh",
    City: "Baloda Bazar",
  },
  {
    Id: "292",
    State: "Chhattisgarh",
    City: "Bemetra",
  },
  {
    Id: "293",
    State: "Chhattisgarh",
    City: "Bhatapara",
  },
  {
    Id: "294",
    State: "Chhattisgarh",
    City: "Bilaspur",
  },
  {
    Id: "295",
    State: "Chhattisgarh",
    City: "Birgaon",
  },
  {
    Id: "296",
    State: "Chhattisgarh",
    City: "Champa",
  },
  {
    Id: "297",
    State: "Chhattisgarh",
    City: "Chirmiri",
  },
  {
    Id: "298",
    State: "Chhattisgarh",
    City: "Dalli-Rajhara",
  },
  {
    Id: "299",
    State: "Chhattisgarh",
    City: "Dhamtari",
  },
  {
    Id: "300",
    State: "Chhattisgarh",
    City: "Dipka",
  },
  {
    Id: "301",
    State: "Chhattisgarh",
    City: "Dongargarh",
  },
  {
    Id: "302",
    State: "Chhattisgarh",
    City: "Durg-Bhilai Nagar",
  },
  {
    Id: "303",
    State: "Chhattisgarh",
    City: "Gobranawapara",
  },
  {
    Id: "304",
    State: "Chhattisgarh",
    City: "Jagdalpur",
  },
  {
    Id: "305",
    State: "Chhattisgarh",
    City: "Janjgir",
  },
  {
    Id: "306",
    State: "Chhattisgarh",
    City: "Jashpurnagar",
  },
  {
    Id: "307",
    State: "Chhattisgarh",
    City: "Kanker",
  },
  {
    Id: "308",
    State: "Chhattisgarh",
    City: "Kawardha",
  },
  {
    Id: "309",
    State: "Chhattisgarh",
    City: "Kondagaon",
  },
  {
    Id: "310",
    State: "Chhattisgarh",
    City: "Korba",
  },
  {
    Id: "311",
    State: "Chhattisgarh",
    City: "Mahasamund",
  },
  {
    Id: "312",
    State: "Chhattisgarh",
    City: "Mahendragarh",
  },
  {
    Id: "313",
    State: "Chhattisgarh",
    City: "Mungeli",
  },
  {
    Id: "314",
    State: "Chhattisgarh",
    City: "Naila Janjgir",
  },
  {
    Id: "315",
    State: "Chhattisgarh",
    City: "Raigarh",
  },
  {
    Id: "316",
    State: "Chhattisgarh",
    City: "Raipur",
  },
  {
    Id: "317",
    State: "Chhattisgarh",
    City: "Rajnandgaon",
  },
  {
    Id: "318",
    State: "Chhattisgarh",
    City: "Sakti",
  },
  {
    Id: "319",
    State: "Chhattisgarh",
    City: "Tilda Newra",
  },
  {
    Id: "320",
    State: "Dadra & Nagar Haveli",
    City: "Amli",
  },
  {
    Id: "321",
    State: "Dadra & Nagar Haveli",
    City: "Silvassa",
  },
  {
    Id: "322",
    State: "Daman & Diu",
    City: "Daman and Diu",
  },
  {
    Id: "323",
    State: "Daman & Diu",
    City: "Daman and Diu",
  },
  {
    Id: "324",
    State: "Delhi",
    City: "Asola",
  },
  {
    Id: "325",
    State: "Delhi",
    City: "Delhi",
  },
  {
    Id: "326",
    State: "Goa",
    City: "Aldona",
  },
  {
    Id: "327",
    State: "Goa",
    City: "Curchorem Cacora",
  },
  {
    Id: "328",
    State: "Goa",
    City: "Madgaon",
  },
  {
    Id: "329",
    State: "Goa",
    City: "Mapusa",
  },
  {
    Id: "330",
    State: "Goa",
    City: "Margao",
  },
  {
    Id: "331",
    State: "Goa",
    City: "Marmagao",
  },
  {
    Id: "332",
    State: "Goa",
    City: "Panaji",
  },
  {
    Id: "333",
    State: "Gujarat",
    City: "Ahmedabad",
  },
  {
    Id: "334",
    State: "Gujarat",
    City: "Amreli",
  },
  {
    Id: "335",
    State: "Gujarat",
    City: "Anand",
  },
  {
    Id: "336",
    State: "Gujarat",
    City: "Ankleshwar",
  },
  {
    Id: "337",
    State: "Gujarat",
    City: "Bharuch",
  },
  {
    Id: "338",
    State: "Gujarat",
    City: "Bhavnagar",
  },
  {
    Id: "339",
    State: "Gujarat",
    City: "Bhuj",
  },
  {
    Id: "340",
    State: "Gujarat",
    City: "Cambay",
  },
  {
    Id: "341",
    State: "Gujarat",
    City: "Dahod",
  },
  {
    Id: "342",
    State: "Gujarat",
    City: "Deesa",
  },
  {
    Id: "344",
    State: "Gujarat",
    City: "Dholka",
  },
  {
    Id: "345",
    State: "Gujarat",
    City: "Gandhinagar",
  },
  {
    Id: "346",
    State: "Gujarat",
    City: "Godhra",
  },
  {
    Id: "347",
    State: "Gujarat",
    City: "Himatnagar",
  },
  {
    Id: "348",
    State: "Gujarat",
    City: "Idar",
  },
  {
    Id: "349",
    State: "Gujarat",
    City: "Jamnagar",
  },
  {
    Id: "350",
    State: "Gujarat",
    City: "Junagadh",
  },
  {
    Id: "351",
    State: "Gujarat",
    City: "Kadi",
  },
  {
    Id: "352",
    State: "Gujarat",
    City: "Kalavad",
  },
  {
    Id: "353",
    State: "Gujarat",
    City: "Kalol",
  },
  {
    Id: "354",
    State: "Gujarat",
    City: "Kapadvanj",
  },
  {
    Id: "355",
    State: "Gujarat",
    City: "Karjan",
  },
  {
    Id: "356",
    State: "Gujarat",
    City: "Keshod",
  },
  {
    Id: "357",
    State: "Gujarat",
    City: "Khambhalia",
  },
  {
    Id: "358",
    State: "Gujarat",
    City: "Khambhat",
  },
  {
    Id: "359",
    State: "Gujarat",
    City: "Kheda",
  },
  {
    Id: "360",
    State: "Gujarat",
    City: "Khedbrahma",
  },
  {
    Id: "361",
    State: "Gujarat",
    City: "Kheralu",
  },
  {
    Id: "362",
    State: "Gujarat",
    City: "Kodinar",
  },
  {
    Id: "363",
    State: "Gujarat",
    City: "Lathi",
  },
  {
    Id: "364",
    State: "Gujarat",
    City: "Limbdi",
  },
  {
    Id: "365",
    State: "Gujarat",
    City: "Lunawada",
  },
  {
    Id: "366",
    State: "Gujarat",
    City: "Mahesana",
  },
  {
    Id: "367",
    State: "Gujarat",
    City: "Mahuva",
  },
  {
    Id: "368",
    State: "Gujarat",
    City: "Manavadar",
  },
  {
    Id: "369",
    State: "Gujarat",
    City: "Mandvi",
  },
  {
    Id: "370",
    State: "Gujarat",
    City: "Mangrol",
  },
  {
    Id: "371",
    State: "Gujarat",
    City: "Mansa",
  },
  {
    Id: "372",
    State: "Gujarat",
    City: "Mehmedabad",
  },
  {
    Id: "373",
    State: "Gujarat",
    City: "Modasa",
  },
  {
    Id: "374",
    State: "Gujarat",
    City: "Morvi",
  },
  {
    Id: "375",
    State: "Gujarat",
    City: "Nadiad",
  },
  {
    Id: "376",
    State: "Gujarat",
    City: "Navsari",
  },
  {
    Id: "377",
    State: "Gujarat",
    City: "Padra",
  },
  {
    Id: "378",
    State: "Gujarat",
    City: "Palanpur",
  },
  {
    Id: "379",
    State: "Gujarat",
    City: "Palitana",
  },
  {
    Id: "380",
    State: "Gujarat",
    City: "Pardi",
  },
  {
    Id: "381",
    State: "Gujarat",
    City: "Patan",
  },
  {
    Id: "382",
    State: "Gujarat",
    City: "Petlad",
  },
  {
    Id: "383",
    State: "Gujarat",
    City: "Porbandar",
  },
  {
    Id: "384",
    State: "Gujarat",
    City: "Radhanpur",
  },
  {
    Id: "385",
    State: "Gujarat",
    City: "Rajkot",
  },
  {
    Id: "386",
    State: "Gujarat",
    City: "Rajpipla",
  },
  {
    Id: "387",
    State: "Gujarat",
    City: "Rajula",
  },
  {
    Id: "388",
    State: "Gujarat",
    City: "Ranavav",
  },
  {
    Id: "389",
    State: "Gujarat",
    City: "Rapar",
  },
  {
    Id: "390",
    State: "Gujarat",
    City: "Salaya",
  },
  {
    Id: "391",
    State: "Gujarat",
    City: "Sanand",
  },
  {
    Id: "392",
    State: "Gujarat",
    City: "Savarkundla",
  },
  {
    Id: "393",
    State: "Gujarat",
    City: "Sidhpur",
  },
  {
    Id: "394",
    State: "Gujarat",
    City: "Sihor",
  },
  {
    Id: "395",
    State: "Gujarat",
    City: "Songadh",
  },
  {
    Id: "396",
    State: "Gujarat",
    City: "Surat",
  },
  {
    Id: "397",
    State: "Gujarat",
    City: "Talaja",
  },
  {
    Id: "398",
    State: "Gujarat",
    City: "Thangadh",
  },
  {
    Id: "399",
    State: "Gujarat",
    City: "Tharad",
  },
  {
    Id: "400",
    State: "Gujarat",
    City: "Umbergaon",
  },
  {
    Id: "401",
    State: "Gujarat",
    City: "Umreth",
  },
  {
    Id: "402",
    State: "Gujarat",
    City: "Una",
  },
  {
    Id: "403",
    State: "Gujarat",
    City: "Unjha",
  },
  {
    Id: "404",
    State: "Gujarat",
    City: "Upleta",
  },
  {
    Id: "405",
    State: "Gujarat",
    City: "Vadnagar",
  },
  {
    Id: "406",
    State: "Gujarat",
    City: "Vadodara",
  },
  {
    Id: "407",
    State: "Gujarat",
    City: "Valsad",
  },
  {
    Id: "408",
    State: "Gujarat",
    City: "Vapi",
  },
  {
    Id: "409",
    State: "Gujarat",
    City: "Vapi",
  },
  {
    Id: "410",
    State: "Gujarat",
    City: "Veraval",
  },
  {
    Id: "411",
    State: "Gujarat",
    City: "Vijapur",
  },
  {
    Id: "412",
    State: "Gujarat",
    City: "Viramgam",
  },
  {
    Id: "413",
    State: "Gujarat",
    City: "Visnagar",
  },
  {
    Id: "414",
    State: "Gujarat",
    City: "Vyara",
  },
  {
    Id: "415",
    State: "Gujarat",
    City: "Wadhwan",
  },
  {
    Id: "416",
    State: "Gujarat",
    City: "Wankaner",
  },
  {
    Id: "417",
    State: "Gujarat",
    City: "Adalaj",
  },
  {
    Id: "418",
    State: "Gujarat",
    City: "Adityana",
  },
  {
    Id: "419",
    State: "Gujarat",
    City: "Alang",
  },
  {
    Id: "420",
    State: "Gujarat",
    City: "Ambaji",
  },
  {
    Id: "421",
    State: "Gujarat",
    City: "Ambaliyasan",
  },
  {
    Id: "422",
    State: "Gujarat",
    City: "Andada",
  },
  {
    Id: "423",
    State: "Gujarat",
    City: "Anjar",
  },
  {
    Id: "424",
    State: "Gujarat",
    City: "Anklav",
  },
  {
    Id: "425",
    State: "Gujarat",
    City: "Antaliya",
  },
  {
    Id: "426",
    State: "Gujarat",
    City: "Arambhada",
  },
  {
    Id: "427",
    State: "Gujarat",
    City: "Atul",
  },
  {
    Id: "428",
    State: "Haryana",
    City: "Ballabhgarh",
  },
  {
    Id: "429",
    State: "Haryana",
    City: "Ambala",
  },
  {
    Id: "430",
    State: "Haryana",
    City: "Ambala",
  },
  {
    Id: "431",
    State: "Haryana",
    City: "Asankhurd",
  },
  {
    Id: "432",
    State: "Haryana",
    City: "Assandh",
  },
  {
    Id: "433",
    State: "Haryana",
    City: "Ateli",
  },
  {
    Id: "434",
    State: "Haryana",
    City: "Babiyal",
  },
  {
    Id: "435",
    State: "Haryana",
    City: "Bahadurgarh",
  },
  {
    Id: "436",
    State: "Haryana",
    City: "Barwala",
  },
  {
    Id: "437",
    State: "Haryana",
    City: "Bhiwani",
  },
  {
    Id: "438",
    State: "Haryana",
    City: "Charkhi Dadri",
  },
  {
    Id: "439",
    State: "Haryana",
    City: "Cheeka",
  },
  {
    Id: "440",
    State: "Haryana",
    City: "Ellenabad 2",
  },
  {
    Id: "441",
    State: "Haryana",
    City: "Faridabad",
  },
  {
    Id: "442",
    State: "Haryana",
    City: "Fatehabad",
  },
  {
    Id: "443",
    State: "Haryana",
    City: "Ganaur",
  },
  {
    Id: "444",
    State: "Haryana",
    City: "Gharaunda",
  },
  {
    Id: "445",
    State: "Haryana",
    City: "Gohana",
  },
  {
    Id: "446",
    State: "Haryana",
    City: "Gurgaon",
  },
  {
    Id: "447",
    State: "Haryana",
    City: "Haibat(Yamuna Nagar)",
  },
  {
    Id: "448",
    State: "Haryana",
    City: "Hansi",
  },
  {
    Id: "449",
    State: "Haryana",
    City: "Hisar",
  },
  {
    Id: "450",
    State: "Haryana",
    City: "Hodal",
  },
  {
    Id: "451",
    State: "Haryana",
    City: "Jhajjar",
  },
  {
    Id: "452",
    State: "Haryana",
    City: "Jind",
  },
  {
    Id: "453",
    State: "Haryana",
    City: "Kaithal",
  },
  {
    Id: "454",
    State: "Haryana",
    City: "Kalan Wali",
  },
  {
    Id: "455",
    State: "Haryana",
    City: "Kalka",
  },
  {
    Id: "456",
    State: "Haryana",
    City: "Karnal",
  },
  {
    Id: "457",
    State: "Haryana",
    City: "Ladwa",
  },
  {
    Id: "458",
    State: "Haryana",
    City: "Mahendragarh",
  },
  {
    Id: "459",
    State: "Haryana",
    City: "Mandi Dabwali",
  },
  {
    Id: "460",
    State: "Haryana",
    City: "Narnaul",
  },
  {
    Id: "461",
    State: "Haryana",
    City: "Narwana",
  },
  {
    Id: "462",
    State: "Haryana",
    City: "Palwal",
  },
  {
    Id: "463",
    State: "Haryana",
    City: "Panchkula",
  },
  {
    Id: "464",
    State: "Haryana",
    City: "Panipat",
  },
  {
    Id: "465",
    State: "Haryana",
    City: "Pehowa",
  },
  {
    Id: "466",
    State: "Haryana",
    City: "Pinjore",
  },
  {
    Id: "467",
    State: "Haryana",
    City: "Rania",
  },
  {
    Id: "468",
    State: "Haryana",
    City: "Ratia",
  },
  {
    Id: "469",
    State: "Haryana",
    City: "Rewari",
  },
  {
    Id: "470",
    State: "Haryana",
    City: "Rohtak",
  },
  {
    Id: "471",
    State: "Haryana",
    City: "Safidon",
  },
  {
    Id: "472",
    State: "Haryana",
    City: "Samalkha",
  },
  {
    Id: "473",
    State: "Haryana",
    City: "Shahbad",
  },
  {
    Id: "474",
    State: "Haryana",
    City: "Sirsa",
  },
  {
    Id: "475",
    State: "Haryana",
    City: "Sohna",
  },
  {
    Id: "476",
    State: "Haryana",
    City: "Sonipat",
  },
  {
    Id: "477",
    State: "Haryana",
    City: "Taraori",
  },
  {
    Id: "478",
    State: "Haryana",
    City: "Thanesar",
  },
  {
    Id: "479",
    State: "Haryana",
    City: "Tohana",
  },
  {
    Id: "480",
    State: "Haryana",
    City: "Yamunanagar",
  },
  {
    Id: "481",
    State: "Himachal Pradesh",
    City: "Arki",
  },
  {
    Id: "482",
    State: "Himachal Pradesh",
    City: "Baddi",
  },
  {
    Id: "483",
    State: "Himachal Pradesh",
    City: "Bilaspur",
  },
  {
    Id: "484",
    State: "Himachal Pradesh",
    City: "Chamba",
  },
  {
    Id: "485",
    State: "Himachal Pradesh",
    City: "Dalhousie",
  },
  {
    Id: "486",
    State: "Himachal Pradesh",
    City: "Dharamsala",
  },
  {
    Id: "487",
    State: "Himachal Pradesh",
    City: "Hamirpur",
  },
  {
    Id: "488",
    State: "Himachal Pradesh",
    City: "Mandi",
  },
  {
    Id: "489",
    State: "Himachal Pradesh",
    City: "Nahan",
  },
  {
    Id: "490",
    State: "Himachal Pradesh",
    City: "Shimla",
  },
  {
    Id: "491",
    State: "Himachal Pradesh",
    City: "Solan",
  },
  {
    Id: "492",
    State: "Himachal Pradesh",
    City: "Sundarnagar",
  },
  {
    Id: "493",
    State: "Jammu & Kashmir",
    City: "Jammu",
  },
  {
    Id: "494",
    State: "Jammu & Kashmir",
    City: "Achabbal",
  },
  {
    Id: "495",
    State: "Jammu & Kashmir",
    City: "Akhnoor",
  },
  {
    Id: "496",
    State: "Jammu & Kashmir",
    City: "Anantnag",
  },
  {
    Id: "497",
    State: "Jammu & Kashmir",
    City: "Arnia",
  },
  {
    Id: "498",
    State: "Jammu & Kashmir",
    City: "Awantipora",
  },
  {
    Id: "499",
    State: "Jammu & Kashmir",
    City: "Bandipore",
  },
  {
    Id: "500",
    State: "Jammu & Kashmir",
    City: "Baramula",
  },
  {
    Id: "501",
    State: "Jammu & Kashmir",
    City: "Kathua",
  },
  {
    Id: "502",
    State: "Jammu & Kashmir",
    City: "Leh",
  },
  {
    Id: "503",
    State: "Jammu & Kashmir",
    City: "Punch",
  },
  {
    Id: "504",
    State: "Jammu & Kashmir",
    City: "Rajauri",
  },
  {
    Id: "505",
    State: "Jammu & Kashmir",
    City: "Sopore",
  },
  {
    Id: "506",
    State: "Jammu & Kashmir",
    City: "Srinagar",
  },
  {
    Id: "507",
    State: "Jammu & Kashmir",
    City: "Udhampur",
  },
  {
    Id: "508",
    State: "Jharkhand",
    City: "Amlabad",
  },
  {
    Id: "509",
    State: "Jharkhand",
    City: "Ara",
  },
  {
    Id: "510",
    State: "Jharkhand",
    City: "Barughutu",
  },
  {
    Id: "511",
    State: "Jharkhand",
    City: "Bokaro Steel City",
  },
  {
    Id: "512",
    State: "Jharkhand",
    City: "Chaibasa",
  },
  {
    Id: "513",
    State: "Jharkhand",
    City: "Chakradharpur",
  },
  {
    Id: "514",
    State: "Jharkhand",
    City: "Chandrapura",
  },
  {
    Id: "515",
    State: "Jharkhand",
    City: "Chatra",
  },
  {
    Id: "516",
    State: "Jharkhand",
    City: "Chirkunda",
  },
  {
    Id: "517",
    State: "Jharkhand",
    City: "Churi",
  },
  {
    Id: "518",
    State: "Jharkhand",
    City: "Daltonganj",
  },
  {
    Id: "519",
    State: "Jharkhand",
    City: "Deoghar",
  },
  {
    Id: "520",
    State: "Jharkhand",
    City: "Dhanbad",
  },
  {
    Id: "521",
    State: "Jharkhand",
    City: "Dumka",
  },
  {
    Id: "522",
    State: "Jharkhand",
    City: "Garhwa",
  },
  {
    Id: "523",
    State: "Jharkhand",
    City: "Ghatshila",
  },
  {
    Id: "524",
    State: "Jharkhand",
    City: "Giridih",
  },
  {
    Id: "525",
    State: "Jharkhand",
    City: "Godda",
  },
  {
    Id: "526",
    State: "Jharkhand",
    City: "Gomoh",
  },
  {
    Id: "527",
    State: "Jharkhand",
    City: "Gumia",
  },
  {
    Id: "528",
    State: "Jharkhand",
    City: "Gumla",
  },
  {
    Id: "529",
    State: "Jharkhand",
    City: "Hazaribag",
  },
  {
    Id: "530",
    State: "Jharkhand",
    City: "Hussainabad",
  },
  {
    Id: "531",
    State: "Jharkhand",
    City: "Jamshedpur",
  },
  {
    Id: "532",
    State: "Jharkhand",
    City: "Jamtara",
  },
  {
    Id: "533",
    State: "Jharkhand",
    City: "Jhumri Tilaiya",
  },
  {
    Id: "534",
    State: "Jharkhand",
    City: "Khunti",
  },
  {
    Id: "535",
    State: "Jharkhand",
    City: "Lohardaga",
  },
  {
    Id: "536",
    State: "Jharkhand",
    City: "Madhupur",
  },
  {
    Id: "537",
    State: "Jharkhand",
    City: "Mihijam",
  },
  {
    Id: "538",
    State: "Jharkhand",
    City: "Musabani",
  },
  {
    Id: "539",
    State: "Jharkhand",
    City: "Pakaur",
  },
  {
    Id: "540",
    State: "Jharkhand",
    City: "Patratu",
  },
  {
    Id: "541",
    State: "Jharkhand",
    City: "Phusro",
  },
  {
    Id: "542",
    State: "Jharkhand",
    City: "Ramgarh",
  },
  {
    Id: "543",
    State: "Jharkhand",
    City: "Ranchi",
  },
  {
    Id: "544",
    State: "Jharkhand",
    City: "Sahibganj",
  },
  {
    Id: "545",
    State: "Jharkhand",
    City: "Saunda",
  },
  {
    Id: "546",
    State: "Jharkhand",
    City: "Simdega",
  },
  {
    Id: "547",
    State: "Jharkhand",
    City: "Tenu Dam-cum- Kathhara",
  },
  {
    Id: "548",
    State: "Karnataka",
    City: "Arasikere",
  },
  {
    Id: "549",
    State: "Karnataka",
    City: "Bangalore",
  },
  {
    Id: "550",
    State: "Karnataka",
    City: "Belgaum",
  },
  {
    Id: "551",
    State: "Karnataka",
    City: "Bellary",
  },
  {
    Id: "552",
    State: "Karnataka",
    City: "Chamrajnagar",
  },
  {
    Id: "553",
    State: "Karnataka",
    City: "Chikkaballapur",
  },
  {
    Id: "554",
    State: "Karnataka",
    City: "Chintamani",
  },
  {
    Id: "555",
    State: "Karnataka",
    City: "Chitradurga",
  },
  {
    Id: "556",
    State: "Karnataka",
    City: "Gulbarga",
  },
  {
    Id: "557",
    State: "Karnataka",
    City: "Gundlupet",
  },
  {
    Id: "558",
    State: "Karnataka",
    City: "Hassan",
  },
  {
    Id: "559",
    State: "Karnataka",
    City: "Hospet",
  },
  {
    Id: "560",
    State: "Karnataka",
    City: "Hubli",
  },
  {
    Id: "561",
    State: "Karnataka",
    City: "Karkala",
  },
  {
    Id: "562",
    State: "Karnataka",
    City: "Karwar",
  },
  {
    Id: "563",
    State: "Karnataka",
    City: "Kolar",
  },
  {
    Id: "564",
    State: "Karnataka",
    City: "Kota",
  },
  {
    Id: "565",
    State: "Karnataka",
    City: "Lakshmeshwar",
  },
  {
    Id: "566",
    State: "Karnataka",
    City: "Lingsugur",
  },
  {
    Id: "567",
    State: "Karnataka",
    City: "Maddur",
  },
  {
    Id: "568",
    State: "Karnataka",
    City: "Madhugiri",
  },
  {
    Id: "569",
    State: "Karnataka",
    City: "Madikeri",
  },
  {
    Id: "570",
    State: "Karnataka",
    City: "Magadi",
  },
  {
    Id: "571",
    State: "Karnataka",
    City: "Mahalingpur",
  },
  {
    Id: "572",
    State: "Karnataka",
    City: "Malavalli",
  },
  {
    Id: "573",
    State: "Karnataka",
    City: "Malur",
  },
  {
    Id: "574",
    State: "Karnataka",
    City: "Mandya",
  },
  {
    Id: "575",
    State: "Karnataka",
    City: "Mangalore",
  },
  {
    Id: "576",
    State: "Karnataka",
    City: "Manvi",
  },
  {
    Id: "577",
    State: "Karnataka",
    City: "Mudalgi",
  },
  {
    Id: "578",
    State: "Karnataka",
    City: "Mudbidri",
  },
  {
    Id: "579",
    State: "Karnataka",
    City: "Muddebihal",
  },
  {
    Id: "580",
    State: "Karnataka",
    City: "Mudhol",
  },
  {
    Id: "581",
    State: "Karnataka",
    City: "Mulbagal",
  },
  {
    Id: "582",
    State: "Karnataka",
    City: "Mundargi",
  },
  {
    Id: "583",
    State: "Karnataka",
    City: "Mysore",
  },
  {
    Id: "584",
    State: "Karnataka",
    City: "Nanjangud",
  },
  {
    Id: "585",
    State: "Karnataka",
    City: "Pavagada",
  },
  {
    Id: "586",
    State: "Karnataka",
    City: "Puttur",
  },
  {
    Id: "587",
    State: "Karnataka",
    City: "Rabkavi Banhatti",
  },
  {
    Id: "588",
    State: "Karnataka",
    City: "Raichur",
  },
  {
    Id: "589",
    State: "Karnataka",
    City: "Ramanagaram",
  },
  {
    Id: "590",
    State: "Karnataka",
    City: "Ramdurg",
  },
  {
    Id: "591",
    State: "Karnataka",
    City: "Ranibennur",
  },
  {
    Id: "592",
    State: "Karnataka",
    City: "Robertson Pet",
  },
  {
    Id: "593",
    State: "Karnataka",
    City: "Ron",
  },
  {
    Id: "594",
    State: "Karnataka",
    City: "Sadalgi",
  },
  {
    Id: "595",
    State: "Karnataka",
    City: "Sagar",
  },
  {
    Id: "596",
    State: "Karnataka",
    City: "Sakleshpur",
  },
  {
    Id: "597",
    State: "Karnataka",
    City: "Sandur",
  },
  {
    Id: "598",
    State: "Karnataka",
    City: "Sankeshwar",
  },
  {
    Id: "599",
    State: "Karnataka",
    City: "Saundatti-Yellamma",
  },
  {
    Id: "600",
    State: "Karnataka",
    City: "Savanur",
  },
  {
    Id: "601",
    State: "Karnataka",
    City: "Sedam",
  },
  {
    Id: "602",
    State: "Karnataka",
    City: "Shahabad",
  },
  {
    Id: "603",
    State: "Karnataka",
    City: "Shahpur",
  },
  {
    Id: "604",
    State: "Karnataka",
    City: "Shiggaon",
  },
  {
    Id: "605",
    State: "Karnataka",
    City: "Shikapur",
  },
  {
    Id: "606",
    State: "Karnataka",
    City: "Shimoga",
  },
  {
    Id: "607",
    State: "Karnataka",
    City: "Shorapur",
  },
  {
    Id: "608",
    State: "Karnataka",
    City: "Shrirangapattana",
  },
  {
    Id: "609",
    State: "Karnataka",
    City: "Sidlaghatta",
  },
  {
    Id: "610",
    State: "Karnataka",
    City: "Sindgi",
  },
  {
    Id: "611",
    State: "Karnataka",
    City: "Sindhnur",
  },
  {
    Id: "612",
    State: "Karnataka",
    City: "Sira",
  },
  {
    Id: "613",
    State: "Karnataka",
    City: "Sirsi",
  },
  {
    Id: "614",
    State: "Karnataka",
    City: "Siruguppa",
  },
  {
    Id: "615",
    State: "Karnataka",
    City: "Srinivaspur",
  },
  {
    Id: "616",
    State: "Karnataka",
    City: "Talikota",
  },
  {
    Id: "617",
    State: "Karnataka",
    City: "Tarikere",
  },
  {
    Id: "618",
    State: "Karnataka",
    City: "Tekkalakota",
  },
  {
    Id: "619",
    State: "Karnataka",
    City: "Terdal",
  },
  {
    Id: "620",
    State: "Karnataka",
    City: "Tiptur",
  },
  {
    Id: "621",
    State: "Karnataka",
    City: "Tumkur",
  },
  {
    Id: "622",
    State: "Karnataka",
    City: "Udupi",
  },
  {
    Id: "623",
    State: "Karnataka",
    City: "Vijayapura",
  },
  {
    Id: "624",
    State: "Karnataka",
    City: "Wadi",
  },
  {
    Id: "625",
    State: "Karnataka",
    City: "Yadgir",
  },
  {
    Id: "626",
    State: "Kerala",
    City: "Adoor",
  },
  {
    Id: "627",
    State: "Kerala",
    City: "Akathiyoor",
  },
  {
    Id: "628",
    State: "Kerala",
    City: "Alappuzha",
  },
  {
    Id: "629",
    State: "Kerala",
    City: "Ancharakandy",
  },
  {
    Id: "630",
    State: "Kerala",
    City: "Aroor",
  },
  {
    Id: "631",
    State: "Kerala",
    City: "Ashtamichira",
  },
  {
    Id: "632",
    State: "Kerala",
    City: "Attingal",
  },
  {
    Id: "633",
    State: "Kerala",
    City: "Avinissery",
  },
  {
    Id: "634",
    State: "Kerala",
    City: "Chalakudy",
  },
  {
    Id: "635",
    State: "Kerala",
    City: "Changanassery",
  },
  {
    Id: "636",
    State: "Kerala",
    City: "Chendamangalam",
  },
  {
    Id: "637",
    State: "Kerala",
    City: "Chengannur",
  },
  {
    Id: "638",
    State: "Kerala",
    City: "Cherthala",
  },
  {
    Id: "639",
    State: "Kerala",
    City: "Cheruthazham",
  },
  {
    Id: "640",
    State: "Kerala",
    City: "Chittur-Thathamangalam",
  },
  {
    Id: "641",
    State: "Kerala",
    City: "Chockli",
  },
  {
    Id: "642",
    State: "Kerala",
    City: "Erattupetta",
  },
  {
    Id: "643",
    State: "Kerala",
    City: "Guruvayoor",
  },
  {
    Id: "644",
    State: "Kerala",
    City: "Irinjalakuda",
  },
  {
    Id: "645",
    State: "Kerala",
    City: "Kadirur",
  },
  {
    Id: "646",
    State: "Kerala",
    City: "Kalliasseri",
  },
  {
    Id: "647",
    State: "Kerala",
    City: "Kalpetta",
  },
  {
    Id: "648",
    State: "Kerala",
    City: "Kanhangad",
  },
  {
    Id: "649",
    State: "Kerala",
    City: "Kanjikkuzhi",
  },
  {
    Id: "650",
    State: "Kerala",
    City: "Kannur",
  },
  {
    Id: "651",
    State: "Kerala",
    City: "Kasaragod",
  },
  {
    Id: "652",
    State: "Kerala",
    City: "Kayamkulam",
  },
  {
    Id: "653",
    State: "Kerala",
    City: "Kochi",
  },
  {
    Id: "654",
    State: "Kerala",
    City: "Kodungallur",
  },
  {
    Id: "655",
    State: "Kerala",
    City: "Kollam",
  },
  {
    Id: "656",
    State: "Kerala",
    City: "Koothuparamba",
  },
  {
    Id: "657",
    State: "Kerala",
    City: "Kothamangalam",
  },
  {
    Id: "658",
    State: "Kerala",
    City: "Kottayam",
  },
  {
    Id: "659",
    State: "Kerala",
    City: "Kozhikode",
  },
  {
    Id: "660",
    State: "Kerala",
    City: "Kunnamkulam",
  },
  {
    Id: "661",
    State: "Kerala",
    City: "Malappuram",
  },
  {
    Id: "662",
    State: "Kerala",
    City: "Mattannur",
  },
  {
    Id: "663",
    State: "Kerala",
    City: "Mavelikkara",
  },
  {
    Id: "664",
    State: "Kerala",
    City: "Mavoor",
  },
  {
    Id: "665",
    State: "Kerala",
    City: "Muvattupuzha",
  },
  {
    Id: "666",
    State: "Kerala",
    City: "Nedumangad",
  },
  {
    Id: "667",
    State: "Kerala",
    City: "Neyyattinkara",
  },
  {
    Id: "668",
    State: "Kerala",
    City: "Ottappalam",
  },
  {
    Id: "669",
    State: "Kerala",
    City: "Palai",
  },
  {
    Id: "670",
    State: "Kerala",
    City: "Palakkad",
  },
  {
    Id: "671",
    State: "Kerala",
    City: "Panniyannur",
  },
  {
    Id: "672",
    State: "Kerala",
    City: "Pappinisseri",
  },
  {
    Id: "673",
    State: "Kerala",
    City: "Paravoor",
  },
  {
    Id: "674",
    State: "Kerala",
    City: "Pathanamthitta",
  },
  {
    Id: "675",
    State: "Kerala",
    City: "Payyannur",
  },
  {
    Id: "676",
    State: "Kerala",
    City: "Peringathur",
  },
  {
    Id: "677",
    State: "Kerala",
    City: "Perinthalmanna",
  },
  {
    Id: "678",
    State: "Kerala",
    City: "Perumbavoor",
  },
  {
    Id: "679",
    State: "Kerala",
    City: "Ponnani",
  },
  {
    Id: "680",
    State: "Kerala",
    City: "Punalur",
  },
  {
    Id: "681",
    State: "Kerala",
    City: "Quilandy",
  },
  {
    Id: "682",
    State: "Kerala",
    City: "Shoranur",
  },
  {
    Id: "683",
    State: "Kerala",
    City: "Taliparamba",
  },
  {
    Id: "684",
    State: "Kerala",
    City: "Thiruvalla",
  },
  {
    Id: "685",
    State: "Kerala",
    City: "Thiruvananthapuram",
  },
  {
    Id: "686",
    State: "Kerala",
    City: "Thodupuzha",
  },
  {
    Id: "687",
    State: "Kerala",
    City: "Thrissur",
  },
  {
    Id: "688",
    State: "Kerala",
    City: "Tirur",
  },
  {
    Id: "689",
    State: "Kerala",
    City: "Vadakara",
  },
  {
    Id: "690",
    State: "Kerala",
    City: "Vaikom",
  },
  {
    Id: "691",
    State: "Kerala",
    City: "Varkala",
  },
  {
    Id: "692",
    State: "Lakshadweep",
    City: "Kavaratti",
  },
  {
    Id: "693",
    State: "Madhya Pradesh",
    City: "Ashok Nagar",
  },
  {
    Id: "694",
    State: "Madhya Pradesh",
    City: "Balaghat",
  },
  {
    Id: "695",
    State: "Madhya Pradesh",
    City: "Betul",
  },
  {
    Id: "696",
    State: "Madhya Pradesh",
    City: "Bhopal",
  },
  {
    Id: "697",
    State: "Madhya Pradesh",
    City: "Burhanpur",
  },
  {
    Id: "698",
    State: "Madhya Pradesh",
    City: "Chhatarpur",
  },
  {
    Id: "699",
    State: "Madhya Pradesh",
    City: "Dabra",
  },
  {
    Id: "700",
    State: "Madhya Pradesh",
    City: "Datia",
  },
  {
    Id: "701",
    State: "Madhya Pradesh",
    City: "Dewas",
  },
  {
    Id: "702",
    State: "Madhya Pradesh",
    City: "Dhar",
  },
  {
    Id: "703",
    State: "Madhya Pradesh",
    City: "Fatehabad",
  },
  {
    Id: "704",
    State: "Madhya Pradesh",
    City: "Gwalior",
  },
  {
    Id: "705",
    State: "Madhya Pradesh",
    City: "Indore",
  },
  {
    Id: "706",
    State: "Madhya Pradesh",
    City: "Itarsi",
  },
  {
    Id: "707",
    State: "Madhya Pradesh",
    City: "Jabalpur",
  },
  {
    Id: "708",
    State: "Madhya Pradesh",
    City: "Katni",
  },
  {
    Id: "709",
    State: "Madhya Pradesh",
    City: "Kotma",
  },
  {
    Id: "710",
    State: "Madhya Pradesh",
    City: "Lahar",
  },
  {
    Id: "711",
    State: "Madhya Pradesh",
    City: "Lundi",
  },
  {
    Id: "712",
    State: "Madhya Pradesh",
    City: "Maharajpur",
  },
  {
    Id: "713",
    State: "Madhya Pradesh",
    City: "Mahidpur",
  },
  {
    Id: "714",
    State: "Madhya Pradesh",
    City: "Maihar",
  },
  {
    Id: "715",
    State: "Madhya Pradesh",
    City: "Malajkhand",
  },
  {
    Id: "716",
    State: "Madhya Pradesh",
    City: "Manasa",
  },
  {
    Id: "717",
    State: "Madhya Pradesh",
    City: "Manawar",
  },
  {
    Id: "718",
    State: "Madhya Pradesh",
    City: "Mandideep",
  },
  {
    Id: "719",
    State: "Madhya Pradesh",
    City: "Mandla",
  },
  {
    Id: "720",
    State: "Madhya Pradesh",
    City: "Mandsaur",
  },
  {
    Id: "721",
    State: "Madhya Pradesh",
    City: "Mauganj",
  },
  {
    Id: "722",
    State: "Madhya Pradesh",
    City: "Mhow Cantonment",
  },
  {
    Id: "723",
    State: "Madhya Pradesh",
    City: "Mhowgaon",
  },
  {
    Id: "724",
    State: "Madhya Pradesh",
    City: "Morena",
  },
  {
    Id: "725",
    State: "Madhya Pradesh",
    City: "Multai",
  },
  {
    Id: "726",
    State: "Madhya Pradesh",
    City: "Murwara",
  },
  {
    Id: "727",
    State: "Madhya Pradesh",
    City: "Nagda",
  },
  {
    Id: "728",
    State: "Madhya Pradesh",
    City: "Nainpur",
  },
  {
    Id: "729",
    State: "Madhya Pradesh",
    City: "Narsinghgarh",
  },
  {
    Id: "730",
    State: "Madhya Pradesh",
    City: "Narsinghgarh",
  },
  {
    Id: "731",
    State: "Madhya Pradesh",
    City: "Neemuch",
  },
  {
    Id: "732",
    State: "Madhya Pradesh",
    City: "Nepanagar",
  },
  {
    Id: "733",
    State: "Madhya Pradesh",
    City: "Niwari",
  },
  {
    Id: "734",
    State: "Madhya Pradesh",
    City: "Nowgong",
  },
  {
    Id: "735",
    State: "Madhya Pradesh",
    City: "Nowrozabad",
  },
  {
    Id: "736",
    State: "Madhya Pradesh",
    City: "Pachore",
  },
  {
    Id: "737",
    State: "Madhya Pradesh",
    City: "Pali",
  },
  {
    Id: "738",
    State: "Madhya Pradesh",
    City: "Panagar",
  },
  {
    Id: "739",
    State: "Madhya Pradesh",
    City: "Pandhurna",
  },
  {
    Id: "740",
    State: "Madhya Pradesh",
    City: "Panna",
  },
  {
    Id: "741",
    State: "Madhya Pradesh",
    City: "Pasan",
  },
  {
    Id: "742",
    State: "Madhya Pradesh",
    City: "Pipariya",
  },
  {
    Id: "743",
    State: "Madhya Pradesh",
    City: "Pithampur",
  },
  {
    Id: "744",
    State: "Madhya Pradesh",
    City: "Porsa",
  },
  {
    Id: "745",
    State: "Madhya Pradesh",
    City: "Prithvipur",
  },
  {
    Id: "746",
    State: "Madhya Pradesh",
    City: "Raghogarh-Vijaypur",
  },
  {
    Id: "747",
    State: "Madhya Pradesh",
    City: "Rahatgarh",
  },
  {
    Id: "748",
    State: "Madhya Pradesh",
    City: "Raisen",
  },
  {
    Id: "749",
    State: "Madhya Pradesh",
    City: "Rajgarh",
  },
  {
    Id: "750",
    State: "Madhya Pradesh",
    City: "Ratlam",
  },
  {
    Id: "751",
    State: "Madhya Pradesh",
    City: "Rau",
  },
  {
    Id: "752",
    State: "Madhya Pradesh",
    City: "Rehli",
  },
  {
    Id: "753",
    State: "Madhya Pradesh",
    City: "Rewa",
  },
  {
    Id: "754",
    State: "Madhya Pradesh",
    City: "Sabalgarh",
  },
  {
    Id: "755",
    State: "Madhya Pradesh",
    City: "Sagar",
  },
  {
    Id: "756",
    State: "Madhya Pradesh",
    City: "Sanawad",
  },
  {
    Id: "757",
    State: "Madhya Pradesh",
    City: "Sarangpur",
  },
  {
    Id: "758",
    State: "Madhya Pradesh",
    City: "Sarni",
  },
  {
    Id: "759",
    State: "Madhya Pradesh",
    City: "Satna",
  },
  {
    Id: "760",
    State: "Madhya Pradesh",
    City: "Sausar",
  },
  {
    Id: "761",
    State: "Madhya Pradesh",
    City: "Sehore",
  },
  {
    Id: "762",
    State: "Madhya Pradesh",
    City: "Sendhwa",
  },
  {
    Id: "763",
    State: "Madhya Pradesh",
    City: "Seoni",
  },
  {
    Id: "764",
    State: "Madhya Pradesh",
    City: "Seoni-Malwa",
  },
  {
    Id: "765",
    State: "Madhya Pradesh",
    City: "Shahdol",
  },
  {
    Id: "766",
    State: "Madhya Pradesh",
    City: "Shajapur",
  },
  {
    Id: "767",
    State: "Madhya Pradesh",
    City: "Shamgarh",
  },
  {
    Id: "768",
    State: "Madhya Pradesh",
    City: "Sheopur",
  },
  {
    Id: "769",
    State: "Madhya Pradesh",
    City: "Shivpuri",
  },
  {
    Id: "770",
    State: "Madhya Pradesh",
    City: "Shujalpur",
  },
  {
    Id: "771",
    State: "Madhya Pradesh",
    City: "Sidhi",
  },
  {
    Id: "772",
    State: "Madhya Pradesh",
    City: "Sihora",
  },
  {
    Id: "773",
    State: "Madhya Pradesh",
    City: "Singrauli",
  },
  {
    Id: "774",
    State: "Madhya Pradesh",
    City: "Sironj",
  },
  {
    Id: "775",
    State: "Madhya Pradesh",
    City: "Sohagpur",
  },
  {
    Id: "776",
    State: "Madhya Pradesh",
    City: "Tarana",
  },
  {
    Id: "777",
    State: "Madhya Pradesh",
    City: "Tikamgarh",
  },
  {
    Id: "778",
    State: "Madhya Pradesh",
    City: "Ujhani",
  },
  {
    Id: "779",
    State: "Madhya Pradesh",
    City: "Ujjain",
  },
  {
    Id: "780",
    State: "Madhya Pradesh",
    City: "Umaria",
  },
  {
    Id: "781",
    State: "Madhya Pradesh",
    City: "Vidisha",
  },
  {
    Id: "782",
    State: "Madhya Pradesh",
    City: "Wara Seoni",
  },
  {
    Id: "783",
    State: "Maharashtra",
    City: "Ahmednagar",
  },
  {
    Id: "784",
    State: "Maharashtra",
    City: "Akola",
  },
  {
    Id: "785",
    State: "Maharashtra",
    City: "Amravati",
  },
  {
    Id: "786",
    State: "Maharashtra",
    City: "Aurangabad",
  },
  {
    Id: "787",
    State: "Maharashtra",
    City: "Baramati",
  },
  {
    Id: "788",
    State: "Maharashtra",
    City: "Chalisgaon",
  },
  {
    Id: "789",
    State: "Maharashtra",
    City: "Chinchani",
  },
  {
    Id: "790",
    State: "Maharashtra",
    City: "Devgarh",
  },
  {
    Id: "791",
    State: "Maharashtra",
    City: "Dhule",
  },
  {
    Id: "792",
    State: "Maharashtra",
    City: "Dombivli",
  },
  {
    Id: "793",
    State: "Maharashtra",
    City: "Durgapur",
  },
  {
    Id: "794",
    State: "Maharashtra",
    City: "Ichalkaranji",
  },
  {
    Id: "795",
    State: "Maharashtra",
    City: "Jalna",
  },
  {
    Id: "796",
    State: "Maharashtra",
    City: "Kalyan",
  },
  {
    Id: "797",
    State: "Maharashtra",
    City: "Latur",
  },
  {
    Id: "798",
    State: "Maharashtra",
    City: "Loha",
  },
  {
    Id: "799",
    State: "Maharashtra",
    City: "Lonar",
  },
  {
    Id: "800",
    State: "Maharashtra",
    City: "Lonavla",
  },
  {
    Id: "801",
    State: "Maharashtra",
    City: "Mahad",
  },
  {
    Id: "802",
    State: "Maharashtra",
    City: "Mahuli",
  },
  {
    Id: "803",
    State: "Maharashtra",
    City: "Malegaon",
  },
  {
    Id: "804",
    State: "Maharashtra",
    City: "Malkapur",
  },
  {
    Id: "805",
    State: "Maharashtra",
    City: "Manchar",
  },
  {
    Id: "806",
    State: "Maharashtra",
    City: "Mangalvedhe",
  },
  {
    Id: "807",
    State: "Maharashtra",
    City: "Mangrulpir",
  },
  {
    Id: "808",
    State: "Maharashtra",
    City: "Manjlegaon",
  },
  {
    Id: "809",
    State: "Maharashtra",
    City: "Manmad",
  },
  {
    Id: "810",
    State: "Maharashtra",
    City: "Manwath",
  },
  {
    Id: "811",
    State: "Maharashtra",
    City: "Mehkar",
  },
  {
    Id: "812",
    State: "Maharashtra",
    City: "Mhaswad",
  },
  {
    Id: "813",
    State: "Maharashtra",
    City: "Miraj",
  },
  {
    Id: "814",
    State: "Maharashtra",
    City: "Morshi",
  },
  {
    Id: "815",
    State: "Maharashtra",
    City: "Mukhed",
  },
  {
    Id: "816",
    State: "Maharashtra",
    City: "Mul",
  },
  {
    Id: "817",
    State: "Maharashtra",
    City: "Mumbai",
  },
  {
    Id: "818",
    State: "Maharashtra",
    City: "Murtijapur",
  },
  {
    Id: "819",
    State: "Maharashtra",
    City: "Nagpur",
  },
  {
    Id: "820",
    State: "Maharashtra",
    City: "Nalasopara",
  },
  {
    Id: "821",
    State: "Maharashtra",
    City: "Nanded-Waghala",
  },
  {
    Id: "822",
    State: "Maharashtra",
    City: "Nandgaon",
  },
  {
    Id: "823",
    State: "Maharashtra",
    City: "Nandura",
  },
  {
    Id: "824",
    State: "Maharashtra",
    City: "Nandurbar",
  },
  {
    Id: "825",
    State: "Maharashtra",
    City: "Narkhed",
  },
  {
    Id: "826",
    State: "Maharashtra",
    City: "Nashik",
  },
  {
    Id: "827",
    State: "Maharashtra",
    City: "Navi Mumbai",
  },
  {
    Id: "828",
    State: "Maharashtra",
    City: "Nawapur",
  },
  {
    Id: "829",
    State: "Maharashtra",
    City: "Nilanga",
  },
  {
    Id: "830",
    State: "Maharashtra",
    City: "Osmanabad",
  },
  {
    Id: "831",
    State: "Maharashtra",
    City: "Ozar",
  },
  {
    Id: "832",
    State: "Maharashtra",
    City: "Pachora",
  },
  {
    Id: "833",
    State: "Maharashtra",
    City: "Paithan",
  },
  {
    Id: "834",
    State: "Maharashtra",
    City: "Palghar",
  },
  {
    Id: "835",
    State: "Maharashtra",
    City: "Pandharkaoda",
  },
  {
    Id: "836",
    State: "Maharashtra",
    City: "Pandharpur",
  },
  {
    Id: "837",
    State: "Maharashtra",
    City: "Panvel",
  },
  {
    Id: "838",
    State: "Maharashtra",
    City: "Parbhani",
  },
  {
    Id: "839",
    State: "Maharashtra",
    City: "Parli",
  },
  {
    Id: "840",
    State: "Maharashtra",
    City: "Parola",
  },
  {
    Id: "841",
    State: "Maharashtra",
    City: "Partur",
  },
  {
    Id: "842",
    State: "Maharashtra",
    City: "Pathardi",
  },
  {
    Id: "843",
    State: "Maharashtra",
    City: "Pathri",
  },
  {
    Id: "844",
    State: "Maharashtra",
    City: "Patur",
  },
  {
    Id: "845",
    State: "Maharashtra",
    City: "Pauni",
  },
  {
    Id: "846",
    State: "Maharashtra",
    City: "Pen",
  },
  {
    Id: "847",
    State: "Maharashtra",
    City: "Phaltan",
  },
  {
    Id: "848",
    State: "Maharashtra",
    City: "Pulgaon",
  },
  {
    Id: "849",
    State: "Maharashtra",
    City: "Pune",
  },
  {
    Id: "850",
    State: "Maharashtra",
    City: "Purna",
  },
  {
    Id: "851",
    State: "Maharashtra",
    City: "Pusad",
  },
  {
    Id: "852",
    State: "Maharashtra",
    City: "Rahuri",
  },
  {
    Id: "853",
    State: "Maharashtra",
    City: "Rajura",
  },
  {
    Id: "854",
    State: "Maharashtra",
    City: "Ramtek",
  },
  {
    Id: "855",
    State: "Maharashtra",
    City: "Ratnagiri",
  },
  {
    Id: "856",
    State: "Maharashtra",
    City: "Raver",
  },
  {
    Id: "857",
    State: "Maharashtra",
    City: "Risod",
  },
  {
    Id: "858",
    State: "Maharashtra",
    City: "Sailu",
  },
  {
    Id: "859",
    State: "Maharashtra",
    City: "Sangamner",
  },
  {
    Id: "860",
    State: "Maharashtra",
    City: "Sangli",
  },
  {
    Id: "861",
    State: "Maharashtra",
    City: "Sangole",
  },
  {
    Id: "862",
    State: "Maharashtra",
    City: "Sasvad",
  },
  {
    Id: "863",
    State: "Maharashtra",
    City: "Satana",
  },
  {
    Id: "864",
    State: "Maharashtra",
    City: "Satara",
  },
  {
    Id: "865",
    State: "Maharashtra",
    City: "Savner",
  },
  {
    Id: "866",
    State: "Maharashtra",
    City: "Sawantwadi",
  },
  {
    Id: "867",
    State: "Maharashtra",
    City: "Shahade",
  },
  {
    Id: "868",
    State: "Maharashtra",
    City: "Shegaon",
  },
  {
    Id: "869",
    State: "Maharashtra",
    City: "Shendurjana",
  },
  {
    Id: "870",
    State: "Maharashtra",
    City: "Shirdi",
  },
  {
    Id: "871",
    State: "Maharashtra",
    City: "Shirpur-Warwade",
  },
  {
    Id: "872",
    State: "Maharashtra",
    City: "Shirur",
  },
  {
    Id: "873",
    State: "Maharashtra",
    City: "Shrigonda",
  },
  {
    Id: "874",
    State: "Maharashtra",
    City: "Shrirampur",
  },
  {
    Id: "875",
    State: "Maharashtra",
    City: "Sillod",
  },
  {
    Id: "876",
    State: "Maharashtra",
    City: "Sinnar",
  },
  {
    Id: "877",
    State: "Maharashtra",
    City: "Solapur",
  },
  {
    Id: "878",
    State: "Maharashtra",
    City: "Soyagaon",
  },
  {
    Id: "879",
    State: "Maharashtra",
    City: "Talegaon Dabhade",
  },
  {
    Id: "880",
    State: "Maharashtra",
    City: "Talode",
  },
  {
    Id: "881",
    State: "Maharashtra",
    City: "Tasgaon",
  },
  {
    Id: "882",
    State: "Maharashtra",
    City: "Tirora",
  },
  {
    Id: "883",
    State: "Maharashtra",
    City: "Tuljapur",
  },
  {
    Id: "884",
    State: "Maharashtra",
    City: "Tumsar",
  },
  {
    Id: "885",
    State: "Maharashtra",
    City: "Uran",
  },
  {
    Id: "886",
    State: "Maharashtra",
    City: "Uran Islampur",
  },
  {
    Id: "887",
    State: "Maharashtra",
    City: "Wadgaon Road",
  },
  {
    Id: "888",
    State: "Maharashtra",
    City: "Wai",
  },
  {
    Id: "889",
    State: "Maharashtra",
    City: "Wani",
  },
  {
    Id: "890",
    State: "Maharashtra",
    City: "Wardha",
  },
  {
    Id: "891",
    State: "Maharashtra",
    City: "Warora",
  },
  {
    Id: "892",
    State: "Maharashtra",
    City: "Warud",
  },
  {
    Id: "893",
    State: "Maharashtra",
    City: "Washim",
  },
  {
    Id: "894",
    State: "Maharashtra",
    City: "Yevla",
  },
  {
    Id: "895",
    State: "Maharashtra",
    City: "Uchgaon",
  },
  {
    Id: "896",
    State: "Maharashtra",
    City: "Udgir",
  },
  {
    Id: "897",
    State: "Maharashtra",
    City: "Umarga",
  },
  {
    Id: "898",
    State: "Maharashtra",
    City: "Umarkhed",
  },
  {
    Id: "899",
    State: "Maharashtra",
    City: "Umred",
  },
  {
    Id: "900",
    State: "Maharashtra",
    City: "Vadgaon Kasba",
  },
  {
    Id: "901",
    State: "Maharashtra",
    City: "Vaijapur",
  },
  {
    Id: "902",
    State: "Maharashtra",
    City: "Vasai",
  },
  {
    Id: "903",
    State: "Maharashtra",
    City: "Virar",
  },
  {
    Id: "904",
    State: "Maharashtra",
    City: "Vita",
  },
  {
    Id: "905",
    State: "Maharashtra",
    City: "Yavatmal",
  },
  {
    Id: "906",
    State: "Maharashtra",
    City: "Yawal",
  },
  {
    Id: "907",
    State: "Manipur",
    City: "Imphal",
  },
  {
    Id: "908",
    State: "Manipur",
    City: "Kakching",
  },
  {
    Id: "909",
    State: "Manipur",
    City: "Lilong",
  },
  {
    Id: "910",
    State: "Manipur",
    City: "Mayang Imphal",
  },
  {
    Id: "911",
    State: "Manipur",
    City: "Thoubal",
  },
  {
    Id: "912",
    State: "Meghalaya",
    City: "Jowai",
  },
  {
    Id: "913",
    State: "Meghalaya",
    City: "Nongstoin",
  },
  {
    Id: "914",
    State: "Meghalaya",
    City: "Shillong",
  },
  {
    Id: "915",
    State: "Meghalaya",
    City: "Tura",
  },
  {
    Id: "916",
    State: "Mizoram",
    City: "Aizawl",
  },
  {
    Id: "917",
    State: "Mizoram",
    City: "Champhai",
  },
  {
    Id: "918",
    State: "Mizoram",
    City: "Lunglei",
  },
  {
    Id: "919",
    State: "Mizoram",
    City: "Saiha",
  },
  {
    Id: "920",
    State: "Nagaland",
    City: "Dimapur",
  },
  {
    Id: "921",
    State: "Nagaland",
    City: "Kohima",
  },
  {
    Id: "922",
    State: "Nagaland",
    City: "Mokokchung",
  },
  {
    Id: "923",
    State: "Nagaland",
    City: "Tuensang",
  },
  {
    Id: "924",
    State: "Nagaland",
    City: "Wokha",
  },
  {
    Id: "925",
    State: "Nagaland",
    City: "Zunheboto",
  },
  {
    Id: "950",
    State: "Orissa",
    City: "Anandapur",
  },
  {
    Id: "951",
    State: "Orissa",
    City: "Anugul",
  },
  {
    Id: "952",
    State: "Orissa",
    City: "Asika",
  },
  {
    Id: "953",
    State: "Orissa",
    City: "Balangir",
  },
  {
    Id: "954",
    State: "Orissa",
    City: "Balasore",
  },
  {
    Id: "955",
    State: "Orissa",
    City: "Baleshwar",
  },
  {
    Id: "956",
    State: "Orissa",
    City: "Bamra",
  },
  {
    Id: "957",
    State: "Orissa",
    City: "Barbil",
  },
  {
    Id: "958",
    State: "Orissa",
    City: "Bargarh",
  },
  {
    Id: "959",
    State: "Orissa",
    City: "Bargarh",
  },
  {
    Id: "960",
    State: "Orissa",
    City: "Baripada",
  },
  {
    Id: "961",
    State: "Orissa",
    City: "Basudebpur",
  },
  {
    Id: "962",
    State: "Orissa",
    City: "Belpahar",
  },
  {
    Id: "963",
    State: "Orissa",
    City: "Bhadrak",
  },
  {
    Id: "964",
    State: "Orissa",
    City: "Bhawanipatna",
  },
  {
    Id: "965",
    State: "Orissa",
    City: "Bhuban",
  },
  {
    Id: "966",
    State: "Orissa",
    City: "Bhubaneswar",
  },
  {
    Id: "967",
    State: "Orissa",
    City: "Biramitrapur",
  },
  {
    Id: "968",
    State: "Orissa",
    City: "Brahmapur",
  },
  {
    Id: "969",
    State: "Orissa",
    City: "Brajrajnagar",
  },
  {
    Id: "970",
    State: "Orissa",
    City: "Byasanagar",
  },
  {
    Id: "971",
    State: "Orissa",
    City: "Cuttack",
  },
  {
    Id: "972",
    State: "Orissa",
    City: "Debagarh",
  },
  {
    Id: "973",
    State: "Orissa",
    City: "Dhenkanal",
  },
  {
    Id: "974",
    State: "Orissa",
    City: "Gunupur",
  },
  {
    Id: "975",
    State: "Orissa",
    City: "Hinjilicut",
  },
  {
    Id: "976",
    State: "Orissa",
    City: "Jagatsinghapur",
  },
  {
    Id: "977",
    State: "Orissa",
    City: "Jajapur",
  },
  {
    Id: "978",
    State: "Orissa",
    City: "Jaleswar",
  },
  {
    Id: "979",
    State: "Orissa",
    City: "Jatani",
  },
  {
    Id: "980",
    State: "Orissa",
    City: "Jeypur",
  },
  {
    Id: "981",
    State: "Orissa",
    City: "Jharsuguda",
  },
  {
    Id: "982",
    State: "Orissa",
    City: "Joda",
  },
  {
    Id: "983",
    State: "Orissa",
    City: "Kantabanji",
  },
  {
    Id: "984",
    State: "Orissa",
    City: "Karanjia",
  },
  {
    Id: "985",
    State: "Orissa",
    City: "Kendrapara",
  },
  {
    Id: "986",
    State: "Orissa",
    City: "Kendujhar",
  },
  {
    Id: "987",
    State: "Orissa",
    City: "Khordha",
  },
  {
    Id: "988",
    State: "Orissa",
    City: "Koraput",
  },
  {
    Id: "989",
    State: "Orissa",
    City: "Malkangiri",
  },
  {
    Id: "990",
    State: "Orissa",
    City: "Nabarangapur",
  },
  {
    Id: "991",
    State: "Orissa",
    City: "Paradip",
  },
  {
    Id: "992",
    State: "Orissa",
    City: "Parlakhemundi",
  },
  {
    Id: "993",
    State: "Orissa",
    City: "Pattamundai",
  },
  {
    Id: "994",
    State: "Orissa",
    City: "Phulabani",
  },
  {
    Id: "995",
    State: "Orissa",
    City: "Puri",
  },
  {
    Id: "996",
    State: "Orissa",
    City: "Rairangpur",
  },
  {
    Id: "997",
    State: "Orissa",
    City: "Rajagangapur",
  },
  {
    Id: "998",
    State: "Orissa",
    City: "Raurkela",
  },
  {
    Id: "999",
    State: "Orissa",
    City: "Rayagada",
  },
  {
    Id: "1000",
    State: "Orissa",
    City: "Sambalpur",
  },
  {
    Id: "1001",
    State: "Orissa",
    City: "Soro",
  },
  {
    Id: "1002",
    State: "Orissa",
    City: "Sunabeda",
  },
  {
    Id: "1003",
    State: "Orissa",
    City: "Sundargarh",
  },
  {
    Id: "1004",
    State: "Orissa",
    City: "Talcher",
  },
  {
    Id: "1005",
    State: "Orissa",
    City: "Titlagarh",
  },
  {
    Id: "1006",
    State: "Orissa",
    City: "Umarkote",
  },
  {
    Id: "1007",
    State: "Pondicherry",
    City: "Karaikal",
  },
  {
    Id: "1008",
    State: "Pondicherry",
    City: "Mahe",
  },
  {
    Id: "1009",
    State: "Pondicherry",
    City: "Pondicherry",
  },
  {
    Id: "1010",
    State: "Pondicherry",
    City: "Yanam",
  },
  {
    Id: "1011",
    State: "Punjab",
    City: "Ahmedgarh",
  },
  {
    Id: "1012",
    State: "Punjab",
    City: "Amritsar",
  },
  {
    Id: "1013",
    State: "Punjab",
    City: "Barnala",
  },
  {
    Id: "1014",
    State: "Punjab",
    City: "Batala",
  },
  {
    Id: "1015",
    State: "Punjab",
    City: "Bathinda",
  },
  {
    Id: "1016",
    State: "Punjab",
    City: "Bhagha Purana",
  },
  {
    Id: "1017",
    State: "Punjab",
    City: "Budhlada",
  },
  {
    Id: "1018",
    State: "Punjab",
    City: "Chandigarh",
  },
  {
    Id: "1019",
    State: "Punjab",
    City: "Dasua",
  },
  {
    Id: "1020",
    State: "Punjab",
    City: "Dhuri",
  },
  {
    Id: "1021",
    State: "Punjab",
    City: "Dinanagar",
  },
  {
    Id: "1022",
    State: "Punjab",
    City: "Faridkot",
  },
  {
    Id: "1023",
    State: "Punjab",
    City: "Fazilka",
  },
  {
    Id: "1024",
    State: "Punjab",
    City: "Firozpur",
  },
  {
    Id: "1025",
    State: "Punjab",
    City: "Firozpur Cantt.",
  },
  {
    Id: "1026",
    State: "Punjab",
    City: "Giddarbaha",
  },
  {
    Id: "1027",
    State: "Punjab",
    City: "Gobindgarh",
  },
  {
    Id: "1028",
    State: "Punjab",
    City: "Gurdaspur",
  },
  {
    Id: "1029",
    State: "Punjab",
    City: "Hoshiarpur",
  },
  {
    Id: "1030",
    State: "Punjab",
    City: "Jagraon",
  },
  {
    Id: "1031",
    State: "Punjab",
    City: "Jaitu",
  },
  {
    Id: "1032",
    State: "Punjab",
    City: "Jalalabad",
  },
  {
    Id: "1033",
    State: "Punjab",
    City: "Jalandhar",
  },
  {
    Id: "1034",
    State: "Punjab",
    City: "Jalandhar Cantt.",
  },
  {
    Id: "1035",
    State: "Punjab",
    City: "Jandiala",
  },
  {
    Id: "1036",
    State: "Punjab",
    City: "Kapurthala",
  },
  {
    Id: "1037",
    State: "Punjab",
    City: "Karoran",
  },
  {
    Id: "1038",
    State: "Punjab",
    City: "Kartarpur",
  },
  {
    Id: "1039",
    State: "Punjab",
    City: "Khanna",
  },
  {
    Id: "1040",
    State: "Punjab",
    City: "Kharar",
  },
  {
    Id: "1041",
    State: "Punjab",
    City: "Kot Kapura",
  },
  {
    Id: "1042",
    State: "Punjab",
    City: "Kurali",
  },
  {
    Id: "1043",
    State: "Punjab",
    City: "Longowal",
  },
  {
    Id: "1044",
    State: "Punjab",
    City: "Ludhiana",
  },
  {
    Id: "1045",
    State: "Punjab",
    City: "Malerkotla",
  },
  {
    Id: "1046",
    State: "Punjab",
    City: "Malout",
  },
  {
    Id: "1047",
    State: "Punjab",
    City: "Mansa",
  },
  {
    Id: "1048",
    State: "Punjab",
    City: "Maur",
  },
  {
    Id: "1049",
    State: "Punjab",
    City: "Moga",
  },
  {
    Id: "1050",
    State: "Punjab",
    City: "Mohali",
  },
  {
    Id: "1051",
    State: "Punjab",
    City: "Morinda",
  },
  {
    Id: "1052",
    State: "Punjab",
    City: "Mukerian",
  },
  {
    Id: "1053",
    State: "Punjab",
    City: "Muktsar",
  },
  {
    Id: "1054",
    State: "Punjab",
    City: "Nabha",
  },
  {
    Id: "1055",
    State: "Punjab",
    City: "Nakodar",
  },
  {
    Id: "1056",
    State: "Punjab",
    City: "Nangal",
  },
  {
    Id: "1057",
    State: "Punjab",
    City: "Nawanshahr",
  },
  {
    Id: "1058",
    State: "Punjab",
    City: "Pathankot",
  },
  {
    Id: "1059",
    State: "Punjab",
    City: "Patiala",
  },
  {
    Id: "1060",
    State: "Punjab",
    City: "Patran",
  },
  {
    Id: "1061",
    State: "Punjab",
    City: "Patti",
  },
  {
    Id: "1062",
    State: "Punjab",
    City: "Phagwara",
  },
  {
    Id: "1063",
    State: "Punjab",
    City: "Phillaur",
  },
  {
    Id: "1064",
    State: "Punjab",
    City: "Qadian",
  },
  {
    Id: "1065",
    State: "Punjab",
    City: "Raikot",
  },
  {
    Id: "1066",
    State: "Punjab",
    City: "Rajpura",
  },
  {
    Id: "1067",
    State: "Punjab",
    City: "Rampura Phul",
  },
  {
    Id: "1068",
    State: "Punjab",
    City: "Rupnagar",
  },
  {
    Id: "1069",
    State: "Punjab",
    City: "Samana",
  },
  {
    Id: "1070",
    State: "Punjab",
    City: "Sangrur",
  },
  {
    Id: "1071",
    State: "Punjab",
    City: "Sirhind Fatehgarh Sahib",
  },
  {
    Id: "1072",
    State: "Punjab",
    City: "Sujanpur",
  },
  {
    Id: "1073",
    State: "Punjab",
    City: "Sunam",
  },
  {
    Id: "1074",
    State: "Punjab",
    City: "Talwara",
  },
  {
    Id: "1075",
    State: "Punjab",
    City: "Tarn Taran",
  },
  {
    Id: "1076",
    State: "Punjab",
    City: "Urmar Tanda",
  },
  {
    Id: "1077",
    State: "Punjab",
    City: "Zira",
  },
  {
    Id: "1078",
    State: "Punjab",
    City: "Zirakpur",
  },
  {
    Id: "1079",
    State: "Rajasthan",
    City: "Bali",
  },
  {
    Id: "1080",
    State: "Rajasthan",
    City: "Banswara",
  },
  {
    Id: "1081",
    State: "Rajasthan",
    City: "Ajmer",
  },
  {
    Id: "1082",
    State: "Rajasthan",
    City: "Alwar",
  },
  {
    Id: "1083",
    State: "Rajasthan",
    City: "Bandikui",
  },
  {
    Id: "1084",
    State: "Rajasthan",
    City: "Baran",
  },
  {
    Id: "1085",
    State: "Rajasthan",
    City: "Barmer",
  },
  {
    Id: "1086",
    State: "Rajasthan",
    City: "Bikaner",
  },
  {
    Id: "1087",
    State: "Rajasthan",
    City: "Fatehpur",
  },
  {
    Id: "1088",
    State: "Rajasthan",
    City: "Jaipur",
  },
  {
    Id: "1089",
    State: "Rajasthan",
    City: "Jaisalmer",
  },
  {
    Id: "1090",
    State: "Rajasthan",
    City: "Jodhpur",
  },
  {
    Id: "1091",
    State: "Rajasthan",
    City: "Kota",
  },
  {
    Id: "1092",
    State: "Rajasthan",
    City: "Lachhmangarh",
  },
  {
    Id: "1093",
    State: "Rajasthan",
    City: "Ladnu",
  },
  {
    Id: "1094",
    State: "Rajasthan",
    City: "Lakheri",
  },
  {
    Id: "1095",
    State: "Rajasthan",
    City: "Lalsot",
  },
  {
    Id: "1096",
    State: "Rajasthan",
    City: "Losal",
  },
  {
    Id: "1097",
    State: "Rajasthan",
    City: "Makrana",
  },
  {
    Id: "1098",
    State: "Rajasthan",
    City: "Malpura",
  },
  {
    Id: "1099",
    State: "Rajasthan",
    City: "Mandalgarh",
  },
  {
    Id: "1100",
    State: "Rajasthan",
    City: "Mandawa",
  },
  {
    Id: "1101",
    State: "Rajasthan",
    City: "Mangrol",
  },
  {
    Id: "1102",
    State: "Rajasthan",
    City: "Merta City",
  },
  {
    Id: "1103",
    State: "Rajasthan",
    City: "Mount Abu",
  },
  {
    Id: "1104",
    State: "Rajasthan",
    City: "Nadbai",
  },
  {
    Id: "1105",
    State: "Rajasthan",
    City: "Nagar",
  },
  {
    Id: "1106",
    State: "Rajasthan",
    City: "Nagaur",
  },
  {
    Id: "1107",
    State: "Rajasthan",
    City: "Nargund",
  },
  {
    Id: "1108",
    State: "Rajasthan",
    City: "Nasirabad",
  },
  {
    Id: "1109",
    State: "Rajasthan",
    City: "Nathdwara",
  },
  {
    Id: "1110",
    State: "Rajasthan",
    City: "Navalgund",
  },
  {
    Id: "1111",
    State: "Rajasthan",
    City: "Nawalgarh",
  },
  {
    Id: "1112",
    State: "Rajasthan",
    City: "Neem-Ka-Thana",
  },
  {
    Id: "1113",
    State: "Rajasthan",
    City: "Nelamangala",
  },
  {
    Id: "1114",
    State: "Rajasthan",
    City: "Nimbahera",
  },
  {
    Id: "1115",
    State: "Rajasthan",
    City: "Nipani",
  },
  {
    Id: "1116",
    State: "Rajasthan",
    City: "Niwai",
  },
  {
    Id: "1117",
    State: "Rajasthan",
    City: "Nohar",
  },
  {
    Id: "1118",
    State: "Rajasthan",
    City: "Nokha",
  },
  {
    Id: "1119",
    State: "Rajasthan",
    City: "Pali",
  },
  {
    Id: "1120",
    State: "Rajasthan",
    City: "Phalodi",
  },
  {
    Id: "1121",
    State: "Rajasthan",
    City: "Phulera",
  },
  {
    Id: "1122",
    State: "Rajasthan",
    City: "Pilani",
  },
  {
    Id: "1123",
    State: "Rajasthan",
    City: "Pilibanga",
  },
  {
    Id: "1124",
    State: "Rajasthan",
    City: "Pindwara",
  },
  {
    Id: "1125",
    State: "Rajasthan",
    City: "Pipar City",
  },
  {
    Id: "1126",
    State: "Rajasthan",
    City: "Prantij",
  },
  {
    Id: "1127",
    State: "Rajasthan",
    City: "Pratapgarh",
  },
  {
    Id: "1128",
    State: "Rajasthan",
    City: "Raisinghnagar",
  },
  {
    Id: "1129",
    State: "Rajasthan",
    City: "Rajakhera",
  },
  {
    Id: "1130",
    State: "Rajasthan",
    City: "Rajaldesar",
  },
  {
    Id: "1131",
    State: "Rajasthan",
    City: "Rajgarh (Alwar)",
  },
  {
    Id: "1132",
    State: "Rajasthan",
    City: "Rajgarh (Churu",
  },
  {
    Id: "1133",
    State: "Rajasthan",
    City: "Rajsamand",
  },
  {
    Id: "1134",
    State: "Rajasthan",
    City: "Ramganj Mandi",
  },
  {
    Id: "1135",
    State: "Rajasthan",
    City: "Ramgarh",
  },
  {
    Id: "1136",
    State: "Rajasthan",
    City: "Ratangarh",
  },
  {
    Id: "1137",
    State: "Rajasthan",
    City: "Rawatbhata",
  },
  {
    Id: "1138",
    State: "Rajasthan",
    City: "Rawatsar",
  },
  {
    Id: "1139",
    State: "Rajasthan",
    City: "Reengus",
  },
  {
    Id: "1140",
    State: "Rajasthan",
    City: "Sadri",
  },
  {
    Id: "1141",
    State: "Rajasthan",
    City: "Sadulshahar",
  },
  {
    Id: "1142",
    State: "Rajasthan",
    City: "Sagwara",
  },
  {
    Id: "1143",
    State: "Rajasthan",
    City: "Sambhar",
  },
  {
    Id: "1144",
    State: "Rajasthan",
    City: "Sanchore",
  },
  {
    Id: "1145",
    State: "Rajasthan",
    City: "Sangaria",
  },
  {
    Id: "1146",
    State: "Rajasthan",
    City: "Sardarshahar",
  },
  {
    Id: "1147",
    State: "Rajasthan",
    City: "Sawai Madhopur",
  },
  {
    Id: "1148",
    State: "Rajasthan",
    City: "Shahpura",
  },
  {
    Id: "1149",
    State: "Rajasthan",
    City: "Shahpura",
  },
  {
    Id: "1150",
    State: "Rajasthan",
    City: "Sheoganj",
  },
  {
    Id: "1151",
    State: "Rajasthan",
    City: "Sikar",
  },
  {
    Id: "1152",
    State: "Rajasthan",
    City: "Sirohi",
  },
  {
    Id: "1153",
    State: "Rajasthan",
    City: "Sojat",
  },
  {
    Id: "1154",
    State: "Rajasthan",
    City: "Sri Madhopur",
  },
  {
    Id: "1155",
    State: "Rajasthan",
    City: "Sujangarh",
  },
  {
    Id: "1156",
    State: "Rajasthan",
    City: "Sumerpur",
  },
  {
    Id: "1157",
    State: "Rajasthan",
    City: "Suratgarh",
  },
  {
    Id: "1158",
    State: "Rajasthan",
    City: "Taranagar",
  },
  {
    Id: "1159",
    State: "Rajasthan",
    City: "Todabhim",
  },
  {
    Id: "1160",
    State: "Rajasthan",
    City: "Todaraisingh",
  },
  {
    Id: "1161",
    State: "Rajasthan",
    City: "Tonk",
  },
  {
    Id: "1162",
    State: "Rajasthan",
    City: "Udaipur",
  },
  {
    Id: "1163",
    State: "Rajasthan",
    City: "Udaipurwati",
  },
  {
    Id: "1164",
    State: "Rajasthan",
    City: "Vijainagar",
  },
  {
    Id: "1165",
    State: "Sikkim",
    City: "Gangtok",
  },
  {
    Id: "1166",
    State: "West Bengal",
    City: "Calcutta",
  },
  {
    Id: "1167",
    State: "Tamil Nadu",
    City: "Arakkonam",
  },
  {
    Id: "1168",
    State: "Tamil Nadu",
    City: "Arcot",
  },
  {
    Id: "1169",
    State: "Tamil Nadu",
    City: "Aruppukkottai",
  },
  {
    Id: "1170",
    State: "Tamil Nadu",
    City: "Bhavani",
  },
  {
    Id: "1171",
    State: "Tamil Nadu",
    City: "Chengalpattu",
  },
  {
    Id: "1172",
    State: "Tamil Nadu",
    City: "Chennai",
  },
  {
    Id: "1173",
    State: "Tamil Nadu",
    City: "Chinna salem",
  },
  {
    Id: "1174",
    State: "Tamil Nadu",
    City: "Coimbatore",
  },
  {
    Id: "1175",
    State: "Tamil Nadu",
    City: "Coonoor",
  },
  {
    Id: "1176",
    State: "Tamil Nadu",
    City: "Cuddalore",
  },
  {
    Id: "1177",
    State: "Tamil Nadu",
    City: "Dharmapuri",
  },
  {
    Id: "1178",
    State: "Tamil Nadu",
    City: "Dindigul",
  },
  {
    Id: "1179",
    State: "Tamil Nadu",
    City: "Erode",
  },
  {
    Id: "1180",
    State: "Tamil Nadu",
    City: "Gudalur",
  },
  {
    Id: "1181",
    State: "Tamil Nadu",
    City: "Gudalur",
  },
  {
    Id: "1182",
    State: "Tamil Nadu",
    City: "Gudalur",
  },
  {
    Id: "1183",
    State: "Tamil Nadu",
    City: "Kanchipuram",
  },
  {
    Id: "1184",
    State: "Tamil Nadu",
    City: "Karaikudi",
  },
  {
    Id: "1185",
    State: "Tamil Nadu",
    City: "Karungal",
  },
  {
    Id: "1186",
    State: "Tamil Nadu",
    City: "Karur",
  },
  {
    Id: "1187",
    State: "Tamil Nadu",
    City: "Kollankodu",
  },
  {
    Id: "1188",
    State: "Tamil Nadu",
    City: "Lalgudi",
  },
  {
    Id: "1189",
    State: "Tamil Nadu",
    City: "Madurai",
  },
  {
    Id: "1190",
    State: "Tamil Nadu",
    City: "Nagapattinam",
  },
  {
    Id: "1191",
    State: "Tamil Nadu",
    City: "Nagercoil",
  },
  {
    Id: "1192",
    State: "Tamil Nadu",
    City: "Namagiripettai",
  },
  {
    Id: "1193",
    State: "Tamil Nadu",
    City: "Namakkal",
  },
  {
    Id: "1194",
    State: "Tamil Nadu",
    City: "Nandivaram-Guduvancheri",
  },
  {
    Id: "1195",
    State: "Tamil Nadu",
    City: "Nanjikottai",
  },
  {
    Id: "1196",
    State: "Tamil Nadu",
    City: "Natham",
  },
  {
    Id: "1197",
    State: "Tamil Nadu",
    City: "Nellikuppam",
  },
  {
    Id: "1198",
    State: "Tamil Nadu",
    City: "Neyveli",
  },
  {
    Id: "1199",
    State: "Tamil Nadu",
    City: "O' Valley",
  },
  {
    Id: "1200",
    State: "Tamil Nadu",
    City: "Oddanchatram",
  },
  {
    Id: "1201",
    State: "Tamil Nadu",
    City: "P.N.Patti",
  },
  {
    Id: "1202",
    State: "Tamil Nadu",
    City: "Pacode",
  },
  {
    Id: "1203",
    State: "Tamil Nadu",
    City: "Padmanabhapuram",
  },
  {
    Id: "1204",
    State: "Tamil Nadu",
    City: "Palani",
  },
  {
    Id: "1205",
    State: "Tamil Nadu",
    City: "Palladam",
  },
  {
    Id: "1206",
    State: "Tamil Nadu",
    City: "Pallapatti",
  },
  {
    Id: "1207",
    State: "Tamil Nadu",
    City: "Pallikonda",
  },
  {
    Id: "1208",
    State: "Tamil Nadu",
    City: "Panagudi",
  },
  {
    Id: "1209",
    State: "Tamil Nadu",
    City: "Panruti",
  },
  {
    Id: "1210",
    State: "Tamil Nadu",
    City: "Paramakudi",
  },
  {
    Id: "1211",
    State: "Tamil Nadu",
    City: "Parangipettai",
  },
  {
    Id: "1212",
    State: "Tamil Nadu",
    City: "Pattukkottai",
  },
  {
    Id: "1213",
    State: "Tamil Nadu",
    City: "Perambalur",
  },
  {
    Id: "1214",
    State: "Tamil Nadu",
    City: "Peravurani",
  },
  {
    Id: "1215",
    State: "Tamil Nadu",
    City: "Periyakulam",
  },
  {
    Id: "1216",
    State: "Tamil Nadu",
    City: "Periyasemur",
  },
  {
    Id: "1217",
    State: "Tamil Nadu",
    City: "Pernampattu",
  },
  {
    Id: "1218",
    State: "Tamil Nadu",
    City: "Pollachi",
  },
  {
    Id: "1219",
    State: "Tamil Nadu",
    City: "Polur",
  },
  {
    Id: "1220",
    State: "Tamil Nadu",
    City: "Ponneri",
  },
  {
    Id: "1221",
    State: "Tamil Nadu",
    City: "Pudukkottai",
  },
  {
    Id: "1222",
    State: "Tamil Nadu",
    City: "Pudupattinam",
  },
  {
    Id: "1223",
    State: "Tamil Nadu",
    City: "Puliyankudi",
  },
  {
    Id: "1224",
    State: "Tamil Nadu",
    City: "Punjaipugalur",
  },
  {
    Id: "1225",
    State: "Tamil Nadu",
    City: "Rajapalayam",
  },
  {
    Id: "1226",
    State: "Tamil Nadu",
    City: "Ramanathapuram",
  },
  {
    Id: "1227",
    State: "Tamil Nadu",
    City: "Rameshwaram",
  },
  {
    Id: "1228",
    State: "Tamil Nadu",
    City: "Rasipuram",
  },
  {
    Id: "1229",
    State: "Tamil Nadu",
    City: "Salem",
  },
  {
    Id: "1230",
    State: "Tamil Nadu",
    City: "Sankarankoil",
  },
  {
    Id: "1231",
    State: "Tamil Nadu",
    City: "Sankari",
  },
  {
    Id: "1232",
    State: "Tamil Nadu",
    City: "Sathyamangalam",
  },
  {
    Id: "1233",
    State: "Tamil Nadu",
    City: "Sattur",
  },
  {
    Id: "1234",
    State: "Tamil Nadu",
    City: "Shenkottai",
  },
  {
    Id: "1235",
    State: "Tamil Nadu",
    City: "Sholavandan",
  },
  {
    Id: "1236",
    State: "Tamil Nadu",
    City: "Sholingur",
  },
  {
    Id: "1237",
    State: "Tamil Nadu",
    City: "Sirkali",
  },
  {
    Id: "1238",
    State: "Tamil Nadu",
    City: "Sivaganga",
  },
  {
    Id: "1239",
    State: "Tamil Nadu",
    City: "Sivagiri",
  },
  {
    Id: "1240",
    State: "Tamil Nadu",
    City: "Sivakasi",
  },
  {
    Id: "1241",
    State: "Tamil Nadu",
    City: "Srivilliputhur",
  },
  {
    Id: "1242",
    State: "Tamil Nadu",
    City: "Surandai",
  },
  {
    Id: "1243",
    State: "Tamil Nadu",
    City: "Suriyampalayam",
  },
  {
    Id: "1244",
    State: "Tamil Nadu",
    City: "Tenkasi",
  },
  {
    Id: "1245",
    State: "Tamil Nadu",
    City: "Thammampatti",
  },
  {
    Id: "1246",
    State: "Tamil Nadu",
    City: "Thanjavur",
  },
  {
    Id: "1247",
    State: "Tamil Nadu",
    City: "Tharamangalam",
  },
  {
    Id: "1248",
    State: "Tamil Nadu",
    City: "Tharangambadi",
  },
  {
    Id: "1249",
    State: "Tamil Nadu",
    City: "Theni Allinagaram",
  },
  {
    Id: "1250",
    State: "Tamil Nadu",
    City: "Thirumangalam",
  },
  {
    Id: "1251",
    State: "Tamil Nadu",
    City: "Thirunindravur",
  },
  {
    Id: "1252",
    State: "Tamil Nadu",
    City: "Thiruparappu",
  },
  {
    Id: "1253",
    State: "Tamil Nadu",
    City: "Thirupuvanam",
  },
  {
    Id: "1254",
    State: "Tamil Nadu",
    City: "Thiruthuraipoondi",
  },
  {
    Id: "1255",
    State: "Tamil Nadu",
    City: "Thiruvallur",
  },
  {
    Id: "1256",
    State: "Tamil Nadu",
    City: "Thiruvarur",
  },
  {
    Id: "1257",
    State: "Tamil Nadu",
    City: "Thoothukudi",
  },
  {
    Id: "1258",
    State: "Tamil Nadu",
    City: "Thuraiyur",
  },
  {
    Id: "1259",
    State: "Tamil Nadu",
    City: "Tindivanam",
  },
  {
    Id: "1260",
    State: "Tamil Nadu",
    City: "Tiruchendur",
  },
  {
    Id: "1261",
    State: "Tamil Nadu",
    City: "Tiruchengode",
  },
  {
    Id: "1262",
    State: "Tamil Nadu",
    City: "Tiruchirappalli",
  },
  {
    Id: "1263",
    State: "Tamil Nadu",
    City: "Tirukalukundram",
  },
  {
    Id: "1264",
    State: "Tamil Nadu",
    City: "Tirukkoyilur",
  },
  {
    Id: "1265",
    State: "Tamil Nadu",
    City: "Tirunelveli",
  },
  {
    Id: "1266",
    State: "Tamil Nadu",
    City: "Tirupathur",
  },
  {
    Id: "1267",
    State: "Tamil Nadu",
    City: "Tirupathur",
  },
  {
    Id: "1268",
    State: "Tamil Nadu",
    City: "Tiruppur",
  },
  {
    Id: "1269",
    State: "Tamil Nadu",
    City: "Tiruttani",
  },
  {
    Id: "1270",
    State: "Tamil Nadu",
    City: "Tiruvannamalai",
  },
  {
    Id: "1271",
    State: "Tamil Nadu",
    City: "Tiruvethipuram",
  },
  {
    Id: "1272",
    State: "Tamil Nadu",
    City: "Tittakudi",
  },
  {
    Id: "1273",
    State: "Tamil Nadu",
    City: "Udhagamandalam",
  },
  {
    Id: "1274",
    State: "Tamil Nadu",
    City: "Udumalaipettai",
  },
  {
    Id: "1275",
    State: "Tamil Nadu",
    City: "Unnamalaikadai",
  },
  {
    Id: "1276",
    State: "Tamil Nadu",
    City: "Usilampatti",
  },
  {
    Id: "1277",
    State: "Tamil Nadu",
    City: "Uthamapalayam",
  },
  {
    Id: "1278",
    State: "Tamil Nadu",
    City: "Uthiramerur",
  },
  {
    Id: "1279",
    State: "Tamil Nadu",
    City: "Vadakkuvalliyur",
  },
  {
    Id: "1280",
    State: "Tamil Nadu",
    City: "Vadalur",
  },
  {
    Id: "1281",
    State: "Tamil Nadu",
    City: "Vadipatti",
  },
  {
    Id: "1282",
    State: "Tamil Nadu",
    City: "Valparai",
  },
  {
    Id: "1283",
    State: "Tamil Nadu",
    City: "Vandavasi",
  },
  {
    Id: "1284",
    State: "Tamil Nadu",
    City: "Vaniyambadi",
  },
  {
    Id: "1285",
    State: "Tamil Nadu",
    City: "Vedaranyam",
  },
  {
    Id: "1286",
    State: "Tamil Nadu",
    City: "Vellakoil",
  },
  {
    Id: "1287",
    State: "Tamil Nadu",
    City: "Vellore",
  },
  {
    Id: "1288",
    State: "Tamil Nadu",
    City: "Vikramasingapuram",
  },
  {
    Id: "1289",
    State: "Tamil Nadu",
    City: "Viluppuram",
  },
  {
    Id: "1290",
    State: "Tamil Nadu",
    City: "Virudhachalam",
  },
  {
    Id: "1291",
    State: "Tamil Nadu",
    City: "Virudhunagar",
  },
  {
    Id: "1292",
    State: "Tamil Nadu",
    City: "Viswanatham",
  },
  {
    Id: "1293",
    State: "Tripura",
    City: "Agartala",
  },
  {
    Id: "1294",
    State: "Tripura",
    City: "Badharghat",
  },
  {
    Id: "1295",
    State: "Tripura",
    City: "Dharmanagar",
  },
  {
    Id: "1296",
    State: "Tripura",
    City: "Indranagar",
  },
  {
    Id: "1297",
    State: "Tripura",
    City: "Jogendranagar",
  },
  {
    Id: "1298",
    State: "Tripura",
    City: "Kailasahar",
  },
  {
    Id: "1299",
    State: "Tripura",
    City: "Khowai",
  },
  {
    Id: "1300",
    State: "Tripura",
    City: "Pratapgarh",
  },
  {
    Id: "1301",
    State: "Tripura",
    City: "Udaipur",
  },
  {
    Id: "1302",
    State: "Uttar Pradesh",
    City: "Achhnera",
  },
  {
    Id: "1303",
    State: "Uttar Pradesh",
    City: "Adari",
  },
  {
    Id: "1304",
    State: "Uttar Pradesh",
    City: "Agra",
  },
  {
    Id: "1305",
    State: "Uttar Pradesh",
    City: "Aligarh",
  },
  {
    Id: "1306",
    State: "Uttar Pradesh",
    City: "Allahabad",
  },
  {
    Id: "1307",
    State: "Uttar Pradesh",
    City: "Amroha",
  },
  {
    Id: "1308",
    State: "Uttar Pradesh",
    City: "Azamgarh",
  },
  {
    Id: "1309",
    State: "Uttar Pradesh",
    City: "Bahraich",
  },
  {
    Id: "1310",
    State: "Uttar Pradesh",
    City: "Ballia",
  },
  {
    Id: "1311",
    State: "Uttar Pradesh",
    City: "Balrampur",
  },
  {
    Id: "1312",
    State: "Uttar Pradesh",
    City: "Banda",
  },
  {
    Id: "1313",
    State: "Uttar Pradesh",
    City: "Bareilly",
  },
  {
    Id: "1314",
    State: "Uttar Pradesh",
    City: "Chandausi",
  },
  {
    Id: "1315",
    State: "Uttar Pradesh",
    City: "Dadri",
  },
  {
    Id: "1316",
    State: "Uttar Pradesh",
    City: "Deoria",
  },
  {
    Id: "1317",
    State: "Uttar Pradesh",
    City: "Etawah",
  },
  {
    Id: "1318",
    State: "Uttar Pradesh",
    City: "Fatehabad",
  },
  {
    Id: "1319",
    State: "Uttar Pradesh",
    City: "Fatehpur",
  },
  {
    Id: "1320",
    State: "Uttar Pradesh",
    City: "Fatehpur",
  },
  {
    Id: "1321",
    State: "Uttar Pradesh",
    City: "Greater Noida",
  },
  {
    Id: "1322",
    State: "Uttar Pradesh",
    City: "Hamirpur",
  },
  {
    Id: "1323",
    State: "Uttar Pradesh",
    City: "Hardoi",
  },
  {
    Id: "1324",
    State: "Uttar Pradesh",
    City: "Jajmau",
  },
  {
    Id: "1325",
    State: "Uttar Pradesh",
    City: "Jaunpur",
  },
  {
    Id: "1326",
    State: "Uttar Pradesh",
    City: "Jhansi",
  },
  {
    Id: "1327",
    State: "Uttar Pradesh",
    City: "Kalpi",
  },
  {
    Id: "1328",
    State: "Uttar Pradesh",
    City: "Kanpur",
  },
  {
    Id: "1329",
    State: "Uttar Pradesh",
    City: "Kota",
  },
  {
    Id: "1330",
    State: "Uttar Pradesh",
    City: "Laharpur",
  },
  {
    Id: "1331",
    State: "Uttar Pradesh",
    City: "Lakhimpur",
  },
  {
    Id: "1332",
    State: "Uttar Pradesh",
    City: "Lal Gopalganj Nindaura",
  },
  {
    Id: "1333",
    State: "Uttar Pradesh",
    City: "Lalganj",
  },
  {
    Id: "1334",
    State: "Uttar Pradesh",
    City: "Lalitpur",
  },
  {
    Id: "1335",
    State: "Uttar Pradesh",
    City: "Lar",
  },
  {
    Id: "1336",
    State: "Uttar Pradesh",
    City: "Loni",
  },
  {
    Id: "1337",
    State: "Uttar Pradesh",
    City: "Lucknow",
  },
  {
    Id: "1338",
    State: "Uttar Pradesh",
    City: "Mathura",
  },
  {
    Id: "1339",
    State: "Uttar Pradesh",
    City: "Meerut",
  },
  {
    Id: "1340",
    State: "Uttar Pradesh",
    City: "Modinagar",
  },
  {
    Id: "1341",
    State: "Uttar Pradesh",
    City: "Muradnagar",
  },
  {
    Id: "1342",
    State: "Uttar Pradesh",
    City: "Nagina",
  },
  {
    Id: "1343",
    State: "Uttar Pradesh",
    City: "Najibabad",
  },
  {
    Id: "1344",
    State: "Uttar Pradesh",
    City: "Nakur",
  },
  {
    Id: "1345",
    State: "Uttar Pradesh",
    City: "Nanpara",
  },
  {
    Id: "1346",
    State: "Uttar Pradesh",
    City: "Naraura",
  },
  {
    Id: "1347",
    State: "Uttar Pradesh",
    City: "Naugawan Sadat",
  },
  {
    Id: "1348",
    State: "Uttar Pradesh",
    City: "Nautanwa",
  },
  {
    Id: "1349",
    State: "Uttar Pradesh",
    City: "Nawabganj",
  },
  {
    Id: "1350",
    State: "Uttar Pradesh",
    City: "Nehtaur",
  },
  {
    Id: "1351",
    State: "Uttar Pradesh",
    City: "NOIDA",
  },
  {
    Id: "1352",
    State: "Uttar Pradesh",
    City: "Noorpur",
  },
  {
    Id: "1353",
    State: "Uttar Pradesh",
    City: "Obra",
  },
  {
    Id: "1354",
    State: "Uttar Pradesh",
    City: "Orai",
  },
  {
    Id: "1355",
    State: "Uttar Pradesh",
    City: "Padrauna",
  },
  {
    Id: "1356",
    State: "Uttar Pradesh",
    City: "Palia Kalan",
  },
  {
    Id: "1357",
    State: "Uttar Pradesh",
    City: "Parasi",
  },
  {
    Id: "1358",
    State: "Uttar Pradesh",
    City: "Phulpur",
  },
  {
    Id: "1359",
    State: "Uttar Pradesh",
    City: "Pihani",
  },
  {
    Id: "1360",
    State: "Uttar Pradesh",
    City: "Pilibhit",
  },
  {
    Id: "1361",
    State: "Uttar Pradesh",
    City: "Pilkhuwa",
  },
  {
    Id: "1362",
    State: "Uttar Pradesh",
    City: "Powayan",
  },
  {
    Id: "1363",
    State: "Uttar Pradesh",
    City: "Pukhrayan",
  },
  {
    Id: "1364",
    State: "Uttar Pradesh",
    City: "Puranpur",
  },
  {
    Id: "1365",
    State: "Uttar Pradesh",
    City: "Purquazi",
  },
  {
    Id: "1366",
    State: "Uttar Pradesh",
    City: "Purwa",
  },
  {
    Id: "1367",
    State: "Uttar Pradesh",
    City: "Rae Bareli",
  },
  {
    Id: "1368",
    State: "Uttar Pradesh",
    City: "Rampur",
  },
  {
    Id: "1369",
    State: "Uttar Pradesh",
    City: "Rampur Maniharan",
  },
  {
    Id: "1370",
    State: "Uttar Pradesh",
    City: "Rasra",
  },
  {
    Id: "1371",
    State: "Uttar Pradesh",
    City: "Rath",
  },
  {
    Id: "1372",
    State: "Uttar Pradesh",
    City: "Renukoot",
  },
  {
    Id: "1373",
    State: "Uttar Pradesh",
    City: "Reoti",
  },
  {
    Id: "1374",
    State: "Uttar Pradesh",
    City: "Robertsganj",
  },
  {
    Id: "1375",
    State: "Uttar Pradesh",
    City: "Rudauli",
  },
  {
    Id: "1376",
    State: "Uttar Pradesh",
    City: "Rudrapur",
  },
  {
    Id: "1377",
    State: "Uttar Pradesh",
    City: "Sadabad",
  },
  {
    Id: "1378",
    State: "Uttar Pradesh",
    City: "Safipur",
  },
  {
    Id: "1379",
    State: "Uttar Pradesh",
    City: "Saharanpur",
  },
  {
    Id: "1380",
    State: "Uttar Pradesh",
    City: "Sahaspur",
  },
  {
    Id: "1381",
    State: "Uttar Pradesh",
    City: "Sahaswan",
  },
  {
    Id: "1382",
    State: "Uttar Pradesh",
    City: "Sahawar",
  },
  {
    Id: "1383",
    State: "Uttar Pradesh",
    City: "Sahjanwa",
  },
  {
    Id: "1385",
    State: "Uttar Pradesh",
    City: "Sambhal",
  },
  {
    Id: "1386",
    State: "Uttar Pradesh",
    City: "Samdhan",
  },
  {
    Id: "1387",
    State: "Uttar Pradesh",
    City: "Samthar",
  },
  {
    Id: "1388",
    State: "Uttar Pradesh",
    City: "Sandi",
  },
  {
    Id: "1389",
    State: "Uttar Pradesh",
    City: "Sandila",
  },
  {
    Id: "1390",
    State: "Uttar Pradesh",
    City: "Sardhana",
  },
  {
    Id: "1391",
    State: "Uttar Pradesh",
    City: "Seohara",
  },
  {
    Id: "1394",
    State: "Uttar Pradesh",
    City: "Shahganj",
  },
  {
    Id: "1395",
    State: "Uttar Pradesh",
    City: "Shahjahanpur",
  },
  {
    Id: "1396",
    State: "Uttar Pradesh",
    City: "Shamli",
  },
  {
    Id: "1399",
    State: "Uttar Pradesh",
    City: "Sherkot",
  },
  {
    Id: "1401",
    State: "Uttar Pradesh",
    City: "Shikohabad",
  },
  {
    Id: "1402",
    State: "Uttar Pradesh",
    City: "Shishgarh",
  },
  {
    Id: "1403",
    State: "Uttar Pradesh",
    City: "Siana",
  },
  {
    Id: "1404",
    State: "Uttar Pradesh",
    City: "Sikanderpur",
  },
  {
    Id: "1405",
    State: "Uttar Pradesh",
    City: "Sikandra Rao",
  },
  {
    Id: "1406",
    State: "Uttar Pradesh",
    City: "Sikandrabad",
  },
  {
    Id: "1407",
    State: "Uttar Pradesh",
    City: "Sirsaganj",
  },
  {
    Id: "1408",
    State: "Uttar Pradesh",
    City: "Sirsi",
  },
  {
    Id: "1409",
    State: "Uttar Pradesh",
    City: "Sitapur",
  },
  {
    Id: "1410",
    State: "Uttar Pradesh",
    City: "Soron",
  },
  {
    Id: "1411",
    State: "Uttar Pradesh",
    City: "Suar",
  },
  {
    Id: "1412",
    State: "Uttar Pradesh",
    City: "Sultanpur",
  },
  {
    Id: "1413",
    State: "Uttar Pradesh",
    City: "Sumerpur",
  },
  {
    Id: "1414",
    State: "Uttar Pradesh",
    City: "Tanda",
  },
  {
    Id: "1415",
    State: "Uttar Pradesh",
    City: "Tanda",
  },
  {
    Id: "1416",
    State: "Uttar Pradesh",
    City: "Tetri Bazar",
  },
  {
    Id: "1417",
    State: "Uttar Pradesh",
    City: "Thakurdwara",
  },
  {
    Id: "1418",
    State: "Uttar Pradesh",
    City: "Thana Bhawan",
  },
  {
    Id: "1419",
    State: "Uttar Pradesh",
    City: "Tilhar",
  },
  {
    Id: "1420",
    State: "Uttar Pradesh",
    City: "Tirwaganj",
  },
  {
    Id: "1421",
    State: "Uttar Pradesh",
    City: "Tulsipur",
  },
  {
    Id: "1422",
    State: "Uttar Pradesh",
    City: "Tundla",
  },
  {
    Id: "1423",
    State: "Uttar Pradesh",
    City: "Unnao",
  },
  {
    Id: "1424",
    State: "Uttar Pradesh",
    City: "Utraula",
  },
  {
    Id: "1425",
    State: "Uttar Pradesh",
    City: "Varanasi",
  },
  {
    Id: "1426",
    State: "Uttar Pradesh",
    City: "Vrindavan",
  },
  {
    Id: "1427",
    State: "Uttar Pradesh",
    City: "Warhapur",
  },
  {
    Id: "1428",
    State: "Uttar Pradesh",
    City: "Zaidpur",
  },
  {
    Id: "1429",
    State: "Uttar Pradesh",
    City: "Zamania",
  },
  {
    Id: "1430",
    State: "Uttarakhand",
    City: "Almora",
  },
  {
    Id: "1431",
    State: "Uttarakhand",
    City: "Bazpur",
  },
  {
    Id: "1432",
    State: "Uttarakhand",
    City: "Chamba",
  },
  {
    Id: "1433",
    State: "Uttarakhand",
    City: "Dehradun",
  },
  {
    Id: "1434",
    State: "Uttarakhand",
    City: "Haldwani",
  },
  {
    Id: "1435",
    State: "Uttarakhand",
    City: "Haridwar",
  },
  {
    Id: "1436",
    State: "Uttarakhand",
    City: "Jaspur",
  },
  {
    Id: "1437",
    State: "Uttarakhand",
    City: "Kashipur",
  },
  {
    Id: "1438",
    State: "Uttarakhand",
    City: "kichha",
  },
  {
    Id: "1439",
    State: "Uttarakhand",
    City: "Kotdwara",
  },
  {
    Id: "1440",
    State: "Uttarakhand",
    City: "Manglaur",
  },
  {
    Id: "1441",
    State: "Uttarakhand",
    City: "Mussoorie",
  },
  {
    Id: "1442",
    State: "Uttarakhand",
    City: "Nagla",
  },
  {
    Id: "1443",
    State: "Uttarakhand",
    City: "Nainital",
  },
  {
    Id: "1444",
    State: "Uttarakhand",
    City: "Pauri",
  },
  {
    Id: "1445",
    State: "Uttarakhand",
    City: "Pithoragarh",
  },
  {
    Id: "1446",
    State: "Uttarakhand",
    City: "Ramnagar",
  },
  {
    Id: "1447",
    State: "Uttarakhand",
    City: "Rishikesh",
  },
  {
    Id: "1448",
    State: "Uttarakhand",
    City: "Roorkee",
  },
  {
    Id: "1449",
    State: "Uttarakhand",
    City: "Rudrapur",
  },
  {
    Id: "1450",
    State: "Uttarakhand",
    City: "Sitarganj",
  },
  {
    Id: "1451",
    State: "Uttarakhand",
    City: "Tehri",
  },
  {
    Id: "1452",
    State: "Uttar Pradesh",
    City: "Muzaffarnagar",
  },
  {
    Id: "1454",
    State: "West Bengal",
    City: "Alipurduar",
  },
  {
    Id: "1455",
    State: "West Bengal",
    City: "Arambagh",
  },
  {
    Id: "1456",
    State: "West Bengal",
    City: "Asansol",
  },
  {
    Id: "1457",
    State: "West Bengal",
    City: "Baharampur",
  },
  {
    Id: "1458",
    State: "West Bengal",
    City: "Bally",
  },
  {
    Id: "1459",
    State: "West Bengal",
    City: "Balurghat",
  },
  {
    Id: "1460",
    State: "West Bengal",
    City: "Bankura",
  },
  {
    Id: "1461",
    State: "West Bengal",
    City: "Barakar",
  },
  {
    Id: "1462",
    State: "West Bengal",
    City: "Barasat",
  },
  {
    Id: "1463",
    State: "West Bengal",
    City: "Bardhaman",
  },
  {
    Id: "1464",
    State: "West Bengal",
    City: "Bidhan Nagar",
  },
  {
    Id: "1465",
    State: "West Bengal",
    City: "Chinsura",
  },
  {
    Id: "1466",
    State: "West Bengal",
    City: "Contai",
  },
  {
    Id: "1467",
    State: "West Bengal",
    City: "Cooch Behar",
  },
  {
    Id: "1468",
    State: "West Bengal",
    City: "Darjeeling",
  },
  {
    Id: "1469",
    State: "West Bengal",
    City: "Durgapur",
  },
  {
    Id: "1470",
    State: "West Bengal",
    City: "Haldia",
  },
  {
    Id: "1471",
    State: "West Bengal",
    City: "Howrah",
  },
  {
    Id: "1472",
    State: "West Bengal",
    City: "Islampur",
  },
  {
    Id: "1473",
    State: "West Bengal",
    City: "Jhargram",
  },
  {
    Id: "1474",
    State: "West Bengal",
    City: "Kharagpur",
  },
  {
    Id: "1475",
    State: "West Bengal",
    City: "Kolkata",
  },
  {
    Id: "1476",
    State: "West Bengal",
    City: "Mainaguri",
  },
  {
    Id: "1477",
    State: "West Bengal",
    City: "Mal",
  },
  {
    Id: "1478",
    State: "West Bengal",
    City: "Mathabhanga",
  },
  {
    Id: "1479",
    State: "West Bengal",
    City: "Medinipur",
  },
  {
    Id: "1480",
    State: "West Bengal",
    City: "Memari",
  },
  {
    Id: "1481",
    State: "West Bengal",
    City: "Monoharpur",
  },
  {
    Id: "1482",
    State: "West Bengal",
    City: "Murshidabad",
  },
  {
    Id: "1483",
    State: "West Bengal",
    City: "Nabadwip",
  },
  {
    Id: "1484",
    State: "West Bengal",
    City: "Naihati",
  },
  {
    Id: "1485",
    State: "West Bengal",
    City: "Panchla",
  },
  {
    Id: "1486",
    State: "West Bengal",
    City: "Pandua",
  },
  {
    Id: "1487",
    State: "West Bengal",
    City: "Paschim Punropara",
  },
  {
    Id: "1488",
    State: "West Bengal",
    City: "Purulia",
  },
  {
    Id: "1489",
    State: "West Bengal",
    City: "Raghunathpur",
  },
  {
    Id: "1490",
    State: "West Bengal",
    City: "Raiganj",
  },
  {
    Id: "1491",
    State: "West Bengal",
    City: "Rampurhat",
  },
  {
    Id: "1492",
    State: "West Bengal",
    City: "Ranaghat",
  },
  {
    Id: "1493",
    State: "West Bengal",
    City: "Sainthia",
  },
  {
    Id: "1494",
    State: "West Bengal",
    City: "Santipur",
  },
  {
    Id: "1495",
    State: "West Bengal",
    City: "Siliguri",
  },
  {
    Id: "1496",
    State: "West Bengal",
    City: "Sonamukhi",
  },
  {
    Id: "1497",
    State: "West Bengal",
    City: "Srirampore",
  },
  {
    Id: "1498",
    State: "West Bengal",
    City: "Suri",
  },
  {
    Id: "1499",
    State: "West Bengal",
    City: "Taki",
  },
  {
    Id: "1500",
    State: "West Bengal",
    City: "Tamluk",
  },
  {
    Id: "1501",
    State: "West Bengal",
    City: "Tarakeswar",
  },
  {
    Id: "1502",
    State: "Karnataka",
    City: "Chikmagalur",
  },
  {
    Id: "1503",
    State: "Karnataka",
    City: "Davanagere",
  },
  {
    Id: "1504",
    State: "Karnataka",
    City: "Dharwad",
  },
  {
    Id: "1505",
    State: "Karnataka",
    City: "Gadag",
  },
];
