/* eslint-disable jsx-a11y/alt-text */
import { useEffect, useState } from "react";
import PageHeader from "../../components/PageHeader";
import { useForm } from "react-hook-form";
import axios from "axios";
import { server } from "../../server";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

function UpdateImages() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { user } = useSelector((state) => state?.profile);
  const [previewImages, setPreviewImages] = useState([]);
  const { handleSubmit } = useForm();
  const [images, setImages] = useState(user?.profile?.images || []);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const storageImages = user?.profile?.images || [];
  const [dbImages, setDbImages] = useState(user?.profile?.images || []);

  useEffect(() => {
    fetchImages();
  }, []);

  const fetchImages = async () => {
    try {
      const { data } = await axios.get(`${server}/profile/user/${user?._id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });

      setDbImages(data?.data?.profile?.[0]?.images);
    } catch (e) {
      toast.error(e?.response?.data?.message || e?.message);
    }
  };

  const handleUploadPhotos = async () => {
    setLoading(true);
    if (previewImages?.length === 0) return alert("Please choose images");

    const formData = new FormData();

    for (let i = 0; i < previewImages.length; i++) {
      formData.append("file", previewImages[i]?.file);
    }

    try {
      const { data } = await axios.patch(`${server}/user/photos`, formData, {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      });
      setLoading(false);
      setImages([]);
      setPreviewImages([]);
      toast.success("Photos updated successfully!");
      setDbImages(data?.data?.images);
    } catch (e) {
      setLoading(false);
      console.log(e?.response?.data?.message || e?.message);
    }
  };

  const handleAddPhoto = (e) => {
    const files = e.target.files;
    if (files.length > 10) return alert("You can choose maximum 10 images");
    let tempArr = [];

    const previewImagesArray = [];
    for (let i = 0; i < files.length; i++) {
      tempArr.push(files[i]);

      const file = files[i];
      const reader = new FileReader();

      if (previewImages?.length + tempArr?.length >= 11) {
        break;
      }

      reader.onload = (e) => {
        previewImagesArray.push({ file: files[i], base64: e.target.result });
        setPreviewImages([...previewImages, ...previewImagesArray]);
      };

      reader.readAsDataURL(file);
    }
    setImages(tempArr);
  };

  const handleDeleteLocalPhoto = (item) => {
    let tempArr = previewImages?.filter((e) => e !== item);
    setPreviewImages(tempArr);
  };

  const handleDeleteDatabaseImage = async (item) => {
    try {
      setDeleteLoading(true);
      const { data } = await axios.delete(
        `${server}/user/deleteImage/${item?._id}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      setDbImages(() => data?.images);
      console.log(data);
      setDeleteLoading(false);
    } catch (e) {
      setDeleteLoading(false);
      toast.error(e?.response?.data?.message || e?.message);
    }
  };

  return (
    <>
      <div className="block-margin-top mb-4">
        <div className="py-4 bg-white rounded-4 block-border">
          <PageHeader name="Images" />

          <form onSubmit={handleSubmit(handleUploadPhotos)} className="px-5">
            <div className="d-flex justify-content-center my-4">
              <label for="fileInput" className="w-100">
                <div className="bg-lightgrey py-4 rounded-4 d-flex justify-content-center">
                  <img
                    src="https://cdn-icons-png.flaticon.com/512/13434/13434886.png"
                    width="200"
                    onClick={() => handleAddPhoto}
                  />
                </div>
              </label>
            </div>

            <div className="px-lg-4 p-4 mx-lg-2 mx-0">
              <div className="pb-3">
                <div className="row g-2 pt-3">
                  {/* Local images */}
                  {previewImages.map((item, index) => (
                    <div className="d-flex justify-content-center" key={index}>
                      <img
                        className="rounded-4 m-1 object-fit-cover"
                        src={item?.base64}
                        width="70"
                        height="70"
                        alt="Preview"
                      />
                      <div className="d-flex justify-content-end">
                        <div className="position-absolute">
                          <div className="bg-dark delete-image-button rounded-pill">
                            <button
                              className="rounded-pill"
                              onClick={() => handleDeleteLocalPhoto(item)}
                              disabled={deleteLoading}
                            >
                              <i className="bi bi-trash"></i>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}

                  {/* Database Images */}
                  {dbImages.map((item, index) => (
                    <div className="d-flex justify-content-center" key={index}>
                      <img
                        className="rounded-4 m-1 object-fit-cover"
                        src={item?.docUrl}
                        width="70"
                        height="70"
                        alt="Preview"
                      />
                      <div className="d-flex justify-content-end">
                        <div className="position-absolute">
                          <div className="bg-dark delete-image-button rounded-pill">
                            <button
                              className="rounded-pill"
                              onClick={() => handleDeleteDatabaseImage(item)}
                              disabled={deleteLoading}
                            >
                              <i className="bi bi-trash"></i>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
                <input
                  id="fileInput"
                  type="file"
                  className="d-none"
                  multiple
                  onChange={handleAddPhoto}
                  accept="image/*"
                  max={10}
                  disabled={deleteLoading}
                />
              </div>
            </div>

            <div className="mx-5 mb-3 pt-3 d-flex justify-content-center">
              <button
                type="submit"
                className="rounded-3 bg-secondary px-5 py-2"
                disabled={loading}
              >
                <p className="my-1 fw-medium text-capitalize text-white fs-7 mx-5">
                  {loading ? "Please wait.." : "Save"}
                </p>
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

export default UpdateImages;
