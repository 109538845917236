import axios from "axios";
import { server } from "../../server";
import { toast } from "react-toastify";

// Send connection request with list of profiles --- Working---
export const sendConnectionAction =
  (receiverId, genderProfiles) => async (dispatch) => {
    dispatch({
      type: "SEND_CONNECTION_REQUEST",
    });

    try {
      const { data } = await axios.post(`${server}/connect/new`, receiverId, {
        headers: { "Content-Type": "application/json" },
      });

      dispatch({
        type: "SEND_CONNECTION_SUCCESS",
        payload: { newConnectionStatus: data?.data?.status },
      });

      // Update gender profile
      dispatch({
        type: "GET_GENDER_PROFILES_REQUEST",
      });
      // Update gender profile
      let profiles = genderProfiles.map((item) =>
        item?.user[0]?._id === data?.data?.receiverId
          ? {
              ...item,
              connection: [...(item.connection || []), data?.data],
            }
          : item
      );
      dispatch({
        type: "GET_GENDER_PROFILES_SUCCESS",
        payload: { genderProfiles: profiles },
      });
    } catch (e) {
      toast.error(e?.response?.data?.message || e.message);
      dispatch({
        type: "SEND_CONNECTION_FAIL",
        payload: { error: e?.response?.data?.message || e.message },
      });
      dispatch({
        type: "GET_GENDER_PROFILES_FAIL",
        payload: { error: e?.response?.data?.message || e.message },
      });
    }
  };

// Cancel connection request with list of profiles
export const cancelConnectionAction =
  (id, genderProfiles) => async (dispatch) => {
    dispatch({
      type: "CANCEL_CONNECTION_REQUEST",
    });

    try {
      const { data } = await axios.delete(`${server}/connect/delete/${id}`, {
        headers: { "Content-Type": "application/json" },
      });

      dispatch({
        type: "CANCEL_CONNECTION_SUCCESS",
        payload: { message: data?.message },
      });

      // Update gender profile
      dispatch({
        type: "GET_GENDER_PROFILES_REQUEST",
      });
      // Update gender profile
      let profiles = genderProfiles.map((item) =>
        item.connection[0]?._id === id
          ? {
              ...item,
              connection: [],
            }
          : item
      );
      dispatch({
        type: "GET_GENDER_PROFILES_SUCCESS",
        payload: { genderProfiles: profiles, myConnections: profiles },
      });
    } catch (e) {
      toast.error(e?.response?.data?.message || e.message);
      dispatch({
        type: "CANCEL_CONNECTION_FAIL",
        payload: { error: e?.response?.data?.message || e?.message },
      });
    }
  };

// Cancel connection request with list of profiles
export const cancelSentConnectionAction =
  (id, sentConnections) => async (dispatch) => {
    dispatch({
      type: "CANCEL_CONNECTION_REQUEST",
    });

    try {
      const { data } = await axios.delete(`${server}/connect/delete/${id}`, {
        headers: { "Content-Type": "application/json" },
      });

      dispatch({
        type: "CANCEL_CONNECTION_SUCCESS",
        payload: { message: data?.message },
      });

      // Update gender profile
      dispatch({
        type: "CANCEL_SENT_CONNECTION_REQUEST",
      });
      // Update gender profile
      let profiles = sentConnections?.filter((e) => e?.user[0]?._id !== id);
      dispatch({
        type: "CANCEL_SENT_CONNECTION_SUCCESS",
        payload: { myConnections: profiles },
      });
    } catch (e) {
      toast.error(e?.response?.data?.message || e.message);
      dispatch({
        type: "CANCEL_CONNECTION_FAIL",
        payload: { error: e?.response?.data?.message || e?.message },
      });
    }
  };

export const getMyConnectionAction = (status) => async (dispatch) => {
  dispatch({
    type: "GET_MY_CONNECTION_REQUEST",
  });

  try {
    const { data } = await axios.get(`${server}/connect/my/${status}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });

    dispatch({
      type: "GET_MY_CONNECTION_SUCCESS",
      payload: { myConnections: data?.data },
    });
  } catch (e) {
    toast.error(e?.response?.data?.message || e.message);
    dispatch({
      type: "GET_MY_CONNECTION_FAIL",
      payload: { error: e?.response?.data?.message || e?.message },
    });
  }
};

export const getConnectionsRequestsToMe = () => async (dispatch) => {
  dispatch({
    type: "GET_CONNECTION_REQUESTS_TO_ME_REQUEST",
  });

  try {
    const { data } = await axios.get(`${server}/connect/received`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });

    dispatch({
      type: "GET_CONNECTION_REQUESTS_TO_ME_SUCCESS",
      payload: { connectionsToMe: data?.data },
    });
  } catch (e) {
    toast.error(e?.response?.data?.message || e.message);
    dispatch({
      type: "GET_CONNECTION_REQUESTS_TO_ME_FAIL",
      payload: { error: e?.response?.data?.message || e?.message },
    });
  }
};

// ---Working
export const getMyAcceptedConnections = () => async (dispatch) => {
  dispatch({
    type: "GET_MY_ACCEPTED_CONNECTIONS_REQUEST",
  });

  try {
    const { data } = await axios.get(`${server}/connect/accepted`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });

    console.log(data?.data[1]?.connection);

    dispatch({
      type: "GET_MY_ACCEPTED_CONNECTIONS_SUCCESS",
      payload: { acceptedConnections: data?.data },
    });
  } catch (e) {
    toast.error(e?.response?.data?.message || e.message);
    dispatch({
      type: "GET_MY_ACCEPTED_CONNECTIONS_FAIL",
      payload: { error: e?.response?.data?.message || e?.message },
    });
  }
};

// Cancel connection requests from my activity page ---Working
export const cancelConnectionRequestMyActivity =
  (id, matches) => async (dispatch) => {
    dispatch({
      type: "CANCEL_SENT_CONNECTIONS_FROM_MY_ACTIVITY_REQUEST",
    });

    try {
      await axios.delete(`${server}/connect/delete/${id}`, {
        headers: { "Content-Type": "application/json" },
      });

      let tempMatches = matches?.filter((e) => e?.connection[0]?._id !== id);
      dispatch({
        type: "GET_MY_CONNECTION_SUCCESS",
        payload: { myConnections: tempMatches },
      });

      dispatch({
        type: "CANCEL_SENT_CONNECTIONS_FROM_MY_ACTIVITY_SUCCESS",
        payload: { myConnections: tempMatches },
      });
    } catch (e) {
      toast.error(e?.response?.data?.message || e.message);
      dispatch({
        type: "CANCEL_SENT_CONNECTIONS_FROM_MY_ACTIVITY_FAIL",
        payload: { error: e?.response?.data?.message || e?.message },
      });
    }
  };

export const rejectReceivedConnectionAction =
  (formData, connectionsToMe) => async (dispatch) => {
    dispatch({
      type: "ACCEPT_MY_RECEIVED_CONNECTION_REQUEST",
    });

    try {
      const { data } = await axios.delete(
        `${server}/connect/delete/${formData?.id}`,
        {
          headers: { "Content-Type": "application/json" },
        }
      );

      let tempConnections = connectionsToMe?.filter(
        (e) => e?.connection[0]?._id !== formData?.id
      );

      dispatch({
        type: "GET_CONNECTION_REQUESTS_TO_ME_SUCCESS",
        payload: { connectionsToMe: tempConnections },
      });

      dispatch({
        type: "ACCEPT_MY_RECEIVED_CONNECTION_SUCCESS",
        payload: { message: data?.message },
      });
    } catch (e) {
      toast.error(e?.response?.data?.message || e.message);
      dispatch({
        type: "ACCEPT_MY_RECEIVED_CONNECTION_FAIL",
        payload: { error: e?.response?.data?.message || e?.message },
      });
    }
  };
