import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { getUserProfileByIdAction } from "../redux/actions/profile/profileAction";
import axios from "axios";
import { defaultUserImage, server } from "../server";
import { toast } from "react-toastify";
import { calculateAge } from "../utills/calculateAge";

function Details() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state?.profile);
  const { profileData: userDetails } = location?.state;
  const { reduxProfile } = useSelector((state) => state?.profile);
  const [profileDetails, setProfileDetails] = useState(userDetails);
  const [connectLoading, setConnectLoading] = useState(false);
  const [shortlistLoading, setShortlistLoading] = useState(false);
  const [reportLoading, setReportLoading] = useState(false);
  const [unlockLoading, setUnlockLoading] = useState(false);
  const [userPreferences, setUserPreferences] = useState("");
  const [myPreferences, setMyPreferences] = useState("");

  useEffect(() => {
    dispatch(getUserProfileByIdAction(userDetails?.user?.[0]?._id));
  }, []);

  // Get user and my preferences
  useEffect(() => {
    const getUserPreferences = async () => {
      const { data } = await axios.get(
        `${server}/preferences/getByUserId/${userDetails?._id}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      setUserPreferences(data?.data);
    };
    getUserPreferences();

    const getMyPreferences = async () => {
      const { data } = await axios.get(`${server}/preferences/my`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      setMyPreferences(data?.data);
    };
    getMyPreferences();
  }, []);

  const handleSendInterest = async () => {
    setConnectLoading(true);
    let formData = {
      receiverId: reduxProfile?._id,
    };
    try {
      const { data } = await axios.post(`${server}/connect/new`, formData, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      let tempProfile = { ...reduxProfile, connection: [data?.data] };
      dispatch({
        type: "GET_USER_PROFILE_BY_ID_SUCCESS",
        payload: { reduxProfile: tempProfile },
      });
      toast.success("Interest Sent Successfully");
      setConnectLoading(false);
    } catch (e) {
      setConnectLoading(false);
      toast.error(e?.response?.data?.message || e?.message);
    }
  };

  const handleCancelInterest = async () => {
    try {
      setConnectLoading(true);
      const { data } = await axios.delete(
        `${server}/connect/delete/${reduxProfile?.connection[0]?._id}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      let tempProfile = { ...reduxProfile, connection: [] };
      dispatch({
        type: "GET_USER_PROFILE_BY_ID_SUCCESS",
        payload: { reduxProfile: tempProfile },
      });
      toast.success("Interest Cancelled Successfully");
      setConnectLoading(false);
    } catch (e) {
      setConnectLoading(false);
      toast.error(e?.response?.data?.message || e?.message);
    }
  };

  const handleAcceptInterest = async () => {
    const formData = {
      status: "accepted",
      id: reduxProfile?.connection?.[0]?._id,
    };
    try {
      const { data } = await axios.put(`${server}/connect/update`, formData, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });

      let tempProfile = { ...reduxProfile, connection: [data?.data] };

      dispatch({
        type: "GET_USER_PROFILE_BY_ID_SUCCESS",
        payload: { reduxProfile: tempProfile },
      });
    } catch (e) {
      toast.error(e?.response?.data?.message || e?.message);
    }
  };

  console.log(shortlistLoading);
  const handleShortlist = async () => {
    try {
      let formData = {
        shortlistedUserId: profileDetails?._id,
      };
      setShortlistLoading(true);
      const { data } = await axios.post(`${server}/shortlist/new`, formData, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      setShortlistLoading(false);
      setProfileDetails({ ...profileDetails, shortlist: [data?.data] });
    } catch (e) {
      setShortlistLoading(false);
      toast.error(e?.response?.data?.message || e?.message);
    }
  };

  const handleCancelShortlist = async () => {
    try {
      setShortlistLoading(true);
      const { data } = await axios.delete(
        `${server}/shortlist/delete/${profileDetails?.shortlist[0]?.shortlistedUserId}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      setShortlistLoading(false);
      setProfileDetails({ ...profileDetails, shortlist: [] });
    } catch (e) {
      setShortlistLoading(false);
      toast.error(e?.response?.data?.message || e?.message);
    }
  };

  const handleUnlockContactDetails = async () => {
    setUnlockLoading(true);
    try {
      const formData = {
        userId: reduxProfile?.user?.[0]?._id,
      };
      const { data } = await axios.put(
        `${server}/user/unlockContactDetails`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      // update user profile data
      dispatch({
        type: "GET_MY_PROFILE_SUCCESS",
        payload: { user: data?.data },
      });

      setUnlockLoading(false);
      toast.success(data?.message);
    } catch (e) {
      setUnlockLoading(false);
      toast.error(e?.response?.data?.message || e?.message);
      navigate("./", { state: { Information: "Upgrade" } });
    }
  };

  // Navigate to chat page
  const handleNavigateToChatPage = async (item) => {
    const formData = {
      sender: user?._id,
      receiver: item?.user?.[0]?._id,
    };

    try {
      const token = localStorage.getItem("token");
      const { data } = await axios.post(
        `${server}/conversation/new`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      navigate("./", {
        state: {
          Information: "Chat",
          selectedUser: { ...item?.user?.[0], profile: item?.profile?.[0] },
        },
      });
    } catch (e) {
      toast.error(e?.response?.data?.message || e?.message);
    }
  };

  return (
    <>
      <div className="block-margin-top mb-4">
        <div className="pb-4 bg-white rounded-4 block-border">
          <div className="p-3 d-flex align-items-center">
            <i
              onClick={() => navigate(-1)}
              className="bi bi-chevron-left cursor-pointer my-1 fw-bold"
            ></i>
          </div>

          <div>
            <div className="position-relative d-flex justify-content-center align-items-end overflow-hidden">
              <div
                className="position-relative w-100 d-flex justify-content-end"
                style={{
                  backgroundImage: `url(${
                    userDetails?.profile[0]?.images?.length > 0
                      ? userDetails.profile[0].images[0].docUrl
                      : defaultUserImage
                  })`,
                  backgroundPosition: "center",
                  backgroundSize: "cover",
                }}
              >
                <img
                  src={
                    userDetails?.profile[0]?.images?.length > 0
                      ? userDetails?.profile[0]?.images[0]?.docUrl
                      : defaultUserImage
                  }
                  className="w-100 object-fit-contain"
                  height="400"
                />
                {userDetails?.profile[0]?.images?.length > 0 && (
                  <div
                    className="position-absolute profile-image-icon cursor-pointer"
                    onClick={() =>
                      navigate("./profile-images", {
                        state: userDetails?.profile[0]?.images,
                      })
                    }
                  >
                    <i className="bi bi-images text-white"></i>
                  </div>
                )}
              </div>
              <div className="w-100 h-75 activity-profile-block position-absolute d-flex align-items-end">
                <div className="p-4">
                  <div>
                    <p className="m-0 fs-9 text-white">
                      GRSTY{userDetails?.user[0]?.customId}
                    </p>
                    <h5 className="m-0 text-white">
                      {userDetails?.user[0]?.name},{" "}
                      {new Date().getFullYear() -
                        new Date(userDetails?.profile[0]?.dob).getFullYear()}
                    </h5>
                    <p className="m-0 fs-9 text-white">
                      {userDetails?.profile[0]?.height?.split("(")[0]}
                      <span class="mx-2 text-white">•</span>
                      {userDetails?.profile[0]?.city}
                      <span class="mx-2">•</span>
                      {
                        userDetails?.profile[0]?.community?.split("-")[
                          userDetails?.profile[0]?.community?.split("-")
                            ?.length - 1
                        ]
                      }
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="px-4 mx-3 pb-4 mb-5">
              <div className="row pt-3 pb-1 my-4">
                <div className="col-lg-7 col-12">
                  <p className="fs-8 mb-2 fw-medium">
                    <i className="bi bi-person me-2 fs-6 text-secondary"></i>
                    Profile Created / Managed by{" "}
                    {userDetails?.user[0]?.profileFor}
                  </p>
                  <p className="fs-8 mb-2 fw-medium">
                    <i className="bi bi-person-standing fs-6 me-2 text-secondary"></i>
                    {userDetails?.profile[0]?.height?.split("(")[0]}
                  </p>
                  <p className="fs-8 mb-2 fw-medium">
                    <i className="bi bi-flower1 me-2 fs-6 text-secondary"></i>
                    {
                      userDetails?.profile[0]?.community?.split("-")[
                        userDetails?.profile[0]?.community?.split("-")?.length -
                          1
                      ]
                    }
                  </p>
                  <p className="fs-8 mb-2 fw-medium">
                    <i className="bi bi-chat-right-dots fs-6 me-2 text-secondary"></i>
                    Mother Tongue is {userDetails?.profile[0]?.motherTongue}
                  </p>
                </div>
                <div className="col-lg-5 col-12">
                  <p className="fs-8 mb-2 fw-medium">
                    <i className="bi bi-pin-map me-2 fs-6 text-secondary"></i>
                    {userDetails?.profile[0]?.state},{" "}
                    {userDetails?.profile[0]?.city}
                  </p>
                  <p className="fs-8 mb-2 fw-medium">
                    <i className="bi bi-wallet2 me-2 fs-6 text-secondary"></i>
                    {userDetails?.profile?.[0]?.annualIncome || "No Income"}
                  </p>
                  <p className="fs-8 mb-2 fw-medium">
                    <i className="bi bi-heart me-2 text-secondary"></i>
                    {profileDetails?.profile?.[0]?.maritalStatus}
                  </p>
                </div>
              </div>

              <div className="row">
                <div className="light-crimson-bg p-3 rounded-4 d-flex align-items-center">
                  <div>
                    <img
                      src="https://cdn-icons-png.flaticon.com/512/10307/10307911.png"
                      width="45"
                    />
                  </div>
                  <div className="ms-3">
                    <p className="fs-8 fw-medium text-black">
                      It’s an 92% Match!
                    </p>
                    <p className="fs-8 text-secondary">
                      Based on mutual preferences
                    </p>
                  </div>
                </div>
              </div>

              <div className="mt-4 pt-2">
                <p className="fs-8 opacity-75 fw-medium">
                  {" "}
                  {`Meet ${userDetails?.user[0]?.name}, a ${
                    new Date().getFullYear() -
                    new Date(userDetails?.profile[0]?.dob).getFullYear()
                  }-year-old ${
                    userDetails?.profile[0]?.community
                  } heritage. Standing ${
                    userDetails?.profile[0]?.height?.split("(")[0]
                  } feet tall, they bring both charm and presence, while their proficiency in ${
                    userDetails?.profile[0]?.motherTongue
                  } enriches every interaction.`}{" "}
                </p>
              </div>

              <div className="mt-5">
                <h5 className="fs-6 mb-4">Education</h5>
                <div className="d-flex align-items-center mb-3">
                  <div className="icon-rounded-block">
                    <img
                      src="https://cdn-icons-png.flaticon.com/512/9217/9217022.png"
                      width="25"
                      className="opacity-75"
                    />
                  </div>
                  <div className="ms-3">
                    <p className="fs-7 lh-1 d-flex flex-column">
                      Highest Qualification
                      <span className="fs-8 mt-1 text-secondary">
                        {userDetails?.profile?.[0]?.highestQualification ||
                          "Not Mentioned"}
                      </span>
                    </p>
                  </div>
                </div>
                <div className="d-flex align-items-center mb-3">
                  <div className="icon-rounded-block">
                    <img
                      src="https://cdn-icons-png.flaticon.com/512/166/166088.png"
                      width="25"
                      className="opacity-50"
                    />
                  </div>
                  <div className="ms-3">
                    <p className="fs-7 lh-1 d-flex flex-column">
                      Highest Degree
                      <span className="fs-8 mt-1 text-secondary">
                        {profileDetails?.profile?.[0]?.highestDegree ||
                          "Not Mentioned"}
                      </span>
                    </p>
                  </div>
                </div>
              </div>

              <div className="mt-5">
                <h5 className="fs-6 mb-4">Contact</h5>
                <div className="light-bg-1 p-4 rounded-4">
                  <h5 className="fs-7">Go Premium to contact matches</h5>
                  <p className="fs-8 opacity-75">
                    Upgrade your membership to start being able to chat with
                    profile you like & unlock contacts.
                  </p>
                  <h5 className="fs-8 mb-0 mt-3">Phone</h5>
                  <div className="d-flex align-content-between justify-content-between">
                    <p className="fs-8 opacity-75">
                      +91
                      {user?.unlockedProfiles?.includes(
                        reduxProfile?.user?.[0]?._id
                      )
                        ? userDetails?.user[0]?.mobile
                        : `${userDetails?.user[0]?.mobile
                            ?.toString()
                            ?.slice(0, 2)}*****${userDetails?.user[0]?.mobile
                            ?.toString()
                            ?.slice(7, 10)}`}
                    </p>
                    <p
                      onClick={handleUnlockContactDetails}
                      disabled={user?.unlockedProfiles?.includes(
                        reduxProfile?.user?.[0]?._id
                      )}
                      className="fs-7 fw-medium text-danger cursor-pointer"
                    >
                      {unlockLoading ? "..." : "UNLOCK"}
                    </p>
                  </div>
                </div>
              </div>

              <div className="mt-5">
                <h5 className="fs-6 mb-4">Career</h5>
                <div className="d-flex align-items-center mb-3">
                  <div className="icon-rounded-block">
                    <img
                      src="https://cdn-icons-png.flaticon.com/512/11325/11325487.png"
                      width="25"
                      className="opacity-75"
                    />
                  </div>
                  <div className="ms-3">
                    <p className="fs-7 lh-1 d-flex flex-column">
                      Profession
                      <span className="fs-8 mt-1 text-secondary">
                        {userDetails?.profile?.[0]?.jobTitle || "Not Working"}
                      </span>
                    </p>
                  </div>
                </div>
                <div className="d-flex align-items-center mb-3">
                  <div className="icon-rounded-block">
                    <img
                      src="https://cdn-icons-png.flaticon.com/512/2169/2169864.png"
                      width="25"
                      className="opacity-75"
                    />
                  </div>
                  <div className="ms-3">
                    <p className="fs-7 lh-1 d-flex flex-column">
                      Annual Income
                      <span className="fs-8 mt-1 text-secondary">
                        {userDetails?.profile?.[0]?.annualIncome || "No Income"}
                      </span>
                    </p>
                  </div>
                </div>
                <div className="d-flex align-items-center mb-3">
                  <div className="icon-rounded-block">
                    <img
                      src="https://cdn-icons-png.flaticon.com/512/10415/10415649.png"
                      width="25"
                      className="opacity-75"
                    />
                  </div>
                  <div className="ms-3">
                    <p className="fs-7 lh-1 d-flex flex-column">
                      Employed In
                      <span className="fs-8 mt-1 text-secondary">
                        {userDetails?.profile?.[0]?.employedIn || "Not Working"}
                      </span>
                    </p>
                  </div>
                </div>
              </div>

              <div className="mt-5">
                <h5 className="fs-6 mb-4">Family</h5>
                <div className="d-flex align-items-center mb-3">
                  <div className="icon-rounded-block">
                    <img
                      src="https://cdn-icons-png.flaticon.com/512/11823/11823998.png"
                      width="25"
                      className="opacity-75"
                    />
                  </div>
                  <div className="ms-3">
                    <p className="fs-7 lh-1 d-flex flex-column">
                      Family Type
                      <span className="fs-8 mt-1 text-secondary">
                        {userDetails?.profile[0]?.familyType}
                      </span>
                    </p>
                  </div>
                </div>
                <div className="d-flex align-items-center mb-3">
                  <div className="icon-rounded-block">
                    <img
                      src="https://cdn-icons-png.flaticon.com/512/6998/6998782.png"
                      width="25"
                      className="opacity-75"
                    />
                  </div>
                  <div className="ms-3">
                    <p className="fs-7 lh-1 d-flex flex-column">
                      Living in
                      <span className="fs-8 mt-1 text-secondary">
                        {userDetails?.profile[0]?.state},{" "}
                        {userDetails?.profile[0]?.city}
                      </span>
                    </p>
                  </div>
                </div>
                <div className="d-flex align-items-center mb-3">
                  <div className="icon-rounded-block">
                    <img
                      src="https://cdn-icons-png.flaticon.com/512/16998/16998906.png"
                      width="25"
                      className="opacity-75"
                    />
                  </div>
                  <div className="ms-3">
                    <p className="fs-7 lh-1 d-flex flex-column">
                      Father Status
                      <span className="fs-8 mt-1 text-secondary">
                        {userDetails?.profile[0]?.fatherStatus}
                      </span>
                    </p>
                  </div>
                </div>
                <div className="d-flex align-items-center mb-3">
                  <div className="icon-rounded-block">
                    <img
                      src="https://cdn-icons-png.flaticon.com/512/16998/16998906.png"
                      width="25"
                      className="opacity-75"
                    />
                  </div>
                  <div className="ms-3">
                    <p className="fs-7 lh-1 d-flex flex-column">
                      Mother Status
                      <span className="fs-8 mt-1 text-secondary">
                        {userDetails?.profile[0]?.motherStatus}
                      </span>
                    </p>
                  </div>
                </div>
                <div className="d-flex align-items-center mb-3">
                  <div className="icon-rounded-block">
                    <img
                      src="https://cdn-icons-png.flaticon.com/512/11823/11823998.png"
                      width="25"
                      className="opacity-75"
                    />
                  </div>
                  <div className="ms-3">
                    <p className="fs-7 lh-1 d-flex flex-column">
                      No. Of Brother
                      <span className="fs-8 mt-1 text-secondary">
                        {userDetails?.profile[0]?.noOfBrothers}
                      </span>
                    </p>
                  </div>
                </div>
                <div className="d-flex align-items-center mb-3">
                  <div className="icon-rounded-block">
                    <img
                      src="https://cdn-icons-png.flaticon.com/512/11823/11823998.png"
                      width="25"
                      className="opacity-75"
                    />
                  </div>
                  <div className="ms-3">
                    <p className="fs-7 lh-1 d-flex flex-column">
                      No. Of Sister
                      <span className="fs-8 mt-1 text-secondary">
                        {userDetails?.profile[0]?.noOfSisters}
                      </span>
                    </p>
                  </div>
                </div>
              </div>

              <div className="mt-5">
                <h5 className="fs-6 mb-4">Lifestyle & Interests</h5>
                <div className="d-flex align-items-center mb-3">
                  <div className="icon-rounded-block">
                    <img
                      src="https://cdn-icons-png.flaticon.com/512/12566/12566137.png"
                      width="25"
                      className="opacity-75"
                    />
                  </div>
                  <div className="ms-3">
                    <p className="fs-7 lh-1 d-flex flex-column">
                      Drinking Habit
                      <span className="fs-8 mt-1 text-secondary">
                        {reduxProfile?.drinking || "Not Filled"}
                      </span>
                    </p>
                  </div>
                </div>
                <div className="d-flex align-items-center mb-3">
                  <div className="icon-rounded-block">
                    <img
                      src="https://cdn-icons-png.flaticon.com/512/16701/16701479.png"
                      width="25"
                      className="opacity-75"
                    />
                  </div>
                  <div className="ms-3">
                    <p className="fs-7 lh-1 d-flex flex-column">
                      Diet
                      <span className="fs-8 mt-1 text-secondary">
                        {reduxProfile?.diet || "Not Filled"}
                      </span>
                    </p>
                  </div>
                </div>
                <div className="d-flex align-items-center mb-3">
                  <div className="icon-rounded-block">
                    <img
                      src="https://cdn-icons-png.flaticon.com/512/2731/2731654.png"
                      width="25"
                      className="opacity-75"
                    />
                  </div>
                  <div className="ms-3">
                    <p className="fs-7 lh-1 d-flex flex-column">
                      Smoking Habit
                      <span className="fs-8 mt-1 text-secondary">
                        {reduxProfile?.smoke || "Not Filled"}
                      </span>
                    </p>
                  </div>
                </div>
                <div className="d-flex align-items-center mb-3">
                  <div className="icon-rounded-block">
                    <img
                      src="https://cdn-icons-png.flaticon.com/512/1076/1076877.png"
                      width="25"
                      className="opacity-75"
                    />
                  </div>
                  <div className="ms-3">
                    <p className="fs-7 lh-1 d-flex flex-column">
                      Pets
                      <span className="fs-8 mt-1 text-secondary">
                        {reduxProfile?.pet || "Not Filled"}
                      </span>
                    </p>
                  </div>
                </div>
                <div className="d-flex align-items-center mb-3">
                  <div className="icon-rounded-block">
                    <img
                      src="https://cdn-icons-png.flaticon.com/512/2460/2460850.png"
                      width="25"
                      className="opacity-75"
                    />
                  </div>
                  <div className="ms-3">
                    <p className="fs-7 lh-1 d-flex flex-column">
                      Workout
                      <span className="fs-8 mt-1 text-secondary">
                        {reduxProfile?.workout || "Not Filled"}
                      </span>
                    </p>
                  </div>
                </div>
              </div>

              <div className="mt-5">
                <h5 className="fs-6 mb-0">Looking For</h5>
                <p className="fs-8 mb-4">These are desired partner qualities</p>
                <div className="row">
                  <div className="col-6">
                    <h6 className="fs-7">Preferences</h6>
                    <img
                      src={
                        userDetails?.profile[0]?.images?.length > 0
                          ? userDetails?.profile[0]?.images[0]?.docUrl
                          : defaultUserImage
                      }
                      height="60"
                      width="60"
                      className="rounded-pill object-fit-cover"
                    />
                  </div>
                  <div className="col-6 d-flex justify-items-end flex-column align-items-end">
                    <h6 className="fs-7">You Match</h6>
                    <img
                      src={
                        user?.profile?.images?.length > 0
                          ? user?.profile?.images[0]?.docUrl
                          : defaultUserImage
                      }
                      height="60"
                      width="60"
                      className="rounded-pill object-fit-cover"
                    />
                  </div>
                </div>

                <div className="row mt-3">
                  <div className="col-8">
                    <p className="fs-7 fw-medium mb-3">
                      Age
                      <br />
                      <span className="text-secondary">
                        {(!userPreferences?.minAge ||
                          !userPreferences?.maxAge) &&
                          "Open to all"}
                        {(userPreferences?.minAge || userPreferences?.maxAge) &&
                          `${userPreferences?.minAge} to ${userPreferences?.maxAge} Years`}
                      </span>
                    </p>
                  </div>
                  <div className="col-4 d-flex justify-items-end flex-column align-items-end">
                    <i
                      className={`bi bi-check2 ${
                        (calculateAge(reduxProfile?.dob) >=
                          userPreferences?.minAge &&
                          calculateAge(reduxProfile?.dob) <=
                            userPreferences?.minAge) ||
                        !userPreferences?.minAge ||
                        !userPreferences?.maxAge
                          ? "text-success"
                          : "text-secondary"
                      } fs-2`}
                    ></i>
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col-8">
                    <p className="fs-7 fw-medium mb-3">
                      Marital Status
                      <br />
                      <span className="text-secondary">
                        {userPreferences?.minAge ||
                          ((userPreferences?.maritalStatus?.length === 0 ||
                            !userPreferences?.maritalStatus) &&
                            "Open to all")}
                        {userPreferences?.maritalStatus?.[0]}{" "}
                        {userPreferences?.maritalStatus?.length > 0 &&
                          "+" + userPreferences?.maritalStatus?.length + "more"}
                      </span>
                    </p>
                  </div>
                  <div className="col-4 d-flex justify-items-end flex-column align-items-end">
                    <i
                      className={`bi bi-check2 ${
                        userPreferences?.maritalStatus?.includes(
                          reduxProfile?.profile?.[0]?.maritalStatus
                        ) ||
                        userPreferences?.maritalStatus?.length === 0 ||
                        !userPreferences?.maritalStatus
                          ? "text-success"
                          : "text-secondary"
                      } fs-2`}
                    ></i>
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col-8">
                    <p className="fs-7 fw-medium mb-3">
                      Religion
                      <br />
                      <span className="text-secondary">
                        {(userPreferences?.religion?.length === 0 ||
                          !userPreferences?.religion) &&
                          "Open to all"}
                        {userPreferences?.religion?.[0]}{" "}
                        {userPreferences?.religion?.length > 0 &&
                          "+" + userPreferences?.religion?.length + "more"}
                      </span>
                    </p>
                  </div>
                  <div className="col-4 d-flex justify-items-end flex-column align-items-end">
                    <i
                      className={`bi bi-check2 ${
                        userPreferences?.religion?.includes(
                          reduxProfile?.profile?.[0]?.religion
                        ) ||
                        userPreferences?.religion?.length === 0 ||
                        !userPreferences?.religion
                          ? "text-success"
                          : "text-secondary"
                      } fs-2`}
                    ></i>
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col-8">
                    <p className="fs-7 fw-medium mb-3">
                      Mother Tongue
                      <br />
                      <span className="text-secondary">
                        {(userPreferences?.motherTongue?.length === 0 ||
                          !userPreferences?.motherTongue) &&
                          "Open to all"}
                        {userPreferences?.motherTongue?.[0]}{" "}
                        {userPreferences?.motherTongue?.length > 0 &&
                          "+" + userPreferences?.motherTongue?.length + "more"}
                      </span>
                    </p>
                  </div>
                  <div className="col-4 d-flex justify-items-end flex-column align-items-end">
                    <i
                      className={`bi bi-check2 ${
                        userPreferences?.motherTongue?.includes(
                          reduxProfile?.profile?.[0]?.motherTongue
                        ) ||
                        userPreferences?.motherTongue?.length === 0 ||
                        !userPreferences?.motherTongue
                          ? "text-success"
                          : "text-secondary"
                      } fs-2`}
                    ></i>
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col-8">
                    <p className="fs-7 fw-medium mb-3">
                      State
                      <br />
                      <span className="text-secondary">
                        {(userPreferences?.state?.length === 0 ||
                          !userPreferences?.state) &&
                          "Open to all"}
                        {userPreferences?.state?.[0]}{" "}
                        {userPreferences?.state?.length > 0 &&
                          "+" + userPreferences?.state?.length + "more"}
                      </span>
                    </p>
                  </div>
                  <div className="col-4 d-flex justify-items-end flex-column align-items-end">
                    <i
                      className={`bi bi-check2 ${
                        userPreferences?.state?.includes(
                          reduxProfile?.profile?.[0]?.state
                        ) ||
                        userPreferences?.state?.length === 0 ||
                        !userPreferences?.state
                          ? "text-success"
                          : "text-secondary"
                      } fs-2`}
                    ></i>
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col-8">
                    <p className="fs-7 fw-medium mb-3">
                      Family Type
                      <br />
                      <span className="text-secondary">
                        {(userPreferences?.familyType?.length === 0 ||
                          !userPreferences?.familyType) &&
                          "Open to all"}
                        {userPreferences?.familyType?.[0]}{" "}
                        {userPreferences?.familyType?.length > 0 &&
                          "+" + userPreferences?.familyType?.length + "more"}
                      </span>
                    </p>
                  </div>
                  <div className="col-4 d-flex justify-items-end flex-column align-items-end">
                    <i
                      className={`bi bi-check2 ${
                        userPreferences?.familyType?.includes(
                          reduxProfile?.profile?.[0]?.familyType
                        ) ||
                        userPreferences?.familyType?.length === 0 ||
                        !userPreferences?.familyType
                          ? "text-success"
                          : "text-secondary"
                      } fs-2`}
                    ></i>
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col-8">
                    <p className="fs-7 fw-medium mb-3">
                      Employed In
                      <br />
                      <span className="text-secondary">
                        {(userPreferences?.employedIn?.length === 0 ||
                          !userPreferences?.employedIn) &&
                          "Open to all"}
                        {userPreferences?.employedIn?.[0]}{" "}
                        {userPreferences?.employedIn?.length > 0 &&
                          "+" + userPreferences?.employedIn?.length + "more"}
                      </span>
                    </p>
                  </div>
                  <div className="col-4 d-flex justify-items-end flex-column align-items-end">
                    <i
                      className={`bi bi-check2 ${
                        userPreferences?.employedIn?.includes(
                          reduxProfile?.profile?.[0]?.employedIn
                        ) ||
                        userPreferences?.employedIn?.length === 0 ||
                        !userPreferences?.employedIn
                          ? "text-success"
                          : "text-secondary"
                      } fs-2`}
                    ></i>
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col-8">
                    <p className="fs-7 fw-medium mb-3">
                      Highest Qualification
                      <br />
                      <span className="text-secondary">
                        {(userPreferences?.qualification?.length === 0 ||
                          !userPreferences?.employedIn) &&
                          "Open to all"}
                        {userPreferences?.qualification?.[0]}{" "}
                        {userPreferences?.qualification?.length > 0 &&
                          "+" + userPreferences?.qualification?.length + "more"}
                      </span>
                    </p>
                  </div>
                  <div className="col-4 d-flex justify-items-end flex-column align-items-end">
                    <i
                      className={`bi bi-check2 ${
                        userPreferences?.qualification?.includes(
                          reduxProfile?.profile?.[0]?.highestDegree
                        ) ||
                        userPreferences?.qualification?.length === 0 ||
                        !userPreferences?.employedIn
                          ? "text-success"
                          : "text-secondary"
                      } fs-2`}
                    ></i>
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col-8">
                    <p className="fs-7 fw-medium mb-3">
                      Job Category
                      <br />
                      <span className="text-secondary">
                        {(userPreferences?.jobCategory?.length === 0 ||
                          !userPreferences?.jobCategory) &&
                          "Open to all"}
                        {userPreferences?.jobCategory?.[0]}{" "}
                        {userPreferences?.jobCategory?.length > 0 &&
                          "+" + userPreferences?.jobCategory?.length + "more"}
                      </span>
                    </p>
                  </div>
                  <div className="col-4 d-flex justify-items-end flex-column align-items-end">
                    <i
                      className={`bi bi-check2 ${
                        userPreferences?.jobCategory?.includes(
                          reduxProfile?.profile?.[0]?.jobCategory
                        ) ||
                        userPreferences?.jobCategory?.length === 0 ||
                        !userPreferences?.jobCategory
                          ? "text-success"
                          : "text-secondary"
                      } fs-2`}
                    ></i>
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col-8">
                    <p className="fs-7 fw-medium mb-3">
                      Annual Income
                      <br />
                      <span className="text-secondary">
                        {(userPreferences?.annualIncome?.length === 0 ||
                          !userPreferences?.annualIncome) &&
                          "Open to all"}
                        {userPreferences?.annualIncome?.[0]}{" "}
                        {userPreferences?.annualIncome?.length > 0 &&
                          "+" + userPreferences?.annualIncome?.length + "more"}
                      </span>
                    </p>
                  </div>
                  <div className="col-4 d-flex justify-items-end flex-column align-items-end">
                    <i
                      className={`bi bi-check2 ${
                        userPreferences?.annualIncome?.includes(
                          reduxProfile?.profile?.[0]?.annualIncome
                        ) ||
                        userPreferences?.annualIncome?.length === 0 ||
                        !userPreferences?.annualIncome
                          ? "text-success"
                          : "text-secondary"
                      } fs-2`}
                    ></i>
                  </div>
                </div>
              </div>
            </div>

            <div className="fixed-bottom">
              <div className="container">
                <div className="row">
                  <div className="col-lg-3 col-12 d-lg-block d-none"></div>
                  <div className="col-lg-6 col-12">
                    <div className="mx-lg-4 mx-0 rounded-3 shadow p-3 bg-crimson mb-lg-3 mb-2">
                      <div className="row">
                        <div className="col-3">
                          {/* Interests */}
                          {reduxProfile?.connection?.length === 0 && (
                            <div
                              className="d-flex align-items-center flex-column"
                              onClick={handleSendInterest}
                              disabled={connectLoading}
                              type="button"
                            >
                              <div className="mb-1 d-flex">
                                <i className="bi bi-envelope-arrow-up fs-5 text-white"></i>
                              </div>
                              <p className="fs-9 text-white lh-1 text-center">
                                Send Interest
                              </p>
                            </div>
                          )}

                          {reduxProfile?.connection?.length > 0 &&
                            reduxProfile?.connection?.[0]?.status === "sent" &&
                            reduxProfile?.connection?.[0]?.senderId !==
                              user?._id && (
                              <div
                                className="d-flex align-items-center flex-column"
                                onClick={handleAcceptInterest}
                                disabled={connectLoading}
                                type="button"
                              >
                                <div className="mb-1 d-flex">
                                  <i className="bi bi-envelope-arrow-up fs-5 text-white"></i>
                                </div>
                                <p className="fs-9 text-white lh-1 text-center">
                                  Accept Interest
                                </p>
                              </div>
                            )}

                          {reduxProfile?.connection?.length > 0 &&
                            reduxProfile?.connection?.[0]?.status === "sent" &&
                            reduxProfile?.connection?.[0]?.senderId ===
                              user?._id && (
                              <div
                                className="d-flex align-items-center flex-column"
                                disabled={connectLoading}
                                activeOpacity={0.8}
                                onClick={handleCancelInterest}
                                type="button"
                              >
                                <div className="mb-1 d-flex">
                                  <i className="bi bi-envelope-arrow-up fs-5 text-white"></i>
                                </div>
                                <p className="fs-9 text-white lh-1 text-center">
                                  Interest Sent
                                </p>
                              </div>
                            )}

                          {reduxProfile?.connection?.length > 0 &&
                            reduxProfile?.connection?.[0]?.status ===
                              "accepted" && (
                              <div
                                className="d-flex align-items-center flex-column"
                                disabled
                                activeOpacity={0.8}
                                type="button"
                              >
                                <div className="mb-1 d-flex">
                                  <i className="bi bi-envelope-arrow-up fs-5 text-white"></i>
                                </div>
                                <p className="fs-9 text-white lh-1 text-center">
                                  Accepted
                                </p>
                              </div>
                            )}
                        </div>

                        {/* Shortlists */}
                        <div className="col-3">
                          {profileDetails?.shortlist?.length === 0 && (
                            <div
                              className="d-flex align-items-center flex-column"
                              disabled={shortlistLoading}
                              onClick={handleShortlist}
                              type="button"
                            >
                              <div className="mb-1 d-flex">
                                <i className="bi bi-star fs-5 text-white"></i>
                              </div>
                              <p className="fs-9 text-white lh-1 text-center">
                                Shortlist
                              </p>
                            </div>
                          )}

                          {profileDetails?.shortlist?.length > 0 && (
                            <div
                              className="d-flex align-items-center flex-column"
                              disabled={shortlistLoading}
                              onClick={handleCancelShortlist}
                              type="button"
                            >
                              <div className="mb-1 d-flex">
                                <i className="bi bi-star fs-5 text-white"></i>
                              </div>
                              <p className="fs-9 text-white lh-1 text-center">
                                Shortlisted
                              </p>
                            </div>
                          )}
                        </div>
                        <div className="col-3">
                          <div
                            className="d-flex align-items-center flex-column cursor-pointer"
                            onClick={() =>
                              handleNavigateToChatPage(userDetails)
                            }
                          >
                            <div className="mb-1 d-flex">
                              <i className="bi bi-chat fs-5 text-white"></i>
                            </div>
                            <p className="fs-9 text-white lh-1 text-center">
                              Chat
                            </p>
                          </div>
                        </div>
                        <div className="col-3">
                          <div
                            className="d-flex align-items-center flex-column"
                            type="button"
                            onClick={() =>
                              navigate("./", {
                                state: {
                                  Information: "Report",
                                  userInfo: userDetails,
                                },
                              })
                            }
                          >
                            <div className="mb-1 d-flex">
                              <i className="bi bi-info-circle fs-5 text-white"></i>
                            </div>
                            <p className="fs-9 text-white lh-1 text-center">
                              Report
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 col-12 d-lg-block d-none"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Details;
