import axios from "axios";
import { server } from "../../../server";

export const getGenderProfilesAction = (gender) => async (dispatch) => {
  dispatch({
    type: "GET_GENDER_PROFILES_REQUEST",
  });

  try {
    let token = await localStorage.getItem("token");
    const { data } = await axios.get(
      `${server}/profile/genderWise/${gender}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
      {
        headers: { "Content-Type": "application/json" },
      }
    );

    dispatch({
      type: "GET_GENDER_PROFILES_SUCCESS",
      payload: { genderProfiles: data?.data },
    });
  } catch (e) {
    dispatch({
      type: "GET_GENDER_PROFILES_FAIL",
      payload: { error: e?.response?.data?.message || e.message },
    });
  }
};

export const getAllProfilesAction = () => async (dispatch) => {
  dispatch({
    type: "GET_ALL_PROFILES_REQUEST",
  });

  try {
    const { data } = await axios.get(`${server}/profile/all`, {
      headers: { "Content-Type": "application/json" },
    });

    dispatch({
      type: "GET_ALL_PROFILES_SUCCESS",
      payload: { femaleProfiles: data?.data },
    });
  } catch (e) {
    dispatch({
      type: "GET_ALL_PROFILES_FAIL",
      payload: { error: e?.response?.data?.message || e.message },
    });
  }
};
