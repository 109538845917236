import {configureStore} from '@reduxjs/toolkit';
import {registerReducer} from './reducers/register/registerReducer';
import {registrationStageReducer} from './reducers/register/registrationStageReducer';
import {profileReducer} from './reducers/profile/profileReducer';
import {matchedReducer} from './reducers/home/matchesReducer';
import {connectReducer} from './reducers/connect';
import {shortListReducer} from './reducers/shortlist';
import {chatReducer} from './reducers/chatReducer';
import {notificationReducer} from './reducers/notification';

const store = configureStore({
  reducer: {
    user: registerReducer,
    regStage: registrationStageReducer,
    profile: profileReducer,
    match: matchedReducer,
    connect: connectReducer,
    shortlist: shortListReducer,
    chat: chatReducer,
    notification: notificationReducer,
  },
});

export default store;
