export const qualificationList = [
  {
    name: "Engineering",
    category: [
      "B.E/ B.Tech",
      "M.E/ M.Tech",
      "M.S/ M.Tech",
      "M.S Engineering",
      "B. Eng (Hons)",
      "M.Eng (Hons)",
      "Engineering Diploma",
      "AE",
      "AET",
    ],
  },
  {
    name: "Art/Design",
    category: [
      "B.A",
      "B.Ed",
      "B.Jmc",
      "BFA",
      "B.Arch",
      "B.Des",
      "BMM",
      "MFA",
      "M.Ed",
      "M.A",
      "MSW",
      "MJMC",
      "M.Arch",
      "M.Des",
      "B.A (Hons)",
      "B.Arch (Hons)",
      "DFA",
      "D.Ed",
      "D.Arch",
      "AA",
      "AFA",
    ],
  },
  {
    name: "Finance/commerce",
    category: [
      "B.Com",
      "CA/CPA",
      "CFA",
      "CS",
      "Bsc/BFin",
      "M.Com",
      "Mse/Mfin/Ms",
      "B.Com (Hons)",
      "Pgd Finance",
    ],
  },
  {
    name: "Computer/IT",
    category: [
      "BCA",
      "B.IT",
      "BCS",
      "BA Computer Science",
      "MCA",
      "PGDCA",
      "IT Diploma",
      "ADIT",
    ],
  },
  {
    name: "Science",
    category: ["B.Sc", "M.Sc", "B.sc (Hons)", "DIPSC", "AS", "AAS"],
  },
  {
    name: "Medicine",
    category: [
      "MBBS",
      "BDS",
      "BPT",
      "BAMS",
      "BHMS",
      "B.Pharma",
      "BVSC",
      "BSN/BSCN",
      "MDS",
      "MCH",
      "M.D",
      "M.S Medicine",
      "MPT",
      "DM",
      "M.Pharma",
      "MYSC",
      "M Med",
      "PGD Medicine",
      "AND",
    ],
  },
  {
    name: "Management",
    category: ["BBA", "BHM", "BBM", "MBA", "PGDM", "ABA", "ADBUS"],
  },
  { name: "Law", category: ["BL/LLB", "ML/LLM", "LLB (Hons)", "ALA"] },
  { name: "Doctorate", category: ["Ph.d", "M.Phil"] },
  {
    name: "Others",
    category: [
      "Bachelor",
      "Master",
      "Diploma",
      "Hounarse",
      "Doctorate",
      "Associate",
    ],
  },
  {
    name: "Non-Graduate",
    category: ["High School", "Less Then High School", "Non Matric"],
  },
];
