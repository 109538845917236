export const motherTongueList = [
  {
    Id: "24",
    Name: "Hindi",
  },
  {
    Id: "1",
    Name: "Aka",
  },
  {
    Id: "2",
    Name: "Arabic",
  },
  {
    Id: "3",
    Name: "Arunachali",
  },
  {
    Id: "4",
    Name: "Assamese",
  },
  {
    Id: "5",
    Name: "Awadhi",
  },
  {
    Id: "6",
    Name: "Baluchi",
  },
  {
    Id: "7",
    Name: "Bengali",
  },
  {
    Id: "8",
    Name: "Bhojpuri",
  },
  {
    Id: "9",
    Name: "Bhutia",
  },
  {
    Id: "10",
    Name: "Brahui",
  },
  {
    Id: "11",
    Name: "Brij",
  },
  {
    Id: "12",
    Name: "Burmese",
  },
  {
    Id: "13",
    Name: "Chattisgarhi",
  },
  {
    Id: "14",
    Name: "Chinese",
  },
  {
    Id: "15",
    Name: "Coorgi",
  },
  {
    Id: "16",
    Name: "Dogri",
  },
  {
    Id: "17",
    Name: "English",
  },
  {
    Id: "18",
    Name: "French",
  },
  {
    Id: "19",
    Name: "Garhwali",
  },
  {
    Id: "20",
    Name: "Garo",
  },
  {
    Id: "21",
    Name: "Gujarati",
  },
  {
    Id: "22",
    Name: "Haryanavi",
  },
  {
    Id: "23",
    Name: "Himachali/Pahari",
  },
  {
    Id: "25",
    Name: "Hindko",
  },
  {
    Id: "26",
    Name: "Kakbarak",
  },
  {
    Id: "27",
    Name: "Kanauji",
  },
  {
    Id: "28",
    Name: "Kannada",
  },
  {
    Id: "29",
    Name: "Kashmiri",
  },
  {
    Id: "30",
    Name: "Khandesi",
  },
  {
    Id: "31",
    Name: "Khasi",
  },
  {
    Id: "32",
    Name: "Konkani",
  },
  {
    Id: "33",
    Name: "Koshali",
  },
  {
    Id: "34",
    Name: "Kumaoni",
  },
  {
    Id: "35",
    Name: "Kutchi",
  },
  {
    Id: "36",
    Name: "Ladakhi",
  },
  {
    Id: "37",
    Name: "Lepcha",
  },
  {
    Id: "38",
    Name: "Magahi",
  },
  {
    Id: "39",
    Name: "Maithili",
  },
  {
    Id: "40",
    Name: "Malay",
  },
  {
    Id: "41",
    Name: "Malayalam",
  },
  {
    Id: "42",
    Name: "Manipuri",
  },
  {
    Id: "43",
    Name: "Marathi",
  },
  {
    Id: "44",
    Name: "Marwari",
  },
  {
    Id: "45",
    Name: "Miji",
  },
  {
    Id: "46",
    Name: "Mizo",
  },
  {
    Id: "47",
    Name: "Monpa",
  },
  {
    Id: "48",
    Name: "Nepali",
  },
  {
    Id: "49",
    Name: "Odia",
  },
  {
    Id: "50",
    Name: "Pashto",
  },
  {
    Id: "51",
    Name: "Persian",
  },
  {
    Id: "52",
    Name: "Punjabi",
  },
  {
    Id: "53",
    Name: "Rajasthani",
  },
  {
    Id: "54",
    Name: "Russian",
  },
  {
    Id: "55",
    Name: "Sanskrit",
  },
  {
    Id: "56",
    Name: "Santhali",
  },
  {
    Id: "57",
    Name: "Seraiki",
  },
  {
    Id: "58",
    Name: "Sindhi",
  },
  {
    Id: "59",
    Name: "Sinhala",
  },
  {
    Id: "60",
    Name: "Sourashtra",
  },
  {
    Id: "61",
    Name: "Spanish",
  },
  {
    Id: "62",
    Name: "Swedish",
  },
  {
    Id: "63",
    Name: "Tagalog",
  },
  {
    Id: "64",
    Name: "Tamil",
  },
  {
    Id: "65",
    Name: "Telugu",
  },
  {
    Id: "66",
    Name: "Tulu",
  },
  {
    Id: "67",
    Name: "Urdu",
  },
  {
    Id: "68",
    Name: "Other",
  },
];
