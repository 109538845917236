import axios from "axios";
import { server } from "../../../server";

export const registrationAction = (formData) => async (dispatch) => {
  dispatch({
    type: "USER_COMPLETE_REGISTRATION_REQUEST",
  });

  try {
    const { data } = await axios.post(`${server}/user/new`, formData, {
      headers: { "Content-Type": "application/json" },
    });

    const itemsToRemove = [
      "images",
      "registerData",
      "basicDetailsData",
      "careerFamilyData",
    ];
    dispatch({
      type: "REGISTRATION_STAGE_MAKE_EMPTY_IMAGE",
    });
    itemsToRemove.map((item) => localStorage.removeItem(item));
    localStorage.setItem("myProfile", JSON.stringify(data?.user));
    localStorage.setItem("token", data?.token);
    dispatch({
      type: "USER_COMPLETE_REGISTRATION_SUCCESS",
      payload: { user: data?.user },
    });

    dispatch({
      type: "GET_MY_PROFILE_SUCCESS",
      payload: { user: data?.user, token: data?.token },
    });

    console.log("Success", data);
  } catch (e) {
    console.log(e?.response?.data?.message || e.message);
    dispatch({
      type: "USER_COMPLETE_REGISTRATION_FAIL",
      payload: { error: e?.response?.data?.message || e.message },
    });
  }
};

// export const getMYProfileAction = () => async dispatch => {
//   dispatch({
//     type: 'GET_USER_PROFILE_REQUEST',
//   });

//   try {
//     const {data} = await axios.get(`${server}/user/me`);

//     dispatch({
//       type: 'GET_USER_PROFILE_SUCCESS',
//       payload: {user: data?.user, token: data?.token},
//     });
//     localStorage.setItem(`myProfile`, JSON.stringify(data?.user));
//     localStorage.setItem(`token`, data?.token);
//   } catch (e) {
//     dispatch({
//       type: 'GET_USER_PROFILE_FAIL',
//       payload: {error: e?.response?.data?.message || e.message},
//     });
//   }
// };

export const sendOtpAction = (formData) => async (dispatch) => {
  dispatch({
    type: "SEND_OTP_REQUEST",
  });

  try {
    const { data } = await axios.put(`${server}/user/sendOtp`, formData, {
      headers: { "Content-Type": "application/json" },
    });

    // from === "login" &&
    //   navigation?.navigate("verifyOTP", { mobile: formData?.mobile });
    dispatch({
      type: "SEND_OTP_SUCCESS",
      payload: { otp: data?.otp },
    });

    console.log("OTP sent");
    // localStorage.setItem("otp", data?.otp.toString());
  } catch (e) {
    console.log(e?.response?.data?.message || e.message);
    dispatch({
      type: "SEND_OTP_FAIL",
      payload: { error: e?.response?.data?.message || e.message },
    });
  }
};

export const sendOtpForLoginAction =
  (formData, navigate) => async (dispatch) => {
    dispatch({
      type: "SEND_OTP_REQUEST",
    });

    try {
      const { data } = await axios.put(
        `${server}/user/sendOtpForLogin`,
        formData
      );

      navigate("/verifyLoginOtp", { state: { mobile: formData?.mobile } });
      dispatch({
        type: "SEND_OTP_SUCCESS",
        payload: { otp: data?.otp },
      });
    } catch (e) {
      console.log("Error", e?.response?.data?.message || e.message);
      dispatch({
        type: "SEND_OTP_FAIL",
        payload: { error: e?.response?.data?.message || e.message },
      });
    }
  };

export const verifyOtpAction = (formData) => async (dispatch) => {
  dispatch({
    type: "VERIFY_OTP_REQUEST",
  });

  try {
    const { data } = await axios.put(`${server}/user/verifyOtp`, formData, {
      headers: { "Content-Type": "application/json" },
    });
    localStorage.setItem("token", data?.token);
    localStorage.setItem("myProfile", JSON.stringify(data?.user));

    dispatch({
      type: "VERIFY_OTP_SUCCESS",
      payload: { user: data?.user },
    });

    dispatch({
      type: "GET_MY_PROFILE_SUCCESS",
      payload: { user: data?.user, token: data?.token },
    });
  } catch (e) {
    console.log(e?.response?.data?.message || e.message);
    dispatch({
      type: "VERIFY_OTP_FAIL",
      payload: { error: e?.response?.data?.message || e.message },
    });
  }
};

export const deleteProfileAction = () => async (dispatch) => {
  dispatch({
    type: "DELETE_PROFILE_REQUEST",
  });

  try {
    await axios.delete(`${server}/user/delete/myProfile`, {
      headers: { "Content-Type": "application/json" },
    });

    localStorage.setItem("token", "");
    localStorage.clear();
    dispatch({
      type: "DELETE_PROFILE_SUCCESS",
    });
  } catch (e) {
    console.log(e?.response?.data?.message || e.message);
    console.log("Hello");
    dispatch({
      type: "DELETE_PROFILE_FAIL",
      payload: { error: e?.response?.data?.message || e.message },
    });
  }
};
