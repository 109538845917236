import { createReducer } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  error: null,
  user: null,
  token: null,
  isAuthenticated: false,
};

export const profileReducer = createReducer(initialState, (builder) => {
  builder
    .addCase("GET_MY_PROFILE_REQUEST", (state, action) => {
      state.loading = true;
      state.error = null;
      state.isAuthenticated = false;
    })
    .addCase("GET_MY_PROFILE_SUCCESS", (state, action) => {
      state.loading = false;
      state.user = action.payload.user;
      state.token = action.payload.token;
      state.isAuthenticated = true;
      state.error = null;
    })
    .addCase("GET_MY_PROFILE_FAIL", (state, action) => {
      state.loading = false;
      state.user = null;
      state.isAuthenticated = false;
      state.error = action.payload.error;
    });

  builder
    .addCase("GET_USER_PROFILE_BY_ID_REQUEST", (state, action) => {
      state.loading = true;
      state.error = null;
    })
    .addCase("GET_USER_PROFILE_BY_ID_SUCCESS", (state, action) => {
      state.loading = false;
      state.reduxProfile = action.payload.reduxProfile;
    })
    .addCase("GET_USER_PROFILE_BY_ID_FAIL", (state, action) => {
      state.loading = false;
      state.error = action.payload.error;
    });

  builder
    .addCase("EDIT_USER_DETAILS_REQUEST", (state, action) => {
      state.loading = true;
      state.error = null;
    })
    .addCase("EDIT_USER_DETAILS_SUCCESS", (state, action) => {
      state.loading = false;
      state.user = action.payload.user;
      state.error = null;
    })
    .addCase("EDIT_USER_DETAILS_FAIL", (state, action) => {
      state.loading = false;
      state.user = null;
      state.error = action.payload.error;
    });

  builder
    .addCase("LOGOUT_REQUEST", (state, action) => {
      state.loading = true;
      state.error = null;
    })
    .addCase("LOGOUT_SUCCESS", (state, action) => {
      state.loading = false;
      state.user = null;
      state.token = null;
      state.isAuthenticated = false;
    })
    .addCase("LOGOUT_FAIL", (state, action) => {
      state.loading = false;
      state.user = null;
      state.isAuthenticated = false;
      state.error = action.payload.error;
    });
});
