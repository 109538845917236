/* eslint-disable jsx-a11y/alt-text */
import { useNavigate } from "react-router-dom";
import { defaultUserImage, server } from "../../server";
import { useSelector } from "react-redux";

function Left() {
  const navigate = useNavigate();
  const { user } = useSelector((state) => state?.profile);

  return (
    <>
      <div className="bg-white p-4 rounded-4 block-border">
        <div className="py-2 d-flex align-items-center">
          <img
            src={
              user?.profile?.images?.length > 0
                ? user?.profile?.images[0]?.docUrl
                : defaultUserImage
            }
            width="60"
            height="60"
            className="rounded-pill object-fit-cover"
          />
          <div className="ms-3">
            <h5 className="m-0 fs-6">Hi, {(user?.name || "User").split(" ")[0]}</h5>
            <p className="fs-9">
              GRSTY{user?.customId}
              <span
                onClick={() =>
                  navigate("./", { state: { Information: "Update" } })
                }
                type="button"
                className="ms-3 text-danger fw-medium"
              >
                Edit Profile
              </span>
            </p>
          </div>
        </div>
        <div className="profile-divider my-3"></div>
        <div className="mt-2">
          <div
            onClick={() =>
              navigate("./", { state: { Information: "Matches" } })
            }
            type="button"
            className="d-flex justify-content-between align-items-center py-2 mb-2"
          >
            <p className="fs-7 text-dark fw-medium">Matches</p>
            <i className="bi bi-chevron-right fs-8"></i>
          </div>
          <div
            onClick={() =>
              navigate("./", { state: { Information: "Activity" } })
            }
            type="button"
            className="d-flex justify-content-between align-items-center py-2 mb-2"
          >
            <p className="fs-7 text-secondary">Activity</p>
            <i className="bi bi-chevron-right fs-8"></i>
          </div>
          <div
            onClick={() => navigate("./", { state: { Information: "Search" } })}
            type="button"
            className="d-flex justify-content-between align-items-center py-2 mb-2"
          >
            <p className="fs-7 text-secondary">Search</p>
            <i className="bi bi-chevron-right fs-8"></i>
          </div>
          <div
            onClick={() =>
              navigate("./", { state: { Information: "Messages" } })
            }
            type="button"
            className="d-flex justify-content-between align-items-center py-2 mb-2"
          >
            <p className="fs-7 text-secondary">Messages</p>
            <i className="bi bi-chevron-right fs-8"></i>
          </div>
          <div
            onClick={() =>
              navigate("./", { state: { Information: "Upgrade" } })
            }
            type="button"
            className="d-flex justify-content-between align-items-center py-2 mb-2"
          >
            <p className="fs-7 text-secondary">Upgrade</p>
            <i className="bi bi-chevron-right fs-8"></i>
          </div>
        </div>
      </div>
    </>
  );
}

export default Left;
