import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { chatEndPoint, defaultUserImage, server } from "../../server";
import { getMyChatProfileList } from "../../redux/actions/chatAction";
import { toast } from "react-toastify";
import { io } from "socket.io-client";

function Messages() {
  const navigate = useNavigate();
  const { user } = useSelector((state) => state?.profile);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [conversationProfiles, setConversationProfiles] = useState([]);
  const { chatList } = useSelector((state) => state?.chat);

  useEffect(() => {
    dispatch(getMyChatProfileList());
  }, []);

  // Web socket integration
  let socketConnection;
  useEffect(() => {
    socketConnection = io(chatEndPoint, {
      auth: {
        userId: user?._id,
      },
    });

    if (socketConnection) {
      socketConnection.emit("chat-profiles", user?._id);
      socketConnection.on("my-conversations", (data) => {
        setConversationProfiles(data);
        setLoading(false);
      });
    } else {
      toast.error("Web socket connection failed");
      setLoading(false);
    }
  }, [socketConnection]);

  const getUserProfileFromConversation = (conversation) => {
    if (conversation?.sender?._id === user?._id) {
      return conversation?.receiver;
    } else {
      return conversation?.sender;
    }
  };

  // Navigate to chat page
  const handleNavigateToChatPage = async (item) => {
    const tempUser = getUserProfileFromConversation(item);

    navigate("./", {
      state: {
        Information: "Chat",
        selectedUser: { ...tempUser, profile: tempUser?.profile },
      },
    });
  };

  return (
    <>
      <div className="block-margin-top mb-4">
        <div className="py-4 bg-white rounded-4 block-border">
          <div className="px-4 mx-2">
            <div className="tab-content">
              <div>
                <div className="mb-4 mt-2">
                  <div className="row">
                    <div className="col-10">
                      <h5 className="fs-6 mb-0 d-flex align-items-center fw-medium">
                        My Conversations
                        <span className="text-secondary fs-6 ms-2">
                          ({conversationProfiles?.length})
                        </span>
                      </h5>
                    </div>
                  </div>
                </div>

                {/* Map conversations */}
                {conversationProfiles?.length > 0 && (
                  <div className="py-1">
                    {[...conversationProfiles]?.map((item, index) => (
                      <div
                        key={index}
                        className="mb-3"
                        onClick={() => handleNavigateToChatPage(item)}
                      >
                        <div className="row">
                          <div className="col-10 d-flex align-items-center">
                            <img
                              src={
                                getUserProfileFromConversation(item)?.profile
                                  ?.images?.[0]?.docUrl || defaultUserImage
                              }
                              className="rounded-pill object-fit-cover"
                              width="55"
                              height="55"
                              alt=""
                            />
                            <div className="ms-4">
                              <div className="lh-1">
                                <div
                                  onClick={() => handleNavigateToChatPage(item)}
                                  className="cursor-pointer"
                                >
                                  <p className="m-0 fw-medium fs-7 text-uppercase text-dark">
                                    {getUserProfileFromConversation(item)?.name}
                                  </p>
                                </div>
                                <p className="m-0 text-secondary fs-8 m-0 my-1">
                                  {item?.lastMessage?.text}
                                </p>
                                <p className="m-0 text-secondary fs-9 text-uppercase m-0">
                                  {item?.lastMessage
                                    ? new Date(
                                        item?.lastMessage?.createdAt
                                      )?.toLocaleString("en-In")
                                    : ""}
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="col-2 text-end">
                            <div
                              onClick={() => handleNavigateToChatPage(item)}
                              className="cursor-pointer"
                            >
                              <i className="bi bi-chat-left-text px-1 text-secondary fs-5"></i>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                )}

                {conversationProfiles?.length === 0 &&
                  chatList?.length === 0 && (
                    <div className="py-4 text-center">
                      <img
                        src="../assets/images/not-found.png"
                        width="220"
                        className="my-3"
                        alt="profile_image"
                      />
                      <div className="mt-5 text-start">
                        <p className="fs-7 mb-0">No Matches, for Chat</p>
                        <p className="text-secondary fs-8 mb-0">
                          Connect with your favorite matches and start chatting.
                        </p>
                      </div>
                    </div>
                  )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Messages;
