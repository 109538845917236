import { useNavigate } from "react-router-dom";
import PageHeader from "../../components/PageHeader";
import { useSelector } from "react-redux";
import { useEffect } from "react";

function Update() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const navigate = useNavigate();
  const { user } = useSelector((state) => state?.profile);
  return (
    <>
      <div className="block-margin-top mb-4">
        <div className="py-4 bg-white rounded-4 block-border">
          <PageHeader name="Update Account" />

          <div className="px-4">
            <div
              onClick={() =>
                navigate("./", {
                  state: { Information: "updateBasic" },
                })
              }
              type="button"
              className="pb-3"
            >
              <div className="d-flex justify-content-between align-items-center">
                <div className="d-flex align-items-center">
                  <img
                    src="https://cdn-icons-png.flaticon.com/512/727/727399.png"
                    width="45"
                  />
                  <div className="ms-3">
                    <h5 className="fs-7 m-0">Basic Details</h5>
                    <p className="fs-9 text-secondary">
                      DOB, Height, Marital Status
                    </p>
                  </div>
                </div>
                <div>
                  <h5 className="fs-6">
                    <i className="bi bi-arrow-right"></i>
                  </h5>
                </div>
              </div>
            </div>
            <div
              onClick={() =>
                navigate("./", {
                  state: { Information: "updateCareer" },
                })
              }
              type="button"
              className="pb-3"
            >
              <div className="d-flex justify-content-between align-items-center">
                <div className="d-flex align-items-center">
                  <img
                    src="https://cdn-icons-png.flaticon.com/512/14436/14436140.png"
                    width="45"
                  />
                  <div className="ms-3">
                    <h5 className="fs-7 m-0">Career & Family</h5>
                    <p className="fs-9 text-secondary">
                      Qualification, Job Title, Income
                    </p>
                  </div>
                </div>
                <div>
                  <h5 className="fs-6">
                    <i className="bi bi-arrow-right"></i>
                  </h5>
                </div>
              </div>
            </div>
            <div
              onClick={() =>
                navigate("./", {
                  state: { Information: "updateImages" },
                })
              }
              type="button"
              className="pb-3"
            >
              <div className="d-flex justify-content-between align-items-center">
                <div className="d-flex align-items-center">
                  <img
                    src="https://cdn-icons-png.flaticon.com/512/9154/9154150.png"
                    width="45"
                  />
                  <div className="ms-3">
                    <h5 className="fs-7 m-0">Image</h5>
                    <p className="fs-9 text-secondary">Update Image</p>
                  </div>
                </div>
                <div>
                  <h5 className="fs-6">
                    <i className="bi bi-arrow-right"></i>
                  </h5>
                </div>
              </div>
            </div>
            <div
              onClick={() =>
                navigate("./", {
                  state: { Information: "partnerPreferences" },
                })
              }
              type="button"
            >
              <div className="d-flex justify-content-between align-items-center">
                <div className="d-flex align-items-center">
                  <img
                    src="https://cdn-icons-png.flaticon.com/512/11273/11273446.png"
                    width="45"
                  />
                  <div className="ms-3">
                    <h5 className="fs-7 m-0">Partner Preferences</h5>
                    <p className="fs-9 text-secondary">
                      Set you Partner Preferences
                    </p>
                  </div>
                </div>
                <div>
                  <h5 className="fs-6">
                    <i className="bi bi-arrow-right"></i>
                  </h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Update;
