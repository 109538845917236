import { useNavigate } from "react-router-dom";

function Right() {
  const navigate = useNavigate();

  return (
    <>
      <div className="bg-white p-4 rounded-4 block-border">
        <div className="pt-3 pb-2">
          <h5 className="fs-6 text-center fw-medium">
            You are <span class="text-crimson">missing</span> out on the premium
            benefits!
          </h5>
          <div className="mt-4 px-4 py-2">
            <div className="d-flex align-items-center mb-1">
              <svg
                height="55"
                width="55"
                viewBox="0 0 48 48"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                alt="profileViewIcon"
                class="me-2"
              >
                <rect width="48" height="48" rx="24" fill="#F5F5FF"></rect>
                <g clip-path="url(#profileViewIcon_svg__a)" fill="#6F6CD0">
                  <path d="M26.526 33a12.89 12.89 0 0 1-7.599-3.693c-2.038-2.038-3.354-4.755-3.736-7.6-.042-.297 0-.594.085-.89.085-.256.212-.51.425-.808.17-.212.424-.382.721-.551a2.12 2.12 0 0 1 .892-.213h2.038c.509 0 1.018.17 1.4.51.34.297.552.637.68 1.104.042.127.042.297.085.424.042.212.085.425.127.594.043.085.043.17.085.213.042.17.127.382.17.552.127.594-.17 1.06-.764 1.485l-.255.213c.382.637.806 1.23 1.316 1.74a7.412 7.412 0 0 0 1.698 1.274l.085-.212c.17-.298.425-.552.764-.68.17-.085.34-.085.51-.085.34 0 .679.085 1.018.213.128.042.255.085.34.085.764.17 1.316.467 1.698.849.552.594.552 1.485.552 2.25v.212c0 .467 0 .934-.085 1.4-.255.977-1.061 1.656-2.038 1.656h-.212V33Zm-7.811-12.226c-.255 0-.552 0-.764.042-.213 0-.382.043-.51.043-.255 0-.467.042-.594.17-.212.212-.17.594-.128.848.68 3.736 3.1 6.963 6.538 8.66l.128.086c.636.297 2.122 1.019 3.099 1.019.424 0 .721-.128.806-.425.043-.085.043-.212.043-.34V29.18c0-.212 0-.467-.128-.636-.084-.213-.34-.34-.636-.425l-.383-.127c-.254-.085-.509-.17-.721-.17h-.043c-.254 0-.34.212-.424.51-.085.212-.17.466-.382.636a.91.91 0 0 1-.51.17c-.212 0-.467-.085-.679-.17-1.868-1.019-3.481-2.674-4.373-4.542a1.232 1.232 0 0 1-.127-.467c0-.297.212-.552.594-.764.043-.043.128-.043.213-.085.17-.043.51-.17.51-.255 0-.043 0-.127-.043-.212a2.82 2.82 0 0 1-.255-.85c0-.042 0-.127-.043-.17-.042-.212-.084-.466-.212-.594-.085-.17-.424-.254-.976-.254ZM25.125 20.773a.744.744 0 0 1-.721-.721c0-.425.34-.722.721-.722h3.057c.424 0 .722.34.722.722 0 .424-.34.721-.722.721h-3.057Z"></path>
                  <path d="M25.125 23.83a.715.715 0 0 1-.721-.722c0-.382.34-.721.721-.721h3.057c.212 0 .424.085.552.254.127.128.212.34.212.51 0 .382-.34.722-.722.722h-3.099v-.043Z"></path>
                  <path d="M30.05 32.49c-.467 0-.722-.424-.722-.806 0-.212.043-.425.17-.552a.685.685 0 0 1 .552-.255h.637c.254 0 .51-.212.51-.51V17.166a.516.516 0 0 0-.51-.51H20.583a.516.516 0 0 0-.51.51v.637c0 .467-.424.722-.806.722-.425 0-.807-.255-.807-.722v-.637c0-1.189.977-2.165 2.123-2.165h10.146c.552 0 1.104.212 1.486.637.382.424.637.934.594 1.528v13.16c0 1.19-.976 2.166-2.122 2.166h-.637Z"></path>
                </g>
              </svg>
              <p className="fs-8 lh-1">Get upto 3x more profile views</p>
            </div>

            <div className="d-flex align-items-center mb-1">
              <svg
                height="55"
                width="55"
                viewBox="0 0 48 48"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                alt="profileViewIcon"
                class="me-2"
              >
                <rect width="48" height="48" rx="24" fill="#E6F7EE"></rect>
                <g clip-path="url(#contactDetailIcon_svg__a)" fill="#26CBA3">
                  <path d="M26.526 33a12.89 12.89 0 0 1-7.599-3.693c-2.038-2.038-3.354-4.755-3.736-7.6-.042-.297 0-.594.085-.89.085-.256.212-.51.425-.808.17-.212.424-.382.721-.551a2.12 2.12 0 0 1 .892-.213h2.038c.509 0 1.018.17 1.4.51.34.297.552.637.68 1.104.042.127.042.297.085.424.042.212.085.425.127.594.043.085.043.17.085.213.042.17.127.382.17.552.127.594-.17 1.06-.764 1.485l-.255.213c.382.637.806 1.23 1.316 1.74a7.412 7.412 0 0 0 1.698 1.274l.085-.212c.17-.298.425-.552.764-.68.17-.085.34-.085.51-.085.34 0 .679.085 1.018.213.128.042.255.085.34.085.764.17 1.316.467 1.698.849.552.594.552 1.485.552 2.25v.212c0 .467 0 .934-.085 1.4-.255.977-1.061 1.656-2.038 1.656h-.212V33Zm-7.811-12.226c-.255 0-.552 0-.764.042-.213 0-.382.043-.51.043-.255 0-.467.042-.594.17-.212.212-.17.594-.128.848.68 3.736 3.1 6.963 6.538 8.66l.128.086c.636.297 2.122 1.019 3.099 1.019.424 0 .721-.128.806-.425.043-.085.043-.212.043-.34V29.18c0-.212 0-.467-.128-.636-.084-.213-.34-.34-.636-.425l-.383-.127c-.254-.085-.509-.17-.721-.17h-.043c-.254 0-.34.212-.424.51-.085.212-.17.466-.382.636a.91.91 0 0 1-.51.17c-.212 0-.467-.085-.679-.17-1.868-1.019-3.481-2.674-4.373-4.542a1.232 1.232 0 0 1-.127-.467c0-.297.212-.552.594-.764.043-.043.128-.043.213-.085.17-.043.51-.17.51-.255 0-.043 0-.127-.043-.212a2.82 2.82 0 0 1-.255-.85c0-.042 0-.127-.043-.17-.042-.212-.084-.466-.212-.594-.085-.17-.424-.254-.976-.254ZM25.125 20.773a.744.744 0 0 1-.721-.721c0-.425.34-.722.721-.722h3.057c.424 0 .722.34.722.722 0 .424-.34.721-.722.721h-3.057Z"></path>
                  <path d="M25.125 23.83a.715.715 0 0 1-.721-.722c0-.382.34-.721.721-.721h3.057c.212 0 .424.085.552.254.127.128.212.34.212.51 0 .382-.34.722-.722.722h-3.099v-.043Z"></path>
                  <path d="M30.05 32.49c-.467 0-.722-.424-.722-.806 0-.212.043-.425.17-.552a.685.685 0 0 1 .552-.255h.637c.254 0 .51-.212.51-.51V17.166a.516.516 0 0 0-.51-.51H20.583a.516.516 0 0 0-.51.51v.637c0 .467-.424.722-.806.722-.425 0-.807-.255-.807-.722v-.637c0-1.189.977-2.165 2.123-2.165h10.146c.552 0 1.104.212 1.486.637.382.424.637.934.594 1.528v13.16c0 1.19-.976 2.166-2.122 2.166h-.637Z"></path>
                </g>
              </svg>
              <p className="fs-8 lh-1">Get access to contact details</p>
            </div>

            <div className="d-flex align-items-center mb-1">
              <svg
                height="55"
                width="55"
                viewBox="0 0 48 48"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                alt="profileViewIcon"
                class="me-2"
              >
                <rect width="48" height="48" rx="24" fill="#F3F8FF"></rect>
                <path
                  d="M33.07 28.83 31 26.71a3.08 3.08 0 0 0-3.4-.57l-.9-.9a7 7 0 1 0-1.41 1.41l.89.89a3 3 0 0 0 .53 3.46l2.12 2.12a3 3 0 0 0 4.24 0 3 3 0 0 0 0-4.29Zm-8.48-4.24a5 5 0 1 1-7.062-7.08 5 5 0 0 1 7.062 7.08Zm7.07 7.07a.999.999 0 0 1-1.42 0l-2.12-2.12a1 1 0 0 1 0-1.42 1 1 0 0 1 1.42 0l2.12 2.12a1.001 1.001 0 0 1 0 1.42Z"
                  fill="#3995E0"
                ></path>
              </svg>
              <p className="fs-8 lh-1">Perform unlimited searches</p>
            </div>
          </div>

          <div className="mt-4">
            <p className="text-center fs-8 fw-medium">
              Flat 75% OFF till 10 Sep
            </p>
            <button
              onClick={() =>
                navigate("./", { state: { Information: "Upgrade" } })
              }
              className="bg-danger rounded-3 w-100 mt-2 py-1 px-5 cursor-pointer"
            >
              <p className="fs-7 text-white text-center my-1">
                Upgrade Now <i className="bi bi-arrow-right ms-2"></i>
              </p>
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default Right;
