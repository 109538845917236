import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { Button, Stack } from "@mui/material";
import axios from "axios";
import { server } from "../../server";
import { toast } from "react-toastify";

export default function Cart() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const location = useLocation();
  const { plan } = location?.state;
  const { user } = useSelector((state) => state.profile);
  const [loading, setLoading] = useState(false);

  const handleMakePayment = async () => {
    setLoading(true);
    try {
      const formData = {
        mobile: user?.mobile,
        amount: plan?.offerPrice,
        plan: plan?._id,
        duration: plan?.duration,
      };
      const { data } = await axios.post(`${server}/payment/new`, formData, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      window.location.href = data.url;
      setLoading(false);
    } catch (e) {
      toast(e?.response?.data?.message || e?.message);
      setLoading(false);
      return;
    }
  };

  return (
    <section className="vh-100 w-100">
      <div className="container pt-5 d-flex justify-content-center">
        <div className="col-lg-5 col-md-7 col-12">
          <div className="shadow px-5 py-4 rounded-4 bg-white">
            <div className="my-2">
              <div className="text-center mb-4 pb-4">
                <img src="/assets/images/logo-red.png" height="35" />
              </div>
              <Stack>
                <div className="row">
                  <div className="col-8">
                    <div className="fw-medium fs-7">
                      <i>{plan?.plan}</i>
                    </div>
                    <div className="fs-7">Duration {plan?.durationTitle}</div>
                  </div>
                  <div className="col-4 text-end">
                    <div>₹{plan?.offerPrice}</div>
                  </div>
                </div>
                <hr />
                <div className="row">
                  <div className="col-8">
                    <div className="fs-7 fw-medium">Total</div>
                  </div>
                  <div className="col-4 text-end fw-medium">
                    <div>₹{plan?.offerPrice}</div>
                  </div>
                </div>
                <Button
                  onClick={handleMakePayment}
                  variant="contained"
                  disabled={loading}
                  className="rounded-3 mt-4"
                >
                  PAY
                </Button>
              </Stack>
              <p className="text-center fs-8 text-secondary mt-4">
                <i className="bi bi-shield-lock"></i> 100% secure payment
                gateway
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
