export const communities = [
  { religion: "Hindu", community: "24 Manai Telugu Chethar" },
  { religion: "Hindu", community: "96 Kuli Maratha" },
  { religion: "Hindu", community: "96 K Kolcanastha" },
  { religion: "Hindu", community: "Adi Andhara" },
  { religion: "Hindu", community: "Adi Dharmi" },
  { religion: "Hindu", community: "Adi Dravida" },
  { religion: "Hindu", community: "Adi Karnataka" },
  { religion: "Hindu", community: "Aganudayar" },
  { religion: "Hindu", community: "Agarwal" },
  { religion: "Hindu", community: "Agnikula Kshatriya" },
  { religion: "Hindu", community: "Agri" },
  { religion: "Hindu", community: "Ahir" },
  { religion: "Hindu", community: "Aham" },
  { religion: "Hindu", community: "Ambalavasi" },
  { religion: "Hindu", community: "Arcot" },
  { religion: "Hindu", community: "Arekatica" },
  { religion: "Hindu", community: "Arora" },
  { religion: "Hindu", community: "Arunthathiyar" },
  { religion: "Hindu", community: "Arya Vysya" },
  { religion: "Hindu", community: "Arya Samaj" },
  { religion: "Hindu", community: "Ayyaraka" },
  { religion: "Hindu", community: "Badaga" },
  { religion: "Hindu", community: "Baghel / Pal / Gaderiya" },
  { religion: "Hindu", community: "Bahi" },
  { religion: "Hindu", community: "Baidya" },
  { religion: "Hindu", community: "Baishnah" },
  { religion: "Hindu", community: "Bishya" },
  { religion: "Hindu", community: "Bajantri" },
  { religion: "Hindu", community: "Balija" },
  { religion: "Hindu", community: "Balija – Naidu" },
  { religion: "Hindu", community: "Banayat – Oriya" },
  { religion: "Hindu", community: "Banik" },
  { religion: "Hindu", community: "Baniya" },
  { religion: "Hindu", community: "Barai" },
  { religion: "Hindu", community: "BarnwalBarujibi" },
  { religion: "Hindu", community: "Besta" },
  { religion: "Hindu", community: "Bahndari" },
  { religion: "Hindu", community: "Bhatia" },
  { religion: "Hindu", community: "Bhatraju" },
  { religion: "Hindu", community: "Bhavasar" },
  { religion: "Hindu", community: "Bhovi" },
  { religion: "Hindu", community: "Billava" },
  { religion: "Hindu", community: "Boya/Nayak/Naik" },
  { religion: "Hindu", community: "Boyer" },
  { religion: "Hindu", community: "Brahmbatt" },
  { religion: "Hindu", community: "Brahmin – Audichya" },
  { religion: "Hindu", community: "Brahmin – Barendra" },
  { religion: "Hindu", community: "Brahmin – Bhatt" },
  { religion: "Hindu", community: "Brahmin – Bhamihar" },
  { religion: "Hindu", community: "Brahmin – Brahmbhatt" },
  { religion: "Hindu", community: "Brahmin – Dadhich" },
  { religion: "Hindu", community: "Brahmin – Danva" },
  { religion: "Hindu", community: "Brahmin – Deshatha" },
  { religion: "Hindu", community: "Brahmin – Dhiman" },
  { religion: "Hindu", community: "Brahmin – Dravida" },
  { religion: "Hindu", community: "Brahmin – Embrandiri" },
  { religion: "Hindu", community: "Brahmin – Gswami" },
  { religion: "Hindu", community: "Brahmin – Gowr" },
  { religion: "Hindu", community: "Brahmin – Gowd Saraswat" },
  { religion: "Hindu", community: "Brahmin – Gujar Gowr" },
  { religion: "Hindu", community: "Brahmin – Gurukkal" },
  { religion: "Hindu", community: "Brahmin – Halva" },
  { religion: "Hindu", community: "Brahmin – Havyaka" },
  { religion: "Hindu", community: "Brahmin – HImachali" },
  { religion: "Hindu", community: "Brahmin – hosala" },
  { religion: "Hindu", community: "Brahmin – Iyevgar" },
  { religion: "Hindu", community: "Brahmin – Iyer" },
  { religion: "Hindu", community: "Brahmin – Jaugiol" },
  { religion: "Hindu", community: "Brahmin – Jhadur" },
  { religion: "Hindu", community: "Brahmin – Jhijhotiya" },
  { religion: "Hindu", community: "Brahmin – Kanyakjbja" },
  { religion: "Hindu", community: "Brahmin – Karhrde" },
  { religion: "Hindu", community: "Brahmin – Kasmiri Pandit" },
  { religion: "Hindu", community: "Brahmin – Kokanastha" },
  { religion: "Hindu", community: "Brahmin – Kota" },
  { religion: "Hindu", community: "Brahmin – Kulin" },
  { religion: "Hindu", community: "Brahmin –Kumaoni" },
  { religion: "Hindu", community: "Brahmin – Madwa" },
  { religion: "Hindu", community: "Brahmin – Modh" },
  { religion: "Hindu", community: "Brahmin – Mohyal" },
  { religion: "Hindu", community: "Brahmin – Nagar" },
  { religion: "Hindu", community: "Brahmin – Namboodiri" },
  { religion: "Hindu", community: "Brahmin – Nuyogi" },
  { religion: "Hindu", community: "Brahmin – Other" },
  { religion: "Hindu", community: "Brahmin – Paliwal" },
  { religion: "Hindu", community: "Brahmin – Panda" },
  { religion: "Hindu", community: "Brahmin – Pareek" },
  { religion: "Hindu", community: "Brahmin – Pushkarnq" },
  { religion: "Hindu", community: "Brahmin – Rarhi" },
  { religion: "Hindu", community: "Brahmin – Rudraj" },
  { religion: "Hindu", community: "Brahmin – Sakaldwipi" },
  { religion: "Hindu", community: "Brahmin – Sanadaya" },
  { religion: "Hindu", community: "Brahmin – Sanketi" },
  { religion: "Hindu", community: "Brahmin – Saraswat" },
  { religion: "Hindu", community: "Brahmin – Sarua" },
  { religion: "Hindu", community: "Brahmin – Saryuparin" },
  { religion: "Hindu", community: "Brahmin – Shivalli" },
  { religion: "Hindu", community: "Brahmin – Shrimali" },
  { religion: "Hindu", community: "Brahmin – Smartha" },
  { religion: "Hindu", community: "Brahmin – Sri Vaishnava" },
  { religion: "Hindu", community: "Brahmin – Smartha" },
  { religion: "Hindu", community: "Brahmin – Stavika" },
  { religion: "Hindu", community: "Brahmin – Tyagi" },
  { religion: "Hindu", community: "Brahmin – Vaidiki" },
  { religion: "Hindu", community: "Brahmin – Vaikhanasa" },
  { religion: "Hindu", community: "Brahmin – Velanadu" },
  { religion: "Hindu", community: "Brahmin – Viswabrahmin" },
  { religion: "Hindu", community: "Brahmin – Vyas" },
  { religion: "Hindu", community: "Brahmo" },
  { religion: "Hindu", community: "Buddar" },
  { religion: "Hindu", community: "Buntlshetty" },
  { religion: "Hindu", community: "CKP" },
  { religion: "Hindu", community: "Chalawadi Holeya" },
  { religion: "Hindu", community: "Chambhar" },
  { religion: "Hindu", community: "Chandravanshi Kaher" },
  { religion: "Hindu", community: "Chasq" },
  { religion: "Hindu", community: "Chaudary" },
  { religion: "Hindu", community: "Chaursia" },
  { religion: "Hindu", community: "Chekkala – Nair" },
  { religion: "Hindu", community: "Chennadasar" },
  { religion: "Hindu", community: "Cheramar" },
  { religion: "Hindu", community: "Chettiar" },
  { religion: "Hindu", community: "Chheri" },
  { religion: "Hindu", community: "Chippolu/Mera" },
  { religion: "Hindu", community: "Devanga" },
  { religion: "Hindu", community: "Dhargar" },
  { religion: "Hindu", community: "Dheevara" },
  { religion: "Hindu", community: "Dhiman" },
  { religion: "Hindu", community: "Dhoba" },
  { religion: "Hindu", community: "Diganbar" },
  { religion: "Hindu", community: "Dommala" },
  { religion: "Hindu", community: "Dusadh" },
  { religion: "Hindu", community: "Ediga" },
  { religion: "Hindu", community: "Ezhava" },
  { religion: "Hindu", community: "Ezhu thachau" },
  { religion: "Hindu", community: "Gabit" },
  { religion: "Hindu", community: "Ganakar" },
  { religion: "Hindu", community: "Gardla" },
  { religion: "Hindu", community: "Ganiga" },
  { religion: "Hindu", community: "Galti" },
  { religion: "Hindu", community: "Gayali" },
  { religion: "Hindu", community: "Gavara" },
  { religion: "Hindu", community: "Ghumar" },
  { religion: "Hindu", community: "Goala" },
  { religion: "Hindu", community: "Goswami" },
  { religion: "Hindu", community: "Goud" },
  { religion: "Hindu", community: "Gounder" },
  { religion: "Hindu", community: "Gowada" },
  { religion: "Hindu", community: "Gramani" },
  { religion: "Hindu", community: "Gudia" },
  { religion: "Hindu", community: "Gujjar" },
  { religion: "Hindu", community: "Gupta" },
  { religion: "Hindu", community: "Guptan" },
  { religion: "Hindu", community: "Gurjar" },
  { religion: "Hindu", community: "Halwai" },
  { religion: "Hindu", community: "Hegde" },
  { religion: "Hindu", community: "Helava" },
  { religion: "Hindu", community: "Hugar" },
  { religion: "Hindu", community: "Intercaste" },
  { religion: "Hindu", community: "Jaalari" },
  { religion: "Hindu", community: "Jaiswal" },
  { religion: "Hindu", community: "Jandra" },
  { religion: "Hindu", community: "Janejam" },
  { religion: "Hindu", community: "Jat" },
  { religion: "Hindu", community: "Jatav" },
  { religion: "Hindu", community: "Jetty Malla" },
  { religion: "Hindu", community: "Kachera" },
  { religion: "Hindu", community: "Kaibarta" },
  { religion: "Hindu", community: "Kakkalan" },
  { religion: "Hindu", community: "Kalar" },
  { religion: "Hindu", community: "Kalivga" },
  { religion: "Hindu", community: "Kaliuga Vysya" },
  { religion: "Hindu", community: "Kalita" },
  { religion: "Hindu", community: "Kalwar" },
  { religion: "Hindu", community: "Kanbaj" },
  { religion: "Hindu", community: "Kamma" },
  { religion: "Hindu", community: "Kamma Niadu" },
  { religion: "Hindu", community: "Kammala" },
  { religion: "Hindu", community: "Kaniyan" },
  { religion: "Hindu", community: "Sansari" },
  { religion: "Hindu", community: "Kanu" },
  { religion: "Hindu", community: "Kapu" },
  { religion: "Hindu", community: "Kapu" },
  { religion: "Hindu", community: "Kapu Naidu" },
  { religion: "Hindu", community: "Karava" },
  { religion: "Hindu", community: "Karmakar" },
  { religion: "Hindu", community: "Kartha" },
  { religion: "Hindu", community: "Karuneegar" },
  { religion: "Hindu", community: "Kasar" },
  { religion: "Hindu", community: "Kashyap" },
  { religion: "Hindu", community: "Kavthiyya" },
  { religion: "Hindu", community: "Kayastha" },
  { religion: "Hindu", community: "Khandayat" },
  { religion: "Hindu", community: "Kharwar" },
  { religion: "Hindu", community: "Khatik" },
  { religion: "Hindu", community: "Khatri" },
  { religion: "Hindu", community: "Kirar" },
  { religion: "Hindu", community: "Kole" },
  { religion: "Hindu", community: "Kole Patel" },
  { religion: "Hindu", community: "Korama" },
  { religion: "Hindu", community: "Kori" },
  { religion: "Hindu", community: "Koshti" },
  { religion: "Hindu", community: "Krishnavalka" },
  { religion: "Hindu", community: "Kshatriya" },
  { religion: "Hindu", community: "Kastriya – Bhavasa" },
  { religion: "Hindu", community: "Kastriya – varma" },
  { religion: "Hindu", community: "Kudmbi" },
  { religion: "Hindu", community: "Kulal" },
  { religion: "Hindu", community: "Kulalar" },
  { religion: "Hindu", community: "Kulita Kumawat" },
  { religion: "Hindu", community: "Kumawat" },
  { religion: "Hindu", community: "Kumbara" },
  { religion: "Hindu", community: "Kumbhakar" },
  { religion: "Hindu", community: "Kummari" },
  { religion: "Hindu", community: "Kumbi" },
  { religion: "Hindu", community: "Kurava" },
  { religion: "Hindu", community: "Kuraavan" },
  { religion: "Hindu", community: "Kurmi" },
  { religion: "Hindu", community: "Murmi Kshtriya" },
  { religion: "Hindu", community: "Kuruba" },
  { religion: "Hindu", community: "Kuruhiva Shetty" },
  { religion: "Hindu", community: "Korumber" },
  { religion: "Hindu", community: "Kurup" },
  { religion: "Hindu", community: "Kushwaha" },
  { religion: "Hindu", community: "Labadi / Banjara" },
  { religion: "Hindu", community: "Lambani" },
  { religion: "Hindu", community: "Leva Matil" },
  { religion: "Hindu", community: "Lingayatu" },
  { religion: "Hindu", community: "Lohana" },
  { religion: "Hindu", community: "Lohar" },
  { religion: "Hindu", community: "Loniya/Lonia" },
  { religion: "Hindu", community: "Tubaba" },
  { religion: "Hindu", community: "Madhesiya" },
  { religion: "Hindu", community: "Madiga" },
  { religion: "Hindu", community: "Mahajan" },
  { religion: "Hindu", community: "Mohar" },
  { religion: "Hindu", community: "Mahendra" },
  { religion: "Hindu", community: "Maheswari" },
  { religion: "Hindu", community: "Mahindra" },
  { religion: "Hindu", community: "Mahishya" },
  { religion: "Hindu", community: "Majabi" },
  { religion: "Hindu", community: "Mala" },
  { religion: "Hindu", community: "Malayalee Variar" },
  { religion: "Hindu", community: "Mali" },
  { religion: "Hindu", community: "Mallah" },
  { religion: "Hindu", community: "Mango Lorean" },
  { religion: "Hindu", community: "Moniyavi" },
  { religion: "Hindu", community: "Mannadiar" },
  { religion: "Hindu", community: "Mannan" },
  { religion: "Hindu", community: "Mepila" },
  { religion: "Hindu", community: "Maratha" },
  { religion: "Hindu", community: "Maruthuuar" },
  { religion: "Hindu", community: "Marvar" },
  { religion: "Hindu", community: "Marwari" },
  { religion: "Hindu", community: "Nataug" },
  { religion: "Hindu", community: "Maurya" },
  { religion: "Hindu", community: "Meda" },
  { religion: "Hindu", community: "Medara" },
  { religion: "Hindu", community: "Meerei" },
  { religion: "Hindu", community: "Meeuavar" },
  { religion: "Hindu", community: "Mehgwal" },
  { religion: "Hindu", community: "Mehra" },
  { religion: "Hindu", community: "Menon" },
  { religion: "Hindu", community: "Meru Darji" },
  { religion: "Hindu", community: "Modak" },
  { religion: "Hindu", community: "Mogaveera" },
  { religion: "Hindu", community: "Monchi" },
  { religion: "Hindu", community: "Mudaliar" },
  { religion: "Hindu", community: "Mudaliar Saina" },
  { religion: "Hindu", community: "Mudaliar – Arcot" },
  { religion: "Hindu", community: "Mudaliar – Senguntha" },
  { religion: "Hindu", community: "Mudiraj" },
  { religion: "Hindu", community: "Munnuru Kapu" },
  { religion: "Hindu", community: "Muthuraja" },
  { religion: "Hindu", community: "Naagavansan" },
  { religion: "Hindu", community: "Nadar" },
  { religion: "Hindu", community: "Nagaralu" },
  { religion: "Hindu", community: "Nai" },
  { religion: "Hindu", community: "Naicker" },
  { religion: "Hindu", community: "Naicker" },
  { religion: "Hindu", community: "Naidu" },
  { religion: "Hindu", community: "Nair" },
  { religion: "Hindu", community: "Nair – Vaniya" },
  { religion: "Hindu", community: "Nair – Velethadattu" },
  { religion: "Hindu", community: "Namasudra" },
  { religion: "Hindu", community: "Nambiar" },
  { religion: "Hindu", community: "Nambisan" },
  { religion: "Hindu", community: "Namdev" },
  { religion: "Hindu", community: "Namosudra" },
  { religion: "Hindu", community: "Nambiar" },
  { religion: "Hindu", community: "Nambisan" },
  { religion: "Hindu", community: "Nandev" },
  { religion: "Hindu", community: "Namogudra" },
  { religion: "Hindu", community: "Napit" },
  { religion: "Hindu", community: "Nayak" },
  { religion: "Hindu", community: "Nayaka" },
  { religion: "Hindu", community: "Neeli" },
  { religion: "Hindu", community: "Nhavi" },
  { religion: "Hindu", community: "Oswal" },
  { religion: "Hindu", community: "Otari" },
  { religion: "Hindu", community: "Panmasali" },
  { religion: "Hindu", community: "Ponchal" },
  { religion: "Hindu", community: "Pandaram" },
  { religion: "Hindu", community: "Panicker" },
  { religion: "Hindu", community: "Parvan" },
  { religion: "Hindu", community: "Parit" },
  { religion: "Hindu", community: "Prakava Kulan" },
  { religion: "Hindu", community: "Partaraj" },
  { religion: "Hindu", community: "Pasi" },
  { religion: "Hindu", community: "Paswan" },
  { religion: "Hindu", community: "Patel" },
  { religion: "Hindu", community: "Patel – Desai" },
  { religion: "Hindu", community: "Patel – Dodia" },
  { religion: "Hindu", community: "Patel – Kadva" },
  { religion: "Hindu", community: "Patel – Leva" },
  { religion: "Hindu", community: "Patraick" },
  { religion: "Hindu", community: "Partra" },
  { religion: "Hindu", community: "Patwa" },
  { religion: "Hindu", community: "Perika" },
  { religion: "Hindu", community: "Pillai" },
  { religion: "Hindu", community: "Pisharady" },
  { religion: "Hindu", community: "Poduval" },
  { religion: "Hindu", community: "Poosala" },
  { religion: "Hindu", community: "Porwal" },
  { religion: "Hindu", community: "Prajapti" },
  { religion: "Hindu", community: "Pulaya" },
  { religion: "Hindu", community: "Raigar" },
  { religion: "Hindu", community: "Rajuka / Dhobi" },
  { religion: "Hindu", community: "Rajbhar" },
  { religion: "Hindu", community: "Rajput" },
  { religion: "Hindu", community: "Rajput – Kumaoni" },
  { religion: "Hindu", community: "Rajput – Lodhhi" },
  { religion: "Hindu", community: "Ramdasia" },
  { religion: "Hindu", community: "Ramgharia" },
  { religion: "Hindu", community: "Rauniyar" },
  { religion: "Hindu", community: "Ravidasia" },
  { religion: "Hindu", community: "Rawat" },
  { religion: "Hindu", community: "Reddy" },
  { religion: "Hindu", community: "Reeli" },
  { religion: "Hindu", community: "Ssk" },
  { religion: "Hindu", community: "Sadgop" },
  { religion: "Hindu", community: "SAgara – Uppara" },
  { religion: "Hindu", community: "Saha" },
  { religion: "Hindu", community: "Sahu" },
  { religion: "Hindu", community: "Saini" },
  { religion: "Hindu", community: "SAiva Vellala" },
  { religion: "Hindu", community: "SAliya" },
  { religion: "Hindu", community: "Sambava" },
  { religion: "Hindu", community: "Santrani" },
  { religion: "Hindu", community: "Savji" },
  { religion: "Hindu", community: "Scheduled Caste (S.C)" },
  { religion: "Hindu", community: "Scheduled Tribe (S.T)" },
  { religion: "Hindu", community: "Senai Thalaiver" },
  { religion: "Hindu", community: "Sepahia" },
  { religion: "Hindu", community: "Setti Balija" },
  { religion: "Hindu", community: "Shah" },
  { religion: "Hindu", community: "Shilpker" },
  { religion: "Hindu", community: "Shimpi" },
  { religion: "Hindu", community: "Sindhi – Bhanusali" },
  { religion: "Hindu", community: "Sindhi – Chhapru" },
  { religion: "Hindu", community: "Sindhi – Dady" },
  { religion: "Hindu", community: "Sindhi – Hyderabadi" },
  { religion: "Hindu", community: "Sindhi – Larai" },
  { religion: "Hindu", community: "Sindhi – Lohana" },
  { religion: "Hindu", community: "Sindhi – Rohiri" },
  { religion: "Hindu", community: "Sindhi – Sehwani" },
  { religion: "Hindu", community: "Sindhi – Thatai" },
  { religion: "Hindu", community: "Sindhi – Amil" },
  { religion: "Hindu", community: "Sindhi – Baibhaud" },
  { religion: "Hindu", community: "Sindhi – Larkana" },
  { religion: "Hindu", community: "Sindhi – Sahiti" },
  { religion: "Hindu", community: "Sindhi – Sakkhar" },
  { religion: "Hindu", community: "Sindhi – Shikarpuri" },
  { religion: "Hindu", community: "Somvaushi" },
  { religion: "Hindu", community: "Sovaar" },
  { religion: "Hindu", community: "Soui" },
  { religion: "Hindu", community: "Sozhiya Vellalar" },
  { religion: "Hindu", community: "Sri Vashnava" },
  { religion: "Hindu", community: "Sri Sayana" },
  { religion: "Hindu", community: "Subrana Banik" },
  { religion: "Hindu", community: "Sugali (Naika)" },
  { religion: "Hindu", community: "Sundhi" },
  { religion: "Hindu", community: "Surya Balija" },
  { religion: "Hindu", community: "Sutar" },
  { religion: "Hindu", community: "Swakua Sali" },
  { religion: "Hindu", community: "Swarnakar" },
  { religion: "Hindu", community: "Tamboli" },
  { religion: "Hindu", community: "Tanti" },
  { religion: "Hindu", community: "Tantvaway" },
  { religion: "Hindu", community: "Telaga" },
  { religion: "Hindu", community: "Teli" },
  { religion: "Hindu", community: "Thachar" },
  { religion: "Hindu", community: "Thakur" },
  { religion: "Hindu", community: "Thandau" },
  { religion: "Hindu", community: "Thigala" },
  { religion: "Hindu", community: "Thiyya" },
  { religion: "Hindu", community: "Thuluva Vellala" },
  { religion: "Hindu", community: "Tili" },
  { religion: "Hindu", community: "Togata" },
  { religion: "Hindu", community: "Turupu Kapu" },
  { religion: "Hindu", community: "Udayar" },
  { religion: "Hindu", community: "Urali Gounder" },
  { religion: "Hindu", community: "Urs" },
  { religion: "Hindu", community: "VAda Blija" },
  { religion: "Hindu", community: "Vada galui" },
  { religion: "Hindu", community: "Vadderr" },
  { religion: "Hindu", community: "Vaduka" },
  { religion: "Hindu", community: "Vaish" },
  { religion: "Hindu", community: "Vaish – Dhaushawat" },
  { religion: "Hindu", community: "Vaishanv" },
  { religion: "Hindu", community: "Vaishnav – Bhatia" },
  { religion: "Hindu", community: "Vaishnav – Vavia" },
  { religion: "Hindu", community: "Vaishya" },
  { religion: "Hindu", community: "Vallala" },
  { religion: "Hindu", community: "Valluvau" },
  { religion: "Hindu", community: "Valmiki" },
  { religion: "Hindu", community: "Vanika Vyashaya" },
  { religion: "Hindu", community: "Vaniya Cheltior" },
  { religion: "Hindu", community: "Vanjara" },
  { religion: "Hindu", community: "Vankar" },
  { religion: "Hindu", community: "Vannan" },
  { religion: "Hindu", community: "Vannar" },
  { religion: "Hindu", community: "Vanniya Kullak Kashstriya" },
  { religion: "Hindu", community: "Vanniyar" },
  { religion: "Hindu", community: "Variar" },
  { religion: "Hindu", community: "Varshvey" },
  { religion: "Hindu", community: "Verv Shaiva" },
  { religion: "Hindu", community: "Velama" },
  { religion: "Hindu", community: "Velar" },
  { religion: "Hindu", community: "Vellalar" },
  { religion: "Hindu", community: "Veluthedattw – Nair" },
  { religion: "Hindu", community: "Vellvva Goumder" },
  { religion: "Hindu", community: "Vishwakarma" },
  { religion: "Hindu", community: "Viswabrahmin" },
  { religion: "Hindu", community: "Vokkaliga" },
  { religion: "Hindu", community: "Vysya" },
  { religion: "Hindu", community: "Waada Balija" },
  { religion: "Hindu", community: "Yadav" },
  { religion: "Hindu", community: "Yellapy" },
  { religion: "Hindu", community: "Other" },
  { religion: "Muslim", community: "Ansari" },
  { religion: "Muslim", community: "Arain" },
  { religion: "Muslim", community: "Awan" },
  { religion: "Muslim", community: "Dekkani" },
  { religion: "Muslim", community: "Jat" },
  { religion: "Muslim", community: "Lebbai" },
  { religion: "Muslim", community: "Memon" },
  { religion: "Muslim", community: "Mughal" },
  { religion: "Muslim", community: "Pathan" },
  { religion: "Muslim", community: "Quraishi" },
  { religion: "Muslim", community: "Rajput" },
  { religion: "Muslim", community: "Shafi" },
  { religion: "Muslim", community: "Sheikh" },
  { religion: "Muslim", community: "Shia" },
  { religion: "Muslim", community: "Shia Ithna Ashariyyah" },
  { religion: "Muslim", community: "Siddiqui" },
  { religion: "Muslim", community: "Sunni" },
  { religion: "Muslim", community: "Sunni – Ehle – Hadith" },
  { religion: "Muslim", community: "Sunni Hanafi" },
  { religion: "Muslim", community: "Sunni Safi" },
  { religion: "Muslim", community: "Syed" },
  { religion: "Muslim", community: "Dawoodi Bohra" },
  { religion: "Muslim", community: "Dukkani" },
  { religion: "Muslim", community: "Dudekula" },
  { religion: "Muslim", community: "Khaja" },
  { religion: "Muslim", community: "Mapila" },
  { religion: "Muslim", community: "Marraicar" },
  { religion: "Muslim", community: "Rawther" },
  { religion: "Muslim", community: "Shai Bohra" },
  { religion: "Muslim", community: "Shia Imami Ashariyyah" },
  { religion: "Muslim", community: "Shia Zaidi" },
  { religion: "Muslim", community: "Sunni Humbali" },
  { religion: "Muslim", community: "Sunni Maliki" },
  { religion: "Muslim", community: "Other" },
];
