import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Button from "@mui/material/Button";
import { TextField } from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useForm } from "react-hook-form";
import axios from "axios";
import { server } from "./server";
import { toast } from "react-toastify";

function Home() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const navigate = useNavigate();
  const storageRegisterData = JSON.parse(localStorage.getItem("registerData"));
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
  } = useForm();
  const [loading, setLoading] = useState(false);
  const [gender, setGender] = useState("");
  const [profileFor, setProfileFor] = useState(storageRegisterData?.profileFor);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (profileFor === "Daughter" || profileFor === "Sister") {
      setGender("Female");
      setValue("gender", "Female");
    } else if (profileFor === "Son" || profileFor === "Brother") {
      setGender("Male");
      setValue("gender", "Male");
    } else {
      setGender("");
      setValue("gender", "");
    }
  }, [profileFor, setValue]);

  const handleSubmitData = async (data) => {
    if (!gender) {
      return toast.warn("Gender required");
    }

    let formData = {
      ...data,
      gender: gender,
    };

    // Check Valid Email and Mobile No.
    try {
      setLoading(true);
      await axios.post(`${server}/user/checkExistingUser`, {
        email: data?.email,
        mobile: data?.mobile,
      });
      setLoading(false);
      localStorage.setItem("registerData", JSON.stringify(formData));
      navigate("/basic");
    } catch (e) {
      setLoading(false);
      console.log(e?.response?.data?.message || e.message);
    }
  };
  return (
    <>
      <Header />

      <section className="bg-homepage-banner position-relative z-1">
        <div className="home-header-shadow z-1"></div>
        <div className="container py-2 d-flex justify-content-between align-items-center z-2 position-relative">
          <img src="/assets/images/logo.png" height="35" />
          <div className="d-flex float-end align-items-center">
            <img src="/assets/images/help-o.png" height="25" className="mx-4" />
            <div
              className="px-4 py-2 rounded-3 bg-white btn-shadow cursor-pointer"
              onClick={() => navigate("/login")}
            >
              <p className="mx-2 home-login-btn fw-medium text-blue">Login</p>
            </div>
          </div>
        </div>

        <div className="d-lg-none d-block p-5 m-5">
          <div className="p-5"></div>
        </div>

        <div className="container d-flex justify-content-end mt-5">
          <div className="col-lg-4 col-md-6 col-12 mb-3 pb-lg-5 pb-2">
            <div className="ms-lg-4 m-md-0 m-lg-0 m-4">
              <div className="p-3 rounded-top-5 bg-light-green">
                <p className="fs-6 text-white mb-4 text-center">
                  Chat Free - Register For Free
                </p>
              </div>
              <div className="rounded-5 bg-white pt-3 px-4 pb-4 register-block-home z-3 position-relative">
                <form
                  onSubmit={handleSubmit(handleSubmitData)}
                  className="mx-3"
                >
                  <FormControl
                    className="w-100 mb-3 mt-3"
                    fullWidth
                    error={errors?.profileFor}
                  >
                    <InputLabel id="profile-for-select-label">
                      Profile For
                    </InputLabel>
                    <Select
                      labelId="profile-for-select-label"
                      className="rounded-3"
                      id="profile-for-select"
                      label="Profile For"
                      defaultValue={storageRegisterData?.profileFor}
                      {...register("profileFor", { required: true })}
                      onChange={(e) => setProfileFor(e.target.value)}
                    >
                      {[
                        "Self",
                        "Son",
                        "Daughter",
                        "Brother",
                        "Sister",
                        "Friend / Relative",
                      ]?.map((item, index) => (
                        <MenuItem key={index} value={item}>
                          {item}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  {(profileFor === "Self" ||
                    profileFor === "Friend / Relative") && (
                    <FormControl className="w-100 mb-3" fullWidth>
                      <InputLabel id="demo-select-small-label">
                        Gender
                      </InputLabel>
                      <Select
                        labelId="demo-select-small-label"
                        className="rounded-3 fs-7"
                        id="profile_gender"
                        label="Gender"
                        value={gender}
                        onChange={(e) => setGender(e.target.value)}
                      >
                        <MenuItem value="Male">Male</MenuItem>
                        <MenuItem value="Female">Female</MenuItem>
                      </Select>
                    </FormControl>
                  )}
                  <TextField
                    id="outlined-basic"
                    label="Full Name"
                    variant="outlined"
                    className="w-100 mb-3 rounded-5"
                    defaultValue={storageRegisterData?.name}
                    {...register("name", {
                      required: true,
                      minLength: {
                        value: 3,
                        message: "Name is too sort!",
                      },
                    })}
                    error={errors?.name}
                  />
                  <TextField
                    id="outlined-basic"
                    label="Phone No"
                    variant="outlined"
                    className="w-100 mb-3 rounded-5"
                    type="text"
                    defaultValue={storageRegisterData?.mobile}
                    {...register("mobile", {
                      required: true,
                      minLength: {
                        value: 10,
                        message: "Mobile no. must be of 10 digits!",
                      },
                      maxLength: {
                        value: 10,
                        message: "Mobile no. must be of 10 digits!",
                      },
                      pattern: {
                        value: /^\d{10}$/,
                        message: "Invalid mobile no.",
                      },
                    })}
                    error={errors?.mobile}
                  />
                  <TextField
                    id="outlined-basic"
                    label="Email Address"
                    variant="outlined"
                    className="w-100 mb-3 rounded-5"
                  />

                  <div className="pt-2 w-100 mb-2 d-flex justify-content-center">
                    <Button
                      type="submit"
                      className="rounded-5 bg-orange w-100 py-2"
                    >
                      <p className="my-1 fw-medium text-capitalize text-white fs-7 mx-5">
                        {loading ? (
                          <div
                            class="spinner-border text-white spinner-border-sm"
                            role="status"
                          ></div>
                        ) : (
                          <p className="text-uppercase">Register Now</p>
                        )}
                      </p>
                    </Button>
                  </div>
                  <p className="fs-8 text-center text-secondary">
                    I agree to the T&C and Privacy Policy
                  </p>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container mt-5 pt-4 d-flex justify-content-center">
          <div className="col-lg-7 col-md-10 col-12 text-center">
            <p className="fs-6 text-secondary">
              PERSONALISED MATCH-MAKING SERVICE
            </p>
            <div class="fs-3 fw-medium">
              Introducing
              <span class="text-danger fst-italic"> Exclusive</span>
            </div>
            <div className="d-flex justify-content-center">
              <div className="bg-danger py-2 px-5 rounded-3 mt-3">
                <p className="text-white fs-7">Exclusive</p>
              </div>
            </div>
            <img src="/assets/images/exclusive.jpg" className="w-100" />
          </div>
        </div>
      </section>

      <section className="bg-light pb-5 position-relative overflow-hidden">
        <div className="container position-relative pb-4">
          <div className="row mt-5 pt-3 mx-lg-5 mx-0">
            <div className="col-12 mb-4">
              <h3 className="mb-0 text-center">
                Impress Over the{" "}
                <span className="text-danger fst-italic">Distance</span>
              </h3>
              <p className="text-center mb-4 pb-2">
                Leave a lasting impression, bridging the distance with grace and
                charm.
              </p>
            </div>
          </div>
          <div className="row g-4 g-lg-3 mx-lg-5 mx-0">
            <div className="col-sm-12 col-md-6 col-xl-4">
              <div className="bg-white shadow rounded-5 text-center p-5 position-relative h-100">
                <img
                  src="https://www.jeevansathi.com/images/jspc/featuresPromotion/match-hour.svg"
                  className="mb-4"
                  width="150"
                  alt=""
                />
                <h5 className="fs-5 mb-2">Matching Algorithms</h5>
                <p className="fs-7 mt-3">
                  Uses advanced algorithms to match individuals.
                </p>
              </div>
            </div>
            <div className="col-sm-12  col-md-6 col-xl-4">
              <div className="bg-white shadow rounded-5 text-center p-5 position-relative h-100">
                <img
                  src="https://www.jeevansathi.com/images/jspc/featuresPromotion/video-profile.svg"
                  className="mb-4"
                  width="150"
                  alt=""
                />
                <h5 className="fs-5 mb-2">Exclusive Matchmaking</h5>
                <p className="fs-7 mt-3">
                  Step into a world of refined connections and everlasting love.
                </p>
              </div>
            </div>
            <div className="col-sm-12  col-md-6 col-xl-4">
              <div className="bg-white shadow rounded-5 text-center p-5 position-relative h-100">
                <img
                  src="https://www.jeevansathi.com/images/jspc/featuresPromotion/match-hour.svg"
                  className="mb-4"
                  width="150"
                  alt=""
                />
                <h5 className="fs-5 mb-2">Secure Platform</h5>
                <p className="fs-7 mt-3">
                  Ensure the highest level of security and privacy.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="position-relative py-3 mt-5">
        <div class="container py-4">
          <div class="row align-items-center justify-content-between mx-lg-5 mx-0">
            <div class="col-lg-6 position-relative z-index-9">
              <h3 className="mb-4 fs-2 fw-medium">
                Personalized match recommendations
                <br />
                <span className="text-danger fst-italic">Powered by AI</span>
              </h3>
              <p>
                At GoRishtey.com, we understand the significance of finding a
                life partner who complements your values, aspirations, and
                dreams. Our mission is to be your steadfast companion on this
                journey of love, connection, and companionship.
              </p>
              <p className="mt-3">
                We take immense pride in bringing hearts together and fostering
                lasting relationships.
              </p>
              <Link class="btn btn-success mt-5 px-5 py-2 rounded-pill">
                About Us
              </Link>
            </div>
            <div class="col-lg-6 position-relative text-center mt-5 mt-lg-0 d-flex align-items-center justify-content-end">
              <img
                src="/assets/images/couple-1.jpg"
                class="rounded-5 z-index-1 position-relative shadow"
                alt="Image"
                height="500"
              />
            </div>
          </div>
        </div>
      </section>

      <section className="bg-light py-5 my-5 position-relative overflow-hidden">
        <div className="container my-5">
          <div className="row g-4 g-lg-3 mt-3 mx-lg-5 mx-0">
            <div className="col-md-6 col-lg-4">
              <div className="rounded-5 bg-white shadow text-center position-relative px-4 py-5">
                <figure className="position-absolute top-0 start-100 translate-middle d-none d-lg-block">
                  <svg width="182.9px" height="86px" viewBox="0 0 182.9 86">
                    <path
                      className="fill-secondary"
                      d="M127.3,19.8c0.9,0.7,1.8,1.5,2.8,2c9.3,4.5,17.1,11.1,24.4,18.3c6.5,6.4,11.9,13.7,15.8,22 c1.5,3.2,2.7,6.6,4.2,10.2c2.5-4.2,4.1-8.9,8.6-11.5c-2.2,3.9-4.7,7.7-6.5,11.9c-1.7,4.1-2.6,8.6-3.9,13.4 c-4.1-6.1-7-13.2-14.9-15.6c3.8-1.4,6.2,0.5,14.1,10.3c1-2.2,1.8-4.1,1.1-6.5c-3.8-13.6-11.4-24.8-21.4-34.6 c-5.8-5.7-12.3-10.6-19.2-14.9c-7-4.3-14.4-8-22.2-10.9c-10.7-3.8-21.5-6.6-32.8-7.7C63.9,5,50.7,5.9,38,10.4 c-14.1,5-26,13.2-35,25.4c-0.5,0.7-1.2,1.4-1.8,2.1C1.1,38,0.8,38,0.4,38.1c-0.9-0.9-0.2-1.7,0.3-2.4c4.7-6.7,10.5-12.4,17.2-17.1 C31.7,8.9,47.2,4.7,63.8,4C77.1,3.5,90,5.8,102.7,9.3c2.2,0.6,4.3,1.8,6.5,2.6c0.9,0.4,2,0.5,2.9,0.7 C117.1,15,122.2,17.4,127.3,19.8z"
                    />
                  </svg>
                </figure>
                <div className="px-4">
                  <div className="d-flex justify-content-center">
                    <div className="shadow rounded-pill p-4 mb-3">
                      <img
                        src="https://cdn-icons-png.flaticon.com/512/9684/9684447.png"
                        width="30"
                        alt=""
                      />
                    </div>
                  </div>
                  <h5 className="mt-4 mb-3">Sign Up</h5>
                  <p className="text-truncate-2 fs-7 pb-0 mb-0">
                    Register for free & put up your Matrimony Profile
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-4">
              <div className="rounded-5 bg-white shadow text-center position-relative px-4 py-5">
                <figure className="position-absolute top-100 start-100 translate-middle mt-n3 d-none d-lg-block">
                  <svg width="182.9px" height="86px" viewBox="0 0 182.9 86">
                    <path
                      className="fill-secondary"
                      d="M127.3,70.2c0.9-0.7,1.8-1.5,2.8-2c9.3-4.5,17.1-11.1,24.4-18.3c6.5-6.4,11.9-13.7,15.8-22 c1.5-3.2,2.7-6.6,4.2-10.2c2.5,4.2,4.1,8.9,8.6,11.5c-2.2-3.9-4.7-7.7-6.5-11.9c-1.7-4.1-2.6-8.6-3.9-13.4 c-4.1,6.1-7,13.2-14.9,15.6c3.8,1.4,6.2-0.5,14.1-10.3c1,2.2,1.8,4.1,1.1,6.5c-3.8,13.6-11.4,24.8-21.4,34.6 c-5.8,5.7-12.3,10.6-19.2,14.9c-7,4.3-14.4,8-22.2,10.9c-10.7,3.8-21.5,6.6-32.8,7.7C63.9,85,50.7,84.1,38,79.6 c-14.1-5-26-13.2-35-25.4c-0.5-0.7-1.2-1.4-1.8-2.1c-0.1-0.1-0.4-0.1-0.8-0.2c-0.9,0.9-0.2,1.7,0.3,2.4c4.7,6.7,10.5,12.4,17.2,17.1 c13.7,9.7,29.2,14,45.9,14.6c13.3,0.5,26.2-1.8,38.8-5.3c2.2-0.6,4.3-1.8,6.5-2.6c0.9-0.4,2-0.5,2.9-0.7 C117.1,74.9,122.2,72.6,127.3,70.2z"
                    />
                  </svg>
                </figure>
                <div className="px-4">
                  <div className="d-flex justify-content-center">
                    <div className="shadow rounded-pill p-4 mb-3">
                      <img
                        src="https://cdn-icons-png.flaticon.com/512/9684/9684657.png"
                        width="30"
                        alt=""
                      />
                    </div>
                  </div>
                  <h5 className="mt-4 mb-3">Connect</h5>
                  <p className="text-truncate-2 fs-7 pb-0 mb-0">
                    Select Profile & Connect with Matches you like
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-4 text-center">
              <div className="rounded-5 bg-white shadow text-center position-relative px-4 py-5">
                <div className="d-flex justify-content-center">
                  <div className="shadow rounded-pill p-4 mb-3">
                    <img
                      src="https://cdn-icons-png.flaticon.com/512/9684/9684466.png"
                      width="30"
                      alt=""
                    />
                  </div>
                </div>
                <h5 className="mt-4 mb-3">Interact</h5>
                <p className="text-truncate-2 fs-7 pb-0 mb-0">
                  Become a Premium Member & Start a Conversation
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="pt-5 pb-4 mt-3 mb-5 d-none d-lg-block">
        <div class="container">
          <div className="rounded-5" style={{ backgroundColor: "#ff5a60" }}>
            <div class="row g-4 align-items-center px-5">
              <div class="col-lg-6 pe-0 pe-lg-5 d-flex justify-content-center">
                <img
                  src="https://www.jeevansathi.com/images/jspc/commonimg/Group_64_new.png"
                  class="rounded-2 pt-5"
                  alt=""
                  height="400"
                />
              </div>
              <div class="col-lg-6 ps-0 ps-lg-5">
                <h6 className="text-white">STAY CONNECTED WITH</h6>
                <h2 class="mb-4 text-white">
                  GoRishtey.com <br /> Mobile App
                </h2>
                <p class="mb-4 fs-6 text-white me-lg-5 pe-lg-5">
                  Download our apps rated best in the online matrimony segment.
                </p>
                <div className="mt-5">
                  <a
                    href="https://play.google.com/store/apps/details?id=com.gorishteyapp"
                    target="_blank"
                  >
                    <img
                      src="assets/images/google-play.svg"
                      alt=""
                      width="170"
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="pt-5 pb-4">
        <div class="container d-flex justify-content-center">
          <div className="text-center col-lg-10 col-11">
            <img src="/assets/images/logo-red.png" width="140" />
            <p className="fs-6 mt-4">
              Your Trusted Partner in Matrimony and Matchmaking
            </p>
            <p className="mt-3 text-secondary fs-7">
              At GoRishtey, we understand that finding the right life partner is
              one of the most significant decisions you'll ever make. Our
              mission is to simplify & enrich your journey towards a harmonious
              and fulfilling marriage.
            </p>
            <p className="mt-3 text-secondary fs-7">
              Have questions or need assistance? Our friendly team is here to
              help.
              <br className="d-lg-block d-none" />
              Reach out to us through{" "}
              <span className="text-dark">contact@gorishtey.com</span>
            </p>
          </div>
        </div>
      </section>

      <section className="pt-5 position-relative overflow-hidden">
        <div className="container position-relative d-flex justify-content-center">
          <div className="d-flex justify-content-center col-lg-7 col-md-10 col-12 ms-5">
            <img src="/assets/images/steps.svg" className="w-100" />
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
}

export default Home;
