import React from "react";
import { useLocation } from "react-router-dom";
import { Slide } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";

const ProfileImages = () => {
  const location = useLocation();
  const Images = location?.state;

  return (
    <section className="bg-black">
      <div className="container">
        <Slide>
          {Images.map((slide, index) => (
            <div
              key={index}
              className="w-100 vh-100 d-flex justify-content-center"
            >
              <img src={slide.docUrl} className="vh-100" />
            </div>
          ))}
        </Slide>
      </div>
    </section>
  );
};

export default ProfileImages;
