import { useEffect } from "react";
import { Link } from "react-router-dom";
import Header from "../../components/Header";
import Footer from "../../components/Footer";

function TermsOfUse() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <section class="mb-5 position-relative overflow-hidden">
        <div class="container z-index-9 position-relative">
          <div class="row py-0 align-items-center text-center text-sm-start">
            <div class="col-sm-11 col-lg-8 col-xl-8 all-text-white">
              <div class="mt-5">
                <h1 class="display-5">Terms Of Use</h1>
                <p class="m-0">
                  Find Solutions to Common Queries: Navigate Through the
                  Frequently Asked Questions (FAQs) Section at GoRishtey.com. If
                  not find answer contact our support team.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="mb-5 pb-5 position-relative overflow-hidden">
        <div class="container z-index-9 position-relative">
          <h5 className="fw-normal mb-2 mt-4">
            Your Guide to a Fulfilling GoRishtey.com Experience
          </h5>
          <p>
            Welcome to GoRishtey.com! These Terms of Use are designed to outline
            the guidelines and expectations for your experience on our platform.
            By accessing and using GoRishtey.com, you agree to abide by these
            terms, ensuring a positive and respectful environment for all users.
          </p>

          <h5 className="fw-normal mb-2 mt-4">User Conduct</h5>
          <p>
            Authenticity: Provide accurate and truthful information when
            creating your profile. Any misrepresentation or falsification may
            lead to the termination of your account. Respectful Interaction:
            Interact with other users with respect and kindness. Any form of
            harassment, abuse, or inappropriate behavior is strictly prohibited.
            Safety Awareness: Exercise caution when sharing personal information
            and interacting with others. GoRishtey.com is not responsible for
            any interactions that occur outside of our platform.
          </p>

          <h5 className="fw-normal mb-2 mt-4">Content and Communication</h5>
          <p>
            User-Generated Content: You are solely responsible for the content
            you post on GoRishtey.com, including text, photos, and any other
            media. Ensure that your content is appropriate, respectful, and in
            compliance with our guidelines. Rights: By posting content on
            GoRishtey.com, you grant us a non-exclusive, worldwide, royalty-free
            license to use, reproduce, modify, and distribute the content for
            promotional purposes.
          </p>

          <h5 className="fw-normal mb-2 mt-4">Privacy and Security</h5>
          <p>
            Profile Information: You have the option to control the visibility
            of your profile information. However, please note that certain
            information will be used for our verification and matchmaking
            processes. Data Security: We take the security of your personal
            information seriously. Please refer to our Privacy Policy for
            details on how we handle and protect your data.
          </p>

          <h5 className="fw-normal mb-2 mt-4">Membership Plans and Payments</h5>
          <p>
            Membership: When you choose a membership plan, you agree to the
            associated fees and terms. Membership is non-transferable and
            non-refundable. Renewal and Cancellation: Membership plans may
            automatically renew unless canceled before the renewal date.
            Cancellation policies vary based on your chosen plan.
          </p>

          <h5 className="fw-normal mb-2 mt-4">Termination</h5>
          <p>
            GoRishtey.com reserves the right to terminate or suspend accounts
            that violate our Terms of Use or engage in fraudulent, harmful, or
            abusive behavior.
          </p>
          <p>
            These Terms of Use are designed to foster a positive, safe, and
            respectful environment for all our users. By using GoRishtey.com,
            you acknowledge your agreement to these terms and commit to
            contributing to a fulfilling and enriching experience for everyone
            on our platform. Thank you for being a part of GoRishtey.com's
            journey towards meaningful connections and lasting relationships.
          </p>

          <h5 className="fw-normal mb-2 mt-4">Refund Policy</h5>
          <p>GoRishtey does not offer refunds.</p>
        </div>
      </section>

      <Footer />
    </>
  );
}

export default TermsOfUse;
