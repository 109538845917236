import { useEffect } from "react";
import { Link } from "react-router-dom";
import Header from "../../components/Header";
import Footer from "../../components/Footer";

function PrivacyPolicy() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <section class="mb-5 position-relative overflow-hidden">
        <div class="container z-index-9 position-relative">
          <div class="row py-0 align-items-center text-center text-sm-start">
            <div class="col-sm-11 col-lg-8 col-xl-8 all-text-white">
              <div class="mt-5">
                <h1 class="display-5">Privacy Policy</h1>
                <p class="m-0">
                  Find Solutions to Common Queries: Navigate Through the
                  Frequently Asked Questions (FAQs) Section at GoRishtey.com. If
                  not find answer contact our support team.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="mb-5 pb-5 position-relative overflow-hidden">
        <div class="container z-index-9 position-relative">
          <h5 className="fw-normal mb-2 mt-4">
            Safeguarding Your Trust and Information
          </h5>
          <p>
            Welcome to the GoRishtey.com Privacy Policy, where your privacy and
            data security are our top priorities. This policy outlines how we
            collect, use, and protect your personal information as you engage
            with our platform.
          </p>

          <h5 className="fw-normal mb-2 mt-4">Information We Collect</h5>
          <p>
            Profile Information: When you create an account, we collect
            information such as your name, age, gender, location, and other
            details relevant to matchmaking. Communication: We gather
            information from your interactions with other users, including
            messages and communications on the platform. Usage Data: We collect
            data about your interactions with our website, including pages
            visited, features used, and preferences.
          </p>

          <h5 className="fw-normal mb-2 mt-4">How We Use Your Information</h5>
          <p>
            Matchmaking: Your profile information is used to create compatible
            matches and provide a personalized experience. Communication: We use
            your information to facilitate interactions with potential matches
            and provide customer support. Improvements: Your data helps us
            enhance our platform, features, and user experience.
          </p>

          <h5 className="fw-normal mb-2 mt-4">Data Security</h5>
          <p>
            Protection Measures: We implement security measures to safeguard
            your personal information from unauthorized access, disclosure, or
            destruction. Third Parties: We may share limited information with
            trusted third-party service providers for specific purposes, such as
            payment processing or verification.
          </p>

          <h5 className="fw-normal mb-2 mt-4">Your Choices</h5>
          <p>
            Profile Visibility: You have control over the visibility of your
            profile information. Communication Preferences: You can manage your
            communication preferences and opt-out of certain types of messages.
          </p>

          <h5 className="fw-normal mb-2 mt-4">Cookies and Tracking</h5>
          <p>
            Cookies: We use cookies to enhance your browsing experience and
            gather usage information. Third-Party Tracking: Some third-party
            services may use tracking technologies on our website.
          </p>

          <h5 className="fw-normal mb-2 mt-4">Changes to the Privacy Policy</h5>
          <p>
            Our Privacy Policy may be updated periodically to reflect changes in
            our practices. We recommend reviewing this policy regularly.
          </p>

          <h5 className="fw-normal mb-2 mt-4">Contact Us</h5>
          <p>
            If you have questions about our Privacy Policy or how we handle your
            data, feel free to contact our support team. <br />
            <br /> Thank you for entrusting GoRishtey.com with your information.
            Your privacy is of utmost importance to us, and we are committed to
            providing you with a secure and respectful platform for your journey
            towards meaningful connections and companionship.
          </p>
        </div>
      </section>

      <Footer />
    </>
  );
}

export default PrivacyPolicy;
