/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useEffect } from "react";
import { TextField } from "@mui/material";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { verifyOtpAction } from "../redux/actions/register/registerAction";
import { useForm } from "react-hook-form";

function VerifyLoginOTP() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const location = useLocation();
  const mobile = location.state?.mobile;
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { otp, loading } = useSelector((state) => state?.user);

  console.log(location);

  useEffect(() => {
    if (!mobile) {
      return navigate(-1);
    }
    window.scrollTo(0, 0);
  }, []);

  const handleVerifyOtp = (data) => {
    const formData = { mobile, otp: data?.userOtp };
    dispatch(verifyOtpAction(formData));
  };

  return (
    <>
      <section className="login-page-bg pb-5">
        <div className="sticky-top">
          <div className="container py-2 d-flex justify-content-between align-items-center z-2 position-relative">
            <img src="/assets/images/logo.png" height="35" />
            <div className="d-flex float-end align-items-center">
              <img
                src="/assets/images/help-o.png"
                height="25"
                className="mx-4"
              />
              <Link
                to="/"
                className="px-4 py-2 rounded-3 bg-white btn-shadow cursor-pointer"
              >
                <p className="mx-2 home-login-btn fw-medium text-blue">
                  Register
                </p>
              </Link>
            </div>
          </div>
        </div>

        <form
          onSubmit={handleSubmit(handleVerifyOtp)}
          className="d-flex justify-content-center mt-5"
        >
          <div className="col-lg-4 col-md-9 col-11 bg-white rounded-4">
            <div className="px-lg-4 px-2 mx-5 py-lg-5 py-4 mt-3">
              <div className="mb-4">
                <h5 className="fs-5 mb-0 fw-medium text-dark">Verify OTP</h5>
                <p className="fs-8 text-secondary">
                  Sent a text message with verification code
                </p>
              </div>

              <TextField
                id="outlined-basic"
                label="Enter OTP"
                placeholder="OTP"
                variant="outlined"
                className="w-50"
                {...register("userOtp", {
                  required: true,
                  minLength: 4,
                  maxLength: 4,
                })}
                error={errors?.userOtp}
              />

              <div className="mb-3 pt-3 d-flex">
                <button
                  type="submit"
                  className="rounded-3 bg-secondary px-4 py-2"
                >
                  <p className="my-1 fw-medium text-capitalize text-white fs-7 mx-5">
                    {loading ? (
                      <div
                        class="spinner-border text-white spinner-border-sm"
                        role="status"
                      ></div>
                    ) : (
                      "Verify OTP"
                    )}
                  </p>
                </button>
              </div>
            </div>
          </div>
        </form>
      </section>
    </>
  );
}

export default VerifyLoginOTP;
