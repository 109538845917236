import React, { useEffect, useState } from "react";
import { TextField } from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import PageHeader from "../../components/PageHeader";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { communities } from "../../jsonFiles/communities";
import { stateCityList } from "../../jsonFiles/stateCityList";
import { heightList, religionList } from "../register/Basic";
import { motherTongueList } from "../../jsonFiles/motherTongueList";
import { toast } from "react-toastify";
import axios from "axios";
import { server } from "../../server";

function UpdateBasic() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm();
  const { user } = useSelector((state) => state?.profile);
  const [selectedState, setSelectedState] = useState(
    user?.profile?.state || ""
  );
  const [loading, setLoading] = useState(false);

  const handleSubmitData = async (data) => {
    setLoading(true);
    const day = parseInt(data?.day.trim());
    const month = parseInt(data?.month.trim()) - 1; // JavaScript months are 0-based
    const year = parseInt(data?.year.trim());

    const dobDate = new Date(year, month, day);

    // Validate DOB
    if (
      dobDate.getFullYear() !== year ||
      dobDate.getMonth() !== month ||
      dobDate.getDate() !== day
    ) {
      toast.error("Invalid date of birth");
      setLoading(false);
      return;
    }

    const dobMilliseconds = dobDate.getTime();

    const currentDate = new Date();
    const minAgeDate = new Date(
      currentDate.getFullYear() - 18,
      currentDate.getMonth(),
      currentDate.getDate()
    );
    const maxAgeDate = new Date(
      currentDate.getFullYear() - 60,
      currentDate.getMonth(),
      currentDate.getDate()
    );

    if (dobMilliseconds > minAgeDate.getTime()) {
      toast.error("Age must be at least 18 years");
      setLoading(false);
      return;
    }

    if (dobMilliseconds < maxAgeDate.getTime()) {
      toast.error("Age must be less than 60 years");
      setLoading(false);
      return;
    }

    const formData = {
      dob: dobMilliseconds.toString(),
      motherTongue: data?.motherTongue,
      religion: data?.religion,
      community: data?.community,
      maritalStatus: data?.maritalStatus,
      height: data?.height,
      state: selectedState,
      city: data?.city,
      pinCode: data?.pinCode,
      // For app functionality
      day: data?.day,
      month: data?.month,
      year: data?.year,
    };

    try {
      const { data } = await axios.put(`${server}/profile/update`, formData, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });

      setLoading(false);
      console.log(data);
      toast.success(data?.message);
    } catch (e) {
      setLoading(false);
      toast.error(e?.response?.data?.message || e.message);
    }
  };

  let uniqueCommunitiesList =
    getValues("religion") === "Hindu"
      ? communities
          ?.filter((e) => e?.religion === "Hindu")
          ?.map((e) => e?.community)
      : getValues("religion") === "Muslim"
      ? communities
          ?.filter((e) => e?.religion === "Muslim")
          ?.map((e) => e?.community)
      : communities?.map((e) => e?.community);

  console.log(user);

  return (
    <>
      <div className="block-margin-top mb-4">
        <div className="py-4 bg-white rounded-4 block-border">
          <PageHeader name="Basic Details" />

          <form className="px-5" onSubmit={handleSubmit(handleSubmitData)}>
            <div className="mb-4">
              <h5 className="fs-6 fw-medium text-secondary">BASIC</h5>
            </div>
            <div className="row g-3">
              <div className="col-3">
                <TextField
                  id="outlined-basic"
                  label="Day"
                  placeholder="DD"
                  variant="outlined"
                  className="w-100 mb-3"
                  defaultValue={new Date(user?.profile?.dob)?.getDate()}
                  {...register("day", { required: true })}
                  error={errors?.day}
                />
              </div>
              <div className="col-5">
                <TextField
                  id="outlined-basic"
                  label="Month"
                  placeholder="MM"
                  variant="outlined"
                  className="w-100 mb-3"
                  defaultValue={new Date(user?.profile?.dob)?.getMonth()}
                  {...register("month", { required: true })}
                  error={errors?.month}
                />
              </div>
              <div className="col-4">
                <TextField
                  id="outlined-basic"
                  label="Year"
                  placeholder="YYYY"
                  variant="outlined"
                  className="w-100 mb-3"
                  defaultValue={new Date(user?.profile?.dob)?.getFullYear()}
                  {...register("year", { required: true })}
                  error={errors?.year}
                />
              </div>
            </div>
            <FormControl className="w-100 mb-3" fullWidth>
              <InputLabel id="demo-select-small-label">Height</InputLabel>
              <Select
                labelId="demo-select-small-label"
                id="demo-select-small"
                label="Height"
                defaultValue={user?.profile?.height}
                {...register("height", { required: true })}
                error={errors?.height}
              >
                {heightList?.map((item, index) => (
                  <MenuItem key={index} value={item}>
                    {item}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl className="w-100 mb-3" fullWidth>
              <InputLabel id="demo-select-small-label">
                Marital Status
              </InputLabel>
              <Select
                labelId="demo-select-small-label"
                id="demo-select-small"
                label="Marital Status"
                defaultValue={user?.profile?.maritalStatus}
                {...register("maritalStatus", { required: true })}
                error={errors?.maritalStatus}
              >
                {[
                  "Never Married",
                  "Awaiting Divorce",
                  "Divorced",
                  "Widowed",
                  "Annulled",
                ]?.map((item, index) => (
                  <MenuItem key={index} value={item}>
                    {item}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <div className="my-4">
              <h5 className="fs-6 fw-medium text-secondary">SOCIAL</h5>
            </div>
            <FormControl className="w-100 mb-3" fullWidth>
              <InputLabel id="demo-select-small-label">
                Mother Tongue
              </InputLabel>
              <Select
                labelId="demo-select-small-label"
                id="demo-select-small"
                label="Mother Tongue"
                defaultValue={user?.profile?.motherTongue}
                {...register("motherTongue", { required: true })}
                error={errors?.motherTongue}
              >
                {motherTongueList?.map((item, index) => (
                  <MenuItem key={index} value={item?.Name}>
                    {item?.Name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl className="w-100 mb-3" fullWidth>
              <InputLabel id="demo-select-small-label">Religion</InputLabel>
              <Select
                labelId="demo-select-small-label"
                id="demo-select-small"
                label="Religion"
                defaultValue={user?.profile?.religion}
                {...register("religion", { required: true })}
                error={errors?.religion}
              >
                {religionList?.map((item, index) => (
                  <MenuItem key={index} value={item}>
                    {item}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl className="w-100 mb-3" fullWidth>
              <InputLabel id="demo-select-small-label">Community</InputLabel>
              <Select
                labelId="demo-select-small-label"
                id="demo-select-small"
                label="Community"
                defaultValue={user?.profile?.community}
                {...register("community", { required: true })}
                error={errors?.community}
              >
                {uniqueCommunitiesList?.map((item, index) => (
                  <MenuItem value={item} key={index}>
                    {item}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl className="w-100 mb-3" fullWidth>
              <InputLabel id="demo-select-small-label">State</InputLabel>
              <Select
                labelId="demo-select-small-label"
                id="demo-select-small"
                label="State"
                value={selectedState}
                defaultValue={user?.profile?.State}
                onChange={(e) => setSelectedState(e.target.value)}
                error={errors?.state}
              >
                {[...new Set(stateCityList?.map((e) => e?.State))]?.map(
                  (item, index) => (
                    <MenuItem key={index} value={item}>
                      {item}
                    </MenuItem>
                  )
                )}
              </Select>
            </FormControl>
            <FormControl className="w-100 mb-3" fullWidth>
              <InputLabel id="demo-select-small-label">City</InputLabel>
              <Select
                labelId="demo-select-small-label"
                id="demo-select-small"
                label="City"
                defaultValue={user?.profile?.city}
                {...register("city", { required: true })}
                error={errors?.city}
              >
                {stateCityList
                  ?.filter((e) => e?.State === selectedState)
                  ?.map((item, index) => (
                    <MenuItem key={index} value={item?.City}>
                      {item?.City}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
            <TextField
              id="outlined-basic"
              label="Pincode"
              variant="outlined"
              className="w-100 mb-3"
              defaultValue={user?.profile?.pinCode}
              {...register("pinCode", {
                required: true,
                minLength: {
                  value: 6,
                },
                maxLength: {
                  value: 6,
                },
              })}
              error={errors?.pinCode}
            />

            <div className="mx-5 mb-3 pt-3 d-flex justify-content-center">
              <button
                disabled={loading}
                type="submit"
                className="rounded-3 bg-secondary px-5 py-2"
              >
                <p className="my-1 fw-medium text-capitalize text-white fs-7 mx-5">
                  {loading ? "Please wait.." : "Save"}
                </p>
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

export default UpdateBasic;
