import {createReducer} from '@reduxjs/toolkit';

const initialState = {
  loading: false,
  error: null,
};

export const matchedReducer = createReducer(initialState, builder => {
  builder
    .addCase('GET_GENDER_PROFILES_REQUEST', (state, action) => {
      state.loading = true;
      state.error = null;
    })
    .addCase('GET_GENDER_PROFILES_SUCCESS', (state, action) => {
      state.loading = false;
      state.genderProfiles = action.payload.genderProfiles;
      state.error = null;
    })
    .addCase('GET_GENDER_PROFILES_FAIL', (state, action) => {
      state.loading = false;
      state.error = action.payload.error;
    });

  builder
    .addCase('GET_ALL_PROFILES_REQUEST', (state, action) => {
      state.loading = true;
      state.error = null;
    })
    .addCase('GET_ALL_PROFILES_SUCCESS', (state, action) => {
      state.loading = false;
      state.femaleProfiles = action.payload.femaleProfiles;
      state.error = null;
    })
    .addCase('GET_ALL_PROFILES_FAIL', (state, action) => {
      state.loading = false;
      state.error = action.payload.error;
    });

  // Remove all matches
  builder.addCase('REMOVE_ALL_MATCHES', (state, action) => {
    state.genderProfiles = [];
  });

  // builder
  //   .addCase('SEND_CONNECTION_REQUEST', (state, action) => {
  //     state.loading = true;
  //     state.error = null;
  //   })
  //   .addCase('SEND_CONNECTION_SUCCESS', (state, action) => {
  //     state.loading = false;
  //     state.newConnectionStatus = action.payload.newConnectionStatus;
  //     state.error = null;
  //   })
  //   .addCase('SEND_CONNECTION_FAIL', (state, action) => {
  //     state.loading = false;
  //     state.error = action.payload.error;
  //   });

  // builder
  //   .addCase('SEND_SHORTLIST_REQUEST', (state, action) => {
  //     state.loading = true;
  //     state.error = null;
  //   })
  //   .addCase('SEND_SHORTLIST_SUCCESS', (state, action) => {
  //     state.loading = false;
  //     state.newConnectionStatus = action.payload.newConnectionStatus;
  //     state.error = null;
  //   })
  //   .addCase('SEND_SHORTLIST_FAIL', (state, action) => {
  //     state.loading = false;
  //     state.error = action.payload.error;
  //   });
});
