/* eslint-disable jsx-a11y/alt-text */
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { io } from "socket.io-client";
import { chatEndPoint, defaultUserImage, server } from "../../server";
import { toast } from "react-toastify";

function Chat() {
  const navigate = useNavigate();
  const location = useLocation();
  const { selectedUser } = location?.state;
  const { user } = useSelector((state) => state?.profile);
  const scrollContainerRef = useRef();
  const dispatch = useDispatch();
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(true);
  const [userData, setUserData] = useState("");
  const [messagesList, setMessagesList] = useState([]);
  const currentMessage = useRef();

  //   Socket connection
  let socketConnection;
  useEffect(() => {
    socketConnection = io(chatEndPoint, {
      auth: {
        userId: user?._id,
      },
    });

    socketConnection.on("onlineUser", (data) => {
      dispatch({
        type: "STORE_ONLINE_USER_ID",
        payload: {
          onlineUser: data,
        },
      });
    });

    socketConnection.emit("message-page", selectedUser?.profile?.userId);

    socketConnection.emit("seen", {
      messageByUserId: selectedUser?.profile?.userId,
    });

    socketConnection.on("message-user", (data) => {
      setUserData(data);
    });

    socketConnection.on("previous-messages", (data) => {
      setMessagesList(data?.messages);
    });

    socketConnection.emit("chat-profiles", user?._id);
    socketConnection.on("my-conversations", (data) => {
      dispatch({
        type: "STORE_CHAT_CONVERSATIONS",
        payload: {
          conversations: data,
        },
      });
    });

    setLoading(false);
    return () => {
      socketConnection.disconnect();
    };
  }, [socketConnection]);

  useEffect(() => {
    if (currentMessage.current) {
      currentMessage.current.scrollToEnd({ animated: true });
    }
  }, [messagesList]);

  const handleSendMessage = async (e) => {
    e.preventDefault();
    if (!message) toast.warn("Nothing to send!");
    let sConnection = io(chatEndPoint, {
      auth: {
        userId: user?._id,
      },
    });

    if (sConnection) {
      sConnection.emit("new-message", {
        sender: user?._id,
        receiver: userData?._id,
        message: message,
        messageByUserId: user?._id,
      });

      setMessage("");
    } else {
      toast.warn("No connection");
    }
  };

  // Date wise messages
  const getDate = (timestamp) => {
    const date = new Date(timestamp);
    return date.toLocaleDateString("en-US", {
      year: "numeric",
      month: "short",
      day: "numeric",
    });
  };

  return (
    <>
      <div className="block-margin-top mb-4">
        <div className="py-2 bg-white rounded-4 block-border">
          <div className="px-4 mx-2">
            <div className="tab-content border-bottom pb-1">
              <div className="row mb-2">
                <div className="col-10 mt-1">
                  <div className="d-flex align-items-center">
                    <Link onClick={() => navigate(-1)}>
                      <i className="bi bi-chevron-left text-dark"></i>
                    </Link>
                    <img
                      src={
                        selectedUser?.profile?.images?.length > 0
                          ? selectedUser?.profile?.images[0]?.docUrl
                          : defaultUserImage
                      }
                      className="object-fit-cover rounded-pill ms-3"
                      width="45"
                      height="45"
                      alt=""
                    />
                    <div className="ms-3">
                      <h6 className="m-0 fw-medium fs-6 text-dark">
                        {`${selectedUser?.name}`}
                      </h6>
                      <p className="m-0 fs-9 text-secondary">
                        GRSTY{`${selectedUser?.customId}`}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="chat_block">
              <div className="profile_chat_area_block" ref={scrollContainerRef}>
                {messagesList &&
                  messagesList?.length > 0 &&
                  messagesList?.map((item, index) => (
                    <div key={index} className="mb-2">
                      <div className="text-center">
                        {index === 0 ||
                        getDate(messagesList[index - 1].createdAt) !==
                          getDate(item?.createdAt) ? (
                          <div className="text-center my-2">
                            <span className="bg-light rounded-3 text-white px-2">
                              <span className="mx-3 text-secondary fs-8">
                                {getDate(item.createdAt)}
                              </span>
                            </span>
                          </div>
                        ) : null}
                      </div>

                      {item?.messageByUserId !== user?._id && (
                        <div key={index} className="d-flex">
                          <div className="mb-1">
                            <p className="bg-black text-white rounded-3 ps-3 pe-5 py-2 me-2 fs-7 m-0">
                              {item?.text}
                            </p>
                            <div className="text-uppercase fs-9 ms-1">
                              {item?.createdAt
                                ? new Date(item?.createdAt).toLocaleTimeString(
                                    "en-IN",
                                    { hour: "2-digit", minute: "2-digit" }
                                  )
                                : ""}
                            </div>
                          </div>
                          <img
                            src={
                              user?.user?.[0]?.images?.[0]?.docUrl
                                ? user?.profile?.[0]?.images?.[0]?.docUrl
                                : defaultUserImage
                            }
                            className="mt-1 rounded-pill ms-1 object-fit-cover"
                            width="30"
                            height="30"
                          />
                        </div>
                      )}

                      {item?.messageByUserId === user?._id && (
                        <div className="d-flex justify-content-end">
                          <div className="mb-1">
                            <p className="bg-crimson text-white rounded-3 ps-3 pe-5 py-2 me-2 fs-7 m-0">
                              {item?.text}
                            </p>
                            <div className="text-uppercase fs-9 ms-1">
                              {item?.createdAt
                                ? new Date(item?.createdAt).toLocaleTimeString(
                                    "en-IN",
                                    { hour: "2-digit", minute: "2-digit" }
                                  )
                                : ""}
                            </div>
                          </div>
                          <img
                            src={
                              selectedUser?.profile?.images?.length > 0
                                ? selectedUser?.profile?.images[0]?.docUrl
                                : defaultUserImage
                            }
                            className="mt-1 rounded-pill me-1 object-fit-cover"
                            width="30"
                            height="30"
                          />
                        </div>
                      )}
                    </div>
                  ))}
              </div>
            </div>

            <form
              className="my_chat_message_box pt-2"
              onSubmit={handleSendMessage}
            >
              <input
                type="text"
                placeholder="Write Something"
                className="profile_chat_input_box form-control position-relative rounded-pill px-4 w-100"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
              />
              <button
                className="input-group-text chat_send_button position-absolute m-1 rounded-pill"
                type="submit"
              >
                <i className="bi bi-send text-white"></i>
              </button>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}

export default Chat;
