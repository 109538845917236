import {createReducer} from '@reduxjs/toolkit';

const initialState = {
  loading: false,
  error: null,
};

export const connectReducer = createReducer(initialState, builder => {
  builder
    .addCase('SEND_CONNECTION_REQUEST', (state, action) => {
      state.loading = true;
      state.error = null;
    })
    .addCase('SEND_CONNECTION_SUCCESS', (state, action) => {
      state.loading = false;
      state.connections = action.payload.connections;
      state.error = null;
    })
    .addCase('SEND_CONNECTION_FAIL', (state, action) => {
      state.loading = false;
      state.error = action.payload.error;
    });

  builder
    .addCase('CANCEL_CONNECTION_REQUEST', (state, action) => {
      state.loading = true;
      state.error = null;
    })
    .addCase('CANCEL_CONNECTION_SUCCESS', (state, action) => {
      state.loading = false;
      state.message = action.payload.message;
      state.error = null;
    })
    .addCase('CANCEL_CONNECTION_FAIL', (state, action) => {
      state.loading = false;
      state.error = action.payload.error;
    });

  builder
    .addCase('CANCEL_SENT_CONNECTION_REQUEST', (state, action) => {
      state.loading = true;
      state.error = null;
    })
    .addCase('CANCEL_SENT_CONNECTION_SUCCESS', (state, action) => {
      state.loading = false;
      state.myConnections = action.payload.myConnections;
      state.error = null;
    })
    .addCase('CANCEL_SENT_CONNECTION_FAIL', (state, action) => {
      state.loading = false;
      state.error = action.payload.error;
    });

  builder
    .addCase('GET_MY_CONNECTION_REQUEST', (state, action) => {
      state.loading = true;
      state.error = null;
    })
    .addCase('GET_MY_CONNECTION_SUCCESS', (state, action) => {
      state.loading = false;
      state.myConnections = action.payload.myConnections;
      state.error = null;
    })
    .addCase('GET_MY_CONNECTION_FAIL', (state, action) => {
      state.loading = false;
      state.error = action.payload.error;
    });

  builder
    .addCase(
      'CANCEL_SENT_CONNECTIONS_FROM_MY_ACTIVITY_REQUEST',
      (state, action) => {
        state.loading = true;
        state.error = null;
      },
    )
    .addCase(
      'CANCEL_SENT_CONNECTIONS_FROM_MY_ACTIVITY_SUCCESS',
      (state, action) => {
        state.loading = false;
        state.myConnections = action.payload.myConnections;
        state.error = null;
      },
    )
    .addCase(
      'CANCEL_SENT_CONNECTIONS_FROM_MY_ACTIVITY_FAIL',
      (state, action) => {
        state.loading = false;
        state.error = action.payload.error;
      },
    );

  builder
    .addCase('ACCEPT_MY_RECEIVED_CONNECTION_REQUEST', (state, action) => {
      state.loading = true;
      state.error = null;
    })
    .addCase('ACCEPT_MY_RECEIVED_CONNECTION_SUCCESS', (state, action) => {
      state.loading = false;
      state.myReceivedConnections = action.payload.myReceivedConnections;
      state.error = null;
    })
    .addCase('ACCEPT_MY_RECEIVED_CONNECTION_FAIL', (state, action) => {
      state.loading = false;
      state.error = action.payload.error;
    });

  builder
    .addCase('REJECT_MY_RECEIVED_CONNECTION_REQUEST', (state, action) => {
      state.loading = true;
      state.error = null;
    })
    .addCase('REJECT_MY_RECEIVED_CONNECTION_SUCCESS', (state, action) => {
      state.loading = false;
      state.myReceivedConnections = action.payload.myReceivedConnections;
      state.error = null;
    })
    .addCase('REJECT_MY_RECEIVED_CONNECTION_FAIL', (state, action) => {
      state.loading = false;
      state.error = action.payload.error;
    });

  builder
    .addCase('GET_CONNECTION_REQUESTS_TO_ME_REQUEST', (state, action) => {
      state.loading = true;
      state.error = null;
    })
    .addCase('GET_CONNECTION_REQUESTS_TO_ME_SUCCESS', (state, action) => {
      state.loading = false;
      state.connectionsToMe = action.payload.connectionsToMe;
      state.error = null;
    })
    .addCase('GET_CONNECTION_REQUESTS_TO_ME_FAIL', (state, action) => {
      state.loading = false;
      state.error = action.payload.error;
    });
});
