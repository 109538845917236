import axios from "axios";
import { toast } from "react-toastify";
import { server } from "../../server";

export const getNotifications = () => async (dispatch) => {
  dispatch({
    type: "FETCH_NOTIFICATION_REQUEST",
  });

  try {
    const { data } = await axios.get(`${server}/notification/my/${20}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });

    dispatch({
      type: "FETCH_NOTIFICATION_SUCCESS",
      payload: { data: data?.data },
    });
  } catch (e) {
    toast(e?.response?.data?.message || e.message);
    dispatch({
      type: "FETCH_NOTIFICATION_FAIL",
      payload: { error: e?.response?.data?.message || e.message },
    });
  }
};
