import { createContext, useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getMyProfileAction } from "../redux/actions/profile/profileAction";
import { useNavigate } from "react-router-dom";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  // Chat
  const [selectedChat, setSelectedChat] = useState("");
  const [notification, setNotification] = useState([]);
  const [chats, setChats] = useState();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user } = useSelector((state) => state?.profile);
  const [initialLoading, setInitialLoading] = useState(true);

  useEffect(() => {
    if (user) {
      navigate("/my");
    }
  }, [user]);

  useEffect(() => {
    const fetchMyProfile = async () => {
      await dispatch(getMyProfileAction());
      setInitialLoading(false);
    };

    fetchMyProfile();
  }, []);

  if (initialLoading) {
    return (
      <div className="vh-100 d-flex justify-content-center align-items-center">
        <div class="spinner-border text-secondary" role="status"></div>
      </div>
    );
  }

  return (
    <AuthContext.Provider
      value={{
        selectedChat,
        setSelectedChat,
        notification,
        setNotification,
        chats,
        setChats,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};
