import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { defaultUserImage } from "../../server";
import { calculateAge } from "../../utills/calculateAge";
import PageHeader from "../../components/PageHeader";

export default function SearchedProfiles() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const navigate = useNavigate();
  const location = useLocation();
  const { profilesData } = location.state;

  return (
    <div className="block-margin-top mb-4">
      <div className="py-3 bg-white rounded-4 block-border">
        <PageHeader name={`Search Result (${profilesData?.length || 0})`} />

        <div className="px-4 pb-2">
          <div className="row g-3">
            {profilesData?.length > 0 &&
              profilesData.map((item, index) => (
                <div className="col-6" key={index}>
                  <div
                    onClick={() =>
                      navigate("./", {
                        state: {
                          Information: "Details",
                          profileData: item,
                        },
                      })
                    }
                    type="button"
                    className="position-relative d-flex justify-content-center align-items-end rounded-4 overflow-hidden"
                  >
                    <img
                      src={
                        item?.profile?.[0]?.images?.[0]?.docUrl ||
                        defaultUserImage
                      }
                      className="w-100 object-fit-cover"
                      height="380"
                      alt="Profile"
                    />
                    <div className="w-100 h-75 activity-profile-block position-absolute d-flex align-items-end">
                      <div className="p-4">
                        <div>
                          <p className="m-0 fs-9 text-white">{`GRSTY${item?.user?.[0]?.customId}`}</p>
                          <h6 className="m-0 mb-2 text-white">
                            {item?.user?.[0]?.name},{" "}
                            {calculateAge(item?.profile?.[0]?.dob)}
                          </h6>
                          <p className="m-0 fs-9 text-white">
                            {item?.profile?.[0]?.height}
                            <span className="mx-2 text-white">•</span>
                            {item?.profile?.[0]?.city}
                            <span className="mx-2">•</span>
                            {item?.profile?.[0]?.community}
                          </p>
                          <p className="m-0 fs-9 text-white">
                            {item?.profile?.[0]?.jobTitle || "Not Working"}
                          </p>
                          <p className="m-0 fs-9 text-white">
                            {item?.profile?.[0]?.annualIncome || "No Income"}
                          </p>
                        </div>
                        <div className="d-flex align-items-center profile-details-line">
                          <div className="d-flex align-items-center">
                            <p className="m-0 fs-9 text-white">
                              {item?.profile?.[0]?.highestDegree}
                            </p>
                          </div>
                          <span className="mx-2 text-white">•</span>
                          <div className="d-flex align-items-center">
                            <p className="m-0 fs-9 text-white">
                              {item?.profile?.[0]?.maritalStatus}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
}
