import axios from "axios";
import { useEffect, useState } from "react";
import { server } from "../../server";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

function Upgrade() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const navigate = useNavigate();
  const [plans, setPlans] = useState([]);

  useEffect(() => {
    const fetchPlans = async () => {
      try {
        const { data } = await axios.get(`${server}/plan/all`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        });
        setPlans(data?.data);
      } catch (e) {
        toast.error(e?.response?.data?.message || e?.message);
      }
    };

    fetchPlans();
  }, []);

  return (
    <>
      <div className="block-margin-top mb-4">
        <div className="mx-lg-4 mx-2 my-4 px-3 z-1 position-relative">
          {plans?.map((item, index) => (
            <div key={index} className="pb-1">
              <div
                className="p-4 bg-white rounded-4 shadow mb-2 cursor-pointer"
                onClick={() => navigate("./cart", { state: { plan: item } })}
              >
                <div className="d-flex justify-content-between align-items-center">
                  <div className="d-flex align-items-center">
                    <img
                      src={`/assets/images/upgrade-${index}.png`}
                      width="45"
                    />
                    <div className="ms-3">
                      <h5 className="fs-7 m-0">{item?.plan}</h5>
                      <p className="fs-9">
                        Access all features, for {item?.durationTitle}
                      </p>
                    </div>
                  </div>
                  <div>
                    <h5 className="fs-6">₹{item?.offerPrice}</h5>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className="pt-5 pb-4 bg-white rounded-4 block-border upgrade-membership-bottom-block z-0 position-relative">
          <div className="px-4 pt-5 mt-5">
            <div>
              <p className="fs-9 text-center my-2">T & C Apply</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Upgrade;
