import { Link } from "react-router-dom";

function Footer() {
  return (
    <>
      <footer className="pt-5 mt-0 position-relative">
        <div className="container px-5">
          <div className="row g-4 mt-1 py-3">
            <div className="col-lg-3 col-6 text-center">
              <h5 className="mb-2 mb-md-4 text-dark fs-6 border-bottom pb-3">
                Company
              </h5>
              <ul className="nav flex-column">
                <li className="nav-item">
                  <Link className="text-secondary fw-light fs-7" to="/about">
                    About us
                  </Link>
                </li>
                <li className="nav-item">
                  <a className="text-secondary fw-light fs-7" href="#">
                    Why Choose Us?
                  </a>
                </li>
                <li className="nav-item">
                  <a className="text-secondary fw-light fs-7" href="#">
                    Success Stories
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    href="https://play.google.com/store/apps/details?id=com.gorishteyapp"
                    target="_blank"
                    className="text-secondary fw-light fs-7"
                  >
                    Download App
                  </a>
                </li>
                <li className="nav-item">
                  <a className="text-secondary fw-light fs-7" href="#">
                    Contact Us
                  </a>
                </li>
              </ul>
            </div>
            <div className="col-lg-3 col-6 text-center">
              <h5 className="mb-2 mb-md-4 text-dark fs-6 border-bottom pb-3">
                Pages
              </h5>
              <ul className="nav flex-column">
                <li className="nav-item">
                  <Link className="text-secondary fw-light fs-7" to="/about">
                    About us
                  </Link>
                </li>
                <li className="nav-item">
                  <a className="text-secondary fw-light fs-7" href="#">
                    Why Choose Us?
                  </a>
                </li>
                <li className="nav-item">
                  <a className="text-secondary fw-light fs-7" href="#">
                    Success Stories
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    href="https://play.google.com/store/apps/details?id=com.gorishteyapp"
                    target="_blank"
                    className="text-secondary fw-light fs-7"
                  >
                    Download App
                  </a>
                </li>
                <li className="nav-item">
                  <a className="text-secondary fw-light fs-7" href="#">
                    Contact Us
                  </a>
                </li>
              </ul>
            </div>
            <div className="col-lg-3 col-6 text-center">
              <h5 className="mb-2 mb-md-4 text-dark fs-6 border-bottom pb-3">
                Legal
              </h5>
              <ul className="nav flex-column">
                <li className="nav-item">
                  <Link
                    className="text-secondary fw-light fs-7"
                    to="/fraudalert"
                  >
                    Fraud Alert
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    className="text-secondary fw-light fs-7"
                    to="/termsofuse"
                  >
                    Terms of use
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    className="text-secondary fw-light fs-7"
                    to="/privacypolicy"
                  >
                    Privacy policy
                  </Link>
                </li>
                <li className="nav-item">
                  <a className="text-secondary fw-light fs-7" href="#">
                    Payment policy
                  </a>
                </li>
                <li className="nav-item">
                  <a className="text-secondary fw-light fs-7" href="#">
                    Privacy Features
                  </a>
                </li>
                <li className="nav-item">
                  <a className="text-secondary fw-light fs-7" href="#">
                    Cookie policy
                  </a>
                </li>
                <li className="nav-item">
                  <a className="text-secondary fw-light fs-7" href="#">
                    Grievances
                  </a>
                </li>
              </ul>
            </div>
            <div className="col-lg-3 col-6 text-center">
              <h5 className="mb-2 mb-md-4 text-dark fs-6 border-bottom pb-3">
                Get In Touch
              </h5>
              <p className="mb-2 text-secondary">
                <span className="h6 fw-light text-secondary fs-7">
                  +91 8051188443
                </span>
                <br />
                <span className="h6 fw-light text-secondary fs-7">
                  +91 8877887127
                </span>
                <br />
                <span className="h6 fw-light text-secondary fs-7">
                  +91 9835218444
                </span>
                <br />
                <span className="d-block small text-secondary fs-7">
                  (9 AM to 8 PM IST)
                </span>
              </p>
              <p className="mb-0 text-secondary fs-7">
                Email:
                <a
                  href="mailto:contact@gorishtey.com"
                  className="h6 fw-light ms-2 text-secondary fs-7"
                >
                  Send Mail
                </a>
              </p>
              <div className="row g-2 mt-3">
                <div className="col-12 text-center">
                  <a href="#">
                    <img
                      src="https://eduport.webestica.com/assets/images/client/google-play.svg"
                      alt=""
                      height="45"
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>

      <section className="bg-light mt-5">
        <div className="py-3">
          <div className="container px-0 my-2">
            <div className="text-secondary fw-light fs-7 text-center">
              All Rights Reserved © 2024 GoRishtey.com
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Footer;
