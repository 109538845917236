import { useNavigate } from "react-router-dom";

function PageHeader({ name }) {
  const navigate = useNavigate();

  return (
    <>
      <div className="px-4 py-2 mb-3">
        <div className="d-flex align-items-center">
          <i
            onClick={() => navigate(-1)}
            className="bi bi-arrow-left cursor-pointer"
          ></i>
          <div className="px-4">{name}</div>
        </div>
      </div>
    </>
  );
}

export default PageHeader;
