/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useEffect } from "react";
import { TextField } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  registrationAction,
  sendOtpAction,
} from "../../redux/actions/register/registerAction";

function VerifyOTP() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const storageProfile = JSON.parse(localStorage.getItem("registerData"));
  const [userOtp, setUserOtp] = useState("");

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const sendOtp = async () => {
      if (storageProfile) {
        const formData = { mobile: storageProfile.mobile };
        dispatch(sendOtpAction(formData));
      }
    };

    sendOtp();
  }, []);

  console.log(storageProfile);

  const handleVerifyOtp = () => {
    if (!userOtp) return null;

    // Send data to server
    let registerData = localStorage.getItem("registerData");
    let basicDetails = localStorage.getItem("basicDetailsData");
    let careerFamily = localStorage.getItem("careerFamilyData");
    let images = localStorage.getItem("images");
    let formData = {
      ...JSON.parse(registerData),
      ...JSON.parse(basicDetails),
      ...JSON.parse(careerFamily),
      images: JSON.parse(images),
    };

    dispatch(registrationAction(formData));
  };

  return (
    <>
      <section className="login-page-bg pb-5">
        <div className="sticky-top">
          <div className="container py-2 d-flex justify-content-between align-items-center z-2 position-relative">
            <img src="/assets/images/logo.png" height="35" />
            <div className="d-flex float-end align-items-center">
              <img
                src="/assets/images/help-o.png"
                height="25"
                className="mx-4"
              />
              <Link
                to="/login"
                className="px-4 py-2 rounded-3 bg-white btn-shadow cursor-pointer"
              >
                <p className="mx-2 home-login-btn fw-medium text-blue">Login</p>
              </Link>
            </div>
          </div>
        </div>

        <div className="d-flex justify-content-center mt-5">
          <div className="col-lg-4 col-md-9 col-11 bg-white rounded-4">
            <div className="px-lg-4 px-2 mx-5 py-lg-5 py-4 mt-3">
              <div className="mb-4">
                <h5 className="fs-5 mb-0 fw-medium text-dark">
                  Verify Mobile No.
                </h5>
                <p className="fs-8 text-secondary">
                  Sent a text message with verification code on +91
                  {storageProfile?.mobile}
                </p>
              </div>

              <TextField
                id="outlined-basic"
                label="Enter OTP"
                placeholder="OTP"
                variant="outlined"
                className="w-50"
                value={userOtp}
                onChange={(e) => setUserOtp(e.target.value)}
              />

              <div className="mb-3 pt-3 d-flex">
                <button
                  className="rounded-3 bg-secondary px-4 py-2"
                  onClick={handleVerifyOtp}
                >
                  <p className="my-1 fw-medium text-capitalize text-white fs-7 mx-5">
                    Verify OTP
                  </p>
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default VerifyOTP;
